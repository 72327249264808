import { ReactElement } from "react";
import { AiOutlineTeam, AiOutlineUser } from "react-icons/ai";
import { FaBoxes } from "react-icons/fa";
import { MdBusiness } from "react-icons/md";
import { cacheUpdated } from "@/Store/Reducers/cache/cacheSlice";
import { AppCache, CacheSlices, EnrichedCache } from "@/Store/Reducers/cache/CacheTypes";
import api from "@/Api/Api";
import { AppDispatch } from "../Store/store";
import { Entities } from "@shared/Entities/Entities";

export class CacheFunctions {

	/**
	 * @returns The correct slice of the cache or undefined it it's an unused plugin.
	 */
	static getCacheSlice(cache: AppCache, cacheslice: CacheSlices): any[] | undefined {
		switch (cacheslice) {
			case CacheSlices.Agents:
				return cache.Agents;
			case CacheSlices.Categories:
				return cache.Categories;
			case CacheSlices.Channels:
				return cache.Channels;
			case CacheSlices.Companies:
				return cache.Companies;
			case CacheSlices.Kbs:
				return cache.Kbs;
			case CacheSlices.KbFolders:
				return cache.KbSections;
			case CacheSlices.Priorities:
				return cache.Priorities;
			case CacheSlices.Settings:
				return cache.Settings;
			case CacheSlices.Slas:
				return cache.Slas;
			case CacheSlices.Statuses:
				return cache.Statuses;
			case CacheSlices.Teams:
				return cache.Teams;
			case CacheSlices.Users:
				return cache.Users;
		}
	}

	/**
	 * @returns A pluralised name of entities.
	 */
	static getEntityPluralName(entities: Entities): string {
		switch (entities) {
			case Entities.ACTION:
				return "actions";
			case Entities.AGENT:
				return "agents";
			case Entities.ATTACHMENT:
				return "attachments";
			case Entities.AUTOMATION:
				return "automations";
			case Entities.CANNEDREPLY:
				return "canned replies";
			case Entities.CATEGORY:
				return "categories";
			case Entities.CHANNELSEMAIL:
				return "channels";
			case Entities.COMPANY:
				return "companies";
			case Entities.COMPANY_NOTE:
				return "company notes";
			case Entities.EMAILNOTIFICATION:
				return "email notifications";
			case Entities.KB:
				return "KBs";
			case Entities.KBARTICLE:
				return "KB articles";
			case Entities.KBFOLDER:
				return "KB sections";
			case Entities.SLA:
				return "SLAs";
			case Entities.TEAM:
				return "teams";
			case Entities.TICKET:
				return "tickets";
			case Entities.USER:
				return "users";
			case Entities.VIEW:
				return "views";
		}
	}

	/**
	 * @deprecated Use getEntityPluralName().
	 */
	static getCacheSlicePluralName(cacheslice: CacheSlices): string {
		switch (cacheslice) {
			case CacheSlices.Agents:
				return "agents";
			case CacheSlices.Categories:
				return "categories";
			case CacheSlices.Channels:
				return "channels";
			case CacheSlices.Companies:
				return "companies";
			case CacheSlices.Kbs:
				return "KBs";
			case CacheSlices.KbFolders:
				return "KB sections";
			case CacheSlices.Priorities:
				return "priorities";
			case CacheSlices.Settings:
				return "settings";
			case CacheSlices.Slas:
				return "SLAs";
			case CacheSlices.Statuses:
				return "statuses";
			case CacheSlices.Teams:
				return "teams";
			case CacheSlices.Users:
				return "users";
			case CacheSlices.Views:
				return "views";
		}
	}

	static getNameFromCache(id: number | undefined, cache: EnrichedCache, cacheToSearch: CacheSlices, dispatch: AppDispatch): string | null {
		if (id != null && !isNaN(id) && cache != null) {
			const fieldToReturn = "name";

			// Choose the correct cache to search.
			const cacheToScan = CacheFunctions.getCacheSlice(cache, cacheToSearch);

			if (cacheToScan != null) {
				const obj = cacheToScan.find(obj => obj.id == id);

				if (obj != null) {
					if (obj[fieldToReturn] != null) {
						return obj[fieldToReturn];
					}
				} else {
					// Try and fetch the cache again.
					// We only try this if it is a user as they are not picked up when created by the TicketServer.

					if (cacheToSearch == CacheSlices.Users) {
						CacheFunctions.cacheRefreshForUser(id, dispatch);
					}
				}
			}
		}

		return id != null ? id.toString() : null;
	}

	static cacheGetInFlight = false;
	/** A Set of userIds to skip when cacheRefreshForUser is called for them. */
	static missingUserIds = new Set();
	/** A Set of userIds that are waiting for a cacheRefreshForUser call to complete. */
	static inFlightReqs = new Set();

	/**
	 * This function refreshes the cache to try and find missing userIds.
	 * It has a lock so only one GET will be sent at a time. It will not send a GET if it has already failed to find the passed userId.
	 */
	static async cacheRefreshForUser(userId: number, dispatch: AppDispatch) {

		// TODO: A similar process should probably performed for each of the cache entities. This would just be hooked up to cache.getUser().
		// They would all call something like cacheGetWithRefresh(entity, id, dispatch).
		// This would be fragile and complex but I think that is the price to pay for our speedy cache lookups.

		console.log();
		console.log("Cache refresher triggered by: ", userId);

		if (this.missingUserIds.has(userId) || this.inFlightReqs.has(userId)) {
			console.log("Id in missed/inflight set. Returning.", userId);
			return;
		}

		if (this.cacheGetInFlight) {
			console.log("Req in flight. Returning.", userId);
			this.inFlightReqs.add(userId);
			return;
		}

		console.log("Requesting cache.");
		this.cacheGetInFlight = true;
		const result = await api.BasicGet<AppCache>("/cache", { "new": "true" });

		if (result.successful && result.data != null && result) {
			const users = result.data.Users;

			// If the id is not found, add it to the list of missed ids.
			if (users.findIndex(user => user.id == userId) == -1) {
				this.missingUserIds.add(userId);
				console.log("Could not find Id. Adding to missed ids. ", userId);
			}

			// Same for the inflight ids.
			this.inFlightReqs.forEach(inFlightId => {
				this.inFlightReqs.delete(inFlightId);

				if (users.findIndex(user => user.id == inFlightId) == -1) {
					this.missingUserIds.add(inFlightId);
					console.log("Could not inflight Id. Adding to missed ids. ", inFlightId);
				}
			});

			dispatch(cacheUpdated(result.data));
		}

		this.cacheGetInFlight = false;
	}

	static getEntityName(entity: Entities): string {
		switch (entity) {
			case Entities.CATEGORY: return "Category";
			case Entities.COMPANY: return "Company";
			case Entities.TEAM: return "Team";
			case Entities.USER: return "User";

			default: return "";
		}
	}

	static getEntityIcon(entity: Entities): ReactElement | null {
		switch (entity) {
			case Entities.CATEGORY: return <FaBoxes />;
			case Entities.COMPANY: return <MdBusiness />;
			case Entities.TEAM: return <AiOutlineTeam />;
			case Entities.USER: return <AiOutlineUser />;

			default: return null;
		}
	}

	static getEntityTitlePath(entity: Entities): string {
		switch (entity) {
			case Entities.AGENT: return "Agents | GoDesk";
			case Entities.TICKET: return "Tickets | GoDesk";
			case Entities.USER: return "Users | GoDesk";

			default: return "GoDesk";
		}
	}

}
