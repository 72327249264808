import { ReactNode, useEffect, useId, useState } from "react";
import CreatableSelect from "react-select/creatable";
import toast from "react-hot-toast";
import validator from "validator";
import { MultiValue } from "react-select";
import { useCache } from "@/Hooks/useCache";
import { GD_SUPPORT_AGENT_ID } from "@shared/Enums/Enums";

type Option = { value: string; label: ReactNode };

export interface MultiSelectUserEmailProps {
	label?: string;
	className?: string;
	invalid?: boolean;
	value?: string[];
	setValue(newValue: string[]): void;
}

export default function MultiSelectUserEmail(props: MultiSelectUserEmailProps) {
	const controlId = useId();
	const [options, setOptions] = useState<Option[]>([]);

	const { cache } = useCache();

	function stringArrayToOptionArray(values: string[]): Option[] {
		const optionsArray: Option[] = [];

		values.forEach(value => {
			optionsArray.push({
				value: value,
				label: value
			});
		});

		return optionsArray;
	}

	const value = stringArrayToOptionArray(props.value ?? []);

	function multiInputChange(newSelectedOptions: MultiValue<Option>) {
		// TODO: Check that the emails are all unique.

		const finalNewValues: string[] = [];

		newSelectedOptions.forEach(option => {
			let email = option.value; // Could be 'hayden@gmail.com' or 'hayden <hayden@gmail.com'.

			if (email.includes("<") && email.includes(">")) {
				// Reassign email to just the email value.
				email = option.value.substring(
					option.value.indexOf("<") + 1,
					option.value.lastIndexOf(">")
				);
			}

			if (cache.emailUsedByChannel(email)) {
				toast.error("You cannot CC or BCC this email. It is being used as a mailbox in your help desk.");
			} else if (validator.isEmail(email)) {
				finalNewValues.push(option.value);
			} else {
				toast.error(`'${email}' is not a valid email.`);
			}
		});

		props.setValue(finalNewValues);
	}

	useEffect(() => {
		// Create options array.
		const options: Option[] = [];

		// If cacheSlice is empty.
		if (cache.Users != null) {
			cache.Users.forEach(user => {
				// Check it's not disabled or deleted.
				if (!user.disabled && !user.deleted && user.email != null) {
					const value = user.name != null ? user.name + " <" + user.email + ">" : user.email;
					const originalValue = props.value ?? [];
					if (originalValue.includes(value) || originalValue.includes(user.email)) { // Email is already in the value.
						// Do not include existing values
					} else if (user.id == GD_SUPPORT_AGENT_ID) {
						// Ignore the GoDesk support agent
					} else {
						options.push({
							value: value,
							label: <>
								{user.name}
								<span className="text-gray-500">{" <" + user.email + ">"}</span>
							</> });
					}
				}
			});
		}

		setOptions(options);
	}, [cache, props.value]);

	const labelCssInvalid = props.invalid ? "text-red-700" : "";
	const labelJsx = <div className={"text-sm mb-1 " + labelCssInvalid}>{props.label}</div>;

	return (
		<div className="block Form_Component">
			<label htmlFor={controlId}>
				{props.label &&
					labelJsx
				}
			</label>

			<CreatableSelect
				isClearable
				isMulti
				inputId={controlId}
				formatCreateLabel={userInput => `Add ${userInput}`}
				options={options}
				onChange={multiInputChange}
				placeholder="Type any email..."
				value={value}
				// This adds fixed position to Dropdown so options will display over the top of the modal.
				menuPosition="fixed"
			/>
		</div>
	);
}
