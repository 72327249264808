import { Stack } from "@mui/material";
import { FormParent } from "@shared/Components/Form/FormParent";
import { FormReadBox } from "@shared/Components/Form/FormReadBox";
import { FormReadPair } from "@shared/Components/Form/FormReadPair";
import { FormSection } from "@shared/Components/Form/FormSection";
import { CannedReply } from "@shared/Entities/EntityTypes";
import { datetimeToWhenString } from "@shared/Helpers/DateFunctions";
import { sanitizeHtml } from "@shared/Helpers/HtmlFunctions";

interface DisplayProps {
	data: CannedReply;
}

export function CannedReplyDisplay(props: DisplayProps) {
	const noHtmlNote = sanitizeHtml(props.data.note);
	const note = <div dangerouslySetInnerHTML={{ __html: noHtmlNote }}></div>;

	return (
		<FormParent title={props.data.name ?? ""}>
			<FormSection title="Details">
				<FormReadBox>
					<FormReadPair
						name="Name"
						value={props.data.name}
					/>
					<FormReadPair
						name="Description"
						value={props.data.description}
					/>
				</FormReadBox>
			</FormSection>

			{props.data.attachments != null && props.data.attachments.length > 0 &&
				<FormSection title="Attachments">
					<Stack>
						{props.data.attachments.map(attachment =>
							<div key={attachment.id}>
								{attachment.fileNameOriginal}
							</div>
						)}
					</Stack>
				</FormSection>}

			<FormSection title="Content" className="w-[50rem]">
				<div className="text-base p-3 bg-white text-gray-700 rounded border border-gray-200">
					{note}
				</div>
			</FormSection>

			<FormSection>
				<FormReadBox>
					<FormReadPair
						name="Updated"
						value={datetimeToWhenString(props.data.modified)}
					/>
					<FormReadPair
						name="Created"
						value={datetimeToWhenString(props.data.created)}
					/>
				</FormReadBox>
			</FormSection>
		</FormParent>
	);
}
