import { AgGridReact } from "@ag-grid-community/react";
import { ChartData, ChartOptions } from "chart.js";
import { Bar } from "react-chartjs-2";
import { BiExport } from "react-icons/bi";
import saveAs from "file-saver";
import { DateTime } from "luxon";
import { BarData } from "@/Pages/Reporting/BarCount";
import { Button } from "../../Components/Button/Button";

interface SimpleReportProps {
	title: string;
	subtitle: string;
	data?: number[][];
	dataPreprocessed?: BarData[];
	axisTitles: { xLabel: string; yLabel: string };
	dataXTransformer(data: number): string;
	dateRange: [DateTime, DateTime];
}

/**
 * Uses composition to create a simple 2 axis bar chart report.
 */
export function SimpleReport(props: SimpleReportProps) {
	let data: BarData[] = [];

	props.data?.forEach((value) => {
		const label = props.dataXTransformer(value[0]);

		data.push({ label: label, data: value[1] });
	});

	if (props.dataPreprocessed != null) {
		data = props.dataPreprocessed;
	}

	data = data.sort((a, b) => {
		const valA = a.data;
		const valeB = b.data;
		return valA == valeB ? 0 : valA < valeB ? 1 : -1;
	});

	return (
		<>
			<div className="text-3xl my-5">
				{props.title}
			</div>

			<div className="my-3">
				{props.subtitle}
			</div>

			<div className="my-5">
				<Chart axisTitles={props.axisTitles} data={data} />
			</div>

			<div className="mt-5 mb-3">
				<CsvExport axisTitles={props.axisTitles} data={data} title={props.title} date={props.dateRange} />
			</div>

			<div className="h-96 max-w-3xl ag-theme-alpine">
				<AgGridReact
					rowData={data}
					animateRows
					columnDefs={[
						{
							flex: 1,
							headerName: props.axisTitles.xLabel,
							field: "label",
							sortable: true
						},
						{
							flex: 1,
							headerName: props.axisTitles.yLabel,
							field: "data",
							sortable: true
						}
					]}
				/>
			</div>
		</>
	);
}

interface CsvExportProps {
	axisTitles: { xLabel: string; yLabel: string };
	data: { label: string; data: number }[];
	title: string;
	date: [DateTime, DateTime];
}

function CsvExport(props: CsvExportProps) {

	function doExport() {
		let csv = "";

		// Header
		csv += props.axisTitles.xLabel + "," + props.axisTitles.yLabel + "\n";

		// Data
		props.data.forEach(element => {
			csv += element.label + "," + element.data + "\n";
		});

		const startDate = props.date[0].toFormat(("yyyy-LL-dd_hh-mm"));
		const endDate = props.date[1].toFormat(("yyyy-LL-dd_hh-mm"));

		const fileName = props.title + " - " + startDate + " to " + endDate + ".csv";

		// Save
		const blob = new Blob([csv], { type: "text/plain;charset=utf-8" });
		saveAs(blob, fileName);
	}

	return (
		<Button label="Export CSV" icon={<BiExport />} onClick={doExport} />
	);
}

interface ChartProps {
	axisTitles: { xLabel: string; yLabel: string };
	data: { label: string; data: number }[];
}

function Chart(props: ChartProps) {
	const labels: string[] = [];
	const chartData: number[] = [];

	props.data.forEach((value) => {
		labels.push(value.label);
		chartData.push(value.data);
	});

	const data: ChartData<"bar", number[], string> = {
		labels: labels,
		datasets: [{
			data: chartData,

			backgroundColor: "rgba(75,192,192,0.4)",
			borderColor: "rgba(75,192,192,1)",
		}]
	};

	const options: ChartOptions<"bar"> = {
		indexAxis: "x",
		scales: {
			y: {
				beginAtZero: true,
				grid: {
					display: false
				},
				ticks: {
					precision: 0
				}
			},
			x: {
				grid: {
					display: false
				}
			}
		},
		plugins: {
			legend: {
				display: false
			},
		}
	};

	return (
		<div className="bg-white p-10 rounded border border-gray-300 max-w-3xl">
			<Bar data={data} options={options} />
		</div>
	);
}
