import { useState } from "react";
import toast from "react-hot-toast";
import EntityTable from "@/Components/EntityTable/EntityTable";
import { useDeleteTeams } from "@/Api/genApi";
import { MoveTicketsModal } from "@/Pages/Config/Teams/MoveTicketsModal";
import { Entities } from "@shared/Entities/Entities";

export function TeamTable() {
	const [moveTicketsModalOpen, setMoveTicketsModalOpen] = useState(false);
	const [fromTeamIds, setFromTeamIds] = useState<number[]>([]);
	const deleteTeamReq = useDeleteTeams();

	async function onMultiDelete(ids: number[]) {
		setMoveTicketsModalOpen(true);
		setFromTeamIds(ids);
	}

	async function doMultiDelete(ids: number[]) {
		let didError = false;

		for (let i = 0; i < ids.length; i++) {
			await deleteTeamReq.mutateAsync({ id: ids[i] }, {
				onError: error => {
					didError = true;
					toast.error("Could not delete team: " + error.errorMsg);
				}
			});
		}

		if (!didError) {
			// TODO: Invalidate the getAllTeams query.
			toast.success("Deleted teams. Please refresh the table.");
		}
	}

	return (
		<>
			<EntityTable
				entity={Entities.TEAM}
				multiDeleteOverride={onMultiDelete}
				newEntityUrl="/config/manage/teams/-1"
				editEntityUrl="/config/manage/teams/"
			/>

			<MoveTicketsModal
				isOpen={moveTicketsModalOpen}
				onCancel={() => setMoveTicketsModalOpen(false)}
				onComplete={() => {
					setMoveTicketsModalOpen(false);
					doMultiDelete(fromTeamIds);
				}}
				fromTeamIds={fromTeamIds}
			/>
		</>
	);
}
