import { EmailOutlined, BusinessOutlined } from "@mui/icons-material";
import { Box, Typography, Stack, Button, Tooltip } from "@mui/material";
import { User } from "@/Api/genApi.schemas";
import { useCache } from "@/Hooks/useCache";
import { UserAvatar } from "@/Components/Avatar/UserAvatar";

interface UserSearchResultProps {
	user: User;
	onUserSelect(user: User): void;
}

export default function UserSearchResult(props: UserSearchResultProps) {
	// Do fetch.
	const { cache } = useCache();

	return (
		<Box
			border={1}
			borderRadius={2}
			borderColor="gray"
			paddingY={1}
			paddingX={2}
			marginBottom={2}
			marginRight={2}
			display="flex"
			alignItems="center"
			justifyContent="space-between"
		>
			<Stack direction="row" spacing={2} alignItems="center">
				{/* Avatar and Name */}
				<UserAvatar widthPx={50} user={props.user} />
				<Stack spacing={0.5}>
					<Typography
						variant="body1"
						fontWeight="500"
						maxWidth={230}
						noWrap
					>
						<Stack direction="row" spacing={0.5}>
							<div>{props.user.name}</div>
							{props.user.isPrimaryContact &&
								<Tooltip title="Primary contact">
									<span>{"\u2B50"}</span>
								</Tooltip>}
						</Stack>
					</Typography>
					{/* Email with Icon */}
					<Stack direction="row" alignItems="center" spacing={1}>
						<EmailOutlined fontSize="small" className="text-gray-600" />
						<Typography variant="body2" color="textSecondary" maxWidth={200} noWrap>
							{props.user.email}
						</Typography>
					</Stack>
					{/* Company with Icon */}
					<Stack direction="row" alignItems="center" spacing={1}>
						<BusinessOutlined fontSize="small" className="text-gray-600" />
						<Typography variant="body2" color="textSecondary" maxWidth={200} noWrap>
							{cache.getCompany(props.user?.companyId)?.name}
						</Typography>
					</Stack>
				</Stack>
			</Stack>
			<Box display="flex" alignItems="center">
				<Button
					variant="outlined"
					size="small"
					onClick={() => props.onUserSelect(props.user)}
				>
					Assign
				</Button>
			</Box>
		</Box>
	);
}
