import { Control } from "react-hook-form";
import { DateTime } from "luxon";
import { ApiCallerBase, SortModelItem, QueryParams } from "@shared/Api/ApiCallerBase";
import { getEndpoint } from "@shared/Api/ApiEndpoints";
import { ResGetSome, ResGetOne, ResCreate, ResUpdate, ResDelete } from "@shared/Api/ApiResponses";
import { Entities } from "@shared/Entities/Entities";
import { ViewFilters } from "@shared/Models/View";
import { MetricEvent, MetricEventProperties, ReportDashboard } from "@shared/Entities/EntityTypes";

/**
 * Provides useful calls for the GoDesk Api.
 */
export class ApiCallerBaseForApi extends ApiCallerBase {
	async getAllEntities<T>(entityType: Entities): Promise<ResGetSome<T>> {
		return this.getEntities<T>(entityType, 99999, 0);
	}

	/**
	 * Get all for one entity.
	 *
	 * @param limit Max number of results.
	 * @param offset Used in pagination.
	 * @param sortModel Sort the results. If you wanted to sort a ticket by creation date: [{colId: "customCreated", sort: "asc"}]. See {@link SortModelItem}.
	 * @param filterModel Filter the results. If you wanted to just get agent 1's tickets: {agentId: {type: "number", comparison: "equals", value: 1}}. See {@link ViewFilters} for all the different types of filter.
	 */
	async getEntities<T>(entityType: Entities, limit: number, offset: number = 0, sortModel?: SortModelItem[], filterModel?: ViewFilters): Promise<ResGetSome<T>> {
		const params: QueryParams = { limit: limit, offset: offset };

		if (sortModel != null) {
			params.sort = JSON.stringify(sortModel);
		}

		if (filterModel != null) {
			params.filter = JSON.stringify(filterModel);
		}

		const endpoint = getEndpoint(entityType);

		return this.BasicGet("/" + endpoint, params);
	}

	async getEntity<T>(entityType: Entities, id: number): Promise<ResGetOne<T>> {
		const endpoint = getEndpoint(entityType);

		return this.BasicGet("/" + endpoint + "/" + id);
	}

	async createEntity<T>(entityType: Entities, data: object, control?: Control<any>): Promise<ResCreate<T>> {
		let endpoint = getEndpoint(entityType);

		// TODO: Find a better way to do this maybe a new optional prop??
		if (endpoint == "attachments") {
			endpoint = "attachments/single";
		}

		this.clearNewObjectId(data);

		// TODO: Remove the single param when the BE is ready.
		const res = await this.BasicPost<T>("/" + endpoint, data, { single: "true" });
		if (control != null && res.errorMsg != null && res.errorMsg != "") {
			control.setError("root", { message: res.errorMsg });
		}

		return res;
	}

	async updateEntity<T extends object>(entityType: Entities, id: number, data: T, control?: Control<any>): Promise<ResUpdate<T>> {
		const endpoint = getEndpoint(entityType);

		return this.BasicPut("/" + endpoint + "/" + id, data);
	}

	async deleteEntity(entityType: Entities, id: number): Promise<ResDelete> {
		const endpoint = getEndpoint(entityType);

		return this.BasicDelete("/" + endpoint + "/" + id);
	}

	/**
	 * Removes the ID of a new object. Hibernate prefers them set to null or 0 but our convention is -1.
	 */
	private clearNewObjectId(value: unknown) {
		if (value != null && typeof value == "object" && "id" in value) {
			const id = value["id"];

			if (typeof id == "number") {
				value["id"] = null;
			}
		}
	}

	async metricEvent(name: string, properties?: MetricEventProperties) {
		const jsonObjectToString = JSON.stringify(properties);

		const customEvent: MetricEvent = {
			name: name,
			properties: jsonObjectToString,
		};

		return this.BasicPost("/tele/agent", customEvent);
	}

}
