import { ReactNode, useId } from "react";
import { AiOutlinePlusCircle } from "react-icons/ai";
import Select, { StylesConfig } from "react-select";
import Tooltip from "@mui/material/Tooltip";
import { closeMenuOnScrollFix } from "../../../Helpers/OtherFunctions";
import { ControlLabel } from "@shared/Components/FormComponents/ControlLabel";
import { DropdownOption } from "@shared/Components/FormComponents/Dropdown/Dropdown";

export interface MultiSelectDropdownProps<T> {
	value?: T[];
	dataname: string;
	options: DropdownOption<T | undefined>[];
	onChange(key: string, value?: T[]): void;
	label?: ReactNode;
	toolTip?: ReactNode;
	mandatory?: boolean;
	inline?: boolean;
	invalid?: boolean;
	disabled?: boolean;
	widthCss?: string;

	/** This callback enables a 'new' button to the right of the dropdown. */
	newButtonOnClick?(): void;
	newButtonTooltip?: string;
}

/**
 * Composable dropdown component. T is the datatype (usually a number ID).
 */
export default function MultiSelectDropdown<T>(props: MultiSelectDropdownProps<T>) {
	const controlId = useId();

	function handleChange(selectedOptions: unknown) {
		const options = selectedOptions as DropdownOption<T>[];

		const valuesArray: T[] = [];

		options.forEach(option => {
			if (option.value != undefined) {
				valuesArray.push(option.value);
			}
		});

		props.onChange(props.dataname, valuesArray);
	}

	const customStyles: StylesConfig = {
		dropdownIndicator: (provided) => ({
			...provided,
			padding: "5px 7px",
		}) as any,
		indicatorSeparator: (provided) => ({
			...provided,
			display: "none",
		}) as any,
		menu: (provided) => ({
			...provided,
			margin: "1px",
			zIndex: "100"
		}) as any,
		menuPortal: (provided) => ({
			...provided,
			zIndex: "2"
		}) as any,
	};

	const styles = { width: props.widthCss };

	const dropdownValues: DropdownOption<T | undefined>[] = props.value != null && props.value.length > 0 ?
		props.value.map(value => {
			const label = props.options.find(option => option.value == value)?.label;

			return { value: value, label: label };
		})
		:
		[];

	const displayCssContainer = props.inline ? "inline-block" : "block Form_Component";

	return (
		<div className={displayCssContainer} style={styles}>
			{!props.inline &&
				<ControlLabel
					label={props.label}
					mandatory={props.mandatory}
					helperText={props.toolTip}
					controlId={controlId}
					invalid={props.invalid}
				/>}

			<div className="flex w-full items-center">
				<Select
					inputId={controlId}
					isMulti
					value={dropdownValues}
					onChange={handleChange}
					options={props.options}
					styles={customStyles}
					isDisabled={props.disabled}
					menuPlacement="auto"
					// This adds fixed position to Dropdown so options will display over the top of the modal.
					menuPosition="fixed"
					className="grow select-parent"
					closeMenuOnScroll={closeMenuOnScrollFix}
				/>

				{props.newButtonOnClick && props.newButtonTooltip &&
					<Tooltip title={props.newButtonTooltip}>
						<span>
							<AiOutlinePlusCircle
								className="mx-3 cursor-pointer w-5 h-5"
								onClick={props.newButtonOnClick}
							/>
						</span>
					</Tooltip>
				}
			</div>
		</div>
	);
}
