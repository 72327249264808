import { AiOutlineClose } from "react-icons/ai";
import { RxDragHandleDots2 } from "react-icons/rx";

interface DraggableModalTitleProps {
	onClose():void;
	title: React.ReactNode;
	titleIcon?: React.ReactNode;
}

export function DraggableModalTitle(props: DraggableModalTitleProps) {
	return (
		<div id="handle" className={"flex justify-between bg-gray-50 rounded-t-md border-b"}>
			<div className="text-xl font-medium flex items-center gap-2 grow p-4 cursor-move">
				<RxDragHandleDots2 size={25} className="shrink-0" />

				{props.titleIcon}

				{props.title}
			</div>

			<div onClick={props.onClose} className="hover:bg-gray-100 rounded-md p-2 m-2 flex self-center cursor-pointer">
				<AiOutlineClose size={21} />
			</div>
		</div>
	);
}
