import { ReactNode } from "react";
import { DragDropContext, Droppable, DropResult, DraggableProvided, DroppableProvided, Draggable } from "react-beautiful-dnd";

interface DragAndDropListProps<T> {
	ids: T[];
	setIds(ids: T[]): void;
	renderChild(id: T): ReactNode;
	lockAxis?: "x" | "y";
}

/**
 * Creates a draggable list and updates the list on drop.
 */
export function DragAndDropList<T extends number | string>(props: DragAndDropListProps<T>) {

	function handleOnDragEnd(result: DropResult) {
		// If dropped outside of the Droppable zone.
		if (!result.destination) {
			return;
		}

		const newIds = [...props.ids];
		const movedId = props.ids[result.source.index];

		// Move the element to the new pos.
		newIds.splice(result.source.index, 1);
		newIds.splice(result.destination.index, 0, movedId);

		props.setIds(newIds);
	}

	return (
		<DragDropContext onDragEnd={handleOnDragEnd}>
			<Droppable droppableId="droppable-1" direction={props.lockAxis === "x" ? "horizontal" : "vertical"}>
				{(provided: DroppableProvided) => (
					<div
						{...provided.droppableProps}
						ref={provided.innerRef}
						className="p-4 bg-gray-100 rounded-lg flex"
						style={{
							flexDirection: props.lockAxis === "x" ? "row" : "column",
						}}
					>
						{props.ids.map((id, index) => (
							<Draggable key={id} draggableId={`${id}`} index={index}>
								{(provided: DraggableProvided) => {
									let transform = provided.draggableProps.style?.transform;

									// Lock the transform axis if needed by using the prop
									if (transform != null) {

										// Adjust the transform based on the lockAxis prop
										switch (props.lockAxis) {
											case "x":
												transform = `translate(${transform.split(",")[0].split("(").pop()}, 0px)`; // Lock vertical movement by setting y to 0px
												break;
											case "y":
												transform = `translate(0px, ${transform.split(",").pop()}`; // Lock horizontal movement by setting x to 0px
												break;
										}
									}

									return (
										<div
											ref={provided.innerRef}
											{...provided.draggableProps}
											{...provided.dragHandleProps}
											style={{
												...provided.draggableProps.style,
												transform,
											}}
										>
											{props.renderChild(id)}
										</div>
									);
								}}
							</Draggable>
						))}
						{provided.placeholder}
					</div>
				)}
			</Droppable>
		</DragDropContext>
	);
}
