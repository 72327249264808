import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AiOutlineUser } from "react-icons/ai";
import { MdBusiness } from "react-icons/md";
import InfiniteScroll from "react-infinite-scroller";
import { Box } from "@mui/material";
import { SingleUsersCompany } from "@/Pages/Companies/UsersCompaniesPanel/SingleUsersCompany";
import { CompanyList } from "@/Pages/Companies/UsersCompaniesPanel/CompanyList/CompanyList";
import NewEntityModal from "@/Components/Utility/Modals/NewEntityModal";
import { useNumberParams } from "@/Hooks/useNumberParams";
import { Button } from "@/Components/Button/Button";
import { useCache } from "@/Hooks/useCache";
import { Entities } from "@shared/Entities/Entities";
import { Company } from "@shared/Entities/EntityTypes";

interface UsersCompaniesPanelProps {
	companies?: Company[];

	/** If more companies are available. Used by infinate scroll. */
	hasMoreCompanies: boolean;
	/** Use in infinite scroll */
	fetchMoreCompanies(page?: number): Promise<void>;
	/** Used when adding a new company */
	refreshCompanies(): Promise<void>;
}

export function UsersCompaniesPanel(props: UsersCompaniesPanelProps) {
	const { id } = useNumberParams();
	const navigate = useNavigate();
	const { refreshCache } = useCache();

	const [newCompanyModalOpen, setNewCompanyModalOpen] = useState(false);
	const [newUserModalOpen, setNewUserModalOpen] = useState(false);

	async function newCompanyCreated(id: number) {
		setNewCompanyModalOpen(false);

		navigate("/companies/" + id);

		await props.refreshCompanies();
		await refreshCache();
	}

	async function newUserCreated(id: number) {
		setNewUserModalOpen(false);

		navigate("/users/" + id);

		await refreshCache();
	}

	const companySelected = useCallback((id: number) =>
		navigate("/companies/" + id)
	, []); // eslint-disable-line -- Not using relative paths so navigate is not needed.

	const singleUserCount = props.companies?.find((company) => company.id == -1)?.userCount;

	return (
		<Box className="w-72 shrink-0 border-r h-full flex flex-col">
			<Box className="border-b p-4 shrink-0">
				<div className="text-base font-semibold mb-3">Users &amp; Companies</div>

				<div className="flex gap-2">
					<Button
						icon={<MdBusiness />}
						onClick={() => setNewCompanyModalOpen(true)}
						label="New company"
					/>

					<Button
						icon={<AiOutlineUser />}
						onClick={() => setNewUserModalOpen(true)}
						label="New user"
					/>
				</div>
			</Box>
			<div className="pb-3">
				<SingleUsersCompany userCount={singleUserCount} onSelect={companySelected} active={id == -1} />
			</div>
			<Box className="overflow-y-auto flex flex-col border-t grow">
				<InfiniteScroll
					loadMore={props.fetchMoreCompanies}
					hasMore={props.hasMoreCompanies}
					loader={<div className="loader" key={0}>Loading ...</div>}
					useWindow={false}
					threshold={1000}
				>
					<CompanyList companies={props.companies} onSelect={companySelected} selectedId={id} />
				</InfiniteScroll>
			</Box>

			<NewEntityModal
				newCreated={newCompanyCreated}
				isOpen={newCompanyModalOpen}
				entityType={Entities.COMPANY}
				closeModal={() => setNewCompanyModalOpen(false)}
			/>
			<NewEntityModal
				newCreated={newUserCreated}
				isOpen={newUserModalOpen}
				entityType={Entities.USER}
				closeModal={() => setNewUserModalOpen(false)}
			/>
		</Box>
	);
}
