import { useState, useEffect } from "react";
import { AiOutlineUser } from "react-icons/ai";
import { GiTicket } from "react-icons/gi";
import { HiOutlineBookOpen } from "react-icons/hi";
import { MdBusiness } from "react-icons/md";
import toast from "react-hot-toast";
import api from "@/Api/Api";
import { PayloadPagination, GlobalSearch } from "@/Api/genApi.schemas";
import PaginationDisplay from "../../Components/EntityTable/PaginationDisplay";
import { SearchResultAgent, SearchResultArticle, SearchResultCompany, SearchResultTicket, SearchResultUser } from "./SearchResultItems";
import { SearchPageTabs } from "@shared/Enums/Enums";

const dataLimit = 5;

interface SearchResultGroupProps {
	defaultData: GlobalSearch;
	searchTerm: string;
	tabType: SearchPageTabs;
}

export default function SearchResultGroup(props: SearchResultGroupProps) {
	const [data, setData] = useState<GlobalSearch | undefined>(props.defaultData);
	const [pagination, setPagination] = useState<PayloadPagination | undefined>();
	const [dataOffset, setDataOffset] = useState<number>(0);

	useEffect(() => {
		setData(props.defaultData);
		setPagination(getPagination(props.defaultData, props.tabType));
	}, [props.defaultData, props.tabType]);

	async function doSearch(offset: number) {
		setDataOffset(offset);

		if (props.searchTerm != null && props.searchTerm != "") {
			const searchRes = await api.BasicGet<GlobalSearch>("/search", {
				"entity": props.tabType,
				"query": props.searchTerm,
				"limit": dataLimit,
				"offset": offset
			});

			if (searchRes.successful) {
				setData(searchRes.data);
				setPagination(getPagination(searchRes.data, props.tabType));
			} else {
				toast.error("Could not fetch data. " + searchRes.errorCode + ": " + searchRes.errorMsg);
				setData(undefined);
				setPagination(undefined);
			}
		}
	}

	function prevPage() {
		doSearch(dataOffset - dataLimit);
	}

	function nextPage() {
		doSearch(dataOffset + dataLimit);
	}

	let groupIcon = <></>;
	let groupTitle = "";
	let searchItems;

	if (data?.companies != null) {
		data.companies = data.companies.filter(company => company.id != -1);
	}

	switch (props.tabType) {
		case SearchPageTabs.AGENT:
			groupIcon = <AiOutlineUser />;
			groupTitle = "agents";
			searchItems = data?.agents != null ? <>{data?.agents.map((agent) => (
				<SearchResultAgent key={props.searchTerm + agent.id} agent={agent} />
			))}</> : null;
			break;
		case SearchPageTabs.COMPANY:
			groupIcon = <MdBusiness />;
			groupTitle = "companies";
			searchItems = data?.companies != null ? <>{data?.companies.map((company) => (
				<SearchResultCompany key={props.searchTerm + company.id} company={company} />
			))}</> : null;
			break;
		case SearchPageTabs.TICKET:
			groupIcon = <GiTicket />;
			groupTitle = "tickets";
			searchItems = data?.tickets != null ? <>{data?.tickets.map((ticket) => (
				<SearchResultTicket key={props.searchTerm + ticket.id} ticket={ticket} />
			))}</> : null;
			break;
		case SearchPageTabs.USER:
			groupIcon = <AiOutlineUser />;
			groupTitle = "users";
			searchItems = data?.users != null ? <>{data?.users.map((user) => (
				<SearchResultUser key={props.searchTerm + user.id} user={user} />
			))}</> : null;
			break;
		case SearchPageTabs.KBARTICLE:
			groupIcon = <HiOutlineBookOpen />;
			groupTitle = "KB articles";
			searchItems = data?.articles != null ? <>{data?.articles.map((article) => (
				<SearchResultArticle key={props.searchTerm + article.id} article={article} />
			))}</> : null;
			break;
	}

	return (
		<div className="mt-3 border border-gray-400 rounded-xl overflow-hidden">
			<div className="p-3 bg-gray-200 border-b border-gray-400 flex items-center justify-between">
				<div>
					{groupIcon} {(pagination?.total ?? 0) + " " + groupTitle + " found"}
				</div>

				<div>
					<PaginationDisplay pagination={pagination ?? { total: 0, limit: 0, offset: 0 }} prevPage={prevPage} nextPage={nextPage} />
				</div>
			</div>
			<div className="">
				{searchItems}
			</div>
		</div>
	);
}

function getPagination(data: GlobalSearch | undefined, tabType: SearchPageTabs): PayloadPagination | undefined {
	if (data != null) {
		switch (tabType) {
			case SearchPageTabs.AGENT:
				return data.agentsPagination;
			case SearchPageTabs.COMPANY:
				return data.companiesPagination;
			case SearchPageTabs.TICKET:
				return data.ticketsPagination;
			case SearchPageTabs.USER:
				return data.usersPagination;
			case SearchPageTabs.KBARTICLE:
				return data.articlesPagination;
		}
	}
}
