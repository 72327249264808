
import { AiOutlineUser } from "react-icons/ai";
import { MdBusiness } from "react-icons/md";
import { Button } from "@/Components/Button/Button";

interface NoCompanyPlaceholderProps {
	openNewCompanyModal(): void;
	openNewUserModal(): void;
}

export function NoCompanyPlaceholder(props: NoCompanyPlaceholderProps) {
	return (
		<div className="flex flex-col items-center" style={{ textAlign: "center", marginTop: "10%" }}>
			Select a company on the left.
			<br />- or -
			<br />

			<div className="flex gap-2">
				<Button
					icon={<MdBusiness />}
					onClick={props.openNewCompanyModal}
					label="New company"
				/>

				<Button
					icon={<AiOutlineUser />}
					onClick={props.openNewUserModal}
					label="New user"
				/>
			</div>
		</div>
	);
}
