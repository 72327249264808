import { useForm } from "react-hook-form";
import { Button, ButtonBlue } from "../../Button/Button";
import { AlertBox } from "@shared/Components/AlertBox/AlertBox";
import Modal from "@shared/Components/Modal/Modal";
import ModalButtons from "@shared/Components/Modal/ModalButtons";
import ModalContent from "@shared/Components/Modal/ModalContent";
import { InputTypes } from "@shared/Components/FormComponents/TextInput/InputTypes";
import W_TextInput from "@shared/Components/FormComponents/TextInput/W_TextInput";

interface ChangePasswordModalProps {
	updatePassword(password: string): void;
	isOpen: boolean;
	closeModal(): void;
}

interface ChangePasswordForm {
	password: string;
}

export function ChangePasswordModal(props: ChangePasswordModalProps) {
	const { handleSubmit, control, reset } = useForm<ChangePasswordForm>();

	function formSubmit(data: ChangePasswordForm) {
		props.updatePassword(data.password);
		reset();
	}

	function closeModal() {
		reset();
		props.closeModal();
	}

	return (
		<Modal
			isOpen={props.isOpen}
			onClose={closeModal}
			title="Change Password"
		>
			<ModalContent>
				<AlertBox alertType="warning">
					This agent will be logged out once their password is changed.
				</AlertBox>

				<div className="mb-6" />

				<W_TextInput
					control={control}
					dataname="password"
					label="Enter new password"
					autocomplete="new-password"
					type={InputTypes.Password}
					mandatory
					autoFocus
				/>
			</ModalContent>

			<ModalButtons>
				<Button onClick={closeModal} label="Cancel" />
				<ButtonBlue onClick={() => handleSubmit(formSubmit)()} label="Change password" />
			</ModalButtons>
		</Modal>
	);
}
