import { DateTime } from "luxon";
import { Alert } from "@mui/material";
import { useCache } from "@/Hooks/useCache";
import { useGetTicketsByStatusReport } from "@/Api/genApi";
import { SimpleReport } from "../SimpleReport";

interface TicketsByStatusProps {
	dateRange: [DateTime, DateTime];
}

export function TicketsByStatus(props: TicketsByStatusProps) {
	const { cache } = useCache();

	const reportReq = useGetTicketsByStatusReport({ dateFrom: props.dateRange[0].toISODate(), dateTo: props.dateRange[1].toISODate() });

	if (reportReq.error) {
		return <Alert severity="error">
			Could not load report.
		</Alert>;
	}

	function dataXTransformer(data: number): string {
		return cache.getStatus(data)?.name ?? "Unknown";

	}

	return (
		<SimpleReport
			title="Tickets by Status"
			subtitle="Shows tickets in the reporting period split by status."
			axisTitles={{ xLabel: "Status", yLabel: "Tickets" }}
			data={reportReq.data}
			dataXTransformer={dataXTransformer}
			dateRange={props.dateRange}
		/>
	);
}
