import { useRef } from "react";
import { AiOutlineCloseCircle, AiOutlinePlusCircle } from "react-icons/ai";
import { BsPencil } from "react-icons/bs";
import { FaCircle, FaDotCircle, FaMinusCircle } from "react-icons/fa";
import BoringAvatar from "boring-avatars";
import toast from "react-hot-toast";
import { useCache } from "@/Hooks/useCache";
import { ImgWithFallback } from "@/Components/ImageWithFallback/ImgWithFallback";
import { AgentStatus } from "@shared/Enums/Enums";
import { getBase64 } from "@shared/Helpers/OtherFunctions";

const palette = ["#2C98E3", "#0AADE9", "#FCEAB6", "#D33535", "#A52422"];
const paletteDisabled = ["#C8D7E2", "#CEE1E8", "#FBF2DF", "#D0B9B9", "#9E8D8C"];

interface AvatarProps {
	/** The image to use encoded in base64. */
	avatarBase64?: string;
	/** The filename of the avatar img. /pictures/TENANTID/ is prepended. */
	avatarUrlFilename?: string;
	/** Fallsback to name if an img in not provided. */
	name?: string;
	/** Used to create a new avatar icon that does not constantly update. New format EntityName_ + id */
	avatarHashString: string;
	/** Width of the avatar in px */
	widthPx: number;
	/** @deprecated */
	statusIcon?: AgentStatus;
	/** Turns the avatar grayscale. */
	disabled?: boolean;
	/** Turns the avatar grayscale and disabled editing. */
	deleted?: boolean;
	/** Callback for uploading a new image. */
	updateAvatar?(dataBase64: string, mimeType: string): void;
	/** Will display initials insted of the BoringAvatar. */
	fallbackToInitials?: boolean;
}

/** You should probably be using a composed version of this. eg. AgentAvatar */
export default function Avatar(props: AvatarProps) {
	const inputRef = useRef<HTMLInputElement>(null);
	const { cache } = useCache();

	async function onChangeHandler(event: React.FormEvent<HTMLInputElement>) {
		const target = event.target as HTMLInputElement;

		if (target.files != null && target.files[0]) {
			const file = target.files[0];

			if (file.size > 5000000) {
				toast.error("File too big. (5 MB max)");
			} else {
				const avatarData = String(await getBase64(file));
				// let avatarBase64 = btoa(avatarData);
				const mimeType = file.type;

				if (avatarData != null) {
					if (props.updateAvatar != null) {
						props.updateAvatar(avatarData, mimeType);
					}
				}
			}
		}
	}

	function deleteAvatar() {
		if (props.updateAvatar != null) {
			// -1 is a special value that tells the BE to delete the the avatar.
			props.updateAvatar("-1", "");
		}
	}

	function getInitials(name: string | undefined, justFirstName = false): string {
		if (name != null) {
			const wordArray = name.split(" ");
			if (wordArray.length > 1 && wordArray[0].length > 0 && wordArray[1].length > 0 && !justFirstName) {
				return wordArray[0][0].toUpperCase() + wordArray[1][0].toUpperCase();
			} else {
				return name.length > 0 ? name[0].toUpperCase() : "";
			}
		} else {
			return "";
		}
	}

	const widthPx = props.widthPx;

	let avatarJsx = null;

	let statusIcon;
	let iconHeight = Math.ceil(props.widthPx / 3.5);
	const iconHeightOuter = Math.ceil(iconHeight * 1.3);

	// Make sure difference between is an even number for pixel perfect fits.
	if ((iconHeightOuter - iconHeight) % 2 != 0) {
		iconHeight--;
	}

	switch (props.statusIcon) {
		case AgentStatus.OFFLINE: statusIcon = <FaCircle color="black" />; break;
		case AgentStatus.AVAILABLE: statusIcon = <FaCircle color="green" />; break;
		case AgentStatus.IDLE: statusIcon = <FaCircle color="#FAA61A" />; break;
		case AgentStatus.DND: statusIcon = <FaMinusCircle color="red" />; break;
		case AgentStatus.OOO: statusIcon = <FaDotCircle color="grey" />; break;
	}

	if (props.avatarBase64 != null) {
		avatarJsx = <img src={props.avatarBase64} alt="" className="rounded-full" style={{ height: widthPx, width: widthPx }} />;
	} else if (props.avatarUrlFilename != null && props.avatarUrlFilename != "") {
		const url = cache.getPicturesDir() + props.avatarUrlFilename;

		const disabledStyles = props.disabled ? "grayscale" : "";

		avatarJsx = <ImgWithFallback
			src={url}
			className={disabledStyles + " rounded-full object-cover"}
			style={{ height: widthPx, width: widthPx }}
		            />;
	} else {
		if (props.fallbackToInitials) {
			avatarJsx = <div className="rounded-full text-white text-center" style={{ lineHeight: widthPx + "px", backgroundColor: "#457b9d" }}>
				{getInitials(props.name, props.widthPx < 30)}
			</div>;
		} else {
			avatarJsx = <BoringAvatar
				size={widthPx}
				name={props.avatarHashString}
				variant="beam"
				colors={props.disabled ? paletteDisabled : palette}
			            />;
		}
	}

	function clickFileInput() {
		if (props.updateAvatar != null && inputRef.current != null) {
			inputRef.current.click();
		}
	}

	return (
		<div className="relative inline-block leading-none" style={{ height: widthPx, width: widthPx }}>
			{avatarJsx}

			{props.statusIcon != null ?
				<span
					className="absolute top-0 right-0 bg-white rounded-full flex items-center justify-center"
					style={{ height: iconHeightOuter + "px", width: iconHeightOuter + "px", fontSize: iconHeight + "px" }}
				>
					{statusIcon}
				</span>
				: null}

			{props.updateAvatar != null && !props.deleted ?
				<>
					<div
						className="avatar-overlay absolute top-0 rounded-full text-white cursor-pointer flex items-center justify-center"
						style={{ height: widthPx, width: widthPx, fontSize: (props.widthPx / 2) }}
						title="Upload new profile picture (Max 5 MB)"
						onClick={clickFileInput}
					>
						<BsPencil />
					</div>

					{props.updateAvatar != null && ((props.avatarBase64 != null && props.avatarBase64 != "") || (props.avatarUrlFilename != null && props.avatarUrlFilename != "")) ?
						<span
							className="absolute bottom-0 right-0 bg-white rounded-full flex items-center justify-center cursor-pointer"
							title="Remove profile picture"
							style={{ height: iconHeightOuter + "px", width: iconHeightOuter + "px", fontSize: iconHeightOuter + "px" }}
							onClick={deleteAvatar}
						>
							<AiOutlineCloseCircle />
						</span>
						: null}

					{props.updateAvatar != null && ((props.avatarBase64 == null || props.avatarBase64 == "") && (props.avatarUrlFilename == null || props.avatarUrlFilename == "")) ?
						<span
							className="absolute bottom-0 right-0 bg-white rounded-full flex items-center justify-center cursor-pointer"
							title="Upload new profile picture (Max 5 MB)"
							style={{ height: iconHeightOuter + "px", width: iconHeightOuter + "px", fontSize: iconHeightOuter + "px" }}
							onClick={clickFileInput}
						>
							<AiOutlinePlusCircle />
						</span>
						: null}

					{/* WARNING: Adding a file larger than 2MB to the /public dir will trigger a refresh in dev mode. Do not be alarmed! */}
					<input
						type="file"
						ref={inputRef}
						accept="image/*"
						onChange={onChangeHandler}
						style={{ display: "none" }}
					/>
				</>
				: null}

		</div>
	);
}
