import { DateTime } from "luxon";
import { DeviceFunctions } from "../../../agent-app/src/Helpers/DeviceFunctions";

/**
 * @param datetime An ISO UTC string or a DateTime.
 * @returns A string of the date, formatted like: "10:45 16 May 2023" or locale equivalent.
 */
export function datetimeToLocaleString(datetime: string | DateTime | undefined): string {
	if (datetime != null) {

		// Normalise date to DateTime.
		let date: DateTime;

		if (typeof datetime == "string") {
			date = DateTime.fromISO(datetime, { zone: "UTC" });
		} else {
			date = datetime;
		}

		if (!date.isValid) {
			return "";
		}

		// Adjust date to local time
		const localDate = date.toLocal();

		return localDate.toLocaleString(DateTime.TIME_SIMPLE, { locale: DeviceFunctions.getUserLocale() }) + " " + localDate.toLocaleString(DateTime.DATE_MED, { locale: DeviceFunctions.getUserLocale() });
	}

	return "";
}

/** Date format: 14 Feb */
const noYearFormat = { ...DateTime.DATE_MED, year: undefined };

/**
 * Casts string to a DateTime then formats like: 12 minutes ago / 2 hours ago / 3 weeks ago / in the future.
 */
export function datetimeToWhenString(datetimeUtc: DateTime | string | undefined): string {
	if (datetimeUtc != null) {
		// Normalise date to DateTime.
		let dateUtc: DateTime;

		if (typeof datetimeUtc == "string") {
			dateUtc = DateTime.fromISO(datetimeUtc, { zone: "UTC" });
		} else {
			dateUtc = datetimeUtc;
		}

		if (!dateUtc.isValid) {
			return "";
		}

		// Adjust date to local time
		const localDate = dateUtc.toLocal();

		const diffMins = Math.floor(DateTime.now().diff(localDate, "seconds").seconds / 60);

		if (diffMins < -1) {
			return "In the future";
		} else if (diffMins < 1) { // Less than a minute.
			return "Just now";
		} else if (diffMins < 60) { // Less than an hour.
			if (diffMins == 1) {
				return "1 minute ago";
			} else {
				return diffMins + " minutes ago";
			}
		} else if (diffMins < 1440) { // Less than a day.
			if (diffMins < 120) { // Less than 2 hrs ago.
				return "1 hour ago";
			} else {
				return Math.floor(diffMins / 60) + " hours ago";
			}
		} else if (diffMins < 2880) { // Sooner than 2 days ago.
			return "Yesterday";
		} else if (diffMins < 10080) { // Less than a week.
			return Math.floor(diffMins / 1440) + " days ago";
		} else if (diffMins < 20160) { // Sooner than two weeks ago.
			return "Last week";
		} else if (diffMins < 525600) { // Sooner than one year ago.
			return localDate.toLocaleString(noYearFormat, { locale: DeviceFunctions.getUserLocale() });
		} else {
			return localDate.toLocaleString(DateTime.DATE_MED, { locale: DeviceFunctions.getUserLocale() });
		}
	}

	return "";
}


export function getSlaTimerString(dueDate: DateTime): string {
	// Adjust date to local time
	const localDueDate = dueDate.toLocal();

	const diffMins = localDueDate.diffNow("seconds").seconds / 60;

	if (diffMins < -1400) { // More than one day ago.
		return Math.floor(diffMins / 1440) + "d";
	} else if (diffMins < -60) { // More than one hour ago.
		return Math.floor(diffMins / 60) + "h";
	} else if (diffMins < 0) { // In the past
		return Math.floor(diffMins) + "m";
	} else if (diffMins < 60) { // In less than an hour.
		return Math.ceil(diffMins) + "m";
	} else if (diffMins < 1400) { // In less than a day.
		return Math.ceil(diffMins / 60) + "h";
	} else { // In more than a day.
		return Math.ceil(diffMins / 1400) + "d";
	}
}

export function lengthMinsToString(mins: number): string {
	mins = Math.floor(mins);

	if (mins < 1) {
		return "< 1 min";
	} else if (mins == 1) {
		return "1 min";
	} else if (mins < 60) {
		return mins + " mins";
	} else if (mins < 1440) {
		const hours = Math.floor(mins / 60);
		const remainingMins = mins - (hours * 60);
		return hours + "h " + remainingMins + "m";
	} else {
		const days = Math.floor(mins / 1440);
		const hours = Math.floor((mins - (days * 1440)) / 60);
		const remainingMins = mins - (days * 1440) - (hours * 60);
		return days + "d " + hours + "h " + remainingMins + "m";
	}
}
