import { useRef, useState } from "react";

/**
 * Hook to make a modal async. Call beginModal with await to hang in one function until the modal calls dismissModal.
 * Great for waiting until a modal has been actioned before doing something.
 */
export function usePromiseModalHook() {
	const [isOpen, setIsOpen] = useState(false);

	const ref = useRef<Function | null>(null);

	/** Function to call to open the modal. This function will hang indefinitely until dismissModal is called. */
	async function beginModal() {
		setIsOpen(true);

		// Await a new Promise that will not resolve on its own.
		await new Promise((resolve) => {
			ref.current = resolve; // Set the ref to the promise's resolve function.
		});

		setIsOpen(false);
	}

	/** Function to pass to the modal to call when it closes. */
	async function dismissModal() {
		if (ref.current != null) {
			ref.current(); // Resolve the promise
		}
	}

	return { isOpen, beginModal, dismissModal };
}
