import { DateTime } from "luxon";
import { Alert } from "@mui/material";
import { useCache } from "@/Hooks/useCache";
import { useGetTotalUsersReport } from "@/Api/genApi";
import { SimpleReport } from "../SimpleReport";

interface TotalUsersProps {
	dateRange: [DateTime, DateTime];
}

export function TotalUsers(props: TotalUsersProps) {
	const { cache } = useCache();

	const reportReq = useGetTotalUsersReport();

	if (reportReq.error) {
		return <Alert severity="error">
			Could not load report.
		</Alert>;
	}

	function dataXTransformer(data: number): string {
		return cache.getCompany(data)?.name ?? "Unknown";
	}

	return (
		<SimpleReport
			title="Total Users"
			subtitle="Details how many users your top 20 companies have."
			axisTitles={{ xLabel: "Company", yLabel: "Users" }}
			data={reportReq.data}
			dataXTransformer={dataXTransformer}
			dateRange={props.dateRange}
		/>
	);
}
