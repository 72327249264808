"use client";

import { ReactNode } from "react";
import { Control, Controller } from "react-hook-form";
import isFQDN from "validator/es/lib/isFQDN";
import isEmail from "validator/lib/isEmail";
import isURL from "validator/lib/isURL";
import { Except } from "type-fest";
import TextInput, { TextInputProps } from "./TextInput";
import { InputTypes } from "./InputTypes";
import { ControlError } from "@shared/Components/FormComponents/ControlError";

interface W_TextInputProps extends Except<TextInputProps, "value" | "onChange" | "isWrapped"> {
	control: Control<any>;
	containerClassName?: string;
	urlUseFQDN?: boolean;
	asyncValidation?(v: string | undefined): Promise<string | boolean>;
	/** Use this to transform a string error into jsx. Needed because react-hook-form can only handle string errors */
	errorTransformer?(error?: string): ReactNode;
}

/**
 * The 'W' (wrapped) FormComponents wrap normal Form_ components in a react-hook-form Controller.
 * This hands over control to the useForm() that the control prop comes from.
 * This may seem convoluted but I want to keep my controlled inputs AND use them in react-hook-form to save needing 2 classes of components.
 *
 * There should be no HTML validation in these as it is handled by the react-hook-form rules prop.
 *
 * Do not use this for login forms as react-hook-form does not like auto fill!
 */
export default function W_TextInput(props: W_TextInputProps) {
	const rules: any = {}; // https://react-hook-form.com/api/useform/register

	if (props.mandatory) {
		rules.required = { value: true, message: "This is a required field." };
	}

	if (props.asyncValidation != null) {
		const notNullAsyncValidation = props.asyncValidation; // Added for TS.

		rules.validate = (v: string | undefined) => {
			const val1 = getValidationFunction(props.type, props.urlUseFQDN)(v);

			if (val1 === true) {
				return notNullAsyncValidation(v);
			} else {
				return val1;
			}
		};
	} else {
		rules.validate = getValidationFunction(props.type, props.urlUseFQDN);
	}

	// Remove types that are handled by the controller.
	const typeToPass = props.type != InputTypes.Email && props.type != InputTypes.Url ? props.type : undefined;

	return (
		<Controller
			control={props.control}
			name={props.dataname}
			rules={rules}
			render={({
				field: { onChange, onBlur, value },
				fieldState: { error },
			}) => {
				const transformedError = props.errorTransformer != null ? props.errorTransformer(error?.message) : error?.message;

				return <div className={props.containerClassName}>
					<TextInput
						className={props.className}
						{...props}
						onChange={onChange}
						value={value}
						invalid={error != null}
						type={typeToPass}
						isWrapped // Removes browser mandatory field checking.
						onBlur={newValue => {
							onBlur();

							// Use the trimmed value.
							onChange(newValue);
						}}
					/>

					<ControlError error={transformedError} />
				</div>;
			}}
		/>
	);
}

function getValidationFunction(inputType?: InputTypes, urlUseFQDN?: boolean): (v?: string) => string | boolean {
	switch (inputType) {
		case InputTypes.Email:
			return (v: string | undefined) => v == undefined || isEmail(v) ? true : "Invalid email";
		case InputTypes.Url:
			if (urlUseFQDN) {
				return (v: string | undefined) => v == undefined || v === "" || isFQDN(v) ? true : "Invalid domain";
			} else {
				return (v: string | undefined) => v == undefined || v === "" || isURL(v) ? true : "Invalid URL";
			}
	}

	return () => true;
}
