import { ReactNode } from "react";

interface AgentRegisterParentProps {
	children: ReactNode;
}

export function AgentRegisterParent(props: AgentRegisterParentProps) {
	return (
		<div className="h-screen flex flex-col" style={{ backgroundColor: "#E1F2FE" }}>
			<div className="grow pt-10 sm:pt-16 px-5">
				<div className="max-w-md w-full mx-auto bg-gray-50 py-8 px-5 rounded-md shadow-md">
					<div className="w-2/3 mx-auto mb-6">
						<img src="/logowithtext.png" alt="GoDesk Logo" />
					</div>

					{props.children}
				</div>
			</div>
		</div>
	);
}
