import { KbArticle } from "../Entities/EntityTypes";

/**
 * Gets the slug for a KB article in the specified locale.
 * @param locale The locale to get the slug for. Optional - defaults to 'en_GB'.
 */
export function getKbArticleSlug(article: KbArticle, locale: string = "en_GB"): string {
	let title = article.articleContent?.find(articleContent => articleContent.id.language == locale)?.title;

	if (title == null) {
		return "";
	}

	// Remove emojis and special chars.
	title = title.replace(/[^a-zA-Z0-9\-\s]/g, "");

	title = title.trim();
	title = title.toLocaleLowerCase();

	// Hyphenate.
	title = title.split(/\s+/).join("-");

	// Limit to 100 chars.
	title = title.substring(0, 100);

	title = encodeURIComponent(title);

	return title;
}

/**
 * When the select was open in a scrollable parent div, scrolling the parent div did not close the select.
 * This led to wierd issues where the select box was out of view, but the select menu was still visible.
 *
 * GOTCHA: The select must have 'select-parent' in it's classname.
 */
export function closeMenuOnScrollFix(event: Event) {
	const scrollTarget = event.target as HTMLElement;

	// Get all select parents.
	const selectElements = document.getElementsByClassName("select-parent");

	// Check if any select parents contain the element being scrolled.
	for (let i = 0; i < selectElements.length; i++) {
		const element = selectElements.item(i);

		if (element?.contains(scrollTarget)) {
			return false; // Do not close the select dropdown.
		}
	}

	// Close the select dropdown.
	return true;
}

export async function getBase64(file: Blob): Promise<string | null> {
	return new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = () => {
			if (typeof reader.result === "string") {
				resolve(reader.result);
			} else {
				resolve(null);
			}
		};
		reader.onerror = error => reject(error);
	});
}

export function fileExtensionIsImage(file: string) {
	const imageFileTypes = ["jpg", "png", "bmp", "gif", "tif", "svg", "ico"];

	for (let i = 0; i < imageFileTypes.length; i++) {
		if (file.endsWith(imageFileTypes[i])) {
			return true;
		}
	}

	return false;
}

/*
	* Takes 2 strings {password, confirmPassword} as params and runs validation tests on them.
	* Return null if all tests pass, else return an error string.
	* TODO: Change this to use Validator
	*/
export function validatePassword(password: string, confirmPassword: string): string | null {
	const hasWhiteSpace = /^(?=.*\s)/;
	if (hasWhiteSpace.test(password) || hasWhiteSpace.test(confirmPassword)) {
		return "Passwords cannot contain whitespaces.";
	}

	if (password.length < 8 || confirmPassword.length < 8) {
		return "Passwords must have at least 8 characters.";
	}

	if (confirmPassword != password) {
		return "Passwords do not match.";
	}

	return null;
}

export function bytesToFormattedString(bytes: number | undefined): string {
	if (bytes == undefined || bytes < 1) {
		return "";
	} else if (bytes < 1000) {
		return bytes + " B";
	} else if (bytes < 1000000) {
		const kb = bytes / 1000;
		return (Math.round(kb * 100) / 100) + " KB";
	} else {
		const mb = bytes / 1000000;
		return (Math.round(mb * 100) / 100) + " MB";
	}
}
