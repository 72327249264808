import { FormEvent, useState } from "react";
import { useSearchParams } from "react-router-dom";
import toast from "react-hot-toast";
import { Alert } from "@mui/material";
import { AgentRegisterParent } from "@/Pages/Auth/Register/AgentRegisterParent";
import { useRedeemAgentInvite } from "@/Api/genApi";
import { AgentInviteRedeem } from "@/Api/genApi.schemas";
import { useAgentAuth } from "@/Hooks/useAgentAuth";
import { AgentRegisterForm } from "@/Pages/Auth/Register/AgentRegisterForm";

export function AgentRegister() {
	const [searchParams] = useSearchParams();

	const { login, loginPending, loginError } = useAgentAuth();

	const email = searchParams.get("email");
	const code = searchParams.get("code");

	const redeemInviteReq = useRedeemAgentInvite();

	const validUrlParams = email != null && code != null;

	const [formError, setFormError] = useState<string | null>(null);

	/** Attempts to create the user and then login. */
	async function handleSubmit(event: FormEvent) {
		event.preventDefault();

		const name = (document.getElementById("nameInput") as HTMLInputElement).value ?? "";
		const password = (document.getElementById("passwordInput") as HTMLInputElement).value ?? "";
		const passwordConfirm = (document.getElementById("confirmPasswordInput") as HTMLInputElement).value ?? "";

		// Check passwords match.
		if (password != passwordConfirm) {
			setFormError("Password and confirm password fields must match.");
			return;
		}

		if (email == undefined) {
			setFormError("Email not found.");
			return;
		}

		if (code == undefined) {
			setFormError("Code not found.");
			return;
		}

		setFormError(null);

		// Create account.
		const toastId1 = toast.loading("Creating account...");

		const data: AgentInviteRedeem = {
			code: code,
			name: name,
			password: password
		};

		await redeemInviteReq.mutateAsync({ data: data }, {
			onSuccess: async () => {
				toast.success("Account created.", { id: toastId1 });
				await login(email, password);
			},
			onError: error => toast.error("Could not create account: " + error.errorMsg, { id: toastId1 })
		});
	}

	if (!validUrlParams) {
		return (
			<AgentRegisterParent>
				<Alert severity="error">
					Invalid redeem link. Did you open an old invite email?
					<br /><br />
					Contact your GoDesk admin to obtain a new invite link.
				</Alert>
			</AgentRegisterParent>
		);
	}

	return (
		<AgentRegisterParent>
			<div className="text-lg mb-6">
				Create your GoDesk agent account.
			</div>

			{formError != null &&
				<Alert severity="error">
					{formError}
				</Alert>}

			{loginError != null &&
				<Alert severity="error">
					{loginError}
				</Alert>}

			<form onSubmit={handleSubmit}>
				<AgentRegisterForm
					disabled={redeemInviteReq.isPending || loginPending}
					email={email}
				/>
			</form>
		</AgentRegisterParent>
	);
}
