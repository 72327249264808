import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Button, Stack } from "@mui/material";
import toast from "react-hot-toast";
import LoadingButton from "@mui/lab/LoadingButton";
import { Sla } from "@/Api/genApi.schemas";
import { PriorityGrid } from "@/Pages/Config/SLA/SlaPriorityGrid";
import { useCreateSLAs } from "@/Api/genApi";
import { FormParent } from "@shared/Components/Form/FormParent";
import { W_TextField } from "@shared/Components/FormComponents/W_TextField/W_TextField";
import { FormSection } from "@shared/Components/Form/FormSection";

export function NewSla() {
	const navigate = useNavigate();

	const { control, handleSubmit } = useForm<Sla>({ defaultValues: defaultNewSla });

	const createSlaReq = useCreateSLAs();

	function onSubmit(sla: Sla) {
		createSlaReq.mutate({ data: sla }, {
			onSuccess: () => {
				toast.success("Created SLA.");
				navigate("/config/manage/slas");
			},
			onError: error => toast.error("Could not create SLA: " + error.message)
		});
	}

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<FormParent title="New SLA">
				<FormSection>
					<W_TextField
						control={control}
						name="name"
						label="Name"
						required
					/>

					<PriorityGrid
						control={control}
					/>

					<Stack direction="row" spacing={1}>
						<Button
							variant="outlined"
							color="neutral"
							onClick={() => navigate("/config/manage/slas")}
						>
							Cancel
						</Button>

						<LoadingButton
							variant="contained"
							onClick={() => handleSubmit(onSubmit)()}
							loading={createSlaReq.isPending}
						>
							Create SLA
						</LoadingButton>
					</Stack>
				</FormSection>
			</FormParent>
		</form>
	);
}

const defaultNewSla: Partial<Sla> = {
	id: 0,
	p1: {
		resolution: 4,
		resolutionUnit: "HOUR",
		response: 15,
		responseUnit: "MINUTE"
	},
	p2: {
		resolution: 1,
		resolutionUnit: "DAY",
		response: 1,
		responseUnit: "HOUR"
	},
	p3: {
		resolution: 2,
		resolutionUnit: "DAY",
		response: 2,
		responseUnit: "HOUR"
	},
	p4: {
		resolution: 4,
		resolutionUnit: "DAY",
		response: 4,
		responseUnit: "HOUR"
	},
	p5: {
		resolution: 0,
		resolutionUnit: "HOUR",
		response: 0,
		responseUnit: "HOUR"
	}
};
