import { NavigateFunction } from "react-router-dom";
import { UseFormClearErrors, UseFormSetError } from "react-hook-form";
import toast from "react-hot-toast";
import { FieldError } from "@/legacy/ApiResponse";
import { EnrichedCache } from "@/Store/Reducers/cache/CacheTypes";
import { CollisionNote } from "@/Api/genApi.schemas";
import { FieldErrorCode, GD_SUPPORT_AGENT_ID } from "@shared/Enums/Enums";
import { ErrorPayload, ResBase } from "@shared/Api/ApiResponses";

/**
 * Functions that don't fit into the other functions.
 */
export default class OtherFunctions {

	/**
	 * @deprecated Breaks type safety. use react hook form instead.
	 */
	static SafeChangeProperty(data: any, key: string, newValue: any): any {
		// Copy obj as any so properties can be accessed by name.
		const dataCopy = Object.assign({}, data);

		// If it has property 'key' and it is of the same type as 'newValue', allow. However, this will only catch JS type mismatches, not TS ones.
		// If it does not have the property it should be allowed whatever the type. This is because axiom does not populate null values from the API so they may still be valid.

		// Eslint disabled. Deprecated.
		// eslint-disable-next-line no-prototype-builtins
		if (!dataCopy.hasOwnProperty(key) || (dataCopy.hasOwnProperty(key) && (typeof dataCopy[key] == typeof newValue || typeof newValue == "undefined" || typeof dataCopy[key] == "undefined"))) {
			dataCopy[key] = newValue;
		} else {
			console.log("Error in SafeChangeProperty", data, key, newValue);
		}

		return dataCopy;
	}

	static ticketCountFormat(count?: number): string | undefined {
		if (count != null && count > 0) {
			if (count < 1000) {
				return count.toString();
			} else {
				return Math.floor(count / 1000) + "k";
			}
		} else {
			return undefined;
		}
	}

	/**
	 * Takes the fieldErrors from a response obj and calls setError for each of them.
	 */
	static setFieldErrors(setError: UseFormSetError<any>, fieldErrors?: FieldError[]) {
		if (fieldErrors != null) {
			fieldErrors.forEach(error => {
				setError(error.field, {
					message: error.message
				});
			});
		}
	}

	static setFormErrors(setError: UseFormSetError<never>, clearErrors: UseFormClearErrors<never>, error?: ErrorPayload | null) {
		if (error == null) {
			clearErrors();
			return;
		}

		if (error != null && error.errorMsg != null) {
			if (error.errorField != null) {
				setError(error.errorField as never, { type: "manual", message: error.errorMsg });
			} else {
				setError("root", { type: "manual", message: error.errorMsg });
			}
		}
	}

	static displayEmailTakenError(res: ResBase<unknown>, cache: EnrichedCache, navigate: NavigateFunction) {
		OtherFunctions.displayEmailTakenErrorNormal(cache, navigate, res.formFieldErrorCode, res.dataNumber1);
	}

	/**
	 * Takes an Id then creates a better error message.
	 */
	static displayEmailTakenErrorNormal(cache: EnrichedCache, navigate: NavigateFunction, formFieldErrorCode?: FieldErrorCode | undefined, dataNumber1?: number | undefined) {
		if (formFieldErrorCode == FieldErrorCode.EMAIL_TAKEN_BY_USER && dataNumber1 != undefined) {
			const user = cache.getUser(dataNumber1);

			if (user != undefined) {
				toast.error(
					<div>
						This email is in use by a user: <span className="underline cursor-pointer text-blue-500" onClick={() => navigate("/users/" + user.id)}>{user.name}</span>
					</div>
				);
				return;
			}
		} else if (formFieldErrorCode == FieldErrorCode.EMAIL_TAKEN_BY_EMAIL_CHANNEL && dataNumber1 != undefined) {
			const channel = cache.getChannel(dataNumber1);

			if (channel != undefined) {
				toast.error(
					<div>
						This email is in use by a mailbox: <span className="underline cursor-pointer text-blue-500" onClick={() => navigate("/config/manage/channels/email/" + channel.id)}>{channel.name}</span>
					</div>
				);
				return;
			}
		} else if (formFieldErrorCode == FieldErrorCode.EMAIL_TAKEN_BY_AGENT && dataNumber1 != undefined) {
			const agent = cache.getAgent(dataNumber1);

			if (agent != undefined) {
				toast.error(
					<div>
						This email is in use by a agent: <span className="underline cursor-pointer text-blue-500" onClick={() => navigate("/config/manage/agents/" + agent.id)}>{agent.name}</span>
					</div>
				);
				return;
			}
		}

		toast.error("Unsuccessful please try again.");
	}

	/** Filters out notes that shouldn't be displayed. */
	static filterCollisionNotes(notes?: CollisionNote[], activeAgentId?: number): CollisionNote[] {
		if (notes == undefined) {
			return [];
		}

		let filteredNotes = notes;

		// Remove the active agent and the GoDesk support agent.
		filteredNotes = filteredNotes.filter(note => note.agentId != activeAgentId && note.agentId != GD_SUPPORT_AGENT_ID);

		// Make sure we don't have a VIEWING note if there's also an EDITING/ADDING note.
		filteredNotes = filteredNotes.filter(note => {
			if (note.action == "VIEWING") {
				return !filteredNotes.some(n => n.action == "EDITING" || n.action == "ADDING");
			} else {
				return true;
			}
		});

		return filteredNotes;
	}

}
