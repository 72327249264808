import { CacheSlices } from "@/Store/Reducers/cache/CacheTypes";
import CacheDropdown from "../../../../Components/FormComponents/CacheDropdown/CacheDropdown";
import { Ticket } from "@shared/Entities/EntityTypes";
import { datetimeToLocaleString } from "@shared/Helpers/DateFunctions";

interface TicketSlaSectionProps {
	ticket: Ticket;
	handleSubmit(key: string, newValue: number | undefined): void;
}

export function TicketSlaSection(props: TicketSlaSectionProps) {
	return (
		<div className="border-t border-gray-300 p-3">
			<CacheDropdown
				label="SLA"
				value={props.ticket.slaId}
				dataname={"slaId"}
				onChange={props.handleSubmit}
				cacheSlice={CacheSlices.Slas}
			/>
			<div className="grid grid-cols-[110px_auto] grid-rows-2 gap-y-4">
				<div>Response Due:</div>
				<div className="self-center">{datetimeToLocaleString(props.ticket.responseDue)}</div>
				<div>Resolution Due:</div>
				<div className="self-center">{datetimeToLocaleString(props.ticket.resolutionDue)}</div>
			</div>
		</div>
	);
}
