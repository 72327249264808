import { FormState } from "react-hook-form";
import { AlertBox } from "@shared/Components/AlertBox/AlertBox";

interface FormErrorProps {
	/** Use this or control. */
	error?: string | null;
	/** Use this or error. */
	formState?: FormState<any>;
}

/** Conditionally displays an alert for any form errors. */
export function FormError(props: FormErrorProps) {
	const formErrorMsg = props.formState?.errors?.root?.test?.message;

	if (props.error != null || formErrorMsg != null) {
		return (
			<AlertBox alertType="error">
				{props.error ?? formErrorMsg}
			</AlertBox>
		);
	}
}
