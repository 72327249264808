import { CacheSlices } from "@/Store/Reducers/cache/CacheTypes";
import MultiEntitySelect from "../../FormComponents/MultiEntitySelect/MultiEntitySelect";
import { useAppDispatch, useAppSelector } from "../../../Store/hooks";
import { customFilterApplied } from "../../../Store/Reducers/ticketsPageSlice";
import { ViewSetFilter, ViewTextFilter, isViewNumberFilter, isViewSetFilter, isViewTextFilter } from "@shared/Models/View";
import TextInput from "@shared/Components/FormComponents/TextInput/TextInput";

export function FilterPanelFilters() {
	const dispatch = useAppDispatch();
	const filters = useAppSelector(state => state.ticketsPage.customFilters);

	function getMultiNumberFilterValue(key: string): number[] | undefined {
		const filter = filters != null ? filters[key] : null;

		if (filter != null) {
			if (isViewNumberFilter(filter)) {
				return [filter.value];
			} else if (isViewSetFilter(filter)) {
				return filter.values;
			}
		}
	}

	function setMultiNumberFilterValue(key: string, newValue: number[] | undefined) {
		const newFilters = { ...filters };

		if (newValue != null) {
			const condition: ViewSetFilter = {
				type: "set",
				comparison: "contains",
				values: newValue
			};

			newFilters[key] = condition;
		} else {
			delete newFilters[key];
		}

		dispatch(customFilterApplied(newFilters));
	}

	function getStringFilterValue(key: string): string | undefined {
		const filter = filters != null ? filters[key] : null;

		if (filter != null && isViewTextFilter(filter)) {
			return filter.value;
		}
	}

	function setStringFilterValue(key: string, newValue: string) {
		const newFilters = { ...filters };

		if (newValue != null) {
			const condition: ViewTextFilter = {
				type: "text",
				comparison: "contains",
				value: newValue
			};

			newFilters[key] = condition;
		} else {
			delete newFilters[key];
		}

		dispatch(customFilterApplied(newFilters));
	}

	return (
		<>
			<TextInput
				label="Subject"
				dataname="description"
				value={getStringFilterValue("description")}
				onChangeDebounced={setStringFilterValue}
				clearable
			/>

			<MultiEntitySelect
				dataname="statusId"
				label="Statuses"
				cacheSlice={CacheSlices.Statuses}
				value={getMultiNumberFilterValue("statusId")}
				handleSubmit={setMultiNumberFilterValue}
			/>
			<MultiEntitySelect
				dataname="teamId"
				label="Teams"
				cacheSlice={CacheSlices.Teams}
				value={getMultiNumberFilterValue("teamId")}
				handleSubmit={setMultiNumberFilterValue}
			/>
			<MultiEntitySelect
				dataname="agentId"
				label="Agents"
				extraOptions={[{ value: -1, label: "Unassigned" }]}
				cacheSlice={CacheSlices.Agents}
				value={getMultiNumberFilterValue("agentId")}
				handleSubmit={setMultiNumberFilterValue}
			/>
			<MultiEntitySelect
				dataname="priorityId"
				label="Priorities"
				cacheSlice={CacheSlices.Priorities}
				value={getMultiNumberFilterValue("priorityId")}
				handleSubmit={setMultiNumberFilterValue}
			/>
			<MultiEntitySelect
				dataname="slaId"
				label="SLAs"
				cacheSlice={CacheSlices.Slas}
				value={getMultiNumberFilterValue("slaId")}
				handleSubmit={setMultiNumberFilterValue}
			/>
			<MultiEntitySelect
				dataname="companyId"
				label="Companies"
				extraOptions={[{ value: -1, label: "No company" }]}
				cacheSlice={CacheSlices.Companies}
				value={getMultiNumberFilterValue("companyId")}
				handleSubmit={setMultiNumberFilterValue}
			/>
			<MultiEntitySelect
				dataname="userId"
				label="Users"
				extraOptions={[{ value: -1, label: "No user" }]}
				cacheSlice={CacheSlices.Users}
				value={getMultiNumberFilterValue("userId")}
				handleSubmit={setMultiNumberFilterValue}
			/>
			<MultiEntitySelect
				dataname="categoryId"
				label="Categories"
				extraOptions={[{ value: -1, label: "No category" }]}
				cacheSlice={CacheSlices.Categories}
				value={getMultiNumberFilterValue("categoryId")}
				handleSubmit={setMultiNumberFilterValue}
			/>
		</>
	);
}
