
interface FormReadPairProps {
	name: string;
	value?: string | number | null;
}

/** Renders 2 divs to display in a FormReadBox. */
export function FormReadPair(props: FormReadPairProps) {
	const blankValue = props.value == null || props.value == "";

	return (
		<>
			<div className="text-slate-700">
				{props.name}
			</div>
			<div className={`${!blankValue ? "font-medium" : "text-gray-500"}`}>
				{blankValue ? "-" : props.value}
			</div>
		</>
	);
}
