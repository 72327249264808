
export interface JWT {
	/** Issuer */
	iss: string;
	/** Subject - the user the JWT was issued for */
	sub: string;
	/** Expiry */
	exp: string;
	/** Issued at */
	iat: string;
	/** Scope - the authorities */
	scope: string;
}

export function parseJwt(token: string): JWT {
	const base64Url = token.split(".")[1];
	const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
	const jsonPayload = decodeURIComponent(window.atob(base64).split("").map(function(c) {
		return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
	}).join(""));

	return JSON.parse(jsonPayload);
}

export function isJwtExpired(token: string): boolean {
	// Convert string to JWT.
	const jwt = parseJwt(token);

	// Parse expiry date.
	const expiryDateUnix = parseInt(jwt.exp);
	const expiryDate = new Date(expiryDateUnix * 1000);

	// Compare.
	const now = new Date();
	return expiryDate <= now;
}
