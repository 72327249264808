import { Control, Controller, FieldPath, FieldValues } from "react-hook-form";
import { Except } from "type-fest";
import { Checkbox, FormControlLabel, CheckboxProps } from "@mui/material";

type W_TickboxProps<T extends FieldValues> = Except<CheckboxProps, "name"> & {
	control: Control<T>;
	name: FieldPath<T>;
	label?: string;
};

export function W_Tickbox<T extends FieldValues>(props: W_TickboxProps<T>) {

	return (
		<Controller
			control={props.control}
			name={props.name}
			render={({
				field: { onChange, value }
			}) =>
				<FormControlLabel
					control={
						<Checkbox
							checked={value ?? false}
							onChange={(_event, checked) => onChange(checked)}
						/>
					}
					label={props.label}
				/>
			}
		/>
	);
}
