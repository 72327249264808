import { useSearchParams } from "react-router-dom";

type ParamsNumber = { [k: string]: number | undefined };

export function useNumberQueryParams(): Readonly<ParamsNumber> {
	const [searchParams] = useSearchParams();

	const numObj: ParamsNumber = {};

	searchParams.forEach((value, key) => {
		const numValue = parseInt(value);

		if (!isNaN(numValue)) {
			numObj[key] = numValue;
		}
	});

	return numObj;
}
