import { ChangeEvent, useId } from "react";

export interface TickboxProps {
	value?: boolean; // TODO: This could accept a string of "1" or "0". Could return either bool or string depending on props.value type.
	valueName: string;
	label?: string;
	onChange(key: string, newValue: boolean): void;
	inline?: boolean;
	disabled?: boolean;
}

export function Tickbox(props: TickboxProps) {
	const controlId = useId();

	function onChange(event: ChangeEvent<HTMLInputElement>) {
		if (event.target.checked != props.value) {
			props.onChange(props.valueName, event.target.checked);
		}
	}

	const styles = props.inline ? "inline-flex items-center" : "my-5 flex items-center";

	return (
		<div className={styles}>
			<input
				className="h-4 w-4 text-blue-600 border-gray-300 rounded"
				type="checkbox"
				id={controlId}
				checked={props.value ?? false}
				onChange={onChange}
				disabled={props.disabled}
			/>

			<label className="ml-2 select-none" htmlFor={controlId}>{props.label}</label>
		</div>
	);
}
