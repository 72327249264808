import { useRef } from "react";
import useHotkeys from "@reecelucas/react-use-hotkeys";

interface Options {
	enabled?: boolean;
	enableOnContentEditable?: boolean;
	ignoredElementWhitelist?: ("INPUT" | "TEXTAREA")[];
	eventListenerOptions?: AddEventListenerOptions;
}

interface HotkeysProps {
	hotkeys: string | string[];
	callback: (event: KeyboardEvent) => void;
	options?: Options;
}

/**
 * Hotkey provider.
 */
export function Hotkeys(props: HotkeysProps) {
	const hotkeyRef = useRef<HTMLDivElement>(null);

	/** Stops keyboard events firing behind modals. (So they don't trigger keyboard shortcuts). */
	/** Prevents keyboard events propagating when the modal is active and has no child modals. */
	function onCallback(event: KeyboardEvent) {
		const modals = document.querySelectorAll(".godesk-modal");

		// GOTCHA: This just gets the last node in the document.
		// To get the deepest mode, sort the list by how many parents each node has. Get the one with the most.
		const highestModal = modals.length > 0 ? modals[modals.length - 1] : null;

		// Only do callback if we are a decendent of the highest modal (if there is one!).
		if (highestModal != null && !highestModal?.contains(hotkeyRef.current)) {
			return;
		}

		event.preventDefault();
		props.callback(event);
	}

	useHotkeys(props.hotkeys, onCallback, props.options);

	return (
		<div ref={hotkeyRef}></div>
	);
}
