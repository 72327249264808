import { Control, Controller } from "react-hook-form";
import type { Except } from "type-fest";
import MultiEntitySelect, { MultiEntitySelectProps } from "@/Components/FormComponents/MultiEntitySelect/MultiEntitySelect";
import { ControlError } from "@shared/Components/FormComponents/ControlError";

interface W_MultiEntitySelectProps extends Except<MultiEntitySelectProps, "value" | "handleSubmit"> {
	control: Control<any>;
	className?: string;
}

/**
 * @see W_TextInput
 */
export default function W_MultiEntitySelect(props: W_MultiEntitySelectProps) {
	const rules: any = {}; // https://react-hook-form.com/api/useform/register

	if (props.mandatory) {
		rules.required = { value: true, message: "This is a required field." };
	}

	return (
		<Controller
			control={props.control}
			name={props.dataname}
			rules={rules}
			render={({
				field: { onChange, value },
				fieldState: { error }
			}) => (
				<div className={props.className}>
					<MultiEntitySelect
						{...props}
						handleSubmit={(key: string, newValue: number[] | undefined) => {onChange(newValue);} }
						value={value}
						invalid={error != null}
					/>

					<ControlError error={error?.message} />
				</div>
			)}
		/>
	);
}
