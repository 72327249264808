import { ReactNode } from "react";
import { Box } from "@mui/material";

interface SheetProps {
	children?: ReactNode;
	className?: string;
}

export function Sheet(props: SheetProps) {
	return (
		<Box sx={theme => ({ bgcolor: theme.palette.background.paper })} className={props.className}>
			{props.children}
		</Box>
	);
}
