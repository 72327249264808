import { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Draggable, { DraggableData, DraggableEvent } from "react-draggable";
import { AiOutlineUser } from "react-icons/ai";
import { GiTicket } from "react-icons/gi";
import { IoMdClose } from "react-icons/io";
import { GlobalTab, getGlobalTabUrlPath, HeaderTabEntities } from "@/Components/Header/GlobalTab";
import { useAppDispatch } from "@/Store/hooks";
import { globalTabPinned, globalTabUnpinned } from "@/Store/Reducers/globalTabsSlice";

type Coords = { x: number; y: number };

interface HeaderTabProps {
	tab: GlobalTab;
	handleTabStart(tabHtmlId: string): void;
	handleTabDrag(tabHtmlId: string): void;
	handleTabStop(tabHtmlId: string): void;
	handleTabRemove(tab: GlobalTab): void;
}

export default function HeaderTab(props: HeaderTabProps) {
	const dispatch = useAppDispatch();

	const [isBeingDragged, setIsBeingDragged] = useState(false);

	// This tracks the start pos so we can only register a click if the tab wasn't dragged >5px.
	const [dragStartPos, setDragStartPos] = useState({ x: 0, y: 0 });

	const navigate = useNavigate();
	const location = useLocation();

	const active = location.pathname == getGlobalTabUrlPath(props.tab);

	function closeTab() {
		props.handleTabRemove(props.tab);
	}

	function handleStart(e: DraggableEvent, data: DraggableData) {
		const coords = getEventXY(e);

		if (coords == null) {
			return;
		}

		setDragStartPos(coords);

		setIsBeingDragged(true);
		props.handleTabStart(data.node.id);
	}

	function handleDrag(e: DraggableEvent, data: DraggableData) {
		props.handleTabDrag(data.node.id);
	}

	function handleStop(e: DraggableEvent, data: DraggableData) {
		const coords = getEventXY(e);
		if (coords == null) {
			return;
		}

		setIsBeingDragged(false);

		const dragX = Math.abs(dragStartPos.x - coords.x);
		const dragY = Math.abs(dragStartPos.y - coords.y);

		if (dragX < 5 && dragY < 5) { // Register as a click.
			clickTab(e);
		} else { // Register as a drag.
			props.handleTabStop(data.node.id);
		}
	}

	function getEventXY(e: DraggableEvent): Coords | null {
		if (e instanceof MouseEvent) {
			return { x: e.screenX, y: e.screenY };
		} else if (isTouchEvent(e)) {
			return getTouchEventCoords(e);
		} else { // Is a React.SyntheticEvent.
			if (isTouchEvent(e.nativeEvent)) {
				return getTouchEventCoords(e.nativeEvent);
			}

			if (e.nativeEvent instanceof MouseEvent) {
				return { x: e.nativeEvent.screenX, y: e.nativeEvent.screenY };
			}
		}

		return null;
	}

	/**
	 * Returns the coords of the first touch.
	 */
	function getTouchEventCoords(event: TouchEvent): Coords | null {
		const touch = event.touches.length > 0 ? event.touches[0] : event.changedTouches.length > 0 ? event.changedTouches[0] : null;

		if (touch != null) {
			return { x: touch.screenX, y: touch.screenY };
		}

		return null;
	}

	/**
	 * Custom guard so FF doesn't throw an error when we access TouchEvent.
	 */
	function isTouchEvent(e: DraggableEvent): e is TouchEvent {
		// HACK: This relies on global window.TouchEvent.
		return window.TouchEvent && e instanceof TouchEvent;
	}

	function clickTab(event: any) {
		// Check we didn't click the close button. parentNode check is for if we click the <svg>.
		if (event.target.id != "headerTabCloseBtn" && event.target.parentNode.id != "headerTabCloseBtn") {
			navigate(getGlobalTabUrlPath(props.tab));
		}
	}

	function onAuxClick(event: React.MouseEvent) {
		if (event.button == 1) { // Middle mouse button clicked.
			event.preventDefault();
			closeTab();
		}
	}

	function pinTab() {
		if (!props.tab.pinned) {
			dispatch(globalTabPinned(props.tab));
		} else {
			dispatch(globalTabUnpinned(props.tab));
		}
	}

	const normalStyles = "transition-colors flex relative border-b border-r h-14 items-center text-ellipsis overflow-hidden whitespace-nowrap cursor-pointer ";
	const draggingStyles = "shadow-lg z-10 cursor-grabbing ";
	const activeStyles = active ? "bg-gray-100 " : "bg-white ";
	const pinnedStyles = !props.tab.pinned ? " italic " : "";

	return (
		<Draggable
			axis="x"
			bounds="parent" // TODO: Change this to use the set bounds to stop the bug when scrolled to the right.
			onStart={handleStart}
			onDrag={handleDrag}
			onStop={handleStop}
			position={{ x: 0, y: 0 }}
		>

			<div
				id={"GlobalTab" + props.tab.entity + props.tab.id}
				className={"headerTab basis-60 " + normalStyles + activeStyles + pinnedStyles + (isBeingDragged ? draggingStyles : "")}
				onAuxClick={onAuxClick}
				onDoubleClick={pinTab}
				// Normal onClick done in handleStop().
			>
				<div className="flex items-center text-xl p-2">
					{ props.tab.entity == HeaderTabEntities.TICKETS ? <GiTicket /> : props.tab.entity == HeaderTabEntities.USERS ? <AiOutlineUser /> : <></>}
				</div>
				<div className="flex-grow text-ellipsis overflow-hidden">
					{ props.tab.entity == HeaderTabEntities.TICKETS && props.tab.id > -1 ?
						<>
							<div className="text-ellipsis overflow-hidden">
								{props.tab.title}
							</div>
							<div className="text-gray-600 text-sm">
								# {props.tab.id}
							</div>
						</>
						:
						props.tab.title }
				</div>
				<button onClick={closeTab} onTouchStart={closeTab} id="headerTabCloseBtn" className="shrink-0 text-base mr-1 text-gray-800 hover:bg-gray-300 active:bg-gray-400 w-5 h-5 rounded-full flex items-center justify-center">
					<IoMdClose id="headerTabCloseBtn" />
				</button>
			</div>
		</Draggable>
	);
}
