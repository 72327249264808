import { useAgent } from "@/Hooks/useAgent";
import { useVersionUpdater } from "@/Hooks/useVersionUpdater";
import HeaderSearch from "./HeaderSearch";
import HeaderButtons from "./HeaderButtons";
import HeaderTabs from "./HeaderTabs";
import { GD_SUPPORT_AGENT_ID } from "@shared/Enums/Enums";

export default function Header() {
	const agent = useAgent();

	useVersionUpdater();

	const isRemoteAgent = agent.id == GD_SUPPORT_AGENT_ID;

	return (
		<>
			{isRemoteAgent &&
				<div className="h-6 w-full bg-yellow-300 flex justify-center items-center shrink-0" >
					<div className="text-sm font-semibold">CLIENT INSTANCE</div>
				</div>
			}

			<div
				className="flex items-center justify-between border-b h-14 z-10 flex-shrink-0"
			>
				<div className="flex-grow overflow-x-auto"><HeaderTabs /></div>
				<div className="mx-5"><HeaderSearch /></div>
				<div className="shrink-0"><HeaderButtons /></div>
			</div>
		</>
	);
}
