import { DropdownOption } from "../../../shared/src/Components/FormComponents/Dropdown/Dropdown";
import Dropdown from "@shared/Components/FormComponents/Dropdown/Dropdown";
import TextArea from "@shared/Components/FormComponents/TextArea/TextArea";
import TextInput from "@shared/Components/FormComponents/TextInput/TextInput";
import { CustomField } from "@shared/Entities/EntityTypes";
import { CustomFieldInputType } from "@shared/Enums/Enums";
import MultiSelectDropdown from "@shared/Components/FormComponents/MultiSelectDropdown/MultiSelectDropdown";

type customFieldSubmit = (key: string, newValue: string | undefined) => Promise<void>;

export function renderCustomField(cf: CustomField, customFieldSubmit: customFieldSubmit, valueStr?: string): JSX.Element | undefined {
	if (cf.hidden) {
		return undefined;
	}

	if (valueStr === "undefined") {
		valueStr = undefined;
	}

	if (cf.type == CustomFieldInputType.TEXT_INPUT) {
		return (
			<TextInput
				dataname={cf.name}
				label={cf.label}
				value={valueStr}
				handleSubmit={customFieldSubmit}
				mandatory={cf.mandatory}
				disabled={cf.hidden}
				toolTip={cf.description}
			/>
		);
	} else if (cf.type == CustomFieldInputType.TEXT_AREA) {
		return (
			<TextArea
				dataname={cf.name}
				label={cf.label}
				value={valueStr}
				handleSubmit={customFieldSubmit}
				mandatory={cf.mandatory}
				disabled={cf.hidden}
				toolTip={cf.description}
			/>
		);
	} else if (cf.type == CustomFieldInputType.DROPDOWN && cf.options != undefined) {

		let value: number | undefined = valueStr != null ? parseInt(valueStr) : undefined;

		if (value != null && isNaN(value)) {
			value = undefined;
		}

		const options: DropdownOption<number>[] = cf.options.map(option => {
			return { label: option.label, value: option.id };
		});

		if (!cf.mandatory) {
			options.unshift({ label: "--", value: undefined });
		}

		return (
			<Dropdown<number>
				dataname={cf.name}
				onChange={(key, newValue) => customFieldSubmit(key, newValue != undefined ? String(newValue) : undefined)}
				options={options}
				label={cf.label}
				mandatory={cf.mandatory}
				toolTip={cf.description}
				value={value}
			/>
		);
	} else if (cf.type == CustomFieldInputType.MULTISELECT && cf.options != undefined) {

		const options: DropdownOption<number>[] = cf.options.map(option => {
			return { label: option.label, value: option.id };
		});

		let values: undefined | number[] = undefined;

		if (valueStr != undefined && valueStr != "") {
			values = valueStr.split(",")
				.map(val => parseInt(val))
				.filter(val => !isNaN(val));
		}

		return (
			<MultiSelectDropdown<number>
				dataname={cf.name}
				onChange={(key, newValues) => customFieldSubmit(key, newValues != null && newValues.length > 0 ? newValues.join(",") : undefined)}
				options={options}
				mandatory={cf.mandatory}
				label={cf.label}
				toolTip={cf.description}
				value={values}
			/>
		);
	}
}
