import { useState } from "react";
import { Stack, DialogContent, FormControl, FormLabel } from "@mui/material";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { ApiCreateEntity, ApiUpdateEntity } from "@/legacy/ApiCallerOld";
import { useCache } from "@/Hooks/useCache";
import { CacheSlices } from "@/Store/Reducers/cache/CacheTypes";
import W_MultiEntitySelect from "@/Components/FormComponents/MultiEntitySelect/W_MultiEntitySelect";
import api from "@/Api/Api";
import { Button, ButtonBlue, ButtonRed } from "@/Components/Button/Button";
import { DisplayWidgetModal } from "@/Pages/KB/KbWidgetModal";
import Modal from "@shared/Components/Modal/Modal";
import ModalContent from "@shared/Components/Modal/ModalContent";
import ModalButtons from "@shared/Components/Modal/ModalButtons";
import W_TextInput from "@shared/Components/FormComponents/TextInput/W_TextInput";
import { Kb } from "@shared/Entities/EntityTypes";
import W_Dropdown from "@shared/Components/FormComponents/Dropdown/W_Dropdown";
import { Entities } from "@shared/Entities/Entities";
import { getBase64 } from "@shared/Helpers/OtherFunctions";
import { Tickbox } from "@shared/Components/FormComponents/Tickbox/Tickbox";
import { QuestionTooltip } from "@shared/Components/QuestionTooltip/QuestionTooltip";
import W_TextArea from "@shared/Components/FormComponents/TextArea/W_TextArea";

interface KbModalProps {
	data?: Kb;
	isOpen: boolean;
	closeModal(): void;
	refreshData(): void;
	/** Callback used to select the default KB if the currently one is delete */
	onDelete(): void;
}

export default function KbModal(props: KbModalProps) {
	const { cache, refreshCache } = useCache();

	const [showCategoryDropdown, setShowCategoryDropdown] = useState(props.data?.whitelistedCategoryIds != null && props.data.whitelistedCategoryIds.length > 0);
	const [allowUserSignup, setAllowUserSignup] = useState(props.data?.allowUserSignups ?? true);
	const [allowUserTickets, setAllowUserTickets] = useState(props.data?.allowUserCreatedTickets ?? true);
	const [widgetModalOpen, setWidgetModalOpen] = useState<boolean>(false);

	const editMode = props.data != null;

	const { control, handleSubmit, watch, setValue } = useForm<Kb>({
		defaultValues: {
			isPrivate: false,
			primaryLanguage: "en_GB",
			...props.data
		}
	});

	async function onChangeHandler(event: React.ChangeEvent<HTMLInputElement>, data: "bannerFileData" | "logoFileData" | "faviconFileData", mimeTypeKey: "bannerFileMimetype" | "logoFileMimetype" | "faviconFileMimetype") {
		if (event.target.files != null && event.target.files[0]) {
			const file: Blob = event.target.files[0];

			if (file.size > 16000000) {
				toast.error("Image is too big. Please choose a smaller file (< 16MB).");
			} else {
				const fileDataBase64 = String(await getBase64(file));
				const fileMimeType = file.type;

				if (fileDataBase64 != null && fileMimeType != null) {
					setValue(data, fileDataBase64);
					setValue(mimeTypeKey, fileMimeType);
				}
			}
		}
	}

	async function deleteFile(dataName: "bannerFileData" | "logoFileData" | "faviconFileData", mimeTypeKey: "bannerFileMimetype" | "logoFileMimetype" | "faviconFileMimetype", fileName: "bannerFileName" | "logoFileName" | "faviconFileName") {
		setValue(dataName, undefined);
		setValue(mimeTypeKey, undefined);
		setValue(fileName, undefined);
	}

	async function submitForm(data: Kb) {
		if (data.id > 0) {
			data.allowUserSignups = allowUserSignup;
			data.allowUserCreatedTickets = allowUserTickets;

			const res = await ApiUpdateEntity(Entities.KB, data.id, data);

			if (res.successful) {
				toast.success("Knowledge base updated.");
				props.closeModal();
				await refreshCache();
				props.refreshData();
			} else {
				toast.error("Could not update Knowledge base.");
			}
		} else {
			data.allowUserSignups = allowUserSignup;
			data.allowUserCreatedTickets = allowUserTickets;

			const res = await ApiCreateEntity(Entities.KB, data);

			if (res.successful) {
				toast.success("Knowledge base created.");
				props.closeModal();
				await refreshCache();
				props.refreshData();
			} else {
				toast.error("Could not create a new knowledge base.");
			}
		}
	}

	async function deleteKb() {
		if (props.data == null || props.data.id == 1) {
			return;
		}

		const res = await api.deleteEntity(Entities.KB, props.data.id);

		if (res.successful) {
			toast.success("Knowlege base deleted");

			await refreshCache();
			props.closeModal();
			props.onDelete();
		} else {
			toast.error("Could not delete. " + res.errorMsg);
		}
	}

	const agentAppUrl = window.location.origin;

	const bannerFileName = watch("bannerFileName");
	const bannerFileData = watch("bannerFileData");
	const bannerFileMimetype = watch("bannerFileMimetype");

	const picturesDir = cache.getPicturesDir();

	const bannerUrl = bannerFileName != null ? picturesDir + bannerFileName : undefined;
	const bannerImageLabel = bannerFileName == null && bannerUrl ? "Upload banner image" : "Change banner image";
	const displayBannerImgPreview = (bannerFileData != null && bannerFileMimetype != null) || bannerUrl != null;


	const logoFileName = watch("logoFileName");
	const logoFileData = watch("logoFileData");
	const logoFileMimetype = watch("logoFileMimetype");

	const logoUrl = logoFileName != null ? picturesDir + logoFileName : undefined;
	const logoImageLabel = logoFileName == null && logoUrl ? "Upload logo image" : "Change logo image";
	const displayLogoImgPreview = (logoFileData != null && logoFileMimetype != null) || logoUrl != null;

	const faviconFileName = watch("faviconFileName");
	const faviconFileData = watch("faviconFileData");
	const faviconFileMimetype = watch("faviconFileMimetype");

	const faviconUrl = faviconFileName != null ? picturesDir + faviconFileName : undefined;
	const faviconImageLabel = faviconFileName == null && faviconUrl ? "Upload favicon image" : "Change favicon image";
	const displayFaviconImgPreview = (faviconFileData != null && faviconFileMimetype != null) || faviconUrl != null;

	return (
		<>
			<Modal
				isOpen={props.isOpen}
				onClose={props.closeModal}
				title={editMode ? "Edit knowledge base" : "Create new knowledge base"}
			>

				<ModalContent>
					<div className="pl-2 w-[450px]">
						<Stack spacing={2}>
							<W_TextInput
								control={control}
								dataname="name"
								label="Name"
								mandatory
								autoFocus
							/>

							<W_Dropdown<boolean>
								control={control}
								dataname="isPrivate"
								label="Visibility"
								options={[
									{ label: "Public", value: false },
									{ label: "Private", value: true }
								]}
							/>

							<W_TextArea
								control={control}
								dataname="metaDesc"
								label="Meta description"
								rows={3}
								maxLength={160}
								toolTip="Description of your knowledge base to display on Google."
								placeholder="Find the answers to frequently asked questions. Ask questions and keep up to date with responses with our knowledge base."
							/>

							<FormControl sx={{ gap: "0.5rem" }}>
								<FormLabel>Banner image</FormLabel>

								<DialogContent className="font-medium text-sm pb-4">
									<div>
										For best results, use display height: <span className="font-semibold">200px</span>
									</div>
								</DialogContent>

								{ displayBannerImgPreview &&
									<div className="ratio-[2/1] object-fill">
										<img src={bannerFileData ?? bannerUrl} className=" h-[200px] border" />
									</div>}

								<div className="flex gap-2">
									<label className="btn-grey">
										{bannerImageLabel}

										<input type="file" accept="image/*" onChange={(event) => onChangeHandler(event, "bannerFileData", "bannerFileMimetype")} style={{ display: "none" }} />
									</label>

									{ displayBannerImgPreview &&
										<ButtonRed
											onClick={() => deleteFile("bannerFileData", "bannerFileMimetype", "bannerFileName")}
											label="Remove banner"
										/>}
								</div>
							</FormControl>


							<FormControl sx={{ gap: "0.5rem" }}>
								<FormLabel>Logo image</FormLabel>

								<DialogContent className="font-medium text-sm pb-4">
									<div>
										For best results, use display height: <span className="font-semibold">40px</span>
									</div>
								</DialogContent>

								{ displayLogoImgPreview &&
									<div className="ratio-[5/1] object-fill">
										<img src={logoFileData ?? logoUrl} className="h-[40px] border" />
									</div>}

								<div className="flex gap-2">
									<label className="btn-grey">
										{logoImageLabel}

										<input type="file" accept="image/*" onChange={(event) => onChangeHandler(event, "logoFileData", "logoFileMimetype")} style={{ display: "none" }} />
									</label>

									{ displayLogoImgPreview &&
										<ButtonRed
											onClick={() => deleteFile("logoFileData", "logoFileMimetype", "logoFileName")}
											label="Remove logo"
										/>}
								</div>
							</FormControl>

							<FormControl sx={{ gap: "0.5rem" }}>
								<FormLabel>Favicon image</FormLabel>

								<DialogContent className="font-medium text-sm pb-4">
									<div>
										For best results, use display height: <span className="font-semibold">16px</span>
									</div>
								</DialogContent>

								{ displayFaviconImgPreview &&
									<div className="ratio-[1] object-fill h-[40] w-[40]">
										<img src={faviconFileData ?? faviconUrl} className="w-[30px] h-[30px] border" />
									</div>}

								<div className="flex gap-2">
									<label className="btn-grey">
										{faviconImageLabel}

										<input
											type="file"
											accept="image/*"
											onChange={(event) => onChangeHandler(event, "faviconFileData", "faviconFileMimetype")}
											style={{ display: "none" }}
										/>
									</label>

									{ displayFaviconImgPreview &&
										<ButtonRed
											onClick={() => deleteFile("faviconFileData", "faviconFileMimetype", "faviconFileName")}
											label="Remove favicon"
										/>}
								</div>

							</FormControl>

							<Tickbox
								onChange={() => setShowCategoryDropdown(!showCategoryDropdown)}
								valueName=""
								value={showCategoryDropdown}
								label="Let users select a category when creating a ticket."
							/>

							{showCategoryDropdown &&
								<W_MultiEntitySelect
									control={control}
									dataname="whitelistedCategoryIds"
									cacheSlice={CacheSlices.Categories}
									label="Available categories"
								/>
							}

							<div className="mt-4 p-4 border border-gray-300 rounded-md bg-gray-50">
								<FormLabel component="legend" className="font-medium text-lg mb-4">
									End-user options
								</FormLabel>

								<div className="space-y-3">
									<div className="flex gap-3">
										<Tickbox
											onChange={() => setAllowUserSignup(!allowUserSignup)}
											valueName=""
											value={allowUserSignup}
											label="Allow signups"
											inline
										/>

										<QuestionTooltip tip="Your end-users can sign up to your knowledge base. Once they have done this, they can log tickets, favourite articles and check the status of their existing tickets." />
									</div>

									<div className="flex gap-3">
										<Tickbox
											onChange={() => setAllowUserTickets(!allowUserTickets)}
											valueName=""
											value={allowUserTickets}
											label="Allow ticket creation"
											inline
										/>

										<QuestionTooltip tip="Adds a form to your knowledge base. Users can log tickets here instead of having to email in." />
									</div>
								</div>
							</div>
						</Stack>
					</div>

					{props.data != null &&
						<Button
							onClick={() => setWidgetModalOpen(true)}
							label="Widget code"
							className="mt-5"
						/>}

				</ModalContent>

				<ModalButtons>
					{props.data != null &&
						<ButtonRed
							onClick={deleteKb}
							disabled={props.data.id == 1}
							label="Delete"
						/>}

					<Button
						onClick={props.closeModal}
						label="Cancel"
					/>

					<ButtonBlue
						onClick={() => handleSubmit(submitForm)()}
						label={editMode ? "Update" : "Create"}
					/>
				</ModalButtons>
			</Modal>

			{props.data != null && props.data.id != null &&
				<DisplayWidgetModal
					isOpen={widgetModalOpen}
					close={() => setWidgetModalOpen(false)}
					agentAppUrl={agentAppUrl}
					kbId={props.data.id}
				/>}
		</>
	);
}
