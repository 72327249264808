import { Link } from "react-router-dom";
import { FaArrowRight } from "react-icons/fa";

interface NewFeatureBannerProps {
	title: string;
	link: string;
}

export function NewFeatureBanner(props: NewFeatureBannerProps) {
	return (
		<div className="bg-[#F2F2F3] p-3 flex justify-center">
			<span className="text-[#8C02CD] mr-2">NEW</span>

			{props.title}

			<Link to={props.link} className="ml-2 cursor-pointer underline text-[#8C02CD] flex items-center gap-1">Get started
				<FaArrowRight />
			</Link>
		</div>
	);
}
