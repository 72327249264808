import { configureStore, combineReducers } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { PERSIST, persistReducer, persistStore } from "redux-persist";
import cacheReducer from "@/Store/Reducers/cache/cacheSlice";
import connectionStatusReducer from "./Reducers/connectionStatusSlice";
import globalTabsReducer from "./Reducers/globalTabsSlice";
import loggedInAgentReducer from "./Reducers/loggedInAgentSlice";
import authReducer from "./Reducers/authSlice";
import ticketsPageReducer from "./Reducers/ticketsPageSlice";
import entityTableReducer from "./Reducers/entityTableSlice";
import appReducer from "./Reducers/appSlice";

const ticketsPagePersistConfig = {
	key: "ticketsPage",
	storage,
	blacklist: ["customView"]
};

const rootReducer = {
	auth: authReducer,
	cache: cacheReducer,
	connectionStatus: connectionStatusReducer,
	entityTable: entityTableReducer,
	globalTabs: globalTabsReducer,
	loggedInAgent: loggedInAgentReducer,
	ticketsPage: persistReducer(ticketsPagePersistConfig, ticketsPageReducer),
	app: appReducer
};

const rootPersistConfig = {
	key: "root",
	storage,
	blacklist: ["cache", "ticketsPage"]
};

const persistedReducer = persistReducer(rootPersistConfig, combineReducers(rootReducer));

export const store = configureStore({
	reducer: persistedReducer,
	devTools: true,
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			serializableCheck: {
				ignoredActions: [PERSIST], // Ignores serialisable check when persisting. Required for redux-persist.
			},
		}),
});

export default store;

export const persistor = persistStore(store);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;

// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
