import EntityTable from "../EntityTable";
import { Entities } from "@shared/Entities/Entities";
import { View } from "@shared/Models/View";

interface BasicTableProps {
	entity: Entities;
	view?: View;
}

/**
 * This component exists to take the switch statements out of EntityTable.
 * As individual entities get more logic (e.g. multiselect options) move them into their own table component.
 */
export function BasicTable(props: BasicTableProps) {
	let editLink = undefined;
	switch (props.entity) {
		case Entities.AUTOMATION:
			editLink = "/config/automations/";
			break;
		case Entities.CANNEDREPLY:
			editLink = "/config/manage/cannedreplies/";
			break;
		case Entities.SLA:
			editLink = "/config/manage/slas/";
			break;
	}

	// Warning models to stop any accidental Deletes.
	let multiDeleteWarningMsg = undefined;
	switch (props.entity) {
		case Entities.AUTOMATION:
			multiDeleteWarningMsg = "This will permanently delete these automations.";
			break;
		case Entities.CANNEDREPLY:
			multiDeleteWarningMsg = "This will permanently delete these canned replies.";
			break;
		case Entities.SLA:
			multiDeleteWarningMsg = "This will remove these SLAs from any tickets using them, leaving them without an SLA.";
			break;
	}

	let newLink = undefined;
	switch (props.entity) {
		case Entities.AUTOMATION:
			newLink = "/config/automations/-1";
			break;
		case Entities.CANNEDREPLY:
			newLink = "/config/manage/cannedreplies/-1";
			break;
		case Entities.SLA:
			newLink = "/config/manage/slas/new";
			break;
	}

	return (
		<EntityTable
			entity={props.entity}
			view={props.view}
			multiDeleteWarningMsg={multiDeleteWarningMsg}
			newEntityUrl={newLink}
			editEntityUrl={editLink}
			disableEditButton={props.entity == Entities.CATEGORY}
		/>
	);
}
