
// This enum has strings set so EntityTableFunctions.generateColumnDefs() can use it in an associative array.
export enum Entities {
	ACTION = "ACTION",
	AGENT = "AGENT",
	ATTACHMENT = "ATTACHMENT",
	AUTOMATION = "AUTOMATION",
	CANNEDREPLY = "CANNEDREPLY",
	CATEGORY = "CATEGORY",
	CHANNELSEMAIL = "CHANNELSEMAIL",
	COMPANY = "COMPANY",
	COMPANY_NOTE = "COMPANY_NOTE",
	EMAILNOTIFICATION = "EMAILNOTIFICATION",
	KB = "KB",
	KBARTICLE = "KBARTICLE",
	KBFOLDER = "KBFOLDER",
	SLA = "SLA",
	TEAM = "TEAM",
	TICKET = "TICKET",
	USER = "USER",
	VIEW = "VIEW",

	// Plugins

}
