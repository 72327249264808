import { ReactNode } from "react";
import { TextField, TextFieldProps } from "@mui/material";
import { Except } from "type-fest";

export type NumberFieldProps = Except<TextFieldProps, "name"> & {
	value: number;
	onChange(newValue: number | null): void;
	startAdornment?: ReactNode;
	endAdornment?: ReactNode;
	min?: number;
	max?: number;
};

export function NumberField(props: NumberFieldProps) {

	function onChange(event: React.ChangeEvent<HTMLInputElement>) {
		if (event.target.value == "") {
			props.onChange(null);
		} else {
			const value = Number(event.target.value);

			if (Number.isNaN(value)) {
				// Change ignored.
				return;
			}

			if (props.min != null && value <= props.min) {
				props.onChange(props.min);
			} else if (props.max != null && value >= props.max) {
				props.onChange(props.max);
			} else {
				props.onChange(value);
			}
		}
	}

	// Remove uneeded props.
	const propsCopy = { ...props };
	delete propsCopy.startAdornment;
	delete propsCopy.endAdornment;

	return (
		<TextField
			{...propsCopy}
			onChange={onChange}
			value={props.value ?? ""}
			slotProps={{
				htmlInput: {
					pattern: "[0-9]*",
					max: 1000
				},
				input: {
					startAdornment: props.startAdornment,
					endAdornment: props.endAdornment,
				}
			}}
		/>
	);
}
