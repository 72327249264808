import { ReactNode } from "react";
import { CircularProgress } from "@mui/material";
import { UseQueryResult } from "@tanstack/react-query";
import { Error404 } from "@/Components/Errors/Error404";
import { ErrorPayload } from "@shared/Api/ApiResponses";

interface EntityReqErrorWrapperProps<T> {
	res: UseQueryResult<T, ErrorPayload>;
	render(data: T): ReactNode;
}

export function EntityReqErrorWrapper<T>(props: EntityReqErrorWrapperProps<T>) {
	if (props.res.isLoading) {
		return <CircularProgress />;
	}

	if (props.res.isError) {
		// TODO: Could have a generic error box to use.
		return (
			<div>
				Could not load.<br />
				{props.res.error.errorMsg}
			</div>
		);
	}

	if (props.res.data == null) {
		return <Error404 />;
	}

	return props.render(props.res.data);
}
