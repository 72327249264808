import { MdOutlineErrorOutline } from "react-icons/md";
import { Button } from "@/Components/Button/Button";

export function NoCacheError() {
	return (
		<div className="mx-auto w-72 relative top-1/4 bg-white py-10 px-5 border border-gray-300 rounded">
			<div className="text-5xl text-center text-gray-700 mb-5">
				<MdOutlineErrorOutline />
			</div>
			<div>
				GoDesk could not fetch the cache.
				<br /><br />
				Try refreshing the tab or contact support if the error persists.
				<br /><br />
				<div className="flex justify-center">
					<Button
						label="Refresh"
						onClick={() => location.reload()}
					/>
				</div>
			</div>
		</div>
	);
}
