import { ReactNode } from "react";
import Box from "@mui/material/Box";

interface KeysProps {
	keys: ReactNode[];
	label: string;
}

export default function Keys(props: KeysProps) {
	const keys: ReactNode[] = props.keys.map((key, index) => {
		if (key === "then") {
			return (
				<span key={index} className="inline-block">
					then
				</span>
			);
		} else {
			return <span key={index}>{key}</span>;
		}
	});

	return (
		<Box className="inline-flex gap-1 align-top">
			<span className="mr-1">
				{props.label}
			</span>
			{keys}
		</Box>
	);
}
