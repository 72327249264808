import { useState } from "react";
import { SiZendesk } from "react-icons/si";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { Box, Alert, Checkbox, FormControlLabel } from "@mui/material";
import { ResCreate } from "@/legacy/ApiResponse";
import { Button, ButtonBlue } from "@/Components/Button/Button";
import { ImportProgress } from "./ImportsModal/StepperProgress";
import { ZendeskAccount, ZendeskCreds, ZendeskImportReq } from "./ImportsModal/ZendeskDTOs";
import OtherFunctions from "../../../Helpers/OtherFunctions";
import apiCallerImporter from "../../../Api/ApiCallerImporter";
import { InputTypes } from "@shared/Components/FormComponents/TextInput/InputTypes";
import W_TextInput from "@shared/Components/FormComponents/TextInput/W_TextInput";
import Modal from "@shared/Components/Modal/Modal";
import ModalContent from "@shared/Components/Modal/ModalContent";

interface ImportsModalProps {
	isOpen: boolean;
	onClose(): void;
}

export function ImportsModal(props: ImportsModalProps) {
	/*
	0 = Home
	1 = Info
	2 = Creds
	3 = Estimate
	4 = Progress
	5 = Complete
	*/
	const [page, setPage] = useState(0);

	const [zendeskCreds, setZendeskCreds] = useState<ZendeskCreds | undefined>(undefined);

	let pageDesc = "";
	let currentPage = null;

	function completeCredsPage(creds: ZendeskCreds) {
		setZendeskCreds(creds);
		setPage(3);
	}

	function close() {
		setPage(0);
		props.onClose();
	}

	switch (page) {
		case 0:
			pageDesc = "Choose a service to migrate from.";
			currentPage = <PageHome nextPage={() => setPage(1)} onClose={close} />;
			break;
		case 1:
			pageDesc = "Pre-import information";
			currentPage = <PageInfo nextPage={() => setPage(2)} onClose={close} />;
			break;
		case 2:
			pageDesc = "Please input your Zendesk credentials.";
			currentPage = <PageCreds nextPage={completeCredsPage} onClose={close} />;
			break;
		case 3:
			pageDesc = "Please select which Zendesk entities you want to migrate.";
			if (zendeskCreds != null) {
				currentPage = <PageDataSelection creds={zendeskCreds} nextPage={() => setPage(4)} onClose={close} />;
			}
			break;
		case 4:
			pageDesc = "Importing...";
			currentPage = <PageProgress />;
			break;
		case 5:
			pageDesc = "Import completed.";
			currentPage = <PageCompleted />;
			break;

		default:
			break;
	}

	return (
		<Modal isOpen={props.isOpen} onClose={close} title="Importer" className="">
			<ModalContent>
				<div className="w-[500px]">
					<div className="pb-5">{pageDesc}</div>

					{page > 0 &&
						<Box className="">
							<ImportProgress page={page} />
						</Box>}

					{currentPage}
				</div>
			</ModalContent>
		</Modal>
	);
}

interface PageHomeProps {
	nextPage(): void;
	onClose(): void;
}

function PageHome(props: PageHomeProps) {
	return (
		<>
			<Box>
				<button
					className="w-32 h-32 text-center flex flex-col items-center justify-center text-blue-900 bg-blue-100 rounded"
					onClick={props.nextPage}
				>
					<SiZendesk className="mb-3 text-4xl" />
					Zendesk
				</button>
			</Box>
		</>
	);
}

function PageInfo(props: PageHomeProps) {
	return (
		<>
			<Alert sx={{ marginTop: 2, marginBottom: 2 }} severity="warning">
				<div>
					Before importing, ensure each Zendesk agent has a matching GoDesk agent <b>with the same email</b>.
					<br /><br />
					If there's no match, a new GoDesk agent will be created for each unmatched Zendesk agent.
				</div>
			</Alert>

			<div className=" flex flex-row-reverse gap-4">
				<ButtonBlue onClick={props.nextPage} label="Continue" />

				<Button onClick={props.onClose} label="Cancel" />
			</div>
		</>
	);
}

interface PageCredsProps {
	nextPage(creds: ZendeskCreds): void;
	onClose(): void;
}

function PageCreds(props: PageCredsProps) {
	const { handleSubmit, reset, setError, control } = useForm<ZendeskCreds>({
		defaultValues: { url: "", email: "", token: "" }
	});

	const [formError, setFormError] = useState<string | null>(null);
	const [reqInProgress, setReqInProgress] = useState(false);

	function cancel() {
		reset();
		props.onClose();
	}

	async function onSubmit(data: ZendeskCreds) {
		setFormError(null);
		setReqInProgress(true);

		const res = await apiCallerImporter.BasicPost("/zendesk/check-account", data) as ResCreate<ZendeskAccount>;

		setReqInProgress(false);

		if (res.successful) {
			reset();
			props.nextPage(data);
		} else if (res.fieldErrors != null) {
			OtherFunctions.setFieldErrors(setError, res.fieldErrors);
		} else if (res.errorMsg != null) {
			setFormError(res.errorMsg);
		} else {
			setFormError("Could not validate credentials.");
		}
	}

	return (
		<>
			{formError &&
				<Alert severity="error">{formError}</Alert>}

			<form onSubmit={handleSubmit(onSubmit)}>
				<Box className="my-4">
					<W_TextInput
						control={control}
						mandatory
						dataname="url"
						type={InputTypes.Url}
						label="Zendesk URL"
						toolTip="The URL you use to access your Zendesk instance."
						placeholder="https://your-company.zendesk.com"
					/>

					<W_TextInput
						control={control}
						mandatory
						dataname="email"
						type={InputTypes.Email}
						label="Zendesk admin email"
						toolTip="The email address of your Zendesk admin account."
						placeholder="admin@your-company.com"
					/>

					<W_TextInput
						control={control}
						mandatory
						dataname="token"
						label="Zendesk access token"
						toolTip={"Your Zendesk access token. See guide for how to create this."}
						placeholder="GcAK3Lg84pxCawSumJvE7tzPdF5yBNZfsMXHUqbk"
					/>
				</Box>

				<div className=" flex flex-row-reverse gap-4">
					<input type="submit" className="btn-blue" value="Continue" disabled={reqInProgress} />

					<Button onClick={cancel} label="Cancel" />
				</div>
			</form>
		</>
	);
}

interface PageDataSelectionProps {
	creds: ZendeskCreds;
	nextPage(): void;
	onClose(): void;
}

function PageDataSelection(props: PageDataSelectionProps) {
	const [reqInProgress, setReqInProgress] = useState(false);

	const [importTickets, setImportTickets] = useState(true);
	const [importLast6mTicketsOnly, setImportLast6mTicketsOnly] = useState(true);
	const [importAttachments, setImportAttachments] = useState(true);
	const [importKbArticles, setImportKbArticles] = useState(true);

	async function submit() {
		const importReq: ZendeskImportReq = {
			creds: props.creds,
			importTickets: importTickets,
			importLast6mTicketsOnly: importLast6mTicketsOnly,
			importAttachments: importAttachments,
			importKbArticles: importKbArticles
		};

		setReqInProgress(true);
		const res = await apiCallerImporter.BasicPost("/zendesk/import", importReq) as ResCreate<boolean>;
		setReqInProgress(false);

		if (res.successful && res.data == true) {
			props.nextPage();
		} else if (res.errorMsg != null) {
			toast.error(res.errorMsg);
		} else {
			toast.error("Unknown error.");
		}
	}

	return (
		<>
			<Box className="my-10">
				<FormControlLabel
					control={
						<Checkbox
							checked={importTickets}
							onChange={event => setImportTickets(event.target.checked)}
						/>
					}
					label="Tickets"
				/>

				<Box className="ml-7 flex flex-col">
					<FormControlLabel
						control={
							<Checkbox
								checked={importTickets && importAttachments}
								onChange={event => {
									if (event.target.checked) {
										setImportAttachments(true);
										setImportTickets(true);
									} else {
										setImportAttachments(false);
									}
								}}
							/>
						}
						label="Attachments"
					/>

					<FormControlLabel
						control={
							<Checkbox
								checked={importTickets}
								disabled
							/>
						}
						label="Groups"
					/>

					<FormControlLabel
						control={
							<Checkbox
								checked={importTickets}
								disabled
							/>
						}
						label="Agents"
					/>

					<FormControlLabel
						control={
							<Checkbox
								checked={importTickets}
								disabled
							/>
						}
						label="Organizations"
					/>

					<FormControlLabel
						control={
							<Checkbox
								checked={importTickets}
								disabled
							/>
						}
						label="Users"
					/>
				</Box>


				<Box className="flex flex-col">
					<FormControlLabel
						control={
							<Checkbox
								checked={importKbArticles}
								onChange={event => setImportKbArticles(event.target.checked)}
							/>
						}
						label="KB Articles"
					/>

					<Box className="ml-7 flex flex-col">
						<FormControlLabel
							control={
								<Checkbox
									checked={importKbArticles}
									disabled
								/>
							}
							label="KB Folders"
						/>
					</Box>
				</Box>

				<FormControlLabel
					control={
						<Checkbox
							checked={importLast6mTicketsOnly}
							onChange={event => setImportLast6mTicketsOnly(event.target.checked)}
						/>
					}
					label="Only import last 6 months of tickets"
				/>
			</Box>

			<div className=" flex flex-row-reverse gap-4">
				<ButtonBlue onClick={submit} disabled={reqInProgress} label="Continue" />

				<Button onClick={props.onClose} label="Cancel" />
			</div>
		</>
	);
}

function PageProgress() {

	return (
		<div className="mt-5">
			We loadin'...
		</div>
	);
}

function PageCompleted() {

	return (
		<div>

		</div>
	);
}
