import { Toaster as ToasterHot } from "react-hot-toast";
import { BsInfoCircleFill } from "react-icons/bs";

export function Toaster() {
	return (
		<ToasterHot
			position="top-right"
		/>
	);
}

export const InfoToast = {
	icon: <BsInfoCircleFill style={{ color: "#2684ff", fontSize: "1.5em", flexShrink: "0" }} />
};
