import { useEffect, useState } from "react";
import MultiSelectUserEmail from "../../../../Components/FormComponents/MultiSelectUserEmail/MultiSelectUserEmail";
import DeleteWarningModal from "../../../../Components/Utility/Modals/DeleteWarningModal";
import { Ticket } from "@shared/Entities/EntityTypes";

interface TicketEmailCCSectionProps {
	ticket: Ticket;
	handleSubmit(key: string, newValue: any): void;
}

export function TicketEmailCCSection(props: TicketEmailCCSectionProps) {
	const [showBcc, setShowBcc] = useState(props.ticket.bcc != undefined && props.ticket.bcc.length > 0);
	const [showCc, setShowCc] = useState(props.ticket.cc != undefined && props.ticket.cc.length > 0);
	const [showCcDeleteModal, setShowCcDeleteModal] = useState(false);
	const [showBccDeleteModal, setShowBccDeleteModal] = useState(false);

	useEffect(() => {
		setShowCc(props.ticket.cc != undefined && props.ticket.cc.length > 0);
		setShowBcc(props.ticket.bcc != undefined && props.ticket.bcc.length > 0);
	}, [props.ticket.cc, props.ticket.bcc]);

	function toggleCC() {
		if (!showCc) {
			setShowCc(true);
		} else {
			// Don't display the confirmation modal if we've gone from 0 emails to 0 emails.
			if (props.ticket.cc != undefined && props.ticket.cc.length > 0) {
				setShowCcDeleteModal(true);
			} else {
				setShowCc(false);
			}
		}
	}

	function deleteCc() {
		props.handleSubmit("cc", []);
		setShowCcDeleteModal(false);
		setShowCc(false);
	}

	function toggleBcc() {
		if (!showBcc) {
			setShowBcc(true);
		} else {
			// Don't display the confirmation modal if we've gone from 0 emails to 0 emails.
			if (props.ticket.bcc != undefined && props.ticket.bcc.length > 0) {
				setShowBccDeleteModal(true);
			} else {
				setShowBcc(false);
			}
		}
	}

	function deleteBcc() {
		props.handleSubmit("bcc", []);
		setShowBccDeleteModal(false);
		setShowBcc(false);
	}

	return (
		<div className="border-t border-gray-300 p-3">
			<div className="flex">
				<button
					type="button"
					onClick={() => toggleCC()}
					className={"btn-grey font-bold block mb-1.5 mx-1 w-14 text-sm tracking-wider " + (showCc && "bg-gray-300 hover:bg-gray-300")}
				>
					CC
				</button>

				<button
					type="button"
					onClick={() => toggleBcc()}
					className={"btn-grey font-bold block mb-1.5 mx-1 w-14 text-sm tracking-wider " + (showBcc && "bg-gray-300 hover:bg-gray-300")}
				>
					BCC
				</button>
			</div>

			{showCc &&
				<MultiSelectUserEmail
					className="pt-2"
					label="CC"
					setValue={(newValue) => props.handleSubmit("cc", newValue)}
					value={props.ticket.cc ?? []}
				/>
			}

			{showBcc &&
				<MultiSelectUserEmail
					className="pt-2"
					label="BCC"
					setValue={(newValue) => props.handleSubmit("bcc", newValue)}
					value={props.ticket.bcc ?? []}
				/>
			}

			<DeleteWarningModal isOpen={showCcDeleteModal} onDelete={deleteCc} onCancel={() => setShowCcDeleteModal(false)} message="Are you sure you want to remove the CC list?" />
			<DeleteWarningModal isOpen={showBccDeleteModal} onDelete={deleteBcc} onCancel={() => setShowBccDeleteModal(false)} message="Are you sure you want to remove the BCC list?" />
		</div>
	);
}
