import { FormEvent } from "react";
import { Alert } from "@mui/material";
import { PasswordResetContainer } from "@/Pages/Auth/PasswordReset/PasswordResetContainer";
import { useCreatePasswordResetToken } from "@/Api/genApi";

export default function PasswordResetRequest() {
	const createPasswordResetTokenReq = useCreatePasswordResetToken();

	async function handleResetRequest(event: FormEvent) {
		event.preventDefault();

		const email = (document.getElementById("emailInput") as HTMLInputElement).value;

		createPasswordResetTokenReq.mutate({ params: { email: email } });
	}

	const formDisabled = createPasswordResetTokenReq.isPending || createPasswordResetTokenReq.isSuccess;

	return (
		<PasswordResetContainer>
			{createPasswordResetTokenReq.error != null &&
				<Alert severity="error">
					{parseError(createPasswordResetTokenReq.error.errorMsg)}
				</Alert>}

			{createPasswordResetTokenReq.isSuccess &&
				<Alert severity="success">
					Reset email sent. Please check your emails.
				</Alert>}

			<form className="" onSubmit={handleResetRequest}>
				<label htmlFor="emailInput">Current email</label>
				<input
					id="emailInput"
					type="email"
					placeholder="me@company.com"
					disabled={formDisabled}
					className="mt-1 block w-full shadow-sm border-gray-300 rounded-md py-2 px-3 border focus:border-blue-500 mb-4"
					required
				/>

				<div className="w-full flex flex-row justify-center">
					<input
						className="btn-blue mt-4 cursor-pointer"
						type="submit"
						value="Send"
						disabled={formDisabled}
					/>
				</div>
			</form>
		</PasswordResetContainer>
	);
}

function parseError(errorMsg: string): string {
	if (errorMsg == "notfound") {
		return "Could not find relevent user.";
	} else if (errorMsg == "couldnotsendemail") {
		return "Could not send password reset email. Please contact your GoDesk admin.";
	} else {
		return "Unknown error.";
	}
}
