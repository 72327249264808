import { Control, Controller } from "react-hook-form";
import type { Except } from "type-fest";
import Dropdown, { DropdownProps } from "./Dropdown";
import { ControlError } from "@shared/Components/FormComponents/ControlError";

interface W_DropdownProps<T> extends Except<DropdownProps<T>, "value" | "onChange"> {
	control: Control<any>;
}

/**
 * @see W_TextInput
 */
export default function W_Dropdown<T>(props: W_DropdownProps<T>) {
	const rules: any = {}; // https://react-hook-form.com/api/useform/register

	if (props.mandatory) {
		rules.required = { value: true, message: "This is a required field." };
	}

	return (
		<Controller
			control={props.control}
			name={props.dataname}
			rules={rules}
			render={({
				field: { onChange, value },
				fieldState: { error },
			}) => (
				<>
					<Dropdown<T>
						{...props}
						invalid={error != null}
						onChange={(_key, value) => {onChange(value);} }
						value={value}
					/>

					<ControlError error={error?.message} />
				</>
			)}
		/>
	);
}
