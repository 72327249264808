import { useState } from "react";
import { Alert, CircularProgress } from "@mui/material";
import { useGlobalSearch } from "@/Api/genApi";
import { SearchPageTab } from "@/Pages/SearchPage/SearchPageTab";
import SearchResultGroup from "@/Pages/SearchPage/SearchResultGroup";
import { SearchPageTabs } from "@shared/Enums/Enums";

interface SearchPageResultsProps {
	query: string;
}

export function SearchPageResults(props: SearchPageResultsProps) {
	const searchReq = useGlobalSearch({ query: props.query }, { query: {
		staleTime: Infinity
	} });

	const [activeTab, setActiveTab] = useState<SearchPageTabs>();

	if (searchReq.isPending) {
		return (
			<div className="mt-5">
				<CircularProgress />
			</div>
		);
	}

	if (searchReq.error) {
		return (
			<div className="mt-5">
				<Alert severity="error">
					Could not load search results.
				</Alert>
			</div>
		);
	}

	return (
		<>
			<div className="border-b border-gray-300 flex justify-between">
				<div className="mt-3">
					{searchReq.data.totalResultCount} results found in {searchReq.data.timeTakenMs} ms.
				</div>
				<div>
					<SearchPageTab
						tabType={SearchPageTabs.TICKET}
						activeTab={activeTab}
						ticketCount={searchReq.data.ticketsPagination?.total ?? 0}
						onClick={setActiveTab}
					/>
					<SearchPageTab
						tabType={SearchPageTabs.USER}
						activeTab={activeTab}
						ticketCount={searchReq.data.usersPagination?.total ?? 0}
						onClick={setActiveTab}
					/>
					<SearchPageTab
						tabType={SearchPageTabs.COMPANY}
						activeTab={activeTab}
						ticketCount={searchReq.data.companiesPagination?.total ?? 0}
						onClick={setActiveTab}
					/>
					<SearchPageTab
						tabType={SearchPageTabs.AGENT}
						activeTab={activeTab}
						ticketCount={searchReq.data.agentsPagination?.total ?? 0}
						onClick={setActiveTab}
					/>
					<SearchPageTab
						tabType={SearchPageTabs.KBARTICLE}
						activeTab={activeTab}
						ticketCount={searchReq.data.articlesPagination?.total ?? 0}
						onClick={setActiveTab}
					/>
				</div>
			</div>

			<div>
				{
					activeTab != null ?
						<SearchResultGroup defaultData={searchReq.data} searchTerm={props.query} tabType={activeTab} />
						:
						<>
							<SearchResultGroup defaultData={searchReq.data} searchTerm={props.query} tabType={SearchPageTabs.TICKET} />
							<SearchResultGroup defaultData={searchReq.data} searchTerm={props.query} tabType={SearchPageTabs.USER} />
							<SearchResultGroup defaultData={searchReq.data} searchTerm={props.query} tabType={SearchPageTabs.COMPANY} />
							<SearchResultGroup defaultData={searchReq.data} searchTerm={props.query} tabType={SearchPageTabs.AGENT} />
							<SearchResultGroup defaultData={searchReq.data} searchTerm={props.query} tabType={SearchPageTabs.KBARTICLE} />
						</>
				}
			</div>
		</>
	);
}
