import { memo } from "react";
import { AiOutlineUser } from "react-icons/ai";
import Box from "@mui/material/Box";
import { CompanyAvatar } from "@/Components/Avatar/CompanyAvatar";
import { Company } from "@shared/Entities/EntityTypes";

interface CompanyListItemProps {
	company: Company;
	onSelect(id: number): void;
	active?: boolean;
}

export const CompanyListItem = memo(function CompanyListItem(props: CompanyListItemProps) {
	function onClick() {
		props.onSelect(props.company.id);
	}

	return (
		<Box
			onClick={onClick}
			className={`flex p-2 border-b border-gray-300 w-full cursor-pointer ${props.active ? "bg-gray-100" : "bg-white"}`}
		>
			<div>
				<CompanyAvatar widthPx={30} company={props.company} />
			</div>
			<div className="flex-grow mx-2 my-auto text-left">
				{props.company.name}
			</div>
			<div className="my-auto flex-shrink-0 tabular-nums" title="Active users">
				<AiOutlineUser /> {props.company.userCount}
			</div>
		</Box>
	);
});
