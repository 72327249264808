import { useRef, useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { GlobalTab, getGlobalTabUrlPath, HeaderTabEntities } from "@/Components/Header/GlobalTab";
import NewTicketModal from "@/Components/Utility/Modals/NewTicketModal/NewTicketModal";
import { useAppDispatch, useAppSelector } from "../../Store/hooks";
import { globalTabRemoved, allGlobalTabsReplaced } from "../../Store/Reducers/globalTabsSlice";
import HeaderTab from "./HeaderTab";
import { Hotkeys } from "@shared/lib/Hotkeys";

export default function HeaderTabs() {
	const tabsState = useAppSelector(state => state.globalTabs);
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const scrollDivRef = useRef<HTMLDivElement>(null);

	const [newTicketModalIsOpen, setNewTicketModalIsOpen] = useState(false);

	useEffect(() => {
		// Reset all position and translation attributes when recieving a new tabsState.
		tabsState.tabs.forEach((tab) => {
			const tabElement = document.getElementById(getGlobalTabHtmlId(tab));

			if (tabElement != null) {
				tabElement.style.left = "0";
				tabElement.style.transform = "";
			}
		});
	}, [tabsState]);

	const [tabRightInitialPositions, setTabRightInitialPositions] = useState<number[]>([]);
	const [tabLeftInitialPositions, setTabLeftInitialPositions] = useState<number[]>([]);
	const [draggedTabInitialCentre, setDraggedTabInitialCentre] = useState<number>(0);
	const [tabWidth, setTabWidth] = useState<number>(0);

	function getElementHorizontalCentre(elem: HTMLElement) {
		return elem.getBoundingClientRect().left + ((elem.getBoundingClientRect().right - elem.getBoundingClientRect().left) / 2);
	}

	function getGlobalTabHtmlId(tab: GlobalTab): string {
		return "GlobalTab" + tab.entity + tab.id;
	}

	/**
	 * Called at the start of a tab drag. Sets the vars required for handleTabDrag() to do efficient calculations.
	 */
	function handleTabStart(tabId: string) {
		const draggedTab = document.getElementById(tabId);

		if (draggedTab != null) {
			setDraggedTabInitialCentre(getElementHorizontalCentre(draggedTab));
			setTabWidth(draggedTab.getBoundingClientRect().right - draggedTab.getBoundingClientRect().left);

			const tabRights: number[] = [];
			const tabLefts: number[] = [];

			tabsState.tabs.forEach((tab) => {
				const elm = document.getElementById(getGlobalTabHtmlId(tab));
				if (elm != null) {
					tabRights.push(elm.getBoundingClientRect().right);
					tabLefts.push(elm.getBoundingClientRect().left);
				}
			});

			setTabRightInitialPositions(tabRights);
			setTabLeftInitialPositions(tabLefts);
		}
	}

	function handleTabDrag(tabId: string) {
		const draggedTab = document.getElementById(tabId);

		if (draggedTab != null) {
			const draggedTabCentre = getElementHorizontalCentre(draggedTab);

			if (draggedTabCentre < draggedTabInitialCentre) { // Moved left
				for (let x = 0; x < tabsState.tabs.length; x++) {
					if (getGlobalTabHtmlId(tabsState.tabs[x]) == tabId) {
						break; // Is the tab being dragged
					} else {
						const tabElement = document.getElementById(getGlobalTabHtmlId(tabsState.tabs[x]));
						if (tabElement != null) {
							if (draggedTabCentre < tabRightInitialPositions[x]) {
								tabElement.style.left = tabWidth + "px";
							} else {
								tabElement.style.left = "0";
							}
						}
					}
				}
			} else if (draggedTabCentre > draggedTabInitialCentre) {
				for (let x = tabsState.tabs.length - 1; x >= 0; x--) { // Right to left
					if (getGlobalTabHtmlId(tabsState.tabs[x]) == tabId) {
						break; // Is the tab being dragged
					} else {
						const tabElement = document.getElementById(getGlobalTabHtmlId(tabsState.tabs[x]));
						if (tabElement != null) {
							if (draggedTabCentre > tabLeftInitialPositions[x]) {
								tabElement.style.left = "-" + tabWidth + "px";
							} else {
								tabElement.style.left = "0";
							}
						}
					}
				}
			}
		}
	}

	function handleTabStop(tabId: string) {
		const draggedTab = document.getElementById(tabId);

		if (draggedTab != null) {
			const draggedTabCentre = getElementHorizontalCentre(draggedTab);

			let currArrayPos;
			let newArrayPos; // Default to end of the array.

			for (let x = 0; x < tabsState.tabs.length; x++) {
				if (getGlobalTabHtmlId(tabsState.tabs[x]) == tabId) {
					currArrayPos = x;
				}

				if (newArrayPos == null && draggedTabCentre < tabRightInitialPositions[x]) { // If to the left of this tab and hasn't been set yet.
					// Tab dragged to the left of this one.
					newArrayPos = x;
				}
			}

			if (currArrayPos != null && newArrayPos != null) {
				const newTabs = tabsState.tabs.slice();
				arraymove(newTabs, currArrayPos, newArrayPos);
				dispatch(allGlobalTabsReplaced(newTabs));
			}
		}
	}

	function handleTabRemove(tab: GlobalTab) {
		const urlPath = getGlobalTabUrlPath(tab);

		if (window.location.pathname == urlPath) {
			navigate(getNewUrlAfterTabClose(tabsState.tabs, tab));
		}

		// Remove the tab from the state once the url has been changed.
		dispatch(globalTabRemoved(tab));
	}

	function arraymove(arr: unknown[], fromIndex: number, toIndex: number) {
		const element = arr[fromIndex];
		arr.splice(fromIndex, 1);
		arr.splice(toIndex, 0, element);
	}

	function onMouseDown(event: React.MouseEvent) {
		if (event.button == 1) { // Middle mouse button.
			event.preventDefault(); // Prevent middle mouse scroll disrupting HeaderTab closing.
		}
	}

	return (
		<div
			onWheel={(event) => {
				event.deltaX = event.deltaY;

				if (scrollDivRef.current != null) {
					scrollDivRef.current.scroll({ left: scrollDivRef.current.scrollLeft + event.deltaY / 3, behavior: "instant" });
					// scrollDivRef.current.scrollLeft += event.deltaY / 3;
				}
			}}
			className="flex flex-grow overflow-x-auto "
			onMouseDown={onMouseDown}
			ref={scrollDivRef}
		>
			{tabsState.tabs.map(tab =>
				<HeaderTab
					key={String(tab.entity) + tab.id}
					tab={tab}
					handleTabStart={handleTabStart}
					handleTabDrag={handleTabDrag}
					handleTabStop={handleTabStop}
					handleTabRemove={handleTabRemove}
				/>
			)}
			<button onClick={() => setNewTicketModalIsOpen(true)} className="inline-flex relative bg-white hover:bg-gray-50 active:bg-gray-100 border-b border-r h-14 w-11 items-center justify-center text-xl shrink-0 my-auto transition-colors">
				<AiOutlinePlusCircle />
			</button>

			<NewTicketModal isOpen={newTicketModalIsOpen} onClose={() => setNewTicketModalIsOpen(false)} />
			<Hotkeys callback={() => setNewTicketModalIsOpen(true)} hotkeys="n" />
		</div>
	);
}

export function getNewUrlAfterTabClose(tabsState: GlobalTab[], tabToClose: GlobalTab): string {
	// Shift URL to the tab to the left of this. Or the last opened list if there are no more tabs.
	// Should probably be handled somewhere else but I don't see a problem with doing it here - FOR NOW!
	if (tabsState.length == 1) { // Closed tab is the last one.
		return "/tickets";
	} else {
		const closedTabPos = tabsState.findIndex(tab => tab.id == tabToClose.id && tab.entity == tabToClose.entity);
		let newTab: GlobalTab;
		if (closedTabPos > 0) { // Shift left.
			newTab = tabsState[closedTabPos - 1];
		} else { // Shift right
			newTab = tabsState[closedTabPos + 1];
		}

		if (newTab != null) {
			switch (newTab.entity) {
				case HeaderTabEntities.TICKETS:
					return "/tickets/" + newTab.id;
				case HeaderTabEntities.USERS:
					return "/users/" + newTab.id;
			}
		}
	}

	return "/home";
}
