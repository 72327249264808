import GenericListPage from "../_GenericListPage";
import { Entities } from "@shared/Entities/Entities";

export default function ManageSlas() {
	return (
		<GenericListPage
			urlTitle="SLAs | Config"
			title="Service Level Agreements"
			description="SLAs define how quickly you will respond to and resolve your user's tickets."
			entityType={Entities.SLA}
		/>
	);
}
