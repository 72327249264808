import { FormEvent, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Alert, CircularProgress } from "@mui/material";
import toast from "react-hot-toast";
import { useCheckTokenIsValid, useRedeemPasswordResetToken } from "@/Api/genApi";
import { PasswordResetContainer } from "@/Pages/Auth/PasswordReset/PasswordResetContainer";
import { validatePassword } from "@shared/Helpers/OtherFunctions";

interface PasswordResetRedeemProps {
	token: string;
}

export function PasswordResetRedeem(props: PasswordResetRedeemProps) {
	const navigate = useNavigate();
	const [passwordErrorMsg, setPasswordErrorMsg] = useState<string | null>(null);

	const tokenIsValidReq = useCheckTokenIsValid({ token: props.token }, { query: {
		retry: false
	} });

	const redeemTokenReq = useRedeemPasswordResetToken();

	if (tokenIsValidReq.isPending) {
		return (
			<PasswordResetContainer>
				<CircularProgress />
			</PasswordResetContainer>
		);
	}

	if (tokenIsValidReq.error != null) {
		let errorMsg = "Unknown error.";

		if (tokenIsValidReq.error.errorMsg == "invalidToken") {
			errorMsg = "Invalid token.";
		} else if (tokenIsValidReq.error.errorMsg == "expired") {
			errorMsg = "The reset link has expired.";
		}

		return (
			<PasswordResetContainer>
				<Alert severity="error">
					{errorMsg} Please request a <Link to="/password-reset">new password reset</Link>.
				</Alert>
			</PasswordResetContainer>
		);
	}

	async function handlePasswordUpdate(event: FormEvent) {
		event.preventDefault();

		const password = (document.getElementById("newPasswordInput") as HTMLInputElement).value;
		const confirmPassword = (document.getElementById("confirmPasswordInput") as HTMLInputElement).value;

		const isValidPassMsg = validatePassword(password, confirmPassword);

		if (isValidPassMsg != null) {
			setPasswordErrorMsg(isValidPassMsg);
			return;
		} else {
			setPasswordErrorMsg(null);
		}

		console.log("password: " + password);

		redeemTokenReq.mutate({ params: { token: props.token }, data: { password: password } }, {
			onSuccess: () => {
				toast.success("Password changed!", { duration: 2000 });
				navigate("/login");
			}
		});
	}

	return (
		<PasswordResetContainer>
			{passwordErrorMsg != null &&
				<Alert severity="error">
					{passwordErrorMsg}
				</Alert>}

			{redeemTokenReq.error != null &&
				<Alert severity="error">
					{parseError(redeemTokenReq.error.errorMsg)}
				</Alert>}

			<ul
				className="mb-5"
				style={{ listStyleType: "circle", listStyle: "inside" }}
			>
				<li>Must be at least 8 characters long.</li>
				<li>Must contain no whitespaces.</li>
			</ul>

			<form className="" onSubmit={handlePasswordUpdate}>
				<label htmlFor="newPasswordInput">New password</label>
				<input
					id="newPasswordInput"
					type="password"
					placeholder="&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;"
					disabled={redeemTokenReq.isPending}
					className="mt-1 block w-full shadow-sm border-gray-300 rounded-md py-2 px-3 border focus:border-blue-500 mb-4"
					required
					autoComplete="new-password"
				/>

				<label htmlFor="confirmPasswordInput">Confirm password</label>
				<input
					id="confirmPasswordInput"
					type="password"
					placeholder="&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;"
					disabled={redeemTokenReq.isPending}
					className="mt-1 block w-full shadow-sm border-gray-300 rounded-md py-2 px-3 border focus:border-blue-500"
					required
					autoComplete="new-password"
				/>

				<div className="w-full flex flex-row justify-center">
					<button
						className="btn-blue mt-4 cursor-pointer"
						disabled={redeemTokenReq.isPending}
					>
						Confirm
					</button>
				</div>
			</form>
		</PasswordResetContainer>
	);
}

function parseError(errorMsg: string): string {
	if (errorMsg == "invalidtoken") {
		return "Could not find user.";
	} else if (errorMsg == "expired") {
		return "Token expired. Please request a new one.";
	} else if (errorMsg == "invalidpass") {
		return "Invalid new password.";
	} else {
		return "Could not send reset request email.";
	}
}
