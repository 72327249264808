import { CacheSlices } from "@/Store/Reducers/cache/CacheTypes";
import { useCache } from "@/Hooks/useCache";
import { StatusDropdown } from "@/Components/FormComponents/StatusDropdown/StatusDropdown";
import CacheDropdown from "../../../../Components/FormComponents/CacheDropdown/CacheDropdown";
import { Ticket } from "@shared/Entities/EntityTypes";
import { SettingInt } from "@shared/Enums/SettingEnums";

interface TicketDetailsSectionProps {
	ticket: Ticket;
	handleSubmit(key: string, newValue: number | undefined): void;
}

export function TicketDetailsSection(props: TicketDetailsSectionProps) {
	const { cache } = useCache();

	const defaultChannel = cache.getSettingInt(SettingInt.HELPDESK_DEFAULT_EMAIL_CHANNEL);

	const channelId = props.ticket.channelId != null ? props.ticket.channelId : defaultChannel != null ? defaultChannel : undefined;

	return (
		<div className="border-t border-gray-300 p-3">
			<StatusDropdown
				label="Status"
				mandatory
				value={props.ticket.statusId}
				dataname="statusId"
				onChange={props.handleSubmit}
			/>
			<CacheDropdown
				label="Priority"
				mandatory
				value={props.ticket.priorityId}
				dataname={"priorityId"}
				onChange={props.handleSubmit}
				cacheSlice={CacheSlices.Priorities}
			/>
			<CacheDropdown
				label="Category"
				value={props.ticket.categoryId}
				dataname={"categoryId"}
				onChange={props.handleSubmit}
				cacheSlice={CacheSlices.Categories}
			/>
			<CacheDropdown
				label="Channel"
				mandatory
				value={channelId}
				dataname={"channelId"}
				onChange={props.handleSubmit}
				cacheSlice={CacheSlices.Channels}
			/>
		</div>
	);
}
