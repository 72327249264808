import { useState, FormEvent } from "react";
import { useLocation } from "react-router-dom";
import { Alert } from "@mui/material";
import { LoginParent } from "@/Pages/Auth/Login/LoginParent";
import { MobileWarning } from "@/Pages/Auth/Login/MobileWarning";
import { LoginForm } from "@/Pages/Auth/Login/LoginForm";
import { useAgentAuth } from "@/Hooks/useAgentAuth";
import { DeviceFunctions } from "../../../Helpers/DeviceFunctions";

export default function Login() {
	document.title = "Login | GoDesk";

	const { search } = useLocation();
	const searchParams = new URLSearchParams(search);

	const { login, loginPending, loginError, instanceInvalid } = useAgentAuth({
		redirectIfLoggedIn: true,
		loginRedirectSearchParams: searchParams
	});

	const [formError, setFormError] = useState<string | null>(null);
	const [mobWarningModalOpen, setMobWarningModalOpen] = useState(DeviceFunctions.detectMob);

	// If we've just been logged out, show a msg.
	const didLogout = searchParams.get("loggedout") != null;

	// If we have successfully added a Cname.
	const cnameAdded = searchParams.get("cnamerequest") != null;

	async function handleSubmit(event: FormEvent) {
		event.preventDefault();

		const username = (document.getElementById("usernameInput") as HTMLInputElement).value ?? "";
		const password = (document.getElementById("passwordInput") as HTMLInputElement).value ?? "";

		if (username == undefined) {
			setFormError("Email not found.");
			return;
		}

		if (password == undefined) {
			setFormError("Code not found.");
			return;
		}

		setFormError(null);

		login(username, password);
	}

	if (instanceInvalid) {
		return (
			<LoginParent>
				<Alert severity="error">
					This instance of GoDesk does not exist. Are you on the correct URL?
				</Alert>
			</LoginParent>
		);
	}

	return (
		<LoginParent>
			{formError != null &&
				<Alert severity="error">
					{formError}
				</Alert>}

			{loginError != null &&
				<Alert severity="error">
					{loginError}
				</Alert>}

			{didLogout &&
				<Alert severity="success">
					You have been logged out.
				</Alert>}

			{cnameAdded &&
				<Alert severity="success">
					CNAME added successfully.
				</Alert>}

			<form className="" onSubmit={handleSubmit}>
				<LoginForm disabled={loginPending} />
			</form>

			<MobileWarning
				isOpen={mobWarningModalOpen}
				close={() => setMobWarningModalOpen(false)}
			/>
		</LoginParent>
	);
}
