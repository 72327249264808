import { Link } from "react-router-dom";
import { CacheSlices } from "@/Store/Reducers/cache/CacheTypes";
import { useCache } from "@/Hooks/useCache";
import { useAppDispatch } from "../../../../Store/hooks";
import { CacheFunctions } from "../../../../Helpers/CacheFunctions";
import { datetimeToLocaleString, datetimeToWhenString } from "@shared/Helpers/DateFunctions";
import { TicketHistory } from "@shared/Entities/EntityTypes";

interface TicketHistorySectionProps {
	ticketId: number;
	ticketHistory?: TicketHistory[];
}

export function TicketHistorySection(props: TicketHistorySectionProps) {
	return (
		<div className="border-t border-gray-300 p-3 min-h-[200px] overflow-auto">
			{props.ticketHistory != null ? props.ticketHistory.map((item) => (
				<TicketHistoryItem key={item.id} ticketId={props.ticketId} ticketHistory={item} />
			)) : null}
		</div>
	);
}

interface TicketHistoryItemProps {
	ticketId: number;
	ticketHistory: TicketHistory;
}

function TicketHistoryItem(props: TicketHistoryItemProps) {
	const dispatch = useAppDispatch();

	const { cache } = useCache();
	const dateString = datetimeToWhenString(props.ticketHistory.customCreated);
	const dateMade = datetimeToLocaleString(props.ticketHistory.customCreated);

	const statusName = props.ticketHistory.statusId != null ? CacheFunctions.getNameFromCache(props.ticketHistory.statusId, cache, CacheSlices.Statuses, dispatch) : "--";

	const activeTicketStyle = props.ticketId == props.ticketHistory.id ? " bg-gray-200 border border-gray-300" : " border border-transparent";

	let statusStyles = "";
	switch (props.ticketHistory.statusId) {
		case 1: // Open
			statusStyles = " bg-green-100 border-green-200";
			break;
		case 2: // With User
			statusStyles = " bg-gray-100 border-gray-200";
			break;
		case 3: // Closed
			statusStyles = " bg-blue-100 border-blue-200";
			break;
	}

	return (
		<Link to={"/tickets/" + props.ticketHistory.id}>
			<div className={"grid grid-cols-[5.2rem_auto] grid-row-1 gap-2 items-center py-1 overflow-hidden rounded-lg p-2" + activeTicketStyle}>
				<div className={"border rounded-full py-0.5 px-2 mr-auto text-sm col-span-1 auto-rows-min " + statusStyles}>
					{statusName}
				</div>
				<div className="flex flex-col ml-1">
					<div className="font-semibold line-clamp-3">{props.ticketHistory.description}</div>
					<div title={dateMade}>{dateString}</div>
				</div>
			</div>
		</Link>
	);
}
