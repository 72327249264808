import { ReactNode } from "react";

interface ChannelTypeButtonProps {
	active: boolean;
	onClick(): void;
	label: string;
	tooltip?: string;
	icon: ReactNode;
}

export function ChannelTypeButton(props: ChannelTypeButtonProps) {
	const extraStyleClasses = props.active ? " bg-gray-50 border-blue-300" : " bg-white ";

	return (
		<div onClick={props.onClick} className="cursor-pointer text-center">
			<button type="button" className={"w-20 h-20 shadow-md rounded-md 400 border-2 transition-colors " + extraStyleClasses}>
				{props.icon}
			</button>

			<p
				className={"mt-2 font-medium" + (props.tooltip != null ? " underline decoration-dashed " : "")}
				title={props.tooltip}
			>
				{props.label}
			</p>
		</div>
	);
}
