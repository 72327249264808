import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import { BsPencil } from "react-icons/bs";

interface InlineTextProps {
	value?: string;
	dataname: string;
	handleSubmit(key: string, newValue: string | undefined): void;
	maxLength?: number;
}

export default function InlineText(props: InlineTextProps) {
	const [isEditing, setIsEditing] = useState(false);
	const [value, setValue] = useState<string | undefined>(props.value);
	const inputRef = useRef<any>();

	useEffect(() => {
		setValue(props.value);
	}, [props.value]);

	useEffect(() => {
		if (isEditing) {
			if (inputRef != null && inputRef.current != null) {
				inputRef.current.focus();
			}
		}
	}, [isEditing]);

	function handleChange(event: ChangeEvent<HTMLInputElement>) {
		setValue(event.target.value);
	}

	function handleBlur() {
		if (value != props.value) {
			props.handleSubmit(props.dataname, (value ?? "").trim());
		}

		setIsEditing(false);
	}

	function escPressed() {
		// Reset value and set to read mode. handleBlur() is not called because the <input> tag is no longer being rendered.
		setValue(props.value);
		setIsEditing(false);
	}

	function handleKeyDown(event: React.KeyboardEvent) {
		if (event.key == "Enter" || event.key == "Tab") {
			handleBlur();
		} else if (event.key == "Escape") {
			escPressed();
		}
	}

	return (
		<div className="Form_InlineText inline-flex w-full" >
			{isEditing ?
				<input
					ref={inputRef}
					type="text"
					value={value}
					onChange={handleChange}
					onBlur={handleBlur}
					onKeyDown={handleKeyDown}
					className="w-full p-1 rounded border border-gray-300"
					maxLength={props.maxLength}
				/>
				:
				<div
					onClick={() => setIsEditing(true)}
					className="p-1 flex grow gap-2 items-center cursor-pointer border border-transparent"
				>
					<span className="whitespace-pre-wrap line-clamp-3">
						{value}
					</span>

					<BsPencil className="Form_InlineText_EditButton shrink-0" />
				</div>
			}
		</div>

	);
}
