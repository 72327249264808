import { ReactNode } from "react";
import { BsInfoCircleFill } from "react-icons/bs";
import { IoIosCloseCircle, IoIosWarning } from "react-icons/io";

interface AlertBoxProps {
	alertType: "info" | "warning" | "error";
	children: ReactNode;
	className?: string;
}

export function AlertBox(props: AlertBoxProps) {
	let alertIcon;
	let alertClasses = "rounded-md w-full p-3 border mt-4 mb-3 flex items-center gap-3 shadow-sm " + props.className;

	switch (props.alertType) {
		case "info":
			alertIcon = <BsInfoCircleFill className="text-lg text-blue-600 shrink-0" />;
			alertClasses += " bg-blue-50 border-blue-200 text-blue-500";
			break;
		case "warning":
			alertIcon = <IoIosWarning className="text-xl text-yellow-500 shrink-0" />;
			alertClasses += " bg-yellow-50 border-yellow-300 text-yellow-600";
			break;
		case "error":
			alertIcon = <IoIosCloseCircle className="text-xl text-red-600 shrink-0" />;
			alertClasses += " bg-red-50 border-red-200 text-red-500";
			break;
	}

	return (
	// <Alert
	// 	color={color}
	// 	variant="soft"
	// 	startDecorator={alertIcon}
	// >
	// 	{props.children}
	// </Alert>

		<div className={alertClasses}>
			{alertIcon}
			<div>
				{props.children}
			</div>
		</div>
	);
}
