import { useState } from "react";
import toast from "react-hot-toast";
import { AiOutlineDelete } from "react-icons/ai";
import DeleteWarningModal from "@/Components/Utility/Modals/DeleteWarningModal";
import { useCache } from "@/Hooks/useCache";
import { Button } from "@/Components/Button/Button";
import { useDeleteDemoData } from "@/Api/genApi";
import { SettingBool } from "@shared/Enums/SettingEnums";

export function DeleteDemoDataButton() {
	const { cache, refreshCache } = useCache();

	const [deleteDemoDataModalOpen, setDeleteDemoDataModalOpen] = useState(false);

	const deleteDemoDataReq = useDeleteDemoData();

	async function deleteDemoData() {
		setDeleteDemoDataModalOpen(false);
		const toastId = toast.loading("Deleting...");

		deleteDemoDataReq.mutate(undefined, {
			onSuccess: () => {
				// TODO: Invalidate ticket query key.
				toast.success("Deleted demo data. Please refresh the table.", { id: toastId });
			},
			onError: error => {
				toast.error("Could not delete demo data: " + error.errorMsg, { id: toastId });
			}
		});

		// Refresh cache to get helpdesk.deletedDemoData new setting value.
		await refreshCache();
	}

	return (
		<>
			{!cache.getSettingBool(SettingBool.HELPDESK_DEMO_DATA_DELETED) && !(process.env.NODE_ENV == "development") ?
				<Button icon={<AiOutlineDelete />} label="Delete demo data" onClick={() => setDeleteDemoDataModalOpen(true)} />
				: null}

			<DeleteWarningModal
				isOpen={deleteDemoDataModalOpen}
				onDelete={deleteDemoData}
				onCancel={() => setDeleteDemoDataModalOpen(false)}
				message={"This will remove any demo data left in your system - leaving GoDesk ready to make your own."}
			/>
		</>
	);
}
