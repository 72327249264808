interface ModalButtonsProps {
	children: React.ReactNode;
	leftSlot?: React.ReactNode;
}

export default function ModalButtons(props: ModalButtonsProps) {
	return (
		<div className="flex flex-row justify-end gap-3 shrink-0 p-4 border-t items-center bg-gray-50 rounded-b-md">
			{props.children}
		</div>
	);
}
