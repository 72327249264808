import { ReactNode } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";

interface SidebarButtonProps {
	icon: JSX.Element;
	title: string;
	link: string;
	expanded: boolean;
	tooltipShortcut: ReactNode;
}

export function SidebarButton(props: SidebarButtonProps) {
	const navigate = useNavigate();
	const location = useLocation();

	const active = location.pathname.includes(props.link) || (props.link == "/tickets" && location.pathname == "/");

	const activeStyles = active ? " bg-blue-50 text-blue-800" : " text-gray-700 hover:bg-gray-100 active:bg-gray-200";
	const titleStyles = props.expanded ? " opacity-100" : " opacity-0";

	const button =	<button
		className={"flex items-center px-2.5 py-2.5 my-0.5 rounded w-full whitespace-nowrap" + activeStyles}
		onClick={() => navigate(props.link)}
	               >
		<div className="text-2xl mr-2 flex items-center">{props.icon}</div>
		<div className={"font-semibold transition-opacity" + titleStyles}>{props.title}</div>
	</button>;

	return (
		props.expanded ?
			button
			:
			<Tooltip title={props.tooltipShortcut} placement="right">
				{button}
			</Tooltip>
	);
}
