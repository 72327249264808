import LoadingButton from "@mui/lab/LoadingButton";

interface AgentRegisterFormProps {
	disabled: boolean;
	email?: string;
}

export function AgentRegisterForm(props: AgentRegisterFormProps) {
	return (
		<>
			<label htmlFor="nameInput">Name</label>
			<input
				id="nameInput"
				type="text"
				placeholder="John Smith"
				disabled={props.disabled}
				required
				className="mt-1 block w-full shadow-sm border-gray-300 rounded-md py-2 px-3 border focus:border-blue-500 mb-4"
			/>

			<label htmlFor="emailInput">Email address</label>
			<input
				id="emailInput"
				defaultValue={props.email ?? ""}
				type="text"
				placeholder="me@company.com"
				disabled={props.disabled}
				required
				readOnly
				autoComplete="username"
				className="mt-1 block w-full shadow-sm border-gray-300 rounded-md py-2 px-3 border focus:border-blue-500 mb-4 text-gray-700 cursor-pointer"
			/>

			<label htmlFor="passwordInput">Password</label>
			<input
				id="passwordInput"
				type="password"
				placeholder="&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;"
				disabled={props.disabled}
				required
				autoComplete="new-password"
				className="mt-1 block w-full shadow-sm border-gray-300 rounded-md py-2 px-3 border focus:border-blue-500 mb-4"
			/>

			<label htmlFor="confirmPasswordInput">Confirm password</label>
			<input
				id="confirmPasswordInput"
				type="password"
				placeholder="&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;"
				disabled={props.disabled}
				required
				autoComplete="new-password"
				className="mt-1 block w-full shadow-sm border-gray-300 rounded-md py-2 px-3 border focus:border-blue-500"
			/>

			<div className="w-full flex justify-center mt-4">
				<LoadingButton
					variant="contained"
					type="submit"
					loading={props.disabled}
				>
					Register
				</LoadingButton>
			</div>
		</>
	);
}
