import { ReactNode } from "react";

interface FormReadBoxProps {
	children: ReactNode;
}

/** Renders a grid to display properties in read mode. */
export function FormReadBox(props: FormReadBoxProps) {
	return (
		<div className="grid grid-cols-2 gap-2">
			{props.children}
		</div>
	);
}
