import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { AiOutlineDelete, AiOutlineStop } from "react-icons/ai";
import { MdSettingsBackupRestore } from "react-icons/md";
import { IoPersonAddOutline } from "react-icons/io5";
import toast from "react-hot-toast";
import { HeaderTabEntities } from "@/Components/Header/GlobalTab";
import { CacheSlices } from "@/Store/Reducers/cache/CacheTypes";
import NewEntityModal from "@/Components/Utility/Modals/NewEntityModal";
import { useInviteExistingUser } from "@/Api/genApi";
import { useCache } from "@/Hooks/useCache";
import CacheDropdown from "../../../Components/FormComponents/CacheDropdown/CacheDropdown";
import OtherFunctions from "../../../Helpers/OtherFunctions";
import { Button, ButtonRed } from "../../../Components/Button/Button";
import { globalTabRemoved } from "../../../Store/Reducers/globalTabsSlice";
import { useAppDispatch } from "../../../Store/hooks";
import DeleteWarningModal from "../../../Components/Utility/Modals/DeleteWarningModal";
import { TicketTable } from "../../../Components/EntityTable/Tables/TicketTable";
import { UserAvatar } from "../../../Components/Avatar/UserAvatar";
import { FormSection } from "@shared/Components/Form/FormSection";
import { FormReadBox } from "@shared/Components/Form/FormReadBox";
import { FormReadPair } from "@shared/Components/Form/FormReadPair";
import { datetimeToWhenString } from "@shared/Helpers/DateFunctions";
import { View } from "@shared/Models/View";
import TextInput from "@shared/Components/FormComponents/TextInput/TextInput";
import { InputTypes } from "@shared/Components/FormComponents/TextInput/InputTypes";
import { User } from "@shared/Entities/EntityTypes";
import { Entities } from "@shared/Entities/Entities";

interface UserViewerProps {
	id: number;
	data?: User;
	updateData(data: User): Promise<void>;
	delete(): Promise<void>;
	refreshData(): void;
}

export function UserViewer(props: UserViewerProps) {
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const [deleteUserModalOpen, setDeleteUserModalOpen] = useState(false);
	const { refreshCache } = useCache();

	function handleChangeInFormComponent(key: string, newValue: any) {
		const dataCopy = Object.assign({}, props.data);
		const newData = OtherFunctions.SafeChangeProperty(dataCopy, key, newValue);

		props.updateData(newData);
	}

	function newAvatarCallback(dataBase64: string, mimeType: string) {
		let newData = Object.assign({}, props.data);
		newData = OtherFunctions.SafeChangeProperty(newData, "profilePictureData", dataBase64);
		newData = OtherFunctions.SafeChangeProperty(newData, "profilePictureMimetype", mimeType);

		props.updateData(newData);
	}

	function restoreButtonClick() {
		let newData = Object.assign({}, props.data);
		newData = OtherFunctions.SafeChangeProperty(newData, "disabled", false);

		props.updateData(newData);

		toast.success("User restored.");
	}

	async function disableUser() {
		let newData = Object.assign({}, props.data);
		newData = OtherFunctions.SafeChangeProperty(newData, "disabled", true);

		await props.updateData(newData);

		toast.success("User disabled.");
	}

	async function deleteUser() {
		await props.delete();

		navigate(-1);
		dispatch(globalTabRemoved({ id: props.id, entity: HeaderTabEntities.USERS }));
		await refreshCache();
	}

	// Show only this user's non-deleted tickets.
	const ticketTableView: View = { filters: {
		userId: { type: "number", comparison: "equals", value: props.id },
		deleted: { type: "bool", comparison: "equals", value: false }
	} };

	return (
		props.data != null ?
			<div className="flex h-full">
				<div className="w-[25em]">
					<UserDetails user={props.data} handleSubmit={handleChangeInFormComponent} newAvatarCallback={newAvatarCallback} refreshData={props.refreshData} />

					{props.data.deleted ?
						<></>
						:
						props.data.disabled ?
							<>
								<Button className="ml-4" onClick={restoreButtonClick} icon={<MdSettingsBackupRestore />} label="Restore" />
								<ButtonRed className="ml-2" label="Delete forever" icon={<AiOutlineDelete />} onClick={() => setDeleteUserModalOpen(true)} />
							</>
							:
							<>
								<ButtonRed className="ml-4" label="Disable user" icon={<AiOutlineStop />} onClick={disableUser} />
							</>}
				</div>
				<div className="p-2 flex-grow">
					<TicketTable
						view={ticketTableView}
						sort={{
							sortField: "customCreated",
							sortType: "desc"
						}}
						defaultNewTicket={{
							id: 0,
							userId: props.id
						}}
					/>
				</div>
				<DeleteWarningModal isOpen={deleteUserModalOpen} onDelete={deleteUser} onCancel={() => setDeleteUserModalOpen(false)} message="This will remove all of this user's personal details and close all of their tickets." />
			</div>
			:
			null
	);
}

interface UserDetailsProps {
	user: User;
	handleSubmit(key: string, newValue: any): void;
	newAvatarCallback(dataBase64: string, mimeType: string): void;
	refreshData(): void;
}

function UserDetails(props: UserDetailsProps) {
	const [newCompanyModalOpen, setNewCompanyModalOpen] = useState(false);
	const { refreshCache } = useCache();

	const inviteExistingUserReq = useInviteExistingUser();

	async function userPortalInvite(user: User) {
		if (!user.portalUser) {
			inviteExistingUserReq.mutate({ id: user.id }, {
				onSuccess: () => {
					toast.success("User invite sent.");
					props.refreshData();
				},
				onError: error => {
					toast.error("Error sending user invite: " + error.errorMsg);
				}
			});
		}
	}

	const userPortalText = props.user.portalUser ? "This user has a portal account!" : props.user.portalInvitePending ? "This user has a pending invite." : "This user does not have a portal account.";

	async function newCompanyCreated(companyId: number) {
		setNewCompanyModalOpen(false);
		refreshCache();
		props.handleSubmit("companyId", companyId);
		toast.success("Company created");
	}

	return (
		<>
			<div className="p-3 text-center">
				<div>
					<UserAvatar user={props.user} widthPx={70} updateAvatar={props.newAvatarCallback} />
				</div>
				<div className="text-2xl text-elipsis w-full truncate">
					{props.user.name}
				</div>
				<div className="text-lg text-gray-500 font-medium">{props.user.deleted ? " Deleted" : props.user.disabled ? " Disabled" : null}</div>
			</div>

			<FormSection>
				<TextInput
					label="Name"
					value={props.user.name}
					dataname={"name"}
					handleSubmit={props.handleSubmit}
					disabled={props.user.disabled}
					maxLength={255}
				/>
				<CacheDropdown
					label="Company"
					value={props.user.companyId}
					dataname={"companyId"}
					onChange={props.handleSubmit}
					cacheSlice={CacheSlices.Companies}
					disabled={props.user.disabled}
					newButtonOnClick={!(props.user.deleted || props.user.disabled) ? () => setNewCompanyModalOpen(true) : undefined}
					newButtonTooltip={!(props.user.deleted || props.user.disabled) ? "Create a new company" : undefined}
				/>
			</FormSection>

			<FormSection>
				<TextInput
					label="Email"
					type={InputTypes.Email}
					value={props.user.email}
					dataname={"email"}
					handleSubmit={props.handleSubmit}
					disabled={props.user.disabled}
					maxLength={255}
				/>
				<TextInput
					label="Phone number"
					type={InputTypes.Tel}
					value={props.user.phoneNumber}
					dataname={"phoneNumber"}
					handleSubmit={props.handleSubmit}
					disabled={props.user.disabled}
					maxLength={45}
				/>
			</FormSection>

			<FormSection>
				User Portal
				<div className="mt-2 text-gray-700">
					{userPortalText}
				</div>

				<div>
					{!props.user.portalUser && !(props.user.deleted || props.user.disabled) &&
						<Button
							icon={<IoPersonAddOutline />}
							label="Invite to portal"
							btnClass="btn-blue"
							className="mt-2"
							loading={inviteExistingUserReq.isPending}
							onClick={() => userPortalInvite(props.user)}
							disabled={props.user.portalInvitePending}
						/>}
				</div>
			</FormSection>

			<FormSection>
				<FormReadBox>
					<FormReadPair
						name="Updated"
						value={datetimeToWhenString(props.user.modified)}
					/>
					<FormReadPair
						name="Created"
						value={datetimeToWhenString(props.user.created)}
					/>
				</FormReadBox>
			</FormSection>
			<NewEntityModal newCreated={newCompanyCreated} isOpen={newCompanyModalOpen} entityType={Entities.COMPANY} closeModal={() => setNewCompanyModalOpen(false)} />
		</>
	);
}
