import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import W_CacheDropdown from "@/Components/FormComponents/CacheDropdown/W_CacheDropdown";
import { FieldError } from "@/legacy/ApiResponse";
import { CacheSlices } from "@/Store/Reducers/cache/CacheTypes";
import { useCache } from "@/Hooks/useCache";
import api from "@/Api/Api";
import { useInviteExistingUser } from "@/Api/genApi";
import { Button, ButtonBlue } from "../../Button/Button";
import { CacheFunctions } from "../../../Helpers/CacheFunctions";
import OtherFunctions from "../../../Helpers/OtherFunctions";
import { Tickbox } from "@shared/Components/FormComponents/Tickbox/Tickbox";
import Modal from "@shared/Components/Modal/Modal";
import ModalContent from "@shared/Components/Modal/ModalContent";
import ModalButtons from "@shared/Components/Modal/ModalButtons";
import W_TextInput from "@shared/Components/FormComponents/TextInput/W_TextInput";
import { InputTypes } from "@shared/Components/FormComponents/TextInput/InputTypes";
import { Entities } from "@shared/Entities/Entities";

interface NewEntityModalProps {
	newCreated(id: number): void;
	isOpen: boolean;
	entityType: Entities;
	closeModal(): void;
	defaultNewData?: any;
}

export default function NewEntityModal(props: NewEntityModalProps) {
	const [newCompanyModalOpen, setNewCompanyModalOpen] = useState(false);

	const [sendPortalInvite, setSendPortalInvite] = useState(false);
	const { cache, refreshCache } = useCache();
	const navigate = useNavigate();

	const inviteExistingUserReq = useInviteExistingUser();

	const { handleSubmit, setError, reset, control, setValue } = useForm<any>({ defaultValues: props.defaultNewData });

	useEffect(() => {
		reset(props.defaultNewData);
	}, [props.defaultNewData]);

	async function createNewEntity(data: any) {
		if (data != null) {
			const res = await api.createEntity<any>(props.entityType, data);

			if (res.successful) {
				if (res.data != null && res.data.id != null) {
					reset(props.defaultNewData);
					props.newCreated(res.data.id);
				}

				if (props.entityType == Entities.USER && sendPortalInvite == true && res.data != null && res.data.id != null) {
					inviteExistingUserReq.mutateAsync({ id: res.data.id }, {
						onSuccess: () => {
							toast.success("Portal invite sent.");
						},
						onError: error => {
							toast.error("Failed to send invite: " + error.errorMsg);
						}
					});
				}
			} else if (res.formFieldErrorCode != undefined) {
				OtherFunctions.displayEmailTakenError(res, cache, navigate);
			} else {
				displaySubmitErrors(res.fieldErrors);
			}
		}
	}

	function displaySubmitErrors(fieldErrors?: FieldError[]) {
		fieldErrors?.forEach(error => setError(error.field, {
			type: "manual",
			message: error.message,
		}));
	}

	function closeModal() {
		reset(props.defaultNewData);
		props.closeModal();
	}

	async function newCompanyCreated(companyId: number) {
		setNewCompanyModalOpen(false);
		refreshCache();
		setValue("companyId", companyId);
	}

	const title = "New " + CacheFunctions.getEntityName(props.entityType);
	const icon = CacheFunctions.getEntityIcon(props.entityType);
	let fields = null;
	let extraFields = null;

	switch (props.entityType) {
		case Entities.CATEGORY:
			fields = <>
				<W_TextInput
					control={control}
					dataname="name"
					label="Name"
					autoFocus
					mandatory
				/>
			</>;
			break;

		case Entities.COMPANY:
			fields = <>
				<W_TextInput
					control={control}
					dataname="name"
					label="Name"
					autoFocus
					mandatory
				/>
				<W_TextInput
					control={control}
					dataname="emailDomain"
					label="Email domain"
					type={InputTypes.Url}
					urlUseFQDN
					toolTip="New users with this domain in their email addresses will be added to this company."
					placeholder="their-company.com"
				/>
			</>;
			break;

		case Entities.TEAM:
			fields = <>
				<W_TextInput
					control={control}
					dataname="name"
					label="Name"
					autoFocus
					mandatory
				/>
				<W_TextInput control={control} dataname="description" label="Description" />

				{/* TODO: Add agent multi selection. */}
			</>;
			break;

		case Entities.USER:
			fields = <>
				<W_TextInput
					control={control}
					dataname="name"
					label="Name"
					autoFocus
					mandatory
					maxLength={255}
				/>
				<W_TextInput
					control={control}
					dataname="email"
					label="Email"
					type={InputTypes.Email}
					mandatory
					maxLength={255}
					autocomplete="off"
				/>
				<W_CacheDropdown
					control={control}
					dataname="companyId"
					label="Company"
					cacheSlice={CacheSlices.Companies}
					newButtonOnClick={() => setNewCompanyModalOpen(true)}
					newButtonTooltip="Create a new company"
				/>

				<W_TextInput control={control} dataname="phoneNumber" label="Phone number" type={InputTypes.Tel} />
				{extraFields}

				<NewEntityModal newCreated={newCompanyCreated} isOpen={newCompanyModalOpen} entityType={Entities.COMPANY} closeModal={() => setNewCompanyModalOpen(false)} />
			</>;

			extraFields = <Tickbox value={sendPortalInvite} onChange={() => setSendPortalInvite(!sendPortalInvite)} valueName="" label="Send a portal invite email" />;
			break;

		default:
			break;
	}

	const modalStyles = {
		minWidth: "400px"
	};

	return (
		<Modal
			isOpen={props.isOpen}
			onClose={closeModal}
			title={title}
			titleIcon={icon}
			modalStyle={modalStyles}
		>
			<form onSubmit={handleSubmit(createNewEntity)} >
				<ModalContent>
					<div>
						{fields}

						{extraFields}
					</div>
				</ModalContent>

				<ModalButtons>
					<Button onClick={closeModal} label="Cancel" />
					<ButtonBlue onClick={() => handleSubmit(createNewEntity)()} label="Create" />
				</ModalButtons>
			</form>
		</Modal>
	);
}
