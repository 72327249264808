import { ReactNode } from "react";
import Modal from "@shared/Components/Modal/Modal";
import ModalContent from "@shared/Components/Modal/ModalContent";

interface HotkeyHelpModalProps {
	isOpen: boolean;
	closeModal(): void;
}

export default function HotkeyHelpModal(props: HotkeyHelpModalProps) {

	return (
		<Modal
			isOpen={props.isOpen}
			onClose={props.closeModal}
			title="Keyboard Shortcuts"
		>
			<ModalContent>
				<div className="w-96">
					<div className="flex justify-between mb-2">
						<div>Open keyboard shortcuts</div>
						<KeyIcon>?</KeyIcon>
					</div>
					<br />
					<div className="flex justify-between mb-2">
						<div>Go to Home</div>
						<div>
							<KeyIcon>g</KeyIcon>
							<KeyIcon>h</KeyIcon>
						</div>
					</div>
					<div className="flex justify-between mb-2">
						<div>Go to Tickets</div>
						<div>
							<KeyIcon>g</KeyIcon>
							<KeyIcon>t</KeyIcon>
						</div>
					</div>
					<div className="flex justify-between mb-2">
						<div>Go to Companies</div>
						<div>
							<KeyIcon>g</KeyIcon>
							<KeyIcon>c</KeyIcon>
						</div>
					</div>
					<div className="flex justify-between mb-2">
						<div>Go to Knowledge Base</div>
						<div>
							<KeyIcon>g</KeyIcon>
							<KeyIcon>k</KeyIcon>
						</div>
					</div>
					<div className="flex justify-between mb-2">
						<div>Go to Reporting</div>
						<div>
							<KeyIcon>g</KeyIcon>
							<KeyIcon>r</KeyIcon>
						</div>
					</div>
					<div className="flex justify-between mb-2">
						<div>Go to Admin</div>
						<div>
							<KeyIcon>g</KeyIcon>
							<KeyIcon>a</KeyIcon>
						</div>
					</div>
					<br />
					<div className="flex justify-between mb-2">
						<div>Submit form</div>
						<div>
							<KeyIcon>Ctrl</KeyIcon>
							<KeyIcon>Enter</KeyIcon>
						</div>
					</div>
					<br />
					<div className="flex justify-between mb-2">
						<div>Add reply</div>
						<div>
							<KeyIcon>r</KeyIcon>
						</div>
					</div>
					<div className="flex justify-between mb-2">
						<div>Add private note</div>
						<div>
							<KeyIcon>p</KeyIcon>
						</div>
					</div>
				</div>
			</ModalContent>
		</Modal>
	);
}

interface KeyIconProps {
	children: ReactNode;
}

function KeyIcon(props: KeyIconProps) {
	return (
		<div
			className="inline-block px-2 py-1 ml-1 bg-gray-200 border-gray-300 shadow rounded text-center"
			style={{ minWidth: "25px" }}
		>
			{props.children}
		</div>
	);
}
