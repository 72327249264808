import { BrowserRouter } from "react-router-dom";
import { createRoot } from "react-dom/client";
import { ModuleRegistry } from "@ag-grid-community/core";
import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import * as Sentry from "@sentry/react";
import { Settings } from "luxon";
import { ServiceWorkerManager } from "@/Components/ServiceWorkerManager";
import { ErrorBoundary } from "@/lib/ErrorBoundary";
import { StoreProvider } from "@/Store/StoreProvider";
import { MuiProvider } from "@/Styles/MuiProvider";
import { UseQueryProvider } from "@/Api/UseQueryProvider";
import Routes from "./Routes/Routes";
import { TailwindBreakpoints } from "@shared/Components/Utils/TailwindBreakpoints";
import { Toaster } from "@shared/Components/Utils/Toasts";
import "./index.scss";

const container = document.getElementById("root");
const root = createRoot(container!);

// Luxon
Settings.defaultZone = "utc";
Settings.throwOnInvalid = true;
declare module "luxon" {
	interface TSSettings {
		throwOnInvalid: true;
	}
}

// Sentry
if (process.env.NODE_ENV != "development") {
	Sentry.init({
		dsn: "https://dbbd2fe6c1af4824a0b9a38f76b9e5f2@o4503920154771456.ingest.sentry.io/4505431605116928",
		debug: false,
	});
}

// Register service worker. We do this manually so we can blacklist the oauth domains.
const isOauthDomain = window.location.hostname == "oauth.getgodesk.com" || window.location.hostname == "oauth-stag.getgodesk.com";
if (process.env.NODE_ENV != "development" && !isOauthDomain && "serviceWorker" in navigator) {
	// Register SW.
	console.log("Registering SW.");
	navigator.serviceWorker.register("/service-worker.js");
}

// Activate AG Grid Modules.
ModuleRegistry.registerModules([
	ClientSideRowModelModule
]);

root.render(
	<ErrorBoundary>
		<StoreProvider> {/* Redux */}
			<MuiProvider> {/* Material design */}
				<UseQueryProvider>
					<Toaster />

					<ServiceWorkerManager />

					{process.env.NODE_ENV == "development" ?
						<TailwindBreakpoints />
						: null}

					<BrowserRouter>
						<Routes />
					</BrowserRouter>
				</UseQueryProvider>
			</MuiProvider>
		</StoreProvider>
	</ErrorBoundary>
);
