import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useEntity } from "@/legacy/useEntity";
import { useCache } from "@/Hooks/useCache";
import OtherFunctions from "@/Helpers/OtherFunctions";
import { getImageUploadHandler } from "@/Components/FormComponents/HtmlEditor/imageUploadHandler";
import { EntityViewerToolbar } from "@/Fragments/EntityViewer/EntityViewerToolbar";
import api from "@/Api/Api";
import { CannedReplyDisplay } from "@/Pages/Config/CannedReplies/CannedReplyDisplay";
import { FormError } from "@shared/Components/Form/FormError";
import { FormParent } from "@shared/Components/Form/FormParent";
import { FormSection } from "@shared/Components/Form/FormSection";
import { File, CannedReply } from "@shared/Entities/EntityTypes";
import W_TextInput from "@shared/Components/FormComponents/TextInput/W_TextInput";
import { Entities } from "@shared/Entities/Entities";
import W_HtmlEditor from "@shared/Components/FormComponents/HtmlEditor/W_HtmlEditor";
import { FormHotkeys } from "@shared/Components/Utils/FormHotkeys";
import { Attachments } from "@shared/Attachments/Attachments";

export function CannedReplyViewer() {
	const [id, setId] = useState<number>();
	const [editmode, setEditmode] = useState<boolean>(false);
	const { cache } = useCache();

	const navigate = useNavigate();
	const params = useParams();
	const { data, formError, fieldErrors, updateEntity, createEntity, deleteEntity } = useEntity<CannedReply>(id, Entities.CANNEDREPLY);
	const { control, handleSubmit, reset, setValue, setError, watch } = useForm<CannedReply>({ defaultValues: data });

	const [files, setFiles] = useState<File[]>(data?.attachments ?? []);

	useEffect(() => {
		// Override form data when data changes.
		reset(data);
		setFiles(data?.attachments ?? []);
	}, [data]);

	useEffect(() => {
		// Override errors when they change in props.
		OtherFunctions.setFieldErrors(setError, fieldErrors);
	}, [fieldErrors]);

	// Get id from url.
	useEffect(() => {
		if (params.id != null && !isNaN(parseInt(params.id))) {
			const paramsId = parseInt(params.id);
			setId(paramsId);

			if (paramsId == -1) {
				setEditmode(true);
			}
		}
	}, [params.id]);

	async function onDelete() {
		const deleteSuccess = await deleteEntity();

		if (deleteSuccess) {
			navigate("/config/manage/cannedreplies");
		}
	}

	function cancelEdit() {
		setEditmode(false);
		reset(data);

		if (id == -1) {
			navigate("/config/manage/cannedreplies");
		}
	}

	async function formSubmit(data: CannedReply) {
		if (data.id > 0) {
			data.attachments = files;

			const updateSuccess = await updateEntity(data);

			if (updateSuccess) {
				setEditmode(false);
			}
		} else {
			data.attachments = files;

			const newId = await createEntity(data);

			if (newId != null && newId > -1) {
				setEditmode(false);
				navigate("/config/manage/cannedreplies/" + newId);
			}
		}
	}

	const name = watch("name");

	if (id == null || data == null) {
		return null;
	}

	return (
		<form onSubmit={handleSubmit(formSubmit)}>
			<FormHotkeys onSubmit={handleSubmit(formSubmit)} onCancel={cancelEdit} />

			<EntityViewerToolbar
				editmode={editmode}
				isNew={id == -1}
				backUrl="/config/manage/cannedreplies"
				onEdit={() => setEditmode(true)}
				onCancel={cancelEdit}
				onDelete={onDelete}
			/>

			{editmode ?
				<FormParent title={id == -1 ? "New Canned Reply" : (name ?? "")}>

					<div className="px-3">
						<FormError error={formError} />
					</div>

					<FormSection title="Details">
						<W_TextInput
							control={control}
							dataname="name"
							label="Name"
							mandatory
							autoFocus={id < 0}
						/>

						<W_TextInput
							control={control}
							dataname="description"
							label="Description"
						/>
					</FormSection>

					<FormSection title="Attachments">
						<Attachments
							apiCaller={api}
							attachmentUrl={cache.getAttachmentsDir()}
							files={files}
							setFiles={setFiles}
							deleteCannedReplyAttachments
						/>
					</FormSection>

					<FormSection title="Content" className="w-[50rem]">
						<W_HtmlEditor
							control={control}
							dataname="note"
							agentFeatures
							handleCancel={cancelEdit}
							handleSubmit={(note) => {
								setValue("note", note);
								handleSubmit(formSubmit)();
							}}
							cannedReplies={cache.CannedReplies}
							imageUploadHandler={getImageUploadHandler(cache.getTenantId())}
						/>
					</FormSection>
				</FormParent>
				:
				<CannedReplyDisplay data={data} />}
		</form>
	);
}
