import { Control, Controller } from "react-hook-form";
import { Except } from "type-fest";
import HtmlEditor, { HtmlEditorProps } from "./HtmlEditor";
import { ControlError } from "@shared/Components/FormComponents/ControlError";

interface W_HtmlEditorProps extends Except<HtmlEditorProps, "value" | "handleChange"> {
	control: Control<any>;
	className?: string;
}

/**
 * @see W_TextInput
 */
export default function W_HtmlEditor(props: W_HtmlEditorProps) {
	const rules: any = {}; // https://react-hook-form.com/api/useform/register

	if (props.mandatory) {
		rules.required = { value: true, message: "This is a required field." };
	}

	return (
		<Controller
			control={props.control}
			name={props.dataname}
			rules={rules}
			render={({
				field: { onChange, value },
				fieldState: { error },
			}) => (
				<div className={props.className}>
					<HtmlEditor
						{...props}
						value={value}
						handleChange={(_key, newValue) => onChange(newValue)}
					/>

					<ControlError error={error?.message} />
				</div>
			)}
		/>
	);
}
