import { ReactNode } from "react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

interface UseQueryProviderProps {
	children: ReactNode;
}

export const queryClient = new QueryClient();

export function UseQueryProvider(props: UseQueryProviderProps) {
	return (
		<QueryClientProvider client={queryClient}>
			{props.children}
		</QueryClientProvider>
	);
}
