import { IoAddCircleOutline, IoAlertCircleOutline, IoMailUnreadOutline, IoTrashOutline } from "react-icons/io5";
import Tooltip from "@mui/material/Tooltip";
import { DeletedViewID, InboxViewID, UnresolvedViewID } from "@/Store/Reducers/cache/HardCodedViews";
import { useCache } from "@/Hooks/useCache";
import { TicketCounts } from "@/Api/genApi.schemas";
import ViewButton from "./ViewGroup/ViewButton/ViewButton";
import { useAppDispatch, useAppSelector } from "../../../Store/hooks";
import { customFilterOpened, customViewCreated, viewChanged } from "../../../Store/Reducers/ticketsPageSlice";
import { View } from "@shared/Models/View";

interface ViewsTopHalfProps {
	ticketCounts?: TicketCounts;
}

export function ViewsTopHalf(props: ViewsTopHalfProps) {
	const dispatch = useAppDispatch();
	const { cache } = useCache();

	const viewId = useAppSelector(state => state.ticketsPage.viewId);

	// Casting these to Views as they are hardcoded so we know they exist.
	const inboxView = cache.getView(InboxViewID) as View;
	const unresolvedView = cache.getView(UnresolvedViewID) as View;
	const deletedView = cache.getView(DeletedViewID) as View;

	const customViews = cache.Views.filter(view => view.id != null && typeof view.id != "string" && view.id > 0);

	function newViewCreated() {
		dispatch(customViewCreated());

		dispatch(customFilterOpened({
			deleted: { type: "bool", comparison: "equals", value: false },
		}));
	}

	function getCustomViewTicketCount(viewId: number) {
		return props.ticketCounts?.viewCounts[viewId];
	}

	function setActiveView(view: View) {
		dispatch(viewChanged(view));
	}

	return (
		<div className="flex flex-col gap-1" style={{ scrollbarGutter: "auto" }}>
			<Tooltip title="Open ticket that are assigned to you" placement="bottom" disableInteractive enterDelay={500}>
				<div> {/* Tooltip needs this div for some reason. */}
					<ViewButton
						active={viewId == InboxViewID}
						view={inboxView}
						count={props.ticketCounts?.mine}
						icon={<IoMailUnreadOutline size={18} />}
						setActiveView={setActiveView}
					/>
				</div>
			</Tooltip>

			<Tooltip title="All unclosed tickets" placement="bottom" disableInteractive enterDelay={500}>
				<div> {/* Tooltip needs this div for some reason. */}
					<ViewButton
						active={viewId == UnresolvedViewID}
						view={unresolvedView}
						count={props.ticketCounts?.unresolved}
						icon={<IoAlertCircleOutline size={18} />}
						setActiveView={setActiveView}
					/>
				</div>
			</Tooltip>

			{customViews.map(view =>
				<ViewButton
					key={view.id}
					active={viewId == view.id}
					view={view}
					icon={view.icon ?? ""}
					setActiveView={setActiveView}
					count={getCustomViewTicketCount(view.id as number)}
					hasContextMenu
				/>
			)}

			<ViewButton
				active={viewId == DeletedViewID}
				view={deletedView}
				icon={<IoTrashOutline size={18} />}
				setActiveView={setActiveView}
			/>

			<span
				className="view-button relative z-[1] flex h-9 w-full select-none items-center rounded-md px-1 py-2 text-left leading-4 text-gray-500 hover:bg-gray-100 cursor-pointer border border-transparent"
				onClick={newViewCreated}
			>
				<IoAddCircleOutline size={18} className="w-5 mr-2 flex items-center justify-center" />New View
			</span>
		</div>
	);
}
