import { ChangeEvent, useEffect, useState } from "react";
import { DateTime } from "luxon";
import { AiOutlineCalendar } from "react-icons/ai";
import { Popover } from "@mui/material";
import { Button } from "../Button/Button";

interface DateRangePickerProps {
	title: string;
	dateRange: [DateTime, DateTime];
	setDateRange(range: [DateTime, DateTime]): void;
}

enum Range {
	TODAY, LAST7, LAST30, LAST90, CUSTOM, DEV
}

export function DateRangePicker(props: DateRangePickerProps) {
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const [activeRange, setActiveRange] = useState<Range>(Range.LAST7);
	const [localCustomRange, setLocalCustomRange] = useState<[DateTime, DateTime]>(props.dateRange);

	useEffect(() => {
		// Update custom range when the props range changes.
		setLocalCustomRange(props.dateRange);
	}, [props.dateRange]);

	function onChangeValue(event: any) {
		setActiveRange(event.target.value);
	}

	function customFromDateChange(event: ChangeEvent<HTMLInputElement>) {
		const newFromDate = DateTime.fromSQL(event.target.value);
		setLocalCustomRange([newFromDate, localCustomRange[1]]);
	}

	function customToDateChange(event: ChangeEvent<HTMLInputElement>) {
		const newToDate = DateTime.fromSQL(event.target.value);
		setLocalCustomRange([localCustomRange[0], newToDate]);
	}

	function applyClick() {
		const now = DateTime.now();

		if (activeRange == Range.TODAY) {
			const startOfToday = now.startOf("day");
			const endOfToday = now.endOf("day");

			props.setDateRange([startOfToday, endOfToday]);
		} else if (activeRange == Range.LAST7) {
			const startOf7daysAgo = now.minus({ day: 7 }).startOf("day");
			const endOfYesterday = now.minus({ day: 1 }).endOf("day");

			props.setDateRange([startOf7daysAgo, endOfYesterday]);
		} else if (activeRange == Range.LAST30) {
			const startOf30daysAgo = now.minus({ day: 30 }).startOf("day");
			const endOfYesterday = now.minus({ day: 1 }).endOf("day");

			props.setDateRange([startOf30daysAgo, endOfYesterday]);
		} else if (activeRange == Range.LAST90) {
			const startOf90daysAgo = now.minus({ day: 90 }).startOf("day");
			const endOfYesterday = now.minus({ day: 1 }).endOf("day");

			props.setDateRange([startOf90daysAgo, endOfYesterday]);
		} else if (activeRange == Range.CUSTOM) {
			props.setDateRange(localCustomRange);
		} else if (activeRange == Range.DEV) {
			// The date for the dates in the trials.
			const trialDate = DateTime.fromObject({ year: 2022, month: 8, day: 8 });

			const from = trialDate.minus({ day: 7 }).startOf("day");
			const to = trialDate.minus({ day: 1 }).endOf("day");
			props.setDateRange([from, to]);
		}

		setAnchorEl(null);
	}

	return (
		<>
			<div
				onClick={(event: React.MouseEvent<HTMLDivElement>) => setAnchorEl(event.currentTarget)}
			>
				<Button
					icon={<AiOutlineCalendar />}
					className="font-semibold"
					label={getButtonText(props.dateRange)}
				/>
			</div>

			<Popover
				anchorEl={anchorEl}
				open={anchorEl != null}
				onClose={() => setAnchorEl(null)}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "left",
				}}
			>
				<div className="p-3">
					<div className="font-semibold mb-2">
						Reporting Period
					</div>

					<div onChange={onChangeValue}>
						<input
							type="radio"
							name="timePeriod"
							value={Range.TODAY}
							id="radio1"
							checked={activeRange == Range.TODAY}
							onChange={() => {}}
						/> <label htmlFor="radio1" className="ml-2">Today</label> <br />
						<input
							type="radio"
							name="timePeriod"
							value={Range.LAST7}
							id="radio7"
							checked={activeRange == Range.LAST7}
							onChange={() => {}}
						/> <label htmlFor="radio7" className="ml-2">Last 7 days</label> <br />
						<input
							type="radio"
							name="timePeriod"
							value={Range.LAST30}
							id="radio30"
							checked={activeRange == Range.LAST30}
							onChange={() => {}}
						/> <label htmlFor="radio30" className="ml-2">Last 30 days</label> <br />
						<input
							type="radio"
							name="timePeriod"
							value={Range.LAST90}
							id="radio90"
							checked={activeRange == Range.LAST90}
							onChange={() => {}}
						/> <label htmlFor="radio90" className="ml-2">Last 90 days</label> <br />
						{process.env.NODE_ENV == "development" ?
							<><input
								type="radio"
								name="timePeriod"
								value={Range.DEV}
								id="dev"
								checked={activeRange == Range.DEV}
								onChange={() => {}}
							  /> <label htmlFor="dev" className="ml-2">Trial date</label> <br /></>
							: null}
						<input
							type="radio"
							name="timePeriod"
							value={Range.CUSTOM}
							id="custom"
							checked={activeRange == Range.CUSTOM}
							onChange={() => {}}
						/> <label htmlFor="custom" className="ml-2">Custom</label> <br />
					</div>

					<div className="my-1">
						<input
							type="date"
							className="mr-1"
							disabled={activeRange != Range.CUSTOM}
							id="start"
							name="trip-start"
							onChange={customFromDateChange}
							value={localCustomRange[0].toSQLDate() ?? undefined}
						/>
						<input
							type="date"
							disabled={activeRange != Range.CUSTOM}
							id="start"
							name="trip-start"
							onChange={customToDateChange}
							value={localCustomRange[1].toSQLDate() ?? undefined}
						/>
					</div>

					<div className="mt-3">
						<Button btnClass="btn-blue" label="Apply" onClick={() => applyClick()} />
					</div>
				</div>
			</Popover>
		</>
	);
}

function getButtonText(range: [DateTime, DateTime]): string {
	const now = DateTime.now();

	const startOfToday = now.startOf("day");
	const endOfToday = now.endOf("day");
	const endOfYesterday = now.minus({ day: 1 }).endOf("day");
	const startOf7daysAgo = now.minus({ day: 7 }).startOf("day");
	const startOf30daysAgo = now.minus({ day: 30 }).startOf("day");
	const startOf90daysAgo = now.minus({ day: 90 }).startOf("day");

	if (range[0].equals(startOfToday) && range[1].equals(endOfToday)) {
		return "Today";
	} else if (range[0].equals(startOf7daysAgo) && range[1].equals(endOfYesterday)) {
		return "Last 7 days";
	} else if (range[0].equals(startOf30daysAgo) && range[1].equals(endOfYesterday)) {
		return "Last 30 days";
	} else if (range[0].equals(startOf90daysAgo) && range[1].equals(endOfYesterday)) {
		return "Last 90 days";
	} else {
		return range[0].toSQLDate() + " - " + range[1].toSQLDate();
	}
}
