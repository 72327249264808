import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { useForm } from "react-hook-form";
import ImageUpload from "@/Components/FormComponents/ImageUpload/ImageUpload";
import OtherFunctions from "@/Helpers/OtherFunctions";
import { ApiUpsertSettingsLogo, ApiUpsertSettingsFavicon, ApiUpsertSettingsBanner } from "@/legacy/ApiCallerOld";
import { Button } from "@/Components/Button/Button";
import { FormParent } from "@shared/Components/Form/FormParent";
import { FormSection } from "@shared/Components/Form/FormSection";
import TextInput from "@shared/Components/FormComponents/TextInput/TextInput";
import { Setting } from "@shared/Entities/EntityTypes";
import { AlertBox } from "@shared/Components/AlertBox/AlertBox";

interface SettingsBrandingPageProps {
	data?: Setting[];
	updateData(settingsData: Setting[]): void;
	refreshData?(): void;
}

export function SettingsBrandingPage(props: SettingsBrandingPageProps) {
	const [data, setData] = useState<Map<string, string | undefined | null> | undefined>();
	const { handleSubmit, getValues } = useForm();

	const [logo, setLogo] = useState<any>({});
	const [favicon, setFavicon] = useState<any>({});
	const [banner, setBanner] = useState<any>({});

	const navigate = useNavigate();

	useEffect(() => {
		const settingsMap = new Map();

		if (props.data != null) {
			props.data.forEach((setting) => {
				settingsMap.set(setting.key, setting.value);
			});
		}

		setData(settingsMap);
	}, [props.data]);

	async function formSubmit() {
		if (data != null) {
			const dataCopy = new Map(data);

			// Logo
			if (Object.keys(logo).length !== 0) {
				const logoPath = data.get("helpdesk.logo");

				const response = await ApiUpsertSettingsLogo(logoPath ?? "", logo.pictureData, logo.pictureMimetype);

				if (response.successful) {
					const newFileName = String(response.data);

					// Push the logo without waiting for async state change
					dataCopy.set("helpdesk.logo", newFileName);
				} else {
					toast.error("Error. " + response.errorCode + ": " + response.errorMsg);
				}
			}

			// Favicon
			if (Object.keys(favicon).length !== 0) {
				const faviconPath = data.get("helpdesk.favicon.path");

				const response = await ApiUpsertSettingsFavicon(faviconPath ?? "", favicon.pictureData, favicon.pictureMimetype);

				if (response.successful) {
					const newFileName = response.data == undefined ? null : response.data;

					// Push the favicon without waiting for async state change
					dataCopy.set("helpdesk.favicon.path", newFileName);

					toast.success("Favicon updated. Please refresh the page.");
				} else {
					toast.error("Error. " + response.errorCode + ": " + response.errorMsg);
				}
			}

			// Banner
			if (Object.keys(banner).length !== 0) {
				const bannerPath = data.get("helpdesk.banner.path");

				const response = await ApiUpsertSettingsBanner(bannerPath ?? "", banner.pictureData, banner.pictureMimetype);

				if (response.successful) {
					const newFileName = response.data == undefined ? null : response.data;

					// Push the banner without waiting for async state change
					dataCopy.set("helpdesk.banner.path", newFileName);

					toast.success("Banner updated. Please refresh the page.");
				} else {
					toast.error("Error. " + response.errorCode + ": " + response.errorMsg);
				}
			}

			const settings: Setting[] = [];

			dataCopy.forEach((value, key) => {
				settings.push({ key: key, value: value });
			});


			props.updateData(settings);
			navigate("/config/");
		}
	}

	function handleChange(key: string, newValue: string | null | undefined) {
		const dataCopy = new Map(data ?? []);

		if (newValue != null) {
			dataCopy.set(key, newValue);
		} else {
			dataCopy.set(key, undefined);
		}

		setData(dataCopy);
	}


	function newLogoCallback(dataBase64: string, mimeType: string) {
		let newData = Object.assign({}, getValues());
		newData = OtherFunctions.SafeChangeProperty(newData, "pictureData", dataBase64);
		newData = OtherFunctions.SafeChangeProperty(newData, "pictureMimetype", mimeType);

		setLogo(newData);
	}

	function newFaviconCallback(dataBase64: string, mimeType: string) {
		let newData = Object.assign({}, getValues());
		newData = OtherFunctions.SafeChangeProperty(newData, "pictureData", dataBase64);
		newData = OtherFunctions.SafeChangeProperty(newData, "pictureMimetype", mimeType);

		setFavicon(newData);
	}

	function newKbBannerCallback(dataBase64: string, mimeType: string) {
		let newData = Object.assign({}, getValues());
		newData = OtherFunctions.SafeChangeProperty(newData, "pictureData", dataBase64);
		newData = OtherFunctions.SafeChangeProperty(newData, "pictureMimetype", mimeType);

		setBanner(newData);
	}

	function cancelButtonClick() {
		navigate("/config");
	}

	return (
		<form onSubmit={handleSubmit(formSubmit)} >

			<FormParent
				title="Branding"
				description="Customise the apps images and url."
			>

				<FormSection title="CNAME">

					<div className="mb-2">
						Set up a custom URL for GoDesk.
					</div>
					<Button label="Setup CNAME" linkTo="/config/cname" btnClass="btn-blue" />
				</FormSection>

				<FormSection title="Default Knowledge Base images">
					<AlertBox alertType="info" >
						Each knowledge base can have its own set of images; the ones below are the defaults.
					</AlertBox>

					<div className="my-5">
						<ImageUpload
							label="Default favicon"
							fileUrl={data?.get("helpdesk.favicon.path") != "undefined" ? data?.get("helpdesk.favicon.path") ?? "" : ""}
							fileBase64={favicon.pictureData}
							updateFile={newFaviconCallback}
							height={40}
							width={"100%"}
							defaultImg={"/favicon.ico"}
						/>
					</div>

					<div className="my-5">
						<ImageUpload
							label="Default knowledge base logo (display height: 40px)"
							fileUrl={data?.get("helpdesk.logo") != "undefined" ? data?.get("helpdesk.logo") ?? "" : ""}
							fileBase64={logo.pictureData}
							updateFile={newLogoCallback}
							height={40}
							width={"100%"}
							defaultImg={"/logowithtext.png"}
						/>
					</div>

					<div className="my-5">
						<ImageUpload
							label="Default knowledge base banner (display height: 256px)"
							fileUrl={data?.get("helpdesk.banner.path") != "undefined" ? data?.get("helpdesk.banner.path") ?? "" : ""}
							fileBase64={banner.pictureData}
							updateFile={newKbBannerCallback}
							height={100}
							width={"100%"}
							defaultImg={"/knowledgebase.png"}
						/>
					</div>
				</FormSection>
			</FormParent>

			<div className="flex items-center ml-4 mb-4">
				<Button className="" onClick={cancelButtonClick} label="Cancel" />
				<input type="submit" className="ml-2 btn-blue" value="Update" />
			</div>
		</form>

	);
}
