import { Control, Controller, FieldPath, FieldValues, RegisterOptions } from "react-hook-form";
import { Except } from "type-fest";
import { NumberField, NumberFieldProps } from "@shared/Components/FormComponents/NumberField/NumberField";

type W_NumberFieldProps<T extends FieldValues> = Except<NumberFieldProps, "onChange" | "value"> & {
	control: Control<T>;
	name: FieldPath<T>;
};

export function W_NumberField<T extends FieldValues>(props: W_NumberFieldProps<T>) {
	const rules: RegisterOptions<T> = {}; // https://react-hook-form.com/api/useform/register

	if (props.required) {
		rules.required = { value: true, message: "This is a required field." };
	}

	return (
		<Controller
			control={props.control}
			name={props.name}
			rules={rules}
			render={({
				field: { onChange, value },
				fieldState: { error },
			}) =>
				<NumberField
					{...props}
					onChange={onChange}
					value={value}
					error={error != null}
					helperText={error?.message != null ? error?.message : props.helperText}
				/>
			}
		/>
	);
}
