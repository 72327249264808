import { FieldErrorCode } from "../../Enums/Enums";

/**
 * Handles special error messages because react-hook-form can only handle string errors.
 */
export class SpecialControlErrorMessage {
	readonly errorCode;
	readonly errorData;

	constructor(fieldErrorCode: FieldErrorCode, data: number) {
		this.errorCode = fieldErrorCode;
		this.errorData = data;
	}

	/**
	 * Creates a custom error message to be handled differently.
	 * @returns A special error code.
	 */
	serialise(): string {
		return `#ERR#code=${this.errorCode}#data=${this.errorData}#`;
	}

	/**
	 * Converts a special error message string to a SpecialControlErrorMessage obj like {code: 2, data: 2}.
	 * @param error ........ Expects something like: #ERR#code=2#data=2#
	 * @returns A SpecialControlErrorMessage obj i.e. {code: 2, data: 2}
	 */
	static fromString(error: string): SpecialControlErrorMessage | undefined {
		if (this.isSpecialError(error)) {
			const parts = error.split("#").filter(part => part !== "");

			const codePart = parts.find(part => part.startsWith("code="));
			const dataPart = parts.find(part => part.startsWith("data="));

			if (codePart && dataPart) {
				const code = parseInt(codePart.split("=")[1]);
				const data = parseInt(dataPart.split("=")[1]);

				return new SpecialControlErrorMessage(code, data);
			}
		}
	}

	static isSpecialError(error: string): boolean {
		return error.startsWith("#ERR#");
	}

}
