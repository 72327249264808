
export interface Pagination {
	total?: number;
	offset: number;
	limit: number;
}

export enum FieldErrorCode {
	EMAIL_TAKEN_BY_USER,
	EMAIL_TAKEN_BY_EMAIL_CHANNEL,
	EMAIL_TAKEN_BY_AGENT
}

export enum ConnectionStatuses {
	GREEN = "GREEN",
	ORANGE = "ORANGE",
	RED = "RED"
}

export enum EmailSecurity {
	STARTTLS = "STARTTLS",
	TLSSSL = "TLSSSL"
}

export enum TicketClosePolicy {
	NEVER = "0",
	ONE_WEEK = "1",
	ONE_MONTH = "2",
	THREE_MONTHS = "3"
}

export enum ViewVisibility {
	PRIVATE,
	TEAMS,
	AGENTS,
	GLOBAL
}

export enum TicketRetentionPolicy {
	NEVER = "0",
	ONE_MONTH = "1",
	THREE_MONTHS = "2",
	ONE_YEAR = "3"
}

export enum EmailNotifications {
	USER_TICKETRECEIPT = 1,
	USER_OOHTICKETRESPONSE = 2
}

export enum SearchPageTabs {
	AGENT = "agent",
	COMPANY = "company",
	TICKET = "ticket",
	USER = "user",
	KBARTICLE = "kbarticle"
}

export enum TicketStatuses {
	OPEN = 1,
	WITHUSER = 2,
	CLOSED = 3
}

export type TailwindBreakpoint = "sm" | "md" | "lg" | "xl" | "2xl";

/** The ID of the GoDesk remote support agent. */
export const GD_SUPPORT_AGENT_ID = 3;

export interface TableSort {
	sortField?: string;
	sortType?: "asc" | "desc";
}

// Enums

export enum ActionSystemUse {
	PUBLIC_NOTE,
	PRIVATE_NOTE,
	CLOSE,
	MERGE,
	DELETE,
	UNDELETE,
	SPAM
}

export enum AutoAssignMethod {
	ROUND_ROBIN,
	LOAD_BALANCE
}

export enum AgentStatus {
	OFFLINE = 0,
	AVAILABLE = 1,
	IDLE = 2,
	DND = 3, // Do not disturb
	OOO = 4 // Out of office
}

export enum CustomFieldEntity {
	COMPANY = "COMPANY",
	USER = "USER"
}

export enum CustomFieldInputType {
	TEXT_INPUT = "TEXT_INPUT",
	TEXT_AREA = "TEXT_AREA",
	DROPDOWN = "DROPDOWN",
	MULTISELECT = "MULTISELECT"
}
