import { CSSProperties } from "react";
import { Link } from "react-router-dom";
import { FaCircle } from "react-icons/fa";
import { ICellRendererParams } from "@ag-grid-community/core";
import Tooltip from "@mui/material/Tooltip";
import { FaEye } from "react-icons/fa6";
import { EnrichedCache, CacheSlices } from "@/Store/Reducers/cache/CacheTypes";
import { UserAvatar } from "@/Components/Avatar/UserAvatar";
import { AgentAvatar } from "@/Components/Avatar/AgentAvatar";
import { CollisionNote } from "@/Api/genApi.schemas";
import { Badge } from "../Badge/Badge";
import { SlaBadge } from "./SlaBadge";
import { CacheFunctions } from "../../Helpers/CacheFunctions";
import { AppDispatch } from "../../Store/store";
import { BadgeColor } from "../Badge/BadgeColor";
import { TicketStatuses } from "@shared/Enums/Enums";
import { datetimeToLocaleString, datetimeToWhenString } from "@shared/Helpers/DateFunctions";

enum AvatarEntities {
	AGENT,
	COMPANY,
	USER
}

export enum CellRenderers {
	IdLinkAutomation,
	IdLinkCannedReply,
	IdLinkChannelsEmail,
	IdLinkKbArticle,
	IdLinkSla,
	IdLinkTeam,
	IdLinkTicket,
	IdLinkUser,
	IdLinkView,

	AgentListAgentAvatar,
	AgentAvatar,
	UserAvatar,
	UserListUserAvatar,

	Boolean,
	NumberNoZeros,
	SlaStatus,
	KbArticleStatus,
	TicketDetails,
	PriorityIcon,
	DateHover,
}

export function getRenderer(renderer: CellRenderers, cache: EnrichedCache, dispatch: AppDispatch): Function {
	switch (renderer) {
		case CellRenderers.IdLinkAutomation: return IdLink("/config/automations/");
		case CellRenderers.IdLinkCannedReply: return IdLink("/config/manage/cannedreplies/");
		case CellRenderers.IdLinkChannelsEmail: return IdLink("/config/manage/channels/email/");
		case CellRenderers.IdLinkKbArticle: return IdLink("/kb/article/");
		case CellRenderers.IdLinkSla: return IdLink("/config/manage/slas/");
		case CellRenderers.IdLinkTeam: return IdLink("/config/manage/teams/");
		case CellRenderers.IdLinkTicket: return TicketIdLink("/tickets/", cache);
		case CellRenderers.IdLinkUser: return IdLink("/users/");
		case CellRenderers.IdLinkView: return IdLink("/config/manage/views/");

		case CellRenderers.AgentAvatar: return avatarAndNameRenderer(cache, dispatch, AvatarEntities.AGENT);
		case CellRenderers.UserAvatar: return avatarAndNameRenderer(cache, dispatch, AvatarEntities.USER);

		case CellRenderers.AgentListAgentAvatar: return avatarAndNameRenderer(cache, dispatch, AvatarEntities.AGENT, "id");
		case CellRenderers.UserListUserAvatar: return avatarAndNameRenderer(cache, dispatch, AvatarEntities.USER, "id");

		case CellRenderers.Boolean: return booleanRenderer;
		case CellRenderers.NumberNoZeros: return numberNoZerosRenderer;
		case CellRenderers.SlaStatus: return slaStatusRenderer;
		case CellRenderers.KbArticleStatus: return kbArticleStatusRenderer;
		case CellRenderers.TicketDetails: return ticketDetailsRenderer;
		case CellRenderers.PriorityIcon: return priorityIconRenderer(cache, dispatch);
		case CellRenderers.DateHover: return hoverableDateRenderer;
	}
}

export function IdLink(url: string) {
	return (params: ICellRendererParams) => {
		if (params.data["id"] != null && params.data["id"] > 0) {
			return <Link to={url + params.data["id"]}>{params.value}</Link>;
		} else {
			return params.value;
		}
	};
}

function TicketIdLink(url: string, cache: EnrichedCache) {
	return (params: ICellRendererParams) => {
		const collisionNotes: CollisionNote[] | undefined = params.data["collisionNotes"];

		const activeCollisionNotes = collisionNotes?.filter(t => t.entityId == params.data["id"]);

		let collisionTooltipNote = "";

		if (activeCollisionNotes != null && activeCollisionNotes?.length > 0) {
			activeCollisionNotes?.forEach(note => {
				collisionTooltipNote += cache.getAgent(note.agentId)?.name + " is " + note.action.toLowerCase() + " this ticket\n";
			});
		}

		if (params.data["id"] != null && params.data["id"] > 0 && params.data["collisionNotes"] != null) {
			if (activeCollisionNotes != null && activeCollisionNotes.length > 0 && collisionTooltipNote != "") {
				return (
					<Tooltip title={collisionTooltipNote ?? ""} placement="bottom">
						<Link to={url + params.data["id"]}>{params.value}
							<span className="pl-4">
								<FaEye />
							</span>
						</Link>
					</Tooltip>
				);
			} else {
				return <Link to={url + params.data["id"]}>{params.value}</Link>;
			}
		} else {
			return params.value;
		}
	};
}

function booleanRenderer(params: ICellRendererParams) {
	if (params.value == true) {
		return "True";
	} else {
		return "False";
	}
}

function numberNoZerosRenderer(params: ICellRendererParams) {
	if (params.value == 0) {
		return "-";
	} else {
		return params.value;
	}
}

function kbArticleStatusRenderer(params: ICellRendererParams) {
	if (params.value == true) {
		return <Badge color={BadgeColor.GREEN} label="Published" />;
	} else {
		return <Badge color={BadgeColor.GREY} label="Draft" />;
	}
}

function ticketDetailsRenderer(params: ICellRendererParams) {
	if (params.data["id"] != null && params.data["id"] > 0) {
		const style: CSSProperties = params.data["ticketread"] == null || params.data["ticketread"] == false ? { fontWeight: "bold" } : {};

		return <Link to={"/tickets/" + params.data["id"]} style={style}>{params.value}</Link>;
	} else {
		return params.value;
	}
}

function slaStatusRenderer(params: ICellRendererParams) {
	if (params.data["slaId"] != null && params.data["deleted"] != true && params.data["statusId"] != TicketStatuses.CLOSED) {
		return <SlaBadge responseDueISO={params.value} paused={params.data["statusId"] != TicketStatuses.OPEN} />;
	} else {
		return null;
	}
}

function priorityIconRenderer(cache: EnrichedCache, dispatch: AppDispatch) {
	return (params: ICellRendererParams) => {
		let iconHexCode = "#CCC";
		let icon = null;

		if (params.value != null && params.value != "") {
			switch (params.value) {
				case 1: iconHexCode = "#F03232"; break;
				case 2: iconHexCode = "#F07E32"; break;
				case 3: iconHexCode = "#F0E332"; break;
				case 4: iconHexCode = "#4EDB27"; break;
				case 5: iconHexCode = "#D5D5D5"; break;
			}

			icon = <FaCircle className="mr-1" style={{ color: iconHexCode }} />;
		}

		const name = CacheFunctions.getNameFromCache(params.value, cache, CacheSlices.Priorities, dispatch);

		return <div className="flex items-center">{icon} {name}</div>;
	};
}

function hoverableDateRenderer(params: ICellRendererParams) {
	const dateLocale = datetimeToLocaleString(params.value);
	const dateFormatted = datetimeToWhenString(params.value);

	return <span title={dateLocale}>{dateFormatted}</span>;
}

function avatarAndNameRenderer(cache: EnrichedCache, dispatch: AppDispatch, avatarEntity: AvatarEntities, idField?: string) {
	return (params: ICellRendererParams) => {
		const id = idField != null ? params.data[idField] : params.value;

		let name;
		let deleted;
		let agent;
		let user;
		let avatar;

		if (avatarEntity == AvatarEntities.AGENT) {
			// The first time this is called we don't have the entity.
			name = CacheFunctions.getNameFromCache(id, cache, CacheSlices.Agents, dispatch); // This has a side effect that refreshes the cache if it does not find a entity.

			agent = cache.getAgent(id);

			avatar = <AgentAvatar widthPx={25} agent={agent} />;
			deleted = agent?.deleted;
		} else if (avatarEntity == AvatarEntities.USER) {
			name = CacheFunctions.getNameFromCache(id, cache, CacheSlices.Users, dispatch);

			user = cache.getUser(id);

			avatar = <UserAvatar widthPx={25} user={user} />;
			deleted = user?.deleted;
		}

		if (id != null && id > 0 && name != null) { // User/ agent is valid.
			if (deleted) {
				return <>
					<div style={{ marginRight: "6px", display: "flex" }}>
						{avatar}
					</div>
					<span className="text-gray-700">{name}</span>
				</>;
			} else {
				return <Link to={(avatarEntity === AvatarEntities.AGENT ? "/config/manage/agents/" : "/users/") + id} className="blue-link flex items-center overflow-hidden dec" style={{ textDecoration: "none" }}>
					<div style={{ marginRight: "6px", display: "flex" }}>
						{avatar}
					</div>
					<div className="flex-1 truncate">
						{name}
						{user?.isPrimaryContact &&
							<Tooltip title="Primary contact">
								<span className="pl-1">{"\u2B50"}</span>
							</Tooltip>
						}
					</div>
				</Link>;

			}
		} else {
			return params.value;
		}
	};
}
