import { Hotkeys } from "@shared/lib/Hotkeys";

interface FormHotkeysProps {
	onSubmit(): void;
	onCancel?(): void;
}

export function FormHotkeys(props: FormHotkeysProps) {
	return (
		<>
			<Hotkeys
				hotkeys="Control+Enter"
				callback={props.onSubmit}
				options={{ ignoredElementWhitelist: ["INPUT", "TEXTAREA"] }}
			/>

			{props.onCancel &&
				<Hotkeys
					hotkeys="Escape"
					callback={props.onCancel}
					options={{ ignoredElementWhitelist: ["INPUT", "TEXTAREA"] }}
				/>}
		</>
	);
}
