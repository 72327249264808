import { useEffect, useState } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { RiArticleLine } from "react-icons/ri";
import { Tooltip, IconButton } from "@mui/material";
import { FaRegFolder } from "react-icons/fa";
import { MdAdd } from "react-icons/md";
import { FaPencil } from "react-icons/fa6";
import { FaExternalLinkAlt } from "react-icons/fa";
import toast from "react-hot-toast";
import CacheDropdown from "@/Components/FormComponents/CacheDropdown/CacheDropdown";
import KbModal from "@/Pages/KB/KbModal";
import { SkeletonKbFolder } from "@/Pages/KB/SkeletonKbFolder";
import { KbTreeView } from "@/Pages/KB/KbTreeView";
import KbFolderModal from "@/Components/Utility/Modals/KbfolderModal";
import KbFolderDisplay from "@/Pages/KB/KbFolderDisplay";
import KbArticleViewer from "@/Fragments/EntityViewer/EntitySpecific/KbArticleViewer";
import { KbArticleTable } from "@/Components/EntityTable/Tables/KbArticleTable";
import { useCache } from "@/Hooks/useCache";
import { CacheSlices } from "@/Store/Reducers/cache/CacheTypes";
import api from "@/Api/Api";
import { useAgent } from "@/Hooks/useAgent";
import { Button } from "@/Components/Button/Button";
import { NewFeatureBanner } from "@/Pages/home/NewFeatureBanner";
import { Kb, KbFolder } from "@shared/Entities/EntityTypes";
import { View } from "@shared/Models/View";
import { Entities } from "@shared/Entities/Entities";
import { SettingBool } from "@shared/Enums/SettingEnums";

export default function KB() {
	document.title = "KB | GoDesk";

	const navigate = useNavigate();

	const agent = useAgent();
	const { cache } = useCache();
	const portalUrl = cache.getPortalURL();

	const [kbId, setKbId] = useState(1);
	const [kb, setKb] = useState<Kb | null>(null);
	const [newFolderModalOpen, setNewFolderModalOpen] = useState<boolean>(false);
	const [newKbModalOpen, setNewKbModalOpen] = useState<boolean>(false);
	const [editKbModalOpen, setEditKbModalOpen] = useState<boolean>(false);

	useEffect(() => {
		doFetch();
	}, [kbId]);

	async function doFetch() {
		const res = await api.getEntity<Kb>(Entities.KB, kbId);

		if (res.successful && res.data != null) {
			setKb(res.data);
		} else {
			toast.error("Error. " + res.errorCode + ": " + res.errorMsg);
		}
	}

	let allCount = 0;
	kb?.sections?.flatMap(flattenKbFolder).forEach(section => allCount += section.articles.length);

	let draftCount = 0;
	kb?.sections?.flatMap(flattenKbFolder).forEach(section => {
		const draftArticles = section.articles.filter(article => !article.published);
		draftCount += draftArticles.length;
	});

	const path = window.location.pathname;
	let viewer = null;
	let viewerStyles;

	if (path == "/kb") {
		const flattenedFolders = flattenKbFolders(kb?.sections ?? []);

		viewer =
			<>
				<div>
					{!cache.getSettingBool(SettingBool.GPT_INSTRUCTIONS_SAVED) && agent.isAdmin &&
						<NewFeatureBanner link="/config/ai/assistant" title="AI Powered KB Article Drafts" />
					}
					<div className="flex flex-row flex-wrap gap-10 p-10">
						{flattenedFolders.map(section =>
							<KbFolderDisplay data={section} refreshData={doFetch} key={section.id} />
						)}

						<SkeletonKbFolder onClick={() => setNewFolderModalOpen(true)} />
					</div>
				</div>
			</>;
	} else if (path == "/kb/all") {
		const view: View = {
			filters: {
				kbId: { type: "number", comparison: "equals", value: kbId }
			}
		};
		viewer = <KbArticleTable onMultiDelete={doFetch} view={view} />;
		viewerStyles = { padding: "0.5rem" };
	} else if (path == "/kb/drafts") {
		const view: View = {
			filters: {
				published: { type: "bool", comparison: "equals", value: false },
				kbId: { type: "number", comparison: "equals", value: kbId }
			}
		};

		viewer = <KbArticleTable
			onMultiDelete={doFetch}
			view={view}
		         />;
		viewerStyles = { padding: "0.5rem" };
	} else if (path.includes("/kb/article/")) {
		viewer = <KbArticleViewer onUpdate={doFetch} />;
	}

	let currentKb: Kb | undefined;

	if (kb != null) {
		currentKb = kb;
	}

	function selectDefaultKb() {
		setKbId(1);
	}

	return (
		<div className="flex h-full">
			<div className="w-[280px] border-r border-gray-300 h-full flex flex-col">
				<div className="border-b border-gray-300 p-4">
					<div className="mb-3">
						<div className="font-semibold flex justify-between">
							<div className="">Knowledge Base</div>
						</div>

						<div className="flex items-center">
							<div className="min-w-[180px] min-h-[36px]">
								<CacheDropdown
									cacheSlice={CacheSlices.Kbs}
									dataname=""
									value={kbId}
									mandatory
									onChange={(_key, newValue) => setKbId(newValue ?? 1)}
								/>
							</div>

							<Tooltip title="Edit knowledge base">
								<IconButton onClick={() => setEditKbModalOpen(true)}>
									<FaPencil className="text-base" />
								</IconButton>
							</Tooltip>

							<Tooltip title="Open knowledge base">
								<IconButton>
									<Link target="_blank" to={portalUrl + kbId + "/" + "en_GB"}>
										<FaExternalLinkAlt className="text-base" />
									</Link>
								</IconButton>
							</Tooltip>
						</div>
					</div>

					{newKbModalOpen &&
						<KbModal
							closeModal={() => setNewKbModalOpen(false)}
							isOpen={newKbModalOpen}
							refreshData={doFetch}
							onDelete={selectDefaultKb}
						/>}

					{editKbModalOpen &&
						<KbModal
							closeModal={() => setEditKbModalOpen(false)}
							isOpen={editKbModalOpen}
							refreshData={doFetch}
							data={currentKb}
							onDelete={selectDefaultKb}
						/>}

					<Button
						icon={<MdAdd size={16} />}
						onClick={() => setNewKbModalOpen(true)}
						className="bg-white w-[207px] mb-2"
						label="Create knowledge base"
					/>

					<div className="flex gap-2">
						<Button
							icon={<RiArticleLine />}
							onClick={() => navigate("/kb/article/-1")}
							className="bg-white"
							label="New article"
						/>

						<Button
							icon={<FaRegFolder />}
							onClick={() => setNewFolderModalOpen(true)}
							className="bg-white"
							label="Create folder"
						/>
					</div>
				</div>

				<TopButton title="Preview" link="/kb" />
				<TopButton title="All Articles" count={allCount} link="/kb/all" />
				<TopButton title="Drafts" count={draftCount} link="/kb/drafts" />

				<div className="p-2 overflow-y-auto grow" >
					<KbTreeView folders={kb?.sections ?? []} />
				</div>
			</div>
			<div className="h-full grow overflow-y-auto" style={viewerStyles}>
				{viewer}
			</div>

			<KbFolderModal
				refreshData={doFetch}
				closeModal={() => setNewFolderModalOpen(false)}
				isOpen={newFolderModalOpen}
				data={{ id: 0, kbId: kbId, name: "", articles: [] }}
			/>
		</div>
	);
}

function flattenKbFolders(folders: KbFolder[]): KbFolder[] {
	const sortedFolders: KbFolder[] = [];

	folders.forEach(folder => {
		sortedFolders.push(...flattenKbFolder(folder));
	});

	return sortedFolders;
}

function flattenKbFolder(folder: KbFolder): KbFolder[] {
	const sortedFolders: KbFolder[] = [];

	sortedFolders.push(folder);

	// Recursive call so we get children's children.
	if (folder.childFolders != null) {
		folder.childFolders.forEach(folder => {
			sortedFolders.push(...flattenKbFolder(folder));
		});
	}

	return sortedFolders;
}

interface TopButtonProps {
	title?: string;
	count?: number;
	link: string;
}

function TopButton(props: TopButtonProps) {
	const navigate = useNavigate();
	const location = useLocation();

	const active = location.pathname == props.link;
	const bgStyles = active ? " bg-blue-100" : " hover:bg-gray-100 active:bg-gray-200";

	function onClick() {
		navigate(props.link);
	}

	return (
		<button onClick={onClick} className={"flex px-4 py-3 border-b border-gray-300 w-full " + bgStyles}>
			<div className="flex-grow text-left my-auto">
				{props.title}
			</div>
			{props.count != null ?
				<div className="my-auto" title="">
					<RiArticleLine /> {props.count}
				</div>
				: null}
		</button>
	);
}
