import { BiCheckCircle, BiTrash } from "react-icons/bi";
import { GoNote } from "react-icons/go";
import { HiOutlineMail } from "react-icons/hi";
import { RiSpam2Line } from "react-icons/ri";
import { TicketStatuses } from "@shared/Enums/Enums";
import { Ticket } from "@shared/Entities/EntityTypes";

interface TicketActionButtonsProps {
	ticket: Ticket;
	addReply(): void;
	addPrivateNote(): void;
	closeTicket(): void;
	deleteTicket(): void;
	undeleteTicket(): void;
}

export function ActionButtons(props: TicketActionButtonsProps) {
	const userAssigned = props.ticket.userId != undefined;

	return (
		<div className="border-t border-gray-300 p-3">
			{userAssigned ?
				<ActionButton
					name="Reply"
					icon={<HiOutlineMail />}
					onClick={props.addReply}
				/>
				:
				<ActionButton
					name="Reply (No user assigned)"
					icon={<HiOutlineMail />}
				/>
			}

			<ActionButton
				name="Private note"
				icon={<GoNote />}
				onClick={props.addPrivateNote}
			/>

			{props.ticket.statusId != TicketStatuses.CLOSED &&
				<ActionButton
					name="Close"
					icon={<BiCheckCircle />}
					onClick={props.closeTicket}
				/>
			}

			{!props.ticket.deleted ?
				<ActionButton
					name="Delete"
					icon={<BiTrash />}
					onClick={props.deleteTicket}
				/>
				:
				<ActionButton
					name="Un-Delete"
					icon={<RiSpam2Line />}
					onClick={props.undeleteTicket}
				/>
			}
		</div>
	);
}

interface ActionButtonProps {
	name: string;
	icon: JSX.Element;
	onClick?(): void;
}

export function ActionButton(props: ActionButtonProps) {
	return (
		<button
			className="btn-grey block shadow-none my-1.5 w-full text-left"
			onClick={props.onClick}
			disabled={props.onClick == undefined}
		>
			<span className="mx-2 align-middle">
				{props.icon}
			</span>

			{props.name}
		</button>
	);
}
