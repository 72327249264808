import { ReactNode } from "react";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import store, { persistor } from "@/Store/store";

interface StoreProviderProps {
	children?: ReactNode;
}

export function StoreProvider(props: StoreProviderProps) {
	return (
		<Provider store={store}>
			<PersistGate loading={null} persistor={persistor}>
				{props.children}
			</PersistGate>
		</Provider>
	);
}
