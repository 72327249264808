import { getAccessToken } from "@/legacy/ApiAuth";
import { ApiCallerBase } from "@shared/Api/ApiCallerBase";

function getApiUrl() {
	if (process.env.NODE_ENV == "development") {
		return `http://${window.location.hostname}:8082/importer`;
	}

	return "/importer";
}

const apiCallerImporter = new ApiCallerBase(getApiUrl, getAccessToken);

export default apiCallerImporter;
