import { useRef } from "react";
import { useCache } from "@/Hooks/useCache";
import { getBase64 } from "@shared/Helpers/OtherFunctions";

interface ImageUploadProps {
	fileBase64?: string;
	fileUrl?: string;
	label?: string;
	width?: number | string;
	height?: number;
	defaultImg?: string;
	updateFile?(dataBase64: string, mimeType: string): void;
}

export default function ImageUpload(props: ImageUploadProps) {
	const inputRef = useRef<any>();
	const { cache } = useCache();

	async function onChangeHandler(event: any) {
		if (event.target != null && event.target.files != null && event.target.files[0]) {
			const file: Blob = event.target.files[0];

			if (file.size > 16000000) {
				console.log("FILE TOO BIG.");
			} else {
				const avatarData = String(await getBase64(file));
				// let avatarBase64 = btoa(avatarData);
				const mimeType = file.type;

				if (avatarData != null) {
					if (props.updateFile != null) {
						props.updateFile(avatarData, mimeType);
					}
				}
			}
		}
	}

	function deleteFile() {
		if (props.updateFile != null) {
			props.updateFile("-1", "");
		}
	}

	const widthPx = (props.width ?? "40") + "px";
	const heightPx = (props.height ?? "40") + "px";

	let imgJsx = null;

	if (props.fileBase64 != null) {
		imgJsx = <img src={props.fileBase64 != "-1" ? props.fileBase64 : props.defaultImg} alt={""} className="" style={{ height: heightPx, width: widthPx }} />;
	} else if (props.fileUrl != null && props.fileUrl != "") {
		const url = cache.getPicturesDir() + props.fileUrl;

		imgJsx = <img src={url} alt="" className="" style={{ height: heightPx, width: widthPx }} />;
	} else {
		if (props.defaultImg) {
			imgJsx = <img src={props.defaultImg} alt={""} className="" style={{ height: heightPx, width: widthPx }} />;
		} else {
			imgJsx = <div className="" style={{ height: heightPx, width: widthPx }} />;
		}
	}

	return (
		<div>
			<div className="block text-sm mb-1">
				{props.label}
			</div>

			<div className="flex">
				<div className="mr-2 flex border rounded border-gray-200">
					{imgJsx}
				</div>

				<div className="flex flex-col">
					<p className="cursor-pointer text-blue-700 m-0 mb-auto" onClick={() => props.updateFile != null ? inputRef.current.click() : null }>Upload new image</p>
					<p className="cursor-pointer text-blue-700 m-0" onClick={deleteFile}>Remove</p>
				</div>
			</div>

			{/* WARNING: Adding a file larger than 2MB to the /public dir will trigger a refresh in dev mode. Do not be alarmed! */}
			<input
				type="file"
				ref={inputRef}
				accept="image/*"
				onChange={onChangeHandler}
				style={{ display: "none" }}
			/>
		</div>
	);
}
