import { Control, Controller, FieldPath, FieldValues, RegisterOptions } from "react-hook-form";
import type { Except } from "type-fest";
import { NewDropdown, NewDropdownProps } from "@shared/Components/FormComponents/NewDropdown/NewDropdown";

export type W_NewDropdownProps<T extends number | string, T2 extends FieldValues> = Except<NewDropdownProps<T>, "name"> & {
	control: Control<T2>;
	name: FieldPath<T2>;
};

/**
 * @see W_TextInput
 */
export function W_NewDropdown<T extends number | string, T2 extends FieldValues>(props: W_NewDropdownProps<T, T2>) {
	const rules: RegisterOptions<T2> = {}; // https://react-hook-form.com/api/useform/register

	if (props.required) {
		rules.required = { value: true, message: "This is a required field." };
	}

	return (
		<Controller
			control={props.control}
			name={props.name}
			rules={rules}
			render={({
				field: { onChange, value },
				fieldState: { error },
			}) => (
				<>
					<NewDropdown<T>
						{...props}
						onSelect={onChange}
						value={value}
						error={error != null}

						helperText={error?.message != null ? error?.message : props.helperText}
					/>
				</>
			)}
		/>
	);
}
