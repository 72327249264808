
export function getApiUrl() {
	let apiUrl = "/backend/";

	if (process.env.NODE_ENV == "development" || window.location.hostname == "localhost") {
		apiUrl = "http://" + window.location.hostname + ":8080/";
	}

	// Uncomment to use the staging instance's API.
	// apiUrl = "https://support.getgodesk.com/backend/"

	return apiUrl;
}
