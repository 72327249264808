import { useState } from "react";
import toast from "react-hot-toast";
import { useTestChannelImap, useTestChannelSmtp } from "@/Api/genApi";
import { ChannelsEmail } from "@/Api/genApi.schemas";
import { ChannelsEmail as ChannelsEmailOld } from "@shared/Entities/EntityTypes";

export enum MailboxValidation {
	UNTESTED,
	VALID,
	INVALID
}

export function useEmailChannelTester(channelOld: ChannelsEmailOld) { // TODO: Switch to useQuery version.
	const [imapValidity, setImapValidity] = useState<MailboxValidation>(MailboxValidation.UNTESTED);
	const [smtpValidity, setSmtpValidity] = useState<MailboxValidation>(MailboxValidation.UNTESTED);

	const testImapReq = useTestChannelImap();
	const testSmtpReq = useTestChannelSmtp();

	async function testImap(): Promise<boolean> {
		const toastId = toast.loading("Testing IMAP...");

		const channel = copyAndTrimData(channelOld) as ChannelsEmail; // TODO: Remove.

		let success = false;

		await testImapReq.mutateAsync({ data: channel }, {
			onSuccess: message => {
				if (message == "VALID") {
					toast.success("IMAP Credentials are valid.", { id: toastId, duration: 5000 });
					setImapValidity(MailboxValidation.VALID);
					success = true;
				} else {
					toast.error("Could not validate channel: " + message, { id: toastId });
					setImapValidity(MailboxValidation.INVALID);
				}
			},
			onError: error => {
				toast.error(error.errorMsg, { id: toastId });
				setImapValidity(MailboxValidation.INVALID);
			}
		});

		return success;
	}

	async function testSmtp(): Promise<boolean> {
		const toastId = toast.loading("Testing SMTP...");

		const channel = copyAndTrimData(channelOld) as ChannelsEmail;

		let success = false;

		await testSmtpReq.mutateAsync({ data: channel }, {
			onSuccess: message => {
				if (message == "VALID") {
					toast.success("SMTP Credentials are valid.", { id: toastId, duration: 5000 });
					setSmtpValidity(MailboxValidation.VALID);
					success = true;
				} else {
					toast.error("Could not validate channel: " + message, { id: toastId });
					setSmtpValidity(MailboxValidation.INVALID);
				}
			},
			onError: error => {
				toast.error(error.errorMsg, { id: toastId });
				setSmtpValidity(MailboxValidation.INVALID);
			}
		});

		return success;
	}

	return {
		imapValidity,
		smtpValidity,
		testImap,
		testSmtp
	};
}

function copyAndTrimData(channel: ChannelsEmailOld): ChannelsEmailOld {
	const dataCopy = { ...channel };

	dataCopy.imapServer = dataCopy.imapServer != null ? dataCopy.imapServer.trim() : undefined;
	dataCopy.imapUser = dataCopy.imapUser != null ? dataCopy.imapUser.trim() : undefined;
	dataCopy.smtpServer = dataCopy.smtpServer != null ? dataCopy.smtpServer.trim() : undefined;
	dataCopy.smtpUser = dataCopy.smtpUser != null ? dataCopy.smtpUser.trim() : undefined;

	return dataCopy;
}
