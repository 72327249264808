import { useState } from "react";
import { useNavigate } from "react-router-dom";
import HotkeyHelpModal from "../Modals/HotkeyHelpModal";
import { Hotkeys } from "@shared/lib/Hotkeys";

export default function NavHotkeys() {
	const navigate = useNavigate();
	const [isShortcutHelpOpen, setIsShortcutHelpOpen] = useState(false);

	function closeShortcutHelp() {
		setIsShortcutHelpOpen(false);
	}

	return (
		<>
			<Hotkeys hotkeys="Shift+?" callback={() => setIsShortcutHelpOpen(true)} />

			<Hotkeys hotkeys="g h" callback={() => navigate("/home")} />
			<Hotkeys hotkeys="g t" callback={() => navigate("/tickets")} />
			<Hotkeys hotkeys="g c" callback={() => navigate("/companies")} />
			<Hotkeys hotkeys="g k" callback={() => navigate("/kb")} />
			<Hotkeys hotkeys="g r" callback={() => navigate("/reporting")} />
			<Hotkeys hotkeys="g a" callback={() => navigate("/config")} />

			<HotkeyHelpModal isOpen={isShortcutHelpOpen} closeModal={closeShortcutHelp} />
		</>
	);
}
