import React, { useState, ChangeEvent, ReactNode, useEffect, useRef, useId } from "react";
import { FaTimes } from "react-icons/fa";
import { InputTypes } from "./InputTypes";
import { ControlLabel } from "@shared/Components/FormComponents/ControlLabel";

export interface TextInputProps {
	value?: string;
	dataname: string;
	className?: string;
	label?: ReactNode;
	toolTip?: ReactNode;
	placeholder?: string;
	mandatory?: boolean;
	isWrapped?: boolean; // Disables browser mandatory validation. Leaves label.
	autoFocus?: boolean;
	inline?: boolean;
	min?: number;
	max?: number;
	type?: InputTypes;
	ref?: React.MutableRefObject<any>;
	/** Basically just onBlur */
	handleSubmit?(key: string, newValue: string | undefined): void;
	/** Only used by Address. May deprecate in the future. */
	onKeyDown?(event: any): any;
	onChange?(newValue: string): void;

	/**
	 * @param value The value (trimmed).
	 */
	onBlur?(value: string): void;

	/** Same as onChange but with a 300ms debounce applied. */
	onChangeDebounced?(key: string, newValue: string): void;
	invalid?: boolean;
	disabled?: boolean;
	minLength?: number;
	maxLength?: number;

	/** Displays a button to clear the value. */
	clearable?: boolean;

	/** Adds autocomplete to textInput */
	autocomplete?: "email" | "username" | "current-password" | "new-password" | "off";
}

export default function TextInput(props: TextInputProps) {
	const controlId = useId();
	const [value, setValue] = useState<string>(props.value ?? "");

	const timeoutRef = useRef<NodeJS.Timeout>();

	useEffect(() => {
		setValue(props.value ?? "");
	}, [props.value]);

	function handleChange(event: ChangeEvent<HTMLInputElement>) {
		if (event.nativeEvent instanceof InputEvent) {
			// Only trim if the value was pasted in. Otherwise we could never type a space!
			if (event.nativeEvent.inputType == "insertFromPaste" || event.nativeEvent.inputType == "insertFromDrop") {
				updateValue(event.target.value.trim());
			} else {
				updateValue(event.target.value);
			}
		}
	}

	function updateValue(newValue: string) {
		setValue(newValue);

		if (props.onChange != null) {
			props.onChange(newValue);
		}

		if (props.onChangeDebounced != null) {
			clearTimeout(timeoutRef.current);

			timeoutRef.current = setTimeout(function () {
				if (props.onChangeDebounced != null) {
					props.onChangeDebounced(props.dataname, newValue);
				}
			}, 300);
		}
	}

	function handleBlur() {
		if (props.handleSubmit != null && value != props.value) {
			props.handleSubmit(props.dataname, value);
		}

		const valueTrimmed = value.trim();

		if (props.onBlur != null) {
			props.onBlur(valueTrimmed);
		}
	}

	const displayCssContainer = props.inline ? "inline-block" : "block Form_Component";
	const displayCssInput = props.inline ? "" : "w-full";
	const disabledCss = props.disabled ? " text-gray-600" : "";

	let borderCss = " border-gray-300 focus:border-blue-500";

	if (props.invalid) {
		borderCss = " border-red-300 focus:border-red-400";
	} else if (props.disabled) {
		borderCss = " border-gray-200";
	}

	return (
		<div className={displayCssContainer}>
			{!props.inline &&
				<ControlLabel
					label={props.label}
					mandatory={props.mandatory}
					helperText={props.toolTip}
					controlId={controlId}
					invalid={props.invalid}
				/>}

			<div className="relative clear-button-parent">
				<input
					className={displayCssInput + borderCss + disabledCss + " " + props.className + " shadow-sm rounded-md py-2 px-3 border"}
					type={ props.type != undefined ? props.type : "text" }
					required={props.mandatory && !props.isWrapped}
					autoComplete={props.autocomplete}
					id={controlId}
					value={value}
					ref={props.ref}
					onChange={handleChange}
					onBlur={handleBlur}
					onKeyDown={props.onKeyDown}
					placeholder={props.placeholder}
					minLength={props.minLength != null ? props.minLength : props.type == InputTypes.Password ? 8 : undefined}
					maxLength={props.maxLength}
					min={props.min}
					max={props.max}
					autoFocus={props.autoFocus}
					disabled={props.disabled}
				/>

				{props.clearable && value && (
					<button className="clear-button" onClick={() => updateValue("")}>
						<FaTimes />
					</button>
				)}
			</div>
		</div>
	);
}
