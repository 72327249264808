import { keepPreviousData, useInfiniteQuery } from "@tanstack/react-query";
import { PayloadPagination } from "@/Api/genApi.schemas";

export type GenericPageParams = {
	offset?: number;
	limit?: number;
};

export interface GenericPagedResult<T> {
	data: T[];
	pagination: PayloadPagination;
}

// This could extend UseInfiniteQueryOptions. Can't work out the generics though.
interface UseGodeskInfiniteQueryProps<T> {
	queryKey: readonly unknown[];
	queryFn(params?: GenericPageParams): Promise<GenericPagedResult<T>>;
	enabled?: boolean;
	keepPreviousData?: boolean;
}

/**
 * Hook to wrap useInfiniteQuery for use with a controller returning a PayloadCollection.
 */
export function useGodeskInfiniteQuery<T>(props: UseGodeskInfiniteQueryProps<T>) {
	const pageSize = 10;

	const infiniteReq = useInfiniteQuery({
		staleTime: 30 * 1000,
		refetchInterval: 30 * 1000,
		enabled: props.enabled ?? true,
		placeholderData: props.keepPreviousData ? keepPreviousData : undefined,
		queryKey: props.queryKey,
		queryFn: ({ pageParam }) => props.queryFn({ limit: 10, offset: pageParam * pageSize }),
		initialPageParam: 0,
		getNextPageParam: lastPage => {
			// Check if we're at the end.
			if (lastPage.pagination.total < lastPage.pagination.offset + pageSize) {
				return null;
			}

			return (lastPage.pagination.offset / pageSize) + 1;
		},
	});

	const data = (infiniteReq.data?.pages?.flatMap(page => page.data));

	const total = infiniteReq.data?.pages != null && infiniteReq.data.pages.length > 0 ? infiniteReq.data.pages[0].pagination.total : undefined;

	return {
		req: infiniteReq,
		data: data,
		total: total
	};
}
