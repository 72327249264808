import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useEntity } from "@/legacy/useEntity";
import { useNumberParams } from "@/Hooks/useNumberParams";
import { EntityViewerToolbar } from "@/Fragments/EntityViewer/EntityViewerToolbar";
import CreateEmailChannel from "@/Pages/Config/Channels/ChannelsEmailViewer/CreateEmailChannel";
import { EditEmailChannel } from "@/Pages/Config/Channels/ChannelsEmailViewer/EditEmailChannel";
import { DisplayEmailChannel } from "@/Pages/Config/Channels/ChannelsEmailViewer/DisplayEmailChannel";
import DeleteWarningModal from "@/Components/Utility/Modals/DeleteWarningModal";
import { ChannelsEmail } from "@shared/Entities/EntityTypes";
import { Entities } from "@shared/Entities/Entities";

export function ChannelsEmailViewer() {
	const [editmode, setEditmode] = useState<boolean>(false);
	const [warningModalIsOpen, setWarningModalIsOpen] = useState(false);

	const navigate = useNavigate();
	const { id } = useNumberParams();

	// Get data for Display + deleting.
	const { data, deleteEntity, formError, updateEntity } = useEntity<ChannelsEmail>(id, Entities.CHANNELSEMAIL);

	async function onDelete() {
		const deleteSuccess = await deleteEntity();

		if (deleteSuccess) {
			navigate("/config/manage/channels/email");
		}
	}

	function cancelEdit() {
		setEditmode(false);

		if (id == -1) {
			navigate("/config/manage/channels/email" + id);
		}
	}

	return (
		id != null ?
			<div className="flex flex-col h-full">
				{(!editmode || id == -1) && <EntityViewerToolbar
					editmode={editmode}
					isNew={id == -1}
					backUrl="/config/manage/channels/email"
					onEdit={id != -1 ? () => setEditmode(true) : undefined}
					onCancel={cancelEdit}
					onDelete={() => setWarningModalIsOpen(true)}
					hideNewButton
					widthCss="550px"
				                            />

				}

				<div className="grow overflow-auto w-full">
					{id == -1 ?
						<CreateEmailChannel />
						: editmode ?
							<EditEmailChannel
								id={id}
								cancelEdit={cancelEdit}
								data={data}
								updateEntity={updateEntity}
								formError={formError}
							/>
							: data != null ?
								<DisplayEmailChannel data={data} />
								: null}
				</div>

				<DeleteWarningModal
					isOpen={warningModalIsOpen}
					onCancel={() => setWarningModalIsOpen(false)}
					onDelete={() => id != -1 ? onDelete() : undefined}
					message="Are you sure you want to delete this channel? No further emails will be imported."
				/>
			</div>
			:
			null
	);
}
