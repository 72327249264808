import { useEffect } from "react";
import toast from "react-hot-toast";
import { useRegisterSW } from "virtual:pwa-register/react";

export function ServiceWorkerManager() {
	const {
		needRefresh: [needRefresh],
		updateServiceWorker,
	} = useRegisterSW({
		onRegisteredSW(r) {
			console.log("SW Registered: " + r);
		},
		onRegisterError(error) {
			console.log("SW registration error", error);
		},
		onNeedRefresh() {
			console.log("onNeedRefresh");
		},
		onOfflineReady() {
			console.log("onOfflineReady");
		}
	});

	useEffect(() => {
		if (needRefresh) {
			toast(
				<div className="flex">
					<div className="grow">
						A new version of GoDesk is available. Please click <b>Refresh</b> to upgrade.
					</div>

					<button
						className="btn-grey ml-1 shrink-0"
						onClick={async () => {
							await updateServiceWorker(true);
						}}
					>REFRESH</button>
				</div>
				, { duration: 9999999 });
		}
	}, [needRefresh, updateServiceWorker]);

	return (<></>);
}
