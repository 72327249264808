import { Navigate, useLocation } from "react-router-dom";
import { ReactNode } from "react";
import { useOptionalAgent } from "@/Hooks/useAgent";

interface RequireAgentAuthProps {
	children: ReactNode;
}

export function RequireAgentAuth(props: RequireAgentAuthProps) {
	const { pathname, search } = useLocation();

	const searchParams = new URLSearchParams(search);
	searchParams.append("redirect", pathname);

	const agent = useOptionalAgent();
	const isAuthenticated = agent != null;

	return isAuthenticated ? props.children : <Navigate to={ "/login?" + searchParams.toString() } />;
}
