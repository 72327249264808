import { ColDef, ValueFormatterParams } from "@ag-grid-community/core";
import { CacheSlices, EnrichedCache } from "@/Store/Reducers/cache/CacheTypes";
import { CellRenderers, getRenderer } from "./EntityTableRenderers";
import { CacheFunctions } from "../../Helpers/CacheFunctions";
import { AppDispatch } from "../../Store/store";
import { Entities } from "@shared/Entities/Entities";
import { datetimeToLocaleString, datetimeToWhenString } from "@shared/Helpers/DateFunctions";

enum ValueFormatters {
	Datetime, DatetimeWhen
}

interface ColDefCustom extends ColDef {
	cacheValueFormatter?: CacheSlices;
	customValueFormatter?: ValueFormatters;
	customCellRenderer?: CellRenderers;
}

/**
 * The available ColDefs for each entity.
 */
const availableCols: { [index: string]: { [index: string]: ColDefCustom } } = {
	AGENT: {
		name: {
			headerName: "Name",
			customCellRenderer: CellRenderers.AgentListAgentAvatar
		},
		email: {
			headerName: "Email"
		},
		phoneNumber: {
			headerName: "Phone number"
		},
		jobTitle: {
			headerName: "Job title"
		},
		isAdmin: {
			headerName: "Admin",
			customCellRenderer: CellRenderers.Boolean
		},
	},
	AUTOMATION: {
		name: {
			headerName: "Name",
			customCellRenderer: CellRenderers.IdLinkAutomation
		},
	},
	CANNEDREPLY: {
		name: {
			headerName: "Name",
			customCellRenderer: CellRenderers.IdLinkCannedReply
		},
		description: {
			headerName: "Description"
		},
		ownerAgentId: {
			headerName: "Creator",
			customCellRenderer: CellRenderers.AgentAvatar,
		},
	},
	CATEGORY: {
		name: {
			headerName: "Name"
		},
	},
	CHANNELSEMAIL: {
		name: {
			headerName: "Name",
			customCellRenderer: CellRenderers.IdLinkChannelsEmail
		},
		address: {
			headerName: "Address"
		},
		teamId: {
			headerName: "Team",
			cacheValueFormatter: CacheSlices.Teams
		},
	},
	KBARTICLE: {
		defaultArticleTitle: {
			headerName: "Name",
			flex: 2,
			customCellRenderer: CellRenderers.IdLinkKbArticle
		},
		kbSectionId: {
			headerName: "Folder",
			cacheValueFormatter: CacheSlices.KbFolders,
		},
		published: {
			headerName: "Published",
			customCellRenderer: CellRenderers.KbArticleStatus
		},
		views: {
			headerName: "Views",
		},
		upvotes: {
			headerName: "Upvotes",
		},
		creatorId: {
			headerName: "Creator",
			customCellRenderer: CellRenderers.AgentAvatar,
		},
	},
	SLA: {
		name: {
			headerName: "Name",
			customCellRenderer: CellRenderers.IdLinkSla
		},
	},
	TEAM: {
		name: {
			headerName: "Name",
			customCellRenderer: CellRenderers.IdLinkTeam,
			maxWidth: 300
		},
		description: {
			headerName: "Description"
		},
		ticketCount: {
			headerName: "Tickets",
			customCellRenderer: CellRenderers.NumberNoZeros,
			maxWidth: 150
		},
		agentCount: {
			headerName: "Agents",
			customCellRenderer: CellRenderers.NumberNoZeros,
			maxWidth: 150
		}
	},
	TICKET: {
		id: {
			headerName: "ID",
			minWidth: 95,
			customCellRenderer: CellRenderers.IdLinkTicket,
			resizable: false
		},
		description: {
			headerName: "Subject",
			minWidth: 200,
			flex: 4,
			customCellRenderer: CellRenderers.TicketDetails
		},
		priorityId: {
			headerName: "Priority",
			minWidth: 90,
			cacheValueFormatter: CacheSlices.Priorities,
			customCellRenderer: CellRenderers.PriorityIcon
		},
		statusId: {
			headerName: "Status",
			minWidth: 100,
			cacheValueFormatter: CacheSlices.Statuses
		},
		responseDue: {
			headerName: "SLA",
			minWidth: 100,
			customCellRenderer: CellRenderers.SlaStatus
		},
		userId: {
			headerName: "User",
			minWidth: 180,
			customCellRenderer: CellRenderers.UserAvatar
		},
		agentId: {
			headerName: "Agent",
			minWidth: 180,
			customCellRenderer: CellRenderers.AgentAvatar
		},
		customCreated: {
			headerName: "Created",
			minWidth: 140,
			customCellRenderer: CellRenderers.DateHover
		},
		companyId: {
			headerName: "Company",
			minWidth: 180,
			cacheValueFormatter: CacheSlices.Companies,
		},
	},
	USER: {
		name: {
			headerName: "Name",
			customCellRenderer: CellRenderers.UserListUserAvatar
		},
		email: {
			headerName: "Email"
		},
		phoneNumber: {
			headerName: "Phone number"
		},
		companyId: {
			headerName: "Company",
			cacheValueFormatter: CacheSlices.Companies,
		},
	},
	VIEW: {
		name: {
			headerName: "Name",
			customCellRenderer: CellRenderers.IdLinkView
		},
	},

	// Plugins -----------------------------

};

export function generateColumnDefs(entity: Entities, cache: EnrichedCache, dispatch: AppDispatch, columns?: string[]): ColDef[] {
	const columnArray: ColDef[] = [];

	const columnsToUse = columns != null ? columns : getDefaultColumns(entity);

	columnsToUse.forEach(colName => {
		if (availableCols[entity] != null && availableCols[entity][colName] != null) {
			const column = { ...availableCols[entity][colName] };

			column.field = colName;

			if (column.customValueFormatter != null) {
				if (column.customValueFormatter == ValueFormatters.Datetime) {
					column.valueFormatter = (params: ValueFormatterParams) => { return datetimeToLocaleString(params.value); };
				} else if (column.customValueFormatter == ValueFormatters.DatetimeWhen) {
					column.valueFormatter = (params: ValueFormatterParams) => { return datetimeToWhenString(params.value); };
				}

				delete column.customValueFormatter;
			}

			if (column.cacheValueFormatter != null) {
				const cVF = column.cacheValueFormatter;

				column.valueFormatter = (params: ValueFormatterParams) => {
					const value = CacheFunctions.getNameFromCache(params.data[colName], cache, cVF, dispatch);
					return value != null ? value : "";
				};

				delete column.cacheValueFormatter;
			}

			if (column.customCellRenderer != null) {
				column.cellRenderer = getRenderer(column.customCellRenderer, cache, dispatch);

				delete column.customCellRenderer;
			}

			columnArray.push(column);
		} else {
			console.log("columndefs does not contain this entitytype or columnname.", entity, colName);
		}
	});

	// Add tickbox column.
	columnArray.unshift({
		headerName: "",
		headerCheckboxSelection: true,
		checkboxSelection: true,
		maxWidth: 38,
		resizable: false,
		suppressMovable: true,
		sortable: false,
		filter: false,
		flex: undefined
	});

	// Add the row cursor.
	columnArray.unshift({
		headerName: "",
		maxWidth: 3,
		resizable: false,
		suppressMovable: true,
		sortable: false,
		filter: false,
		flex: undefined,
		cellClass: "rowCursor"
	});

	return columnArray;
}

function getDefaultColumns(entity: Entities): string[] {
	switch (entity) {
		case Entities.AGENT:
			return ["name", "email", "phoneNumber", "jobTitle", "isAdmin"];
			break;
		case Entities.AUTOMATION:
			return ["name"];
			break;
		case Entities.CANNEDREPLY:
			return ["name", "description", "ownerAgentId"];
			break;
		case Entities.CATEGORY:
			return ["name"];
			break;
		case Entities.CHANNELSEMAIL:
			return ["name", "address", "teamId"];
			break;
		case Entities.KBARTICLE:
			return ["defaultArticleTitle", "kbSectionId", "published", "views", "upvotes", "creatorId"];
			break;
		case Entities.SLA:
			return ["name"];
			break;
		case Entities.TEAM:
			return ["name", "description", "ticketCount", "agentCount"];
			break;
		case Entities.TICKET:
			return ["id", "description", "priorityId", "statusId", "responseDue", "userId", "agentId", "customCreated"];
			break;
		case Entities.USER:
			return ["name", "email", "phoneNumber", "companyId"];
			break;
		case Entities.VIEW:
			return ["name"];
			break;
	}

	return [];
}
