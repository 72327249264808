import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { Stack } from "@mui/material";
import toast from "react-hot-toast";
import { Sla } from "@/Api/genApi.schemas";
import { PriorityGrid } from "@/Pages/Config/SLA/SlaPriorityGrid";
import DeleteWarningModal from "@/Components/Utility/Modals/DeleteWarningModal";
import { getGetOneSLAsQueryKey, useDeleteSLAs, useUpdateSLAs } from "@/Api/genApi";
import { LoadingDangerButton } from "@/Components/Button/DangerButton";
import { SaveBar } from "@/Fragments/EntityViewer/SaveBar";
import { queryClient } from "@/Api/UseQueryProvider";
import { W_TextField } from "@shared/Components/FormComponents/W_TextField/W_TextField";
import { FormParent } from "@shared/Components/Form/FormParent";
import { FormSection } from "@shared/Components/Form/FormSection";

interface SlaFormProps {
	id: number;
	defaultData?: Partial<Sla>;
}

export function SlaForm(props: SlaFormProps) {
	const [deleteModalOpen, setDeleteModalOpen] = useState(false);
	const navigate = useNavigate();

	const deleteSlaReq = useDeleteSLAs({ mutation: {
		onSettled: () => {
			toast.success("Deleted.");
			navigate("/config/manage/slas");
		},
		onError: error => toast.error("Could not delete SLA: " + error.message)
	} });

	const updateSlaReq = useUpdateSLAs({ mutation: {
		onSuccess: data => {
			toast.success("Updated");
			reset(data);

			const queryKey = getGetOneSLAsQueryKey(props.id);
			queryClient.invalidateQueries({ queryKey: queryKey });
		},
		onError: error => toast.error("Could not update agent: " + error.errorMsg)
	} });

	const { control, handleSubmit, reset, formState } = useForm<Sla>({ defaultValues: props.defaultData });

	async function onSubmit(sla: Sla) {
		await updateSlaReq.mutateAsync({ id: props.id, data: sla });
	}

	return (
		<>
			<form onSubmit={handleSubmit(onSubmit)}>
				<FormParent title="SLA Settings">
					<FormSection>
						<W_TextField
							control={control}
							name="name"
							label="Name"
							required
						/>

						<PriorityGrid
							control={control}
						/>

						<Stack direction="row" spacing={1}>
							<LoadingDangerButton
								variant="contained"
								onClick={() => setDeleteModalOpen(true)}
								loading={deleteSlaReq.isPending}
								size="medium"
							>
								Delete SLA
							</LoadingDangerButton>
						</Stack>
					</FormSection>
				</FormParent>
			</form>

			<SaveBar formState={formState} onSave={async () => handleSubmit(onSubmit)()} onCancel={reset} />

			<DeleteWarningModal
				isOpen={deleteModalOpen}
				onDelete={() => deleteSlaReq.mutate({ id: props.id })}
				onCancel={() => setDeleteModalOpen(false)}
				message="This will remove this SLA from any tickets using it, leaving them without an SLA."
			/>
		</>
	);
}
