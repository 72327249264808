import { DateTime } from "luxon";
import { MdOutlinePause } from "react-icons/md";
import { datetimeToLocaleString, getSlaTimerString } from "@shared/Helpers/DateFunctions";

interface BadgeProps {
	responseDueISO?: string;
	paused?: boolean;
}

export function SlaBadge(props: BadgeProps) {

	const commonStyles = " rounded-md align-middle inline-flex justify-center items-center h-6 w-12 py-1 text-sm cursor-default ";

	if (props.paused) {
		return <div title="Waiting for user response" className={commonStyles + " bg-gray-200 text-gray-600"}><MdOutlinePause /></div>;
	} else if (props.responseDueISO != null) {
		const now = DateTime.now();
		const responseDue = DateTime.fromISO(props.responseDueISO);

		const label = getSlaTimerString(responseDue);
		const title = datetimeToLocaleString(responseDue);

		if (responseDue < now) {
			return <div title={title} className={commonStyles + "text-white bg-red-600 font-semibold"}>{label}</div>;
		} else if (responseDue < now.plus({ hour: 1 })) {
			return <div title={title} className={commonStyles + "bg-amber-200 text-amber-900 font-bold"}>{label}</div>;
		} else {
			return <div title={title} className={commonStyles + "text-green-700 bg-green-100 font-bold"}>{label}</div>;
		}
	}	else {
		return null;
	}
}
