import { MdOutlineErrorOutline } from "react-icons/md";

export function BadDomainError() {
	return (
		<div className="mx-auto w-96 relative top-1/4 bg-white py-10 px-5 border border-gray-300 rounded">
			<div className="text-5xl text-center text-gray-700 mb-5">
				<MdOutlineErrorOutline />
			</div>
			<div>
				This domain does not appear to exist.
				<br /><br />
				Please contact your admin and ask if the CNAME has been changed recently.
			</div>
		</div>
	);
}
