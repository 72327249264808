import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useInviteUsers } from "@/Api/genApi";
import { MultiUserInvite } from "@/Api/genApi.schemas";
import { Button, ButtonBlue } from "../../Button/Button";
import Modal from "@shared/Components/Modal/Modal";
import ModalButtons from "@shared/Components/Modal/ModalButtons";
import ModalContent from "@shared/Components/Modal/ModalContent";
import W_TextArea from "@shared/Components/FormComponents/TextArea/W_TextArea";
import { AlertBox } from "@shared/Components/AlertBox/AlertBox";

interface AddUserModalProps {
	isOpen: boolean;
	closeModal(): void;
}

export default function AddUserModal(props: AddUserModalProps) {
	const inviteUsersReq = useInviteUsers();

	const { handleSubmit, reset, control } = useForm<MultiUserInvite>({
		defaultValues: {
			emailsToInvite: ""
		}
	});

	async function onSubmit(data: MultiUserInvite) {
		const emails = data.emailsToInvite.split("\n");

		if (emails.length >= 1000) {
			toast.error("You can only invite 1000 users at a time.");
			return;
		}

		const toastId = toast.loading("Sending invites.");

		inviteUsersReq.mutate({ data: data }, {
			onSuccess: () => {
				toast.success("User Invite Successful.", { id: toastId });
				reset();
				props.closeModal();
			},
			onError: error => {
				toast.error("Invite failed. " + error.errorMsg, { id: toastId });
			}
		});
	}

	function close() {
		reset();
		props.closeModal();
	}

	return (
		<Modal
			isOpen={props.isOpen}
			onClose={close}
			title="Send portal invites"
		>
			<ModalContent>
				<div className="text-base text-start mb-6 max-w-2xl">
					Invite new users by email to create a portal account.
					<br /><br />
					Separate each email address with a new line.

					<AlertBox alertType="info">
						A user account will be created automatically for each new person that emails in already.

						You do not need to create accounts upfront.
					</AlertBox>
				</div>

				<W_TextArea
					control={control}
					rows={7}
					dataname="emailsToInvite"
					label="Email addresses"
					mandatory
					autoFocus
					placeholder="user1@company.com&#10;user2@company.com&#10;user3@company.com&#10;user4@company.com&#10;user5@company.com"
				/>
			</ModalContent>

			<ModalButtons>
				<Button onClick={close} label="Cancel" />
				<ButtonBlue onClick={() => handleSubmit(onSubmit)()} label="Send all invites" disabled={inviteUsersReq.isPending} />
			</ModalButtons>
		</Modal>
	);
}
