
export class DeviceFunctions {

	/**
	 * @returns The users browser locale.
	 */
	static getUserLocale(): string {
		if (typeof navigator == "undefined") { // Nextjs rendered on server.
			return "";
		}

		if (navigator.languages != undefined) {return navigator.languages[0];}

		return navigator.language;
	}


	/**
	 * @returns If the user in on a mobile.
	 */
	static detectMob(): boolean {
		const toMatch = [
			/Android/i,
			/webOS/i,
			/iPhone/i,
			/iPad/i,
			/iPod/i,
			/BlackBerry/i,
			/Windows Phone/i
		];

		return toMatch.some((toMatchItem) => {
			return navigator.userAgent.match(toMatchItem);
		});
	}
}
