import { HiOutlineMail } from "react-icons/hi";
import { HeaderTabEntities } from "@/Components/Header/GlobalTab";
import { TicketCollisionNotes } from "@/Components/TicketCollisionNotes";
import { CollisionNote } from "@/Api/genApi.schemas";
import { globalTabUpdated } from "../../../../Store/Reducers/globalTabsSlice";
import InlineText from "../../../../Components/FormComponents/InlineText/InlineText";
import { useAppDispatch } from "../../../../Store/hooks";
import { Ticket } from "@shared/Entities/EntityTypes";

interface TicketHeaderProps {
	ticket: Ticket;
	handleSubmit(key: string, newValue: string | undefined): void;
	collisionNotes?: CollisionNote[];
}

export function TicketHeader(props: TicketHeaderProps) {
	const dispatch = useAppDispatch();

	function handleDescriptionSubmit(key: string, newValue: string | undefined) {
		props.handleSubmit(key, newValue);

		if (newValue) {
			dispatch(globalTabUpdated({ id: props.ticket.id, entity: HeaderTabEntities.TICKETS, title: newValue }));
		}
	}

	return (
		<div className="shrink-0 flex px-3 border-b border-gray-300 text-gray-500 items-center text-xl py-2 overflow-x-auto">
			<HiOutlineMail className="text-xl mt-1 mr-2 shrink-0" />
			<span className="">{"#" + props.ticket.id}</span>
			<span className="ml-2 mr-1 cursor-default">-</span>

			<div className=" grow text-black">
				<InlineText value={props.ticket.description} dataname="description" handleSubmit={handleDescriptionSubmit} />
			</div>

			{props.collisionNotes != null &&
				<TicketCollisionNotes collisionNotes={props.collisionNotes} />
			}
		</div>
	);
}
