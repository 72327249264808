import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { AiOutlineCloseCircle, AiOutlinePlusCircle } from "react-icons/ai";
import { PiUserSwitch } from "react-icons/pi";
import { IconButton, Tooltip } from "@mui/material";
import { MdBusiness, MdOutlineMail, MdOutlinePhone } from "react-icons/md";
import { toast } from "react-hot-toast";
import { CacheSlices } from "@/Store/Reducers/cache/CacheTypes";
import { useCache } from "@/Hooks/useCache";
import api from "@/Api/Api";
import CacheDropdown from "../../../../Components/FormComponents/CacheDropdown/CacheDropdown";
import NewEntityModal from "../../../../Components/Utility/Modals/NewEntityModal";
import { UserAvatar } from "../../../../Components/Avatar/UserAvatar";
import { Entities } from "@shared/Entities/Entities";
import { Ticket, User } from "@shared/Entities/EntityTypes";

interface TicketUserSectionProps {
	ticket: Ticket;
	handleSubmit(key: string, newValue: any): void;
}

export function TicketUserSection(props: TicketUserSectionProps) {
	const { cache, refreshCache } = useCache();
	const navigate = useNavigate();

	const [editMode, setEditMode] = useState(false);
	const [userModalOpen, setUserModalOpen] = useState(false);
	const [newUser, setNewUser] = useState<number | undefined>(undefined);
	const [newCompanyModalOpen, setNewCompanyModalOpen] = useState(false);

	useEffect(() => {
		setEditMode(props.ticket.userId == null);
	}, [props.ticket]);

	function editClick(event: React.MouseEvent) {
		event.preventDefault();
		setEditMode(!editMode);
	}

	function cancelClick(event: React.MouseEvent) {
		event.preventDefault();
		setEditMode(false);
	}

	function newAvatarCallback(avatarBase64: string) {
		props.handleSubmit("avatar", avatarBase64);
	}

	async function newUserCreated(id: number) {
		setUserModalOpen(false);
		await refreshCache();
		setNewUser(id);
		props.handleSubmit("userId", id);
	}

	async function newCompanyCreated(id: number) {
		setNewCompanyModalOpen(false);

		const correctUser = cache.getUser(props.ticket.userId);

		if (correctUser != null) {
			const correctUserCopy = { ...correctUser };

			correctUserCopy.companyId = id;

			const res = await api.updateEntity<User>(Entities.USER, correctUserCopy.id, correctUserCopy);

			if (res.successful) {
				navigate("/companies/" + id);

				await refreshCache();
			} else {
				toast.error("Could not create a company.");
			}
		}

	}

	const user = cache.getUser(props.ticket.userId);

	const company = cache.getCompany(user?.companyId);

	return (
		editMode ?
			<>
				<div className="flex border-t border-gray-300 p-3">
					<CacheDropdown
						widthCss="100%"
						label="User"
						value={newUser ? newUser : props.ticket.userId}
						dataname={"userId"}
						onChange={props.handleSubmit}
						cacheSlice={CacheSlices.Users}
						newButtonOnClick={() => setUserModalOpen(true)}
					/>

					{props.ticket.userId != null ?
						<div>
							<button onClick={cancelClick} style={{ border: "none", backgroundColor: "inherit" }}><AiOutlineCloseCircle /></button>
						</div>
						: null}
				</div>

				<NewEntityModal newCreated={newUserCreated} isOpen={userModalOpen} entityType={Entities.USER} closeModal={() => setUserModalOpen(false)} />
			</>

			:

			<>
				<div className="border-t border-gray-300 p-3 min-h-[110]" style={{ display: "grid", gridTemplateColumns: "70px auto 40px" }}>
					<div>
						<div style={{ marginRight: "10px" }}>
							{user &&
								<UserAvatar user={user} widthPx={70} updateAvatar={newAvatarCallback} />}
						</div>
					</div>

					<div>
						<div className="flex-grow overflow-hidden pl-2">
							<div>
								{user?.deleted ?
									user?.name
									:
									<Link to={"/users/" + user?.id} style={{ width: "max-content" }} className="blue-link truncate">{user?.name}</Link>}
							</div>

							<div className="flex items-center text-nowrap text-ellipsis">
								<div className="mr-2 pt-1"><MdOutlineMail /></div>
								<Tooltip title={user?.email}>
									<div className="truncate" style={{ maxWidth: "200px" }}>{user?.email}</div>
								</Tooltip>
							</div>


							{user?.phoneNumber != null && user.phoneNumber != "" &&
								<div className="flex items-center">
									<div className="mr-2 pt-1"><MdOutlinePhone /></div>
									<div className="truncate">{user?.phoneNumber}</div>
								</div>}

							{company?.id != null ?
								<div className="flex items-center overflow-hidden ">
									<Tooltip title="The user's company">
										<div><MdBusiness /></div>
									</Tooltip>
									<Tooltip title={company.name}>
										<Link className="px-2 pb-1" to={"/companies/" + company?.id} >
											<div className=" text-base underline text-blue-600 truncate max-w-[200px]">
												{company?.name}
											</div>
										</Link>
									</Tooltip>
								</div>
								:
								<>
									{!(user?.deleted || user?.disabled && user.companyId == null) &&
										<div className="flex items-center cursor-pointer" onClick={() => setNewCompanyModalOpen(true)}>
											<Tooltip title="New company">
												<div className="mr-2 pt-1"><AiOutlinePlusCircle /></div>
											</Tooltip>
											<div className="underline text-blue-600">Create a new company</div>
										</div>
									}
								</>
							}
						</div>
					</div>
					<div>

						<div className="mr-2">
							<Tooltip title="Change the user assigned to this ticket">
								<IconButton onClick={editClick} style={{ border: "none", padding: "4,0" }}>
									<PiUserSwitch className="text-xl" />
								</IconButton>
							</Tooltip>
						</div>
					</div>
				</div>
				<NewEntityModal
					newCreated={newCompanyCreated}
					isOpen={newCompanyModalOpen}
					entityType={Entities.COMPANY}
					closeModal={() => setNewCompanyModalOpen(false)}
				/>
			</>
	);
}
