import type { Except } from "type-fest";
import { Control, Controller } from "react-hook-form";
import { EmojiKeyboard, EmojiKeyboardProps } from "@shared/Components/FormComponents/EmojiKeyboard/EmojiKeyboard";
import { ControlError } from "@shared/Components/FormComponents/ControlError";

interface W_EmojiKeyboardProps extends Except<EmojiKeyboardProps, "value" | "updateValue"> {
	control: Control<any>;
	dataname: string;
	mandatory?: boolean;
	className?: string;
}

/**
 * @see W_TextInput
 */
export default function W_EmojiKeyboard(props: W_EmojiKeyboardProps) {
	const rules: any = {}; // https://react-hook-form.com/api/useform/register

	if (props.mandatory) {
		rules.required = { value: true, message: "This is a required field." };
	}

	return (
		<Controller
			control={props.control}
			name={props.dataname}
			rules={rules}
			render={({
				field: { onChange, value },
				fieldState: { error }
			}) => (
				<div className={props.className}>
					<EmojiKeyboard
						value={value}
						updateValue={onChange}

						{...props}
					/>

					<ControlError error={error?.message} />
				</div>
			)}
		/>
	);
}
