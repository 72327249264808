import React, { useState } from "react";
import { Link } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";

export function ButtonBlue(props: ButtonProps) {
	return (
		<Button btnClass="btn-blue" {...props} />
	);
}

export function ButtonRed(props: ButtonProps) {
	return (
		<Button btnClass="btn-red" {...props} />
	);
}

export function ButtonPurple(props: ButtonProps) {
	return (
		<Button btnClass="btn-purple" />
	);
}

interface ButtonProps {
	linkTo?: string;
	icon?: JSX.Element;
	label?: string;
	/** Adds an onHover tooltip. Useful for keyboard shortcuts. */
	tooltip?: string;
	btnClass?: "btn-grey" | "btn-blue" | "btn-red" | "btn-purple";
	onClick?(): void;
	/** Alternate onClick for showing a loading wheel while waiting for the async function to complete. */
	asyncOnClick?(): Promise<void>;

	className?: string;
	style?: React.CSSProperties;

	/** Useful for 'toggle' buttons. */
	active?: boolean;
	disabled?: boolean;
	loading?: boolean;
}

export function Button(props: ButtonProps) {
	const [isLoading, setIsLoading] = useState(false);

	async function onClick() {
		if (props.asyncOnClick) {
			// Show loading wheel while waiting for a async function.
			setIsLoading(true);
			await props.asyncOnClick();
			setIsLoading(false);
		} else if (props.onClick) {
			props.onClick();
		}
	}

	const loadingClass = props.loading || isLoading ? "spinner spinner-show" : "spinner";

	const parentStylesForButton = props.linkTo == null && props.className != null ? props.className + " " : "";

	const activeStyles = props.active ? " bg-slate-200 hover:bg-slate-300  " : "";

	const disabledStyles = props.disabled ? " opacity-40 " : "";

	let buttonJSX = <button
		type="button"
		className={parentStylesForButton + activeStyles + disabledStyles + (props.btnClass ?? "btn-grey")}
		style={props.style}
		onClick={onClick}
		disabled={props.disabled || props.loading || isLoading}
	                >
		{props.icon}
		<div className={loadingClass}></div>

		{
			props.label != null ?
				<span className={"whitespace-nowrap " + (props.icon ? "ml-1" : "")}>
					{props.label}
				</span>
				: null
		}
	</button>;

	// Add the tooltip if provided.
	if (props.tooltip != null) {
		buttonJSX = <Tooltip title={props.tooltip}>
			{buttonJSX}
		</Tooltip>;
	}

	// Add the link if provided.
	if (props.linkTo != null) {
		buttonJSX = <Link className={props.className} to={props.linkTo}>
			{buttonJSX}
		</Link>;
	}

	return (
		buttonJSX
	);
}
