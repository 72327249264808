import { Button, ButtonProps } from "@mui/material";
import LoadingButton, { LoadingButtonProps } from "@mui/lab/LoadingButton";

interface DangerButtonProps extends ButtonProps {}

export function DangerButton(props: DangerButtonProps) {
	return (
		<Button
			variant="contained"
			size="small"
			color="error"
			{...props}
		/>
	);
}

interface LoadingDangerButtonProps extends LoadingButtonProps {}

export function LoadingDangerButton(props: LoadingDangerButtonProps) {
	return (
		<LoadingButton
			variant="contained"
			size="small"
			color="error"
			{...props}
		/>
	);
}
