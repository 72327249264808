import { TicketAction } from "@/Fragments/EntityViewer/EntitySpecific/Ticket/Action/TicketAction";
import { Ticket, Action } from "@shared/Entities/EntityTypes";
import { dateSort, numberSort } from "@shared/Helpers/SortFunctions";

interface TicketActionsProps {
	ticket: Ticket;
	updatePrivateNote(action: Action): void;
	deletePrivateNote(id: number): void;
}

export function TicketActions(props: TicketActionsProps) {
	// Sort actions in reverse date order.
	const actions = (props.ticket.actions ?? [])
		.sort((a, b) => dateSort(a.customCreated, b.customCreated) || numberSort(a.id, b.id))
		.reverse();

	return (
		actions.map((action) => (
			<TicketAction
				key={action.id}
				ticket={props.ticket}
				action={action}
				updatePrivateNote={props.updatePrivateNote}
				deletePrivateNote={props.deletePrivateNote}
			/>
		))
	);
}
