import { Agent as AgentGen } from "@/Api/genApi.schemas";
import Avatar from "./Avatar";
import { Agent } from "@shared/Entities/EntityTypes";

interface AgentAvatarProps {
	agent?: Agent | AgentGen;
	/** Width of the avatar in px */
	widthPx: number;
	/** Callback for uploading a new image. */
	updateAvatar?(dataBase64: string, mimeType: string): void;
	/** Hides the agent status bubble. */
	hideAgentStatus?: boolean;
}

export function AgentAvatar(props: AgentAvatarProps) {
	const statusIcon = props.hideAgentStatus != true ? props.agent?.status : undefined;

	return (
		<Avatar
			name={props.agent?.name}
			avatarHashString={"Agent_" + props.agent?.id}
			widthPx={props.widthPx}
			disabled={props.agent?.disabled}
			deleted={props.agent?.deleted}
			updateAvatar={props.updateAvatar}
			avatarUrlFilename={props.agent?.profilePictureFileName}
			avatarBase64={props.agent?.profilePictureData}
			statusIcon={statusIcon}
		/>
	);
}
