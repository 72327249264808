import { ReactNode, useRef, useState } from "react";
import data from "@emoji-mart/data";
import Picker from "@emoji-mart/react";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { Popover, PopoverOrigin } from "@mui/material";

export interface EmojiKeyboardProps {
	updateValue(newValue?: string): void;
	value?: string;
	label?: ReactNode;
	anchorOrigin?: PopoverOrigin;
	transformOrigin?: PopoverOrigin;
}

export function EmojiKeyboard(props: EmojiKeyboardProps) {
	const [pickerOpen, setPickerOpen] = useState(false);
	const target = useRef(null);

	const handleToggle = () => {
		setPickerOpen(!pickerOpen);
	};

	function onRootClose(event: any) {
		setPickerOpen(false);
	}

	function onEmojiSelect(value: any) {
		props.updateValue(value.native);
		setPickerOpen(false);
	}

	const labelJsx = <div className={"text-sm mb-1 mt-2.5"}>{props.label}</div>;

	return (
		<div className="relative inline-block">
			{props.label != null &&
				<label >
					{props.label &&
						labelJsx
					}
				</label>
			}

			<div className="relative inline-block">
				<button
					className="h-9 w-9 shadow-sm text-lg bg-white rounded-md border border-gray-300 focus:border-blue-500 hover:bg-gray-50 transition-colors duration-150"
					ref={target}
					onClick={handleToggle}
					type="button" // So it does not trigger forms to submit.
				>
					{props.value}
				</button>

				{props.value != null && props.value != "" &&
					<span
						className="absolute bg-white hover:bg-gray-100 rounded-full flex items-center justify-center cursor-pointer"
						title="Remove icon"
						style={{ top: "-5px", right: "-5px", height: "18px", width: "18px", fontSize: "18px" }}
						onClick={() => props.updateValue("")}
					>
						<AiOutlineCloseCircle />
					</span>}
			</div>

			<Popover
				anchorEl={target.current}
				open={pickerOpen}
				onClose={onRootClose}
				anchorOrigin={props.anchorOrigin}
				transformOrigin={props.transformOrigin}
			>
				<div className="w-[316px] h-[435px]">
					<Picker
						data={data}
						onEmojiSelect={onEmojiSelect}
						theme="light"
						maxFrequentRows={1}
						autoFocus
						perLine={8}
					/>
				</div>
			</Popover>
		</div>
	);
}
