import GenericListPage from "./_GenericListPage";
import { Entities } from "@shared/Entities/Entities";

export default function ManageViews() {
	return (
		<GenericListPage
			urlTitle="Views | Config"
			title="Views"
			description="Customise different views."
			entityType={Entities.VIEW}
		/>
	);
}
