import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { BiDownvote, BiLinkExternal, BiUpvote } from "react-icons/bi";
import { GrView } from "react-icons/gr";
import { useForm } from "react-hook-form";
import { HiOutlineClipboardCopy } from "react-icons/hi";
import toast from "react-hot-toast";
import { IoArrowRedoSharp } from "react-icons/io5";
import W_CacheDropdown from "@/Components/FormComponents/CacheDropdown/W_CacheDropdown";
import { GetOne, PostOneNew, Put } from "@/legacy/ApiHttp";
import { useEntity } from "@/legacy/useEntity";
import { useCache } from "@/Hooks/useCache";
import { CacheSlices } from "@/Store/Reducers/cache/CacheTypes";
import { useAgent } from "@/Hooks/useAgent";
import DeleteWarningModal from "@/Components/Utility/Modals/DeleteWarningModal";
import { MoveArticleModal } from "@/Pages/KB/MoveArticleModal";
import { Button } from "../../../Components/Button/Button";
import OtherFunctions from "../../../Helpers/OtherFunctions";
import { EntityViewerToolbar } from "../EntityViewerToolbar";
import { getImageUploadHandler } from "../../../Components/FormComponents/HtmlEditor/imageUploadHandler";
import { useNumberParams } from "../../../Hooks/useNumberParams";
import { useNumberQueryParams } from "../../../Hooks/useNumberQueryParams";
import { PreventWindowClose } from "@shared/lib/PreventWindowClose";
import { FormError } from "@shared/Components/Form/FormError";
import { KbArticle, kbArticleContent } from "@shared/Entities/EntityTypes";
import W_HtmlEditor from "@shared/Components/FormComponents/HtmlEditor/W_HtmlEditor";
import W_TextInput from "@shared/Components/FormComponents/TextInput/W_TextInput";
import { getKbArticleSlug } from "@shared/Helpers/OtherFunctions";
import { Entities } from "@shared/Entities/Entities";
import { FormSection } from "@shared/Components/Form/FormSection";
import { FormReadBox } from "@shared/Components/Form/FormReadBox";
import { FormReadPair } from "@shared/Components/Form/FormReadPair";
import { FormParent } from "@shared/Components/Form/FormParent";
import { FormHotkeys } from "@shared/Components/Utils/FormHotkeys";

interface KbArticleViewerProps {
	/** Callback for when a KbArticle is added/updated/deleted. */
	onUpdate(): void;
}

export default function KbArticleViewer(props: KbArticleViewerProps) {
	const agent = useAgent();
	const { cache } = useCache();
	const navigate = useNavigate();

	const portalUrl = cache.getPortalURL();

	const { id } = useNumberParams();
	const { kbFolderId } = useNumberQueryParams();

	const [deleteModalOpen, setDeleteModalOpen] = useState(false);
	const [editmode, setEditmode] = useState<boolean>(id == -1);
	const [moveArticleModalOpen, setMoveArticleModalOpen] = useState<boolean>(false);
	const [articleDefaultContent, setArticleDefaultContent] = useState<kbArticleContent | undefined>(undefined);

	const { data, formError, fieldErrors, updateEntity, createEntity, deleteEntity, getEntity } = useEntity<KbArticle>(id, Entities.KBARTICLE);

	const defaultArticle = data;

	if (defaultArticle != null && kbFolderId != null) {
		defaultArticle.kbSectionId = kbFolderId;
	}

	const { control, handleSubmit, reset, setValue, getValues, setError } = useForm<KbArticle>({ defaultValues: defaultArticle });

	useEffect(() => {
		fetchContent();

		async function fetchContent() {
			const res = await GetOne<kbArticleContent>("kb_article_content/" + id + "/en_GB");

			if (res.successful && res.data != null) {
				setArticleDefaultContent(res.data);
				setValue("name", res.data.title);
				setValue("body", res.data.body);
			} else {
				setValue("name", "");
				setValue("body", "");
			}
		}
	}, [id, setValue, data, editmode]);

	useEffect(() => {
		// Override form data.
		reset(data);

		// Add any errors.
		OtherFunctions.setFieldErrors(setError, fieldErrors);
	}, [data, fieldErrors]);

	useEffect(() => {
		setEditmode(id == -1);
	}, [id]);

	async function unpublishButtonClick() {
		setValue("published", false);

		await updateEntity(getValues());

		props.onUpdate();
	}

	async function publishButtonClick() {
		setValue("published", true);

		await updateEntity(getValues());

		props.onUpdate();
	}

	async function moveArticle(kbId: number, folderId: number) {
		console.log(kbId, folderId);

		setValue("kbId", kbId);
		setValue("kbSectionId", folderId);

		console.log(getValues());

		await formSubmit(getValues());

		await getEntity();

		props.onUpdate();
		toast.success("Article moved");
	}

	async function formSubmit(data: KbArticle) {
		if (data.id > 0) {
			// First put content.
			const content: kbArticleContent = {
				id: {
					articleId: data.id,
					language: "en_GB"
				},
				title: data.name ?? "",
				body: data.body ?? ""
			};

			await Put("kb_article_content/" + id + "/en_GB", content);

			data.editorId = agent.id;

			// TRANSIENTS
			data.name = undefined;
			data.body = undefined;

			const updateSuccess = await updateEntity(data);

			if (updateSuccess) {
				setEditmode(false);
			}
		} else {

			const content: kbArticleContent = {
				id: {
					articleId: data.id,
					language: "en_GB"
				},
				title: data.name ?? "",
				body: data.body ?? ""
			};

			data.creatorId = agent.id;

			// TRANSIENTS
			data.name = undefined;
			data.body = undefined;

			const newId = await createEntity(data);

			content.id.articleId = newId ?? -1;
			console.log("new", content);
			await PostOneNew("kb_article_content", content);

			if (newId != null && newId > -1) {
				setEditmode(false);
				navigate("/kb/article/" + newId);
			}
		}

		props.onUpdate();
	}

	async function deleteArticle() {
		await deleteEntity();

		props.onUpdate();

		navigate("/kb");
	}

	function cancelEdit() {
		setEditmode(false);
		reset(data);

		if (id == -1) {
			navigate("/kb");
		}
	}

	const kbArticleLink = data != null ? portalUrl + "knowledge-base/" + id + "/" + getKbArticleSlug(data) : "";

	const extraButtons = data?.published ?
		<>
			<button className="ml-2 btn-grey" onClick={unpublishButtonClick}>Unpublish</button>
			{}
			<Button
				onClick={() => setMoveArticleModalOpen(true)}
				icon={<IoArrowRedoSharp />}
				label="Move article"
			/>
			<Button
				btnClass="btn-grey"
				onClick={ () => {
					navigator.clipboard.writeText(kbArticleLink);
					toast.success("Copied to clipboard.");
				}}
				icon={<HiOutlineClipboardCopy />}
				label="Copy public URL"
			/>
			<Button
				className="ml-2 btn-grey"
				onClick={() => {
					window.open(kbArticleLink);
				}}
				icon={<BiLinkExternal />}
				label="Preview"
			/>
		</>
		:
		<button className="ml-2 btn-blue" onClick={publishButtonClick}>Publish</button>;

	return (
		id != null && data != null ?
			<>
				<FormHotkeys onSubmit={handleSubmit(formSubmit)} onCancel={cancelEdit} />

				<form onSubmit={handleSubmit(formSubmit)} className="h-full w-full flex flex-col">
					<EntityViewerToolbar
						editmode={editmode}
						isNew={id == -1}
						backUrl="/kb"
						widthCss="100%"
						onEdit={() => setEditmode(true)}
						onCancel={cancelEdit}
						onDelete={() => setDeleteModalOpen(true)}
						extraEditButtons={extraButtons}
						extraReadButtons={extraButtons}
					/>

					<div className="w-full grow overflow-auto">
						{editmode ?
							<FormParent fullWidth>
								<FormSection limitWidth>
									<W_TextInput
										control={control}
										dataname="name"
										label="Name"
										mandatory
										autoFocus={id < 0}
									/>
									{id == -1 &&
										<W_CacheDropdown
											control={control}
											cacheSlice={CacheSlices.KbFolders}
											dataname="kbSectionId"
											label="Folder"
											mandatory
										/>}

									{data.published ?
										<div className="my-2 flex">
											<div className="flex items-center" title="Views"><GrView /><span className="ml-1 mr-3">{data.views ?? "0"}</span></div>
											<div className="flex items-center" title="Upvotes"><BiUpvote /><span className="ml-1 mr-3">{data.upvotes ?? "0"}</span></div>
											<div className="flex items-center" title="Downvotes"><BiDownvote /><span className="ml-1 mr-3">{data.downvotes ?? "0"}</span></div>
										</div>
										: null}
								</FormSection>

								<FormError error={formError} />

								<FormSection>
									<PreventWindowClose stopClose={() => getValues("body") != "" } />

									<W_HtmlEditor
										control={control}
										heightPx={550}
										dataname="body"
										agentFeatures
										handleCancel={() => {}}
										handleSubmit={(body) => {
											setValue("body", body);
											handleSubmit(formSubmit)();
										}}
										cannedReplies={cache.CannedReplies}
										imageUploadHandler={getImageUploadHandler(cache.getTenantId())}
									/>
								</FormSection>
							</FormParent>
							:
							<KbArticleDisplay article={data} kbArticleContent={articleDefaultContent} />}
					</div>
				</form>

				<DeleteWarningModal
					isOpen={deleteModalOpen}
					onDelete={deleteArticle}
					onCancel={() => setDeleteModalOpen(false)}
					message={"Are you sure you would like to delete this article? This is permanent and cannot be undone."}
				/>

				<MoveArticleModal
					isOpen={moveArticleModalOpen}
					closeModal={() => setMoveArticleModalOpen(false)}
					article={getValues()}
					moveArticle={moveArticle}
				/>
			</>
			:
			null
	);
}

interface KbArticleDisplayProps {
	article: KbArticle;
	kbArticleContent?: kbArticleContent;
}

function KbArticleDisplay(props: KbArticleDisplayProps) {
	const articleContentTitle = props.article.articleContent != undefined ? props.article.articleContent?.find(article => article.id.language == "en_GB") : null;


	const body = props.kbArticleContent?.body.trim() ?? "";

	return (
		<FormParent fullWidth>
			<FormSection title="Details" limitWidth>
				<FormReadBox>
					<FormReadPair
						name="Name"
						value={articleContentTitle?.title}
					/>
					<FormReadPair
						name="Folder"
						value={props.article.sectionName}
					/>

					{props.article.published &&
						<div className="my-2 flex">
							<div className="flex items-center" title="Views"><GrView /><span className="ml-1 mr-3">{props.article.views ?? "0"}</span></div>
							<div className="flex items-center" title="Upvotes"><BiUpvote /><span className="ml-1 mr-3">{props.article.upvotes ?? "0"}</span></div>
							<div className="flex items-center" title="Downvotes"><BiDownvote /><span className="ml-1 mr-3">{props.article.downvotes ?? "0"}</span></div>
						</div>}
				</FormReadBox>
			</FormSection>

			<FormSection title="Article">
				<div
					className="text-base p-3 bg-white text-gray-700 rounded border border-gray-200"
					dangerouslySetInnerHTML={{ __html: body }}
				></div>
			</FormSection>
		</FormParent>
	);
}
