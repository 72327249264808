import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { authCleared } from "../../Store/Reducers/authSlice";
import { agentLoggedOut } from "../../Store/Reducers/loggedInAgentSlice";
import { useAppDispatch } from "../../Store/hooks";

export default function Logout() {
	document.title = "Log Out | GoDesk";

	const dispatch = useAppDispatch();
	const navigate = useNavigate();

	useEffect(() => {
		dispatch(authCleared());
		dispatch(agentLoggedOut());

		navigate("/login?loggedout");
	});

	return (
		<div></div>
	);
}
