import { Bar } from "react-chartjs-2";
import { ChartData, ChartOptions } from "chart.js";
import { CacheSlices } from "@/Store/Reducers/cache/CacheTypes";
import { useCache } from "@/Hooks/useCache";
import { useAppDispatch } from "../../../Store/hooks";
import { CacheFunctions } from "../../../Helpers/CacheFunctions";
import { ReportDashboard } from "@shared/Entities/EntityTypes";

interface TicketsByHourProps {
	homeStats: ReportDashboard;
}

export function TicketsByHour(props: TicketsByHourProps) {
	const monthNames = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23"];
	const graphData = props.homeStats.ticketHourGraph ?? [];

	const data: ChartData<"bar", number[], unknown> = {
		labels: monthNames,
		datasets: [{
			data: graphData,

			backgroundColor: "rgba(75,192,192,0.4)",
			borderColor: "rgba(75,192,192,1)",
		}]
	};

	const options: ChartOptions<"bar"> = {
		scales: {
			y: {
				beginAtZero: true,
				grid: {
					display: false
				},
				ticks: {
					precision: 0
				}
			},
			x: {
				grid: {
					display: false
				}
			}
		},
		plugins: {
			legend: {
				display: false
			},
		}
	};

	return (
		<div className="bg-white p-3 rounded border border-gray-300">
			<div className="text-base text-gray-600 mb-3">Tickets created by hour</div>
			<Bar data={data} options={options} />
		</div>
	);
}

interface TicketsByDayProps {
	homeStats: ReportDashboard;
}

export function TicketsByDay(props: TicketsByDayProps) {
	const monthNames = ["Mon", "Tues", "Wed", "Thurs", "Fri", "Sat", "Sun"];
	const graphData = props.homeStats.ticketDayGraph ?? [];

	const data: ChartData<"bar", number[], unknown> = {
		labels: monthNames,
		datasets: [{
			data: graphData,

			backgroundColor: "rgba(75,192,192,0.4)",
			borderColor: "rgba(75,192,192,1)",
		}]
	};

	const options: ChartOptions<"bar"> = {
		scales: {
			y: {
				beginAtZero: true,
				grid: {
					display: false
				},
				ticks: {
					precision: 0
				}
			},
			x: {
				grid: {
					display: false
				}
			},
		},
		plugins: {
			legend: {
				display: false
			},
		}
	};

	return (
		<div className="bg-white p-3 rounded border border-gray-300">
			<div className="text-base text-gray-600 mb-3">Tickets created by day</div>
			<Bar data={data} options={options} />
		</div>
	);
}

interface TicketsStatusProps {
	homeStats: ReportDashboard;
}

export function TicketStatus(props: TicketsStatusProps) {
	const dispatch = useAppDispatch();
	const { cache } = useCache();

	const labels = [];
	const graphData = [];

	if (props.homeStats.ticketStatus != null) {
		for (const [key, count] of Object.entries(props.homeStats.ticketStatus)) {
			const statusId = parseInt(key);

			const label = CacheFunctions.getNameFromCache(statusId, cache, CacheSlices.Statuses, dispatch);

			labels.push(label);
			graphData.push(count);
		}
	}

	const data: ChartData<"bar", number[], unknown> = {
		labels: labels,
		datasets: [{
			data: graphData,

			backgroundColor: "rgba(75,192,192,0.4)",
			borderColor: "rgba(75,192,192,1)",
		}]
	};

	const options: ChartOptions<"bar"> = {
		indexAxis: "y",
		scales: {
			y: {
				beginAtZero: true,
				grid: {
					display: false
				}
			},
			x: {
				grid: {
					display: false
				},
				ticks: {
					precision: 0
				}
			}
		},
		plugins: {
			legend: {
				display: false
			},
		}
	};

	return (
		<div className="bg-white p-3 rounded border border-gray-300">
			<div className="text-base text-gray-600 mb-3">Created tickets by status</div>
			<Bar data={data} options={options} />
		</div>
	);
}

interface TicketsPriorityProps {
	homeStats: ReportDashboard;
}

export function TicketPriority(props: TicketsPriorityProps) {
	const dispatch = useAppDispatch();
	const { cache } = useCache();

	const labels = [];
	const graphData = [];

	if (props.homeStats.ticketPriority != null) {
		for (const [key, count] of Object.entries(props.homeStats.ticketPriority)) {
			const priorityId = parseInt(key);

			const label = CacheFunctions.getNameFromCache(priorityId, cache, CacheSlices.Priorities, dispatch);

			labels.push(label);
			graphData.push(count);
		}
	}

	const data: ChartData<"bar", number[], unknown> = {
		labels: labels,
		datasets: [{
			data: graphData,

			backgroundColor: "rgba(75,192,192,0.4)",
			borderColor: "rgba(75,192,192,1)",
		}]
	};

	const options: ChartOptions<"bar"> = {
		indexAxis: "y",
		scales: {
			y: {
				beginAtZero: true,
				grid: {
					display: false
				}
			},
			x: {
				grid: {
					display: false
				},
				ticks: {
					precision: 0
				}
			}
		},
		plugins: {
			legend: {
				display: false
			},
		}
	};

	return (
		<div className="bg-white p-3 rounded border border-gray-300">
			<div className="text-base text-gray-600 mb-3">Created tickets by priority</div>
			<Bar data={data} options={options} />
		</div>
	);
}
