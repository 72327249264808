import { CacheSlices } from "@/Store/Reducers/cache/CacheTypes";
import MultiEntitySelect from "@/Components/FormComponents/MultiEntitySelect/MultiEntitySelect";
import { AutoAssignMethod } from "@shared/Enums/Enums";
import { FormParent } from "@shared/Components/Form/FormParent";
import { FormSection } from "@shared/Components/Form/FormSection";
import { FormReadBox } from "@shared/Components/Form/FormReadBox";
import { FormReadPair } from "@shared/Components/Form/FormReadPair";
import { Team } from "@shared/Entities/EntityTypes";
import { datetimeToWhenString } from "@shared/Helpers/DateFunctions";

interface TeamDisplayProps {
	data: Team;
}

export function TeamDisplay(props: TeamDisplayProps) {
	const agentIdsArray = props.data?.agents?.map(agentTeam => agentTeam.agentId);

	return (
		<FormParent title={props.data.name ?? ""}>
			<FormSection title="Details">
				<FormReadBox>
					<FormReadPair
						name="Name"
						value={props.data.name}
					/>
					<FormReadPair
						name="Icon"
						value={props.data.icon}
					/>
					<FormReadPair
						name="Description"
						value={props.data.description}
					/>
				</FormReadBox>

				<MultiEntitySelect
					dataname="agents"
					label="Agents"
					value={agentIdsArray}
					cacheSlice={CacheSlices.Agents}
					handleSubmit={() => {}}
					disabled
				/>
			</FormSection>

			<FormSection title="Ticket Assignment">
				<FormReadBox>
					<FormReadPair
						name="Auto Assign"
						value={props.data.autoAssignMethod == null ? "-" : props.data.autoAssignMethod == AutoAssignMethod.ROUND_ROBIN ? "Round Robin" : "Load Balance"}
					/>
				</FormReadBox>
			</FormSection>

			<FormSection>
				<FormReadBox>
					<FormReadPair
						name="Updated"
						value={datetimeToWhenString(props.data.modified)}
					/>
					<FormReadPair
						name="Created"
						value={datetimeToWhenString(props.data.created)}
					/>
				</FormReadBox>
			</FormSection>
		</FormParent>
	);
}
