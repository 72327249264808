import { Button, Card } from "@mui/material";
import { IoAddCircleOutline } from "react-icons/io5";

interface SkeletonKbFolderProps {
	onClick(): void;
}

export function SkeletonKbFolder(props: SkeletonKbFolderProps) {
	return (
		<Card sx={{ width: 350, border: "2px", padding: 0, borderStyle: "dashed", borderColor: "grey", display: "flex", justifyContent: "center" }} >
			<Button
				className="w-full h-full"
				onClick={props.onClick}
				startIcon={<IoAddCircleOutline size={20} />}
			>
				Create folder
			</Button>
		</Card>
	);
}
