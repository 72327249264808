import { Control, Controller } from "react-hook-form";
import type { Except } from "type-fest";
import { Tickbox, TickboxProps } from "@shared/Components/FormComponents/Tickbox/Tickbox";
import { ControlError } from "@shared/Components/FormComponents/ControlError";

interface W_TickBoxProps extends Except<TickboxProps, "value" | "onChange"> {
	control: Control<any>;
	dataname: string;
}

/**
 * @see W_TextInput
 */
export function W_Tickbox(props: W_TickBoxProps) {
	const rules: any = {}; // https://react-hook-form.com/api/useform/register

	return (
		<Controller
			control={props.control}
			name={props.dataname}
			rules={rules}
			render={({
				field: { onChange, value },
				fieldState: { error }
			}) => (
				<>
					<Tickbox
						{...props}
						onChange={(key: string, newValue: boolean) => {onChange(newValue);} }
						value={value}
					/>

					<ControlError error={error?.message} />
				</>
			)}
		/>
	);
}
