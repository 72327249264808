import GenericListPage from "@/Pages/Config/_GenericListPage";
import { Entities } from "@shared/Entities/Entities";

export default function ManageAutomations() {
	return (
		<GenericListPage
			urlTitle="Automations | Config"
			title="Automations"
			description="Automations are run whenever a new ticket or action is added. Automations run in order. If one is matched, its actions are applied and no further automations will run."
			entityType={Entities.AUTOMATION}
		/>
	);
}
