import { Route, Routes } from "react-router-dom";
import Home from "@/Pages/home/Home";
import KB from "@/Pages/KB/KB";
import { Reporting } from "@/Pages/Reporting/Reporting";
import EntityViewer from "@/legacy/EntityViewer";
import Tickets from "../Pages/Tickets/Tickets";
import NotFound404 from "../Pages/Auth/NotFound404";
import Companies from "../Pages/Companies/Companies";
import ConfigParent from "../Pages/Config/ConfigParent";
import ConfigMenu from "../Pages/Config/ConfigMenu";
import SearchPage from "../Pages/SearchPage/SearchPage";
import GetStarted from "../Pages/GetStarted";
import { Entities } from "@shared/Entities/Entities";

export function ParentPageRoutes() {

	return (
		<Routes>
			<Route path="/" element={<Tickets />} />

			<Route path="/home" element={<Home />} />

			<Route path="/search" element={<SearchPage />} />
			<Route path="/search/:query" element={<SearchPage />} />

			<Route path="/tickets" element={<Tickets />} />
			<Route path="/tickets/:id" element={<EntityViewer entityType={Entities.TICKET} titlePath="Tickets | GoDesk" />} />

			<Route path="/companies" element={<Companies />} />
			<Route path="/companies/:id" element={<Companies />} />

			<Route path="/kb" element={<KB />} />
			<Route path="/kb/article/:id" element={<KB />} />
			<Route path="/kb/all" element={<KB />} />
			<Route path="/kb/drafts" element={<KB />} />

			<Route path="/reporting" element={<Reporting />} />

			<Route path="/users/:id" element={<EntityViewer entityType={Entities.USER} titlePath="Users | GoDesk" />} />

			<Route path="/get-started" element={<GetStarted />} />

			{/* Settings */}
			<Route path="/config" element={<ConfigMenu />} />
			<Route path="/config/*" element={<ConfigParent />} />

			{/* 404 */}
			<Route path="*" element={<NotFound404 link="/" />} />
		</Routes>
	);
}
