import { useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import W_CacheDropdown from "@/Components/FormComponents/CacheDropdown/W_CacheDropdown";
import { TicketMassUpdateReq } from "@/legacy/ApiResponse";
import { ApiMassUpdateTickets } from "@/legacy/ApiCallerOld";
import { CacheSlices } from "@/Store/Reducers/cache/CacheTypes";
import { Button } from "../../Button/Button";
import Modal from "@shared/Components/Modal/Modal";
import ModalContent from "@shared/Components/Modal/ModalContent";
import ModalButtons from "@shared/Components/Modal/ModalButtons";

interface ReAssignTicketsModalProps {
	isOpen: boolean;
	closeModal(): void;
	ids: number[];
}

export default function ReAssignTicketsModal(props: ReAssignTicketsModalProps) {
	const [isLoading, setIsLoading] = useState(false);

	const { handleSubmit, reset, control } = useForm();

	const onSubmit = async (data: any) => {
		setIsLoading(true);

		if (data.teamId != null) {
			const req: TicketMassUpdateReq = {
				ids: props.ids,
				agentId: data.agentId,
				teamId: data.teamId
			};

			// Send req.
			const res = await ApiMassUpdateTickets(req);

			// Display errors.
			if (!res.successful && res.errorMsg) {
				toast.error(res.errorMsg);
			}
		}

		setIsLoading(false);
		close();
	};

	function close() {
		reset();
		props.closeModal();
	}

	const modalStyle = {
		maxWidth: "1000px",
		minWidth: "400px",
		maxHeight: "700px"
	};

	return (
		<Modal
			isOpen={props.isOpen}
			onClose={props.closeModal}
			title="Reassign Ticket"
			modalStyle={modalStyle}
		>
			<ModalContent>
				<W_CacheDropdown
					control={control}
					dataname="teamId"
					label="Teams"
					cacheSlice={CacheSlices.Teams}
					mandatory
				/>
				<W_CacheDropdown
					control={control}
					dataname="agentId"
					label="Agents"
					cacheSlice={CacheSlices.Agents}
				/>
			</ModalContent>

			<ModalButtons>
				<Button btnClass="btn-grey" label="Cancel" onClick={close} />
				<Button btnClass="btn-blue" label="Reassign" onClick={() => handleSubmit(onSubmit)()} disabled={isLoading} />
			</ModalButtons>
		</Modal>
	);
}
