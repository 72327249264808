import { useState } from "react";
import { Menu, MenuItem, Tooltip } from "@mui/material";
import { usePopupState, bindTrigger, bindMenu } from "material-ui-popup-state/hooks";
import { LibraryAddOutlined, MoreHorizOutlined, SmartToyOutlined, UnfoldLessOutlined, UnfoldMoreOutlined } from "@mui/icons-material";
import NewTicketModal from "@/Components/Utility/Modals/NewTicketModal/NewTicketModal";
import KbDraftArticleModal from "@/Components/Utility/Modals/KbDraftArticleModal";
import { Button } from "@/Components/Button/Button";
import { useCache } from "@/Hooks/useCache";
import { Action, Ticket } from "@shared/Entities/EntityTypes";
import { SettingBool } from "@shared/Enums/SettingEnums";

interface PublicActionMenuProps {
	ticket: Ticket;
	action: Action;
	fullMessageDisplayed: boolean;
	fullMessageFetching: boolean;
	toggleFullMessage(): void;
}

/**
 * Context menu for ticket actions.
 * In the future make this a context menu component with context item children with callbacks.
 */
export function PublicActionMenu(props: PublicActionMenuProps) {
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [gptModalOpen, setGptModalOpen] = useState(false);

	const popupState = usePopupState({ variant: "popover" });

	const { cache } = useCache();

	const actionForNewTicket: Action = { ...props.action, id: 0 };

	return (
		<>
			<div className="flex flex-row gap-1.5">
				{cache.getSettingBool(SettingBool.GPT_INSTRUCTIONS_SAVED) && props.action.agentId != null &&
					<Button
						icon={<SmartToyOutlined fontSize="inherit" />}
						label="Draft KB article"
						onClick={() => setGptModalOpen(true)}
						tooltip="Use AI to draft a KB article from this message"
					/>}

				{props.action.hasExpandedNote &&
					<Tooltip title={props.fullMessageDisplayed ? "Hide full message" : "Show full message"} placement="bottom">
						<div>
							<Button
								icon={props.fullMessageDisplayed ? <UnfoldLessOutlined fontSize="inherit" /> : <UnfoldMoreOutlined fontSize="inherit" />}
								onClick={props.toggleFullMessage}
								disabled={props.fullMessageFetching}
							/>
						</div>
					</Tooltip>}

				<div {...bindTrigger(popupState)}>
					<Button icon={ <MoreHorizOutlined fontSize="inherit" />} />
				</div>
			</div>

			<Menu
				{...bindMenu(popupState)}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "right",
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "right",
				}}
			>
				<MenuItem
					onClick={() => {
						popupState.close();
						setIsModalOpen(true);
					}}
				>
					<LibraryAddOutlined fontSize="inherit" className="mr-1" /> Copy to new ticket
				</MenuItem>
			</Menu>

			<NewTicketModal
				isOpen={isModalOpen}
				onClose={() => setIsModalOpen(false)}
				preventSendAsEmail
				defaultNewTicket={{
					id: 0,
					actions: [actionForNewTicket],
					userId: props.ticket.userId,
					teamId: props.ticket.teamId,
					agentId: props.ticket.agentId
				}}
			/>

			{gptModalOpen &&
				<KbDraftArticleModal
					onClose={() => setGptModalOpen(false)}
					action={props.action}
				/>}
		</>
	);
}
