import Modal from "@shared/Components/Modal/Modal";
import ModalButtons from "@shared/Components/Modal/ModalButtons";
import ModalContent from "@shared/Components/Modal/ModalContent";

interface MobileWarningProps {
	isOpen: boolean;
	close(): void;
}

export function MobileWarning(props: MobileWarningProps) {
	return (
		<Modal
			isOpen={props.isOpen}
			onClose={close}
			title="Mobile layout"
		>
			<ModalContent>
				<h2 className="text-lg">
					Unfortunately, GoDesk does not currently have a mobile layout. We recommend using GoDesk on desktops/laptops for now.
					<br /><br />
					Our mobile version will be delivered later this year.
				</h2>
			</ModalContent>

			<ModalButtons>
				<button className="btn-blue" onClick={close}>
					Ok
				</button>
			</ModalButtons>
		</Modal>
	);
}
