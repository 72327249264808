import GenericListPage from "./_GenericListPage";
import { Entities } from "@shared/Entities/Entities";

export default function ManageCategories() {
	return (
		<GenericListPage
			urlTitle="Categories | Config"
			title="Categories"
			description="Use categories to group tickets into similar groups."
			entityType={Entities.CATEGORY}
		/>
	);
}
