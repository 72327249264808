import { DraggableModalTitle } from "@/Components/Utility/Modals/DraggableResizableModal/DraggableModalTitle";
import DraggableResizable from "@/lib/DraggableResizable";

interface DraggableResizableModalProps {
	isOpen: boolean;
	onClose(): void;
	children: React.ReactNode;
	title: string;
	initialPositionPx: { x: number; y: number };
	initialSizePx: { width: number; height: number };
}

export function DraggableResizableModal(props: DraggableResizableModalProps) {

	return (
		props.isOpen &&
			<DraggableResizable
				initialPositionPx={props.initialPositionPx}
				initialSizePx={props.initialSizePx}
				minSizePx={{ width: 300, height: 450 }}
				maxSizePx={{ width: 900, height: 1000 }}
				draggableHandle="#handle"
			>
				<div className="rounded-md bg-[#f9fafb] shadow-lg border h-full w-full flex flex-col" >
					<DraggableModalTitle
						onClose={props.onClose}
						title={props.title}
					/>

					<div className="grow overflow-auto">
						{props.children}
					</div>
				</div>
			</DraggableResizable>
	);
}
