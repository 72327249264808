import { useState } from "react";
import toast from "react-hot-toast";
import { useCreateMicrosoftStateCode } from "@/Api/genApi";
import { ChannelsEmail } from "@/Api/genApi.schemas";
import { getRedirectUri, OauthButtonProps } from "@/Pages/Config/Channels/ChannelsEmailViewer/GmailOauthButton";

export function MsOauthButton(props: OauthButtonProps) {
	const [awaitingCode, setAwaitingCode] = useState(false);

	const createStateCodeReq = useCreateMicrosoftStateCode();

	async function onClick() {
		if (awaitingCode) {
			return;
		}

		let channel = props.prefireCallback != null ? await props.prefireCallback() : props.channel;

		// Replace the channel var if we are in edit mode. (prefireCallback will return undefined).
		if (channel == undefined) {
			channel = props.channel;
		}

		setAwaitingCode(true);

		await createStateCodeReq.mutateAsync({ data: (channel as ChannelsEmail) }, {
			onSuccess: stateCode => {
				const microsoftAuthUrl = "https://login.microsoftonline.com/common/oauth2/v2.0/authorize";

				const redirectUri = getRedirectUri();

				const scopes = "offline_access openid email Mail.ReadWrite Mail.ReadWrite.Shared Mail.Send Mail.Send.Shared";

				const searchParams = new URLSearchParams({
					client_id: "c9060471-c6fe-4d22-b932-96fa5a77ced6",
					redirect_uri: redirectUri,
					scope: scopes,
					response_type: "code",
					response_mode: "query",
					state: stateCode
				});

				const authUrl = microsoftAuthUrl + "?" + searchParams.toString();

				window.location.href = authUrl;
			},
			onError: error => toast.error("Error. " + error.httpCode + ": " + error.errorMsg)
		});

		setAwaitingCode(false);
	}

	return (
		<img
			title="Activate MS 365"
			alt="Activate MS 365"
			className="cursor-pointer w-[215px]"
			onClick={onClick}
			src="/ms-signin-light.png"
		/>
	);
}
