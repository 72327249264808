import { ReactNode } from "react";
import { IoIosWarning } from "react-icons/io";

interface ControlError {
	error?: ReactNode;
}

export function ControlError(props: ControlError) {
	return (
		props.error != null &&
			<div className="text-red-600 mb-3 flex items-center">
				<IoIosWarning className="mr-2 text-lg" />
				{props.error}
			</div>
	);
}
