import { AiOutlineLeft, AiOutlineRight } from "react-icons/ai";
import { PayloadPagination } from "@/Api/genApi.schemas";
import { Button } from "../Button/Button";
import { Pagination } from "@shared/Enums/Enums";

interface PaginationDisplayProps {
	pagination: Pagination | PayloadPagination;
	prevPage(): void;
	nextPage(): void;
}

export default function PaginationDisplay(props: PaginationDisplayProps) {
	const total = props.pagination.total;
	const offset = props.pagination.offset;
	const limit = props.pagination.limit;

	let pageMax;
	if (total != null) {
		if (offset + limit > total) {
			pageMax = total;
		} else {
			pageMax = offset + limit;
		}
	}

	const prevButtonEnabled = offset > 0;
	const nextButtonEnabled = offset + limit < (total ?? 0);

	const pageStart = total == 0 ? 0 : offset + 1;

	return (
		<div className="whitespace-nowrap">
			<Button className="px-2 py-2" disabled={!prevButtonEnabled} icon={<AiOutlineLeft />} onClick={props.prevPage} />
			<span className="mx-2">{pageStart} - {pageMax} of {total}</span>
			<Button className="px-2 py-2" disabled={!nextButtonEnabled} icon={<AiOutlineRight />} onClick={props.nextPage} />
		</div>
	);
}
