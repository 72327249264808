import { Beforeunload } from "react-beforeunload";

interface PreventWindowCloseProps {
	stopClose(): boolean;
}

/**
 * Triggers browser warning if the user closes while stopClose evaluates to true.
 */
export function PreventWindowClose(props: PreventWindowCloseProps) {
	return (
		<Beforeunload
			onBeforeunload={e => {
				if (props.stopClose()) {
					e.preventDefault();
				}
			}}
		/>
	);
}
