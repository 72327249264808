import Box from "@mui/material/Box";
import { AiOutlineUser } from "react-icons/ai";

interface SingleUsersCompanyProps {
	userCount?: number;
	onSelect(id: number): void;
	active?: boolean;
}

export function SingleUsersCompany(props: SingleUsersCompanyProps) {
	function onClick() {
		props.onSelect(-1);
	}

	return (
		<Box
			onClick={onClick}
			className={`flex p-2 border-b border-gray-300 w-full cursor-pointer ${props.active ? "bg-gray-100" : "bg-white"}`}
		>
			<div className="flex w-full">
				<div className="flex-grow flex">
					<div>
						<div className="rounded-full text-white text-center" style={{ lineHeight: "30px", backgroundColor: "#457b9d", height: "30px", width: "30px" }}>
							<AiOutlineUser />
						</div>
					</div>
					<div className="ml-2 my-auto">
						Single Users
					</div>
				</div>
				<div className="my-auto" title="Active single users.">
					<AiOutlineUser /> {props.userCount}
				</div>
			</div>
		</Box>
	);
}
