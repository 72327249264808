import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useCreatePushNotificationSubscription, useGetPushNotificationPublicKey, useRemovePushNotificationSubscription } from "@/Api/genApi";

type SubStatus = "unknown" | "denied" | "active" | "inactive";

export function usePushNotifications() {
	const [status, setStatus] = useState<SubStatus>("unknown");

	const publicKeyReq = useGetPushNotificationPublicKey({ query: {
		staleTime: Infinity
	} });

	const subscribeReq = useCreatePushNotificationSubscription();
	const unsubscribeReq = useRemovePushNotificationSubscription();

	// Get the sub status on mount.
	useEffect(() => {
		getStatus();

		async function getStatus() {
			if (Notification.permission === "denied") {
				setStatus("denied");
				return;
			}

			const registration = await navigator.serviceWorker.getRegistration();
			const subFound = await registration?.pushManager?.getSubscription() != null;

			setStatus(subFound ? "active" : "inactive");
		}
	}, []);

	async function subscribe() {
		if (process.env.NODE_ENV == "development") {
			toast.error("Push Notifications do not work in dev env. (No service workers).");
			return;
		}

		const permissionGranted = await Notification.requestPermission();

		if (permissionGranted != "granted") {
			setStatus("denied");
			return;
		}

		if (publicKeyReq.data == null) {
			setStatus("inactive");
			toast.error("Could not contact the server.");
			return;
		}

		const registration = await navigator.serviceWorker.getRegistration();

		if (registration == null) {
			setStatus("inactive");
			toast.error("Could not find service worker.");
			return;
		}

		const subscription = await registration?.pushManager.subscribe({
			userVisibleOnly: true,
			applicationServerKey: urlB64ToUint8Array(publicKeyReq.data),
		});

		if (subscription) {
			// Serialize and deserialise the subscription to get the keys property into the format we need.
			const sub = JSON.parse(JSON.stringify(subscription));
			delete sub.expirationTime;

			subscribeReq.mutateAsync({ data: sub }, {
				onSuccess: () => {
					setStatus("active");
					toast.success("Subscribed.");
				},
				onError: error => {
					toast.error("Server error. Could not subscribe: " + error.errorMsg);
				}
			});
		} else {
			setStatus("inactive");
			toast.error("Browser error. Could not subscribe.");
		}
	}

	async function unsubscribe() {
		const swRegistration = await navigator.serviceWorker.getRegistration();
		const subscription = await swRegistration?.pushManager.getSubscription();

		if (subscription != null) {
			await subscription.unsubscribe();

			await unsubscribeReq.mutateAsync(undefined, {
				onSuccess: () => toast.success("Unsubscribed."),
				onError: error => console.error("Could not finish removing push notification.", error)
			});
		}

		setStatus("inactive");
	}

	return {
		status,
		subscribe,
		subscribePending: subscribeReq.isPending,
		unsubscribe,
		unsubscribePending: unsubscribeReq.isPending,
	};
}

function urlB64ToUint8Array(base64String: string) {
	const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
	const base64 = (base64String + padding)
		.replace(/-/g, "+")
		.replace(/_/g, "/");
	const rawData = window.atob(base64);
	const outputArray = new Uint8Array(rawData.length);
	for (let i = 0; i < rawData.length; ++i) {
		outputArray[i] = rawData.charCodeAt(i);
	}
	return outputArray;
}
