import { ReactNode } from "react";
import { Link } from "react-router-dom";

interface PasswordResetContainerProps {
	children: ReactNode;
}

export function PasswordResetContainer(props: PasswordResetContainerProps) {
	return (
		<div
			className="h-screen flex items-center justify-center pb-80"
			style={{ backgroundColor: "#E1F2FE" }}
		>
			<div className="max-w-md w-full mx-auto bg-gray-50 py-8 px-5 rounded-md shadow-md">
				<Link to="/login">
					<div className="w-2/3 mx-auto mb-6">
						<img src="/logowithtext.png" alt="GoDesk Logo" />
					</div>
				</Link>

				<h1 className="text-lg font-semibold mb-2">Password reset</h1>

				{props.children}
			</div>
		</div>
	);
}
