import { useState } from "react";
import { useForm } from "react-hook-form";
import { RxDragHandleDots2 } from "react-icons/rx";
import { Button } from "@/Components/Button/Button";
import { DragAndDropList } from "@/Components/DragAndDropList";
import { useAgent } from "@/Hooks/useAgent";
import { Layout } from "@/Api/genApi.schemas";
import { useFormErrorSync } from "@/Hooks/useFormErrorSync";
import ReadOnlyLayoutTable from "@/Components/Layouts/ReadOnlyTable/ReadOnlyLayoutTable";
import { getAvailableColumnIds, getColumnFriendlyName, getDefaultColumnIds, getMandatoryColumnIds } from "@/Components/Layouts/LayoutModal/LayoutModalFunctions";
import Modal from "@shared/Components/Modal/Modal";
import ModalButtons from "@shared/Components/Modal/ModalButtons";
import ModalContent from "@shared/Components/Modal/ModalContent";
import { Tickbox } from "@shared/Components/FormComponents/Tickbox/Tickbox";
import { Entities } from "@shared/Entities/Entities";
import { DropdownOption } from "@shared/Components/FormComponents/Dropdown/Dropdown";
import W_Dropdown from "@shared/Components/FormComponents/Dropdown/W_Dropdown";
import W_TextInput from "@shared/Components/FormComponents/TextInput/W_TextInput";
import { ErrorPayload } from "@shared/Api/ApiResponses";

interface LayoutModalModalProps {
	entity: Entities;
	close(): void;
	data?: Layout;
	onSubmit(data: Layout): void;
	isSubmitting: boolean;
	error?: ErrorPayload | null;
}

const visibilityOptions: DropdownOption<string>[] = [
	{ label: "Only me", value: "PRIVATE" },
	// { label: "Teams", value: "TEAMS" },
	// { label: "Agents", value: "AGENTS" },
	{ label: "Everyone", value: "GLOBAL" },
];

export function LayoutModal(props: LayoutModalModalProps) {
	const agent = useAgent();

	const [columnIds, setColumnIds] = useState<string[]>(() => {
		// props.data?.columns ??
		let allAvailable = getAvailableColumnIds(props.entity);

		// If we already have a layout, put the columns in the correct order.
		if (props.data?.columns) {
			const sortedArray: string[] = [];

			props.data.columns.forEach(enabledColumn => {
				sortedArray.push(enabledColumn);

				// Remove the column from the available cols array.
				allAvailable = allAvailable.filter(id => id != enabledColumn);
			});

			// Add any remaining available columns to the end.
			sortedArray.push(...allAvailable);

			return sortedArray;
		} else {
			return allAvailable;
		}
	});

	const [enabledColumnIds, setEnabledColumnIds] = useState<string[]>(
		props.data?.columns ??
		getDefaultColumnIds(props.entity)
	);

	const [page, setPage] = useState(0);

	const mandatoryColumnIds = getMandatoryColumnIds(props.entity);

	console.log(props.data);

	const { control, handleSubmit, watch, setError, clearErrors } = useForm<Layout>({
		defaultValues: {
			columns: [],
			visibility: "PRIVATE",
			sortModels: [],
			visibilityAgentsIds: [],
			visibilityTeamsIds: [],

			...props.data
		}
	});

	useFormErrorSync(setError, clearErrors, props.error);

	function handleCheckboxChange(id: string) {
		setEnabledColumnIds(prevEnabledColumnIds => {
			if (prevEnabledColumnIds.includes(id)) {
				return prevEnabledColumnIds.filter(col => col !== id);
			} else {
				return [...prevEnabledColumnIds, id];
			}
		});
	}

	function renderAvailableColumn(id: string) {
		return (
			<div className="flex items-center px-2 m-1 bg-white border border-solid border-gray-300 rounded-md shadow-sm">
				<RxDragHandleDots2 className="mr-2" />
				<Tickbox
					onChange={() => handleCheckboxChange(id)}
					valueName=""
					value={enabledColumnIds.includes(id)}
					disabled={mandatoryColumnIds.includes(id)}
				/>
				<span className="ml-1 text-sm font-medium text-gray-700">{getColumnFriendlyName(id)}</span>
			</div>
		);
	}

	async function onSubmit(layout: Layout) {
		const layoutCopy = { ...layout };

		layoutCopy.creatorAgentId = agent.id;

		layoutCopy.columns = columnIds.filter(col => enabledColumnIds.includes(col));
		layoutCopy.entity = Entities.TICKET;

		props.onSubmit(layoutCopy);
	}

	const visibleColumns = columnIds.filter(col => enabledColumnIds.includes(col));
	const id = watch("id");
	const isNew = id == undefined || id < 1;

	return (
		<Modal isOpen={true} onClose={props.close} title={isNew ? "Create layout" : "Edit layout"} >
			<ModalContent disableScroll>
				<form onSubmit={handleSubmit(onSubmit)}>
					{page == 0 &&
						<div className="flex flex-row min-w-[1250px]">
							<div className="shrink-0 min-w-[300px] h-full p-4 bg-gray-50 border-r max-w-sm border-gray-200">
								<h2 className="text-lg font-medium mb-4">Available Columns</h2>

								<div className="overflow-auto">
									<DragAndDropList<string>
										ids={columnIds}
										setIds={setColumnIds}
										renderChild={renderAvailableColumn}
										lockAxis="y"
									/>
								</div>
							</div>

							<div className="grow p-4">
								<ReadOnlyLayoutTable columnIds={visibleColumns} />
							</div>
						</div>}

					{page == 1 &&
						<div className="px-4">
							<W_TextInput
								control={control}
								dataname="name"
								autoFocus
								label="Name"
								toolTip="Give your layout a name."
								mandatory
							/>

							<W_Dropdown
								control={control}
								dataname="visibility"
								options={visibilityOptions}
								label="Visibility"
								toolTip="Choose who can use this layout"
								mandatory
							/>
						</div>
					}
				</form>
			</ModalContent>

			<ModalButtons>
				<Button
					label="Cancel"
					btnClass="btn-grey"
					onClick={props.close}
				/>
				{page == 1 &&
					<Button
						label="Back"
						btnClass="btn-grey"
						onClick={() => setPage(0)}
					/>}
				<Button
					label={page == 0 ? "Next" : isNew ? "Create" : "Submit"}
					btnClass="btn-blue"
					onClick={page == 0 ? () => setPage(1) : () => handleSubmit(onSubmit)()}
					loading={props.isSubmitting}
				/>
			</ModalButtons>
		</Modal>
	);
}
