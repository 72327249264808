import { useState } from "react";
import { Tooltip } from "@mui/material";
import { DeleteOutlineOutlined, EditOutlined } from "@mui/icons-material";
import { useAgent } from "@/Hooks/useAgent";
import DeleteWarningModal from "@/Components/Utility/Modals/DeleteWarningModal";
import { Button } from "@/Components/Button/Button";
import { Action } from "@shared/Entities/EntityTypes";

interface PrivateActionMenuProps {
	action: Action;
	onEdit(): void;
	onDelete(): void;
}

export function PrivateActionMenu(props: PrivateActionMenuProps) {
	const agent = useAgent();

	const [deleteActionModalOpen, setDeleteActionModalOpen] = useState(false);

	return (
		<>
			<div className="flex flex-row gap-1.5">
				<Tooltip title="Edit" placement="bottom">
					<div>
						<Button
							icon={<EditOutlined fontSize="inherit" />}
							onClick={props.onEdit}
						/>
					</div>
				</Tooltip>

				{props.action.agentId == agent.id || agent.isAdmin ?
					<Tooltip title="Delete" placement="bottom">
						<div>
							<Button
								icon={<DeleteOutlineOutlined fontSize="inherit" />}
								onClick={() => setDeleteActionModalOpen(true)}
							/>
						</div>
					</Tooltip>
					: null}
			</div>

			<DeleteWarningModal
				isOpen={deleteActionModalOpen}
				onDelete={props.onDelete}
				onCancel={() => setDeleteActionModalOpen(false)}
				message={"Are you sure you want to permanently delete this private note?"}
			/>
		</>
	);
}
