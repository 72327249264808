import { FaCircle } from "react-icons/fa";
import { Control, Controller, FieldPathByValue } from "react-hook-form";
import SlaTimeAmount from "@/Pages/Config/SLA/SlaTimeAmount";
import { Sla, SlaPriority } from "@/Api/genApi.schemas";

interface PriorityGridProps {
	control: Control<Sla>;
}

export function PriorityGrid(props: PriorityGridProps) {
	return (
		<div className="grid auto-cols-min bg-white border border-gray-200 shadow-sm" style={{ margin: "20px 0", gridTemplateColumns: "130px 1fr 1fr" }}>
			<div className="p-3 bg-gray-100 font-medium">Priority</div>
			<div className="p-3 bg-gray-100 font-medium">Response time</div>
			<div className="p-3 bg-gray-100 font-medium">Resolution time</div>

			<PriorityRow
				label="P1"
				labelColor="#F03232"
				control={props.control}
				name="p1"
			/>

			<PriorityRow
				label="P2"
				labelColor="#F07E32"
				control={props.control}
				name="p2"
			/>

			<PriorityRow
				label="P3"
				labelColor="#F0E332"
				control={props.control}
				name="p3"
			/>

			<PriorityRow
				label="P4"
				labelColor="#4EDB27"
				control={props.control}
				name="p4"
			/>

			<PriorityRow
				label="P5"
				labelColor="#D5D5D5"
				control={props.control}
				name="p5"
			/>
		</div>
	);
}

interface PriorityRowProps {
	label: string;
	labelColor: string;
	control: Control<Sla>;
	name: FieldPathByValue<Sla, SlaPriority>;
}

function PriorityRow(props: PriorityRowProps) {
	return (
		<Controller
			control={props.control}
			name={props.name}
			// rules={{}}
			render={({
				field: { onChange, value },
				fieldState: { error },
			}) => (
				<>
					<div className="border-t inline-flex items-center p-3">
						<FaCircle className="text-sm mr-2" style={{ color: props.labelColor }} />{props.label}
					</div>
					<div className="border-t p-3">
						<SlaTimeAmount
							value={value.response}
							unit={value.responseUnit}
							onChangeValue={newValue => onChange({ ...value, response: newValue })}
							onChangeUnit={newUnit => onChange({ ...value, responseUnit: newUnit })}
						/>
					</div>
					<div className="border-t p-3">
						<SlaTimeAmount
							value={value.resolution}
							unit={value.resolutionUnit}
							onChangeValue={newValue => onChange({ ...value, resolution: newValue })}
							onChangeUnit={newUnit => onChange({ ...value, resolutionUnit: newUnit })}
						/>
					</div>
				</>
			)}
		/>
	);
}
