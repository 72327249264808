import { Control, Controller } from "react-hook-form";
import { Except } from "type-fest";
import TextArea, { TextAreaProps } from "./TextArea";
import { ControlError } from "@shared/Components/FormComponents/ControlError";

interface W_TextAreaProps extends Except<TextAreaProps, "value" | "handleSubmit" | "isWrapped"> {
	control: Control<any>;
	className?: string;
}

/**
 * @see W_TextInput
 */
export default function W_TextArea(props: W_TextAreaProps) {
	const rules: any = {}; // https://react-hook-form.com/api/useform/register

	if (props.mandatory) {
		rules.required = { value: true, message: "This is a required field." };
	}

	return (
		<Controller
			control={props.control}
			name={props.dataname}
			rules={rules}
			render={({
				field: { onChange, value },
				fieldState: { error },
			}) => (
				<div className={props.className}>
					<TextArea
						{...props}
						handleSubmit={(key: string, newValue: string | undefined) => {onChange(newValue);} }
						value={value}
						invalid={error != null}
						isWrapped // Removes browser mandatory field checking.
					/>

					<ControlError error={error?.message} />
				</div>
			)}
		/>
	);
}
