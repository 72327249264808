import axios, { AxiosRequestConfig } from "axios";
import qs from "qs";
import { statusUpdated } from "@/Store/Reducers/connectionStatusSlice";
import store from "@/Store/store";
import { getApiUrl } from "@/Api/ApiUrl";
import { ResBase, ResCreate, ResCreateMulti, ResDelete, ResGetOne, ResGetSome, ResUpdate } from "@/legacy/ApiResponse";
import { getAccessToken } from "@/legacy/ApiAuth";
import { isSlowClient } from "@/Api/custom-instance";
import { ConnectionStatuses } from "@shared/Enums/Enums";

const apiUrl = getApiUrl();

/**
 * Gets the options for Axios to use. Also calls {@link getAccessToken()}.
 */
async function getOptions(params?: object, reqTimeout = 5000): Promise<AxiosRequestConfig> {
	const authToken = await getAccessToken();

	const isSlow = isSlowClient();

	if (isSlow) {
		reqTimeout = 180000;
	}

	if (authToken != null) {
		return {
			headers: {
				Authorization: "Bearer " + authToken,

			},
			timeout: reqTimeout,
			params: params,
			paramsSerializer: paramsSerializer
		};
	} else {
		return {
			timeout: reqTimeout,
			params: params,
			paramsSerializer: paramsSerializer
		};
	}
}

function paramsSerializer(params: any) {
	return qs.stringify(params, { arrayFormat: "repeat" });
}

function handleGodeskError(response: ResBase): ResBase {
	console.log("gd", response);
	if (response.errorField != null && response.errorMsg != null) {
		// Field error.
		return {
			successful: false,
			wasNetworkError: false,
			errorCode: response.errorCode,
			dataNumber1: response.dataNumber1,
			formFieldErrorCode: response.formFieldErrorCode,
			// Can currently only handle a single field error.
			fieldErrors: [{
				field: response.errorField,
				message: response.errorMsg
			}]
		};
	} else {
		return {
			successful: false,
			wasNetworkError: false,
			errorCode: response.errorCode,
			errorMsg: response.errorMsg,
			dataNumber1: response.dataNumber1,
			formFieldErrorCode: response.formFieldErrorCode
		};
	}
}

function handleAxiosError(error: any): ResBase {
	console.error(error);

	if (axios.isAxiosError(error)) {
		if (error.response) {
			// Special case for 502 error message.
			if (error.response.status == 502) {
				return {
					successful: false,
					wasNetworkError: false,
					errorCode: error.response.status,
					errorMsg: "The backend server is down for updates. Please contact us if it doesn't come back online."
				};
			}

			if (error.response.data?.errorField != null) {
				// Field error.
				return {
					successful: false,
					wasNetworkError: false,
					errorCode: error.response.status,
					dataNumber1: error.response.data?.dataNumber1,
					formFieldErrorCode: error.response.data?.formFieldErrorCode,
					// Can currently only handle a single field error.
					fieldErrors: [{
						field: error.response.data?.errorField,
						message: error.response.data?.errorMsg
					}]
				};
			} else {
				return {
					successful: false,
					wasNetworkError: true,
					errorCode: error.response.status,
					errorMsg: error.response.data?.errorMsg,
					dataNumber1: error.response.data?.dataNumber1,
					formFieldErrorCode: error.response.data?.formFieldErrorCode
				};
			}
		} else if (error.request) { // Client never received a response.
			// A special case to stop this from polluting the Redux debugger.
			if (store.getState().connectionStatus.status != ConnectionStatuses.RED) {
				store.dispatch(statusUpdated(ConnectionStatuses.RED));
			}

			return {
				successful: false,
				wasNetworkError: true,
				errorMsg: "Could not reach the server."
			};
		}
	}

	// Anything else.
	return {
		successful: false,
		wasNetworkError: true,
		errorMsg: "Unspecified API error."
	};
}

/**
 * For fetching an object. Like: /tickets/1
 * @deprecated Should be using ApiCaller.
 */
export async function GetOne<T>(endpoint: string, params?: object): Promise<ResGetOne<T>> {
	const reqUrl = apiUrl + "api/" + endpoint;

	console.debug("GET: /" + endpoint);

	const promise = axios.get<ResGetOne<T>>(reqUrl, await getOptions(params))
		.then(response => {
			if (store.getState().connectionStatus.status != ConnectionStatuses.GREEN) {
				store.dispatch(statusUpdated(ConnectionStatuses.GREEN));
			}
			console.debug("Api response:", response.data);

			if (response.data.errorMsg != null) {
				return handleGodeskError(response.data);
			}

			// Do .data transform for payloads if needed.
			if (response.data != null && (response.data as any).data != null) {
				response.data = (response.data as any).data;
			}

			return {
				data: response.data,
				successful: true,
				wasNetworkError: false
			};
		})
		.catch(error => {
			return handleAxiosError(error);
		});

	return promise;
}

/**
 * For fetching arrays of objects. Like: /tickets
 * @deprecated Should be using ApiCaller.
 */
export async function GetSome<T>(endpoint: string, params?: object): Promise<ResGetSome<T>> {
	const reqUrl = apiUrl + "api/" + endpoint;

	console.debug("GET: /" + endpoint);

	const promise = axios.get<ResGetSome<T>>(reqUrl, await getOptions(params))
		.then(response => {
			if (store.getState().connectionStatus.status != ConnectionStatuses.GREEN) {
				store.dispatch(statusUpdated(ConnectionStatuses.GREEN));
			}
			console.debug("Api response:", response.data);

			if (response.data.errorMsg != null) {
				return handleGodeskError(response.data);
			}

			return {
				data: response.data.data,
				pagination: response.data.pagination,
				successful: true,
				wasNetworkError: false
			};
		})
		.catch(error => {
			return handleAxiosError(error);
		});

	return promise;

}

/**
 * Put = update.
 * @deprecated Should be using ApiCaller.
 */
export async function Put<T>(endpoint: string, data: any, params?: object): Promise<ResUpdate<T>> {
	const reqUrl = apiUrl + "api/" + endpoint;

	console.debug("PUT: /" + endpoint);

	const promise = axios.put<ResUpdate<T>>(reqUrl, data, await getOptions(params))
		.then(response => {
			if (store.getState().connectionStatus.status != ConnectionStatuses.GREEN) {
				store.dispatch(statusUpdated(ConnectionStatuses.GREEN));
			}
			console.debug("Api response:", response.data);

			if (response.data.errorMsg != null) {
				return handleGodeskError(response.data);
			}

			return {
				data: response.data.data,
				successful: true,
				wasNetworkError: false
			};
		})

		.catch(error => {
			return handleAxiosError(error);
		});

	return promise;

}

/**
 * Post = create.
 * @deprecated Should be using ApiCaller.
 */
export async function PostOneNew<T>(endpoint: string, data: T, params?: object): Promise<ResCreate<T>> {
	// Clear ID if == -1.
	if ((data as any).id != null && typeof (data as any).id != "object") {
		(data as any).id = null;
	}

	const reqUrl = endpoint.startsWith("importer") ? apiUrl + endpoint : apiUrl + "api/" + endpoint;

	console.debug("POST: /" + endpoint);

	const paramsToUse = { ...params, single: true };

	// Special case for GPT post. Needs increased timeout.
	let options;
	if (endpoint == "gpt/chat") {
		options = await getOptions(paramsToUse, 50000);
	} else {
		options = await getOptions(paramsToUse);
	}

	const promise = axios.post<ResCreate<T>>(reqUrl, data, options)
		.then(response => {
			if (store.getState().connectionStatus.status != ConnectionStatuses.GREEN) {
				store.dispatch(statusUpdated(ConnectionStatuses.GREEN));
			}

			console.debug("Api response:", response.data);

			if (response.data.errorMsg != null) {
				return handleGodeskError(response.data);
			}

			return {
				data: response.data.data,
				successful: true,
				wasNetworkError: false
			};
		})
		.catch(error => {
			return handleAxiosError(error);
		});

	return promise;
}


/**
 * Just calls Post() but without the requirement for data to be an array.
 * Keeping the array requirement for Post() because that's how most endpoints expect data.
 * @deprecated
 */
export async function PostOne<T>(endpoint: string, data: any, params?: object): Promise<ResCreateMulti<T>> {
	return Post(endpoint, data, params);
}

/**
 * Post = create.
 * @deprecated Should be using ApiCaller.
 */
export async function Post<T>(endpoint: string, data: any[], params?: object): Promise<ResCreateMulti<T>> {
	// Clear ID if == -1.
	if (data.forEach != null) { // Array check in case data type has been overridden by PostOne().
		data.forEach(entity => {
			if ((entity as any).id != null) {
				(entity as any).id = null;
			}
		});
	}

	const reqUrl = apiUrl + "api/" + endpoint;

	console.debug("POST: /" + endpoint);

	const paramsToUse = { ...params, multi: true };

	// Special case for GPT post. Needs increased timeout.
	let options;
	if (endpoint == "gpt/chat") {
		options = await getOptions(paramsToUse, 50000);
	} else {
		options = await getOptions(paramsToUse);
	}

	const promise = axios.post<ResCreateMulti<T>>(reqUrl, data, options)
		.then(response => {
			if (store.getState().connectionStatus.status != ConnectionStatuses.GREEN) {
				store.dispatch(statusUpdated(ConnectionStatuses.GREEN));
			}

			console.debug("Api response:", response.data);

			if (response.data.errorMsg != null) {
				return handleGodeskError(response.data);
			}

			return {
				data: response.data.data,
				successful: true,
				wasNetworkError: false
			};
		})
		.catch(error => {
			return handleAxiosError(error);
		});

	return promise;
}

/**
 * @deprecated Should be using ApiCaller.
 */
export async function Delete(endpoint: string, params?: object): Promise<ResDelete> {
	const reqUrl = apiUrl + "api/" + endpoint;

	console.debug("DELETE: /" + endpoint);

	const promise = axios.delete(reqUrl, await getOptions(params))
		.then(response => {
			if (store.getState().connectionStatus.status != ConnectionStatuses.GREEN) {
				store.dispatch(statusUpdated(ConnectionStatuses.GREEN));
			}
			console.debug("Api response:", response.data);

			if (response.data.errorMsg != null) {
				return handleGodeskError(response.data);
			}

			return {
				successful: true,
				wasNetworkError: false
			};
		})
		.catch(error => {
			return handleAxiosError(error);
		});

	return promise;
}

// Special

export async function PostFormData<T>(endpoint: string, data: FormData): Promise<ResCreateMulti<T>> {
	const reqUrl = apiUrl + "api/" + endpoint;

	console.debug("POST (FormData): /" + endpoint);

	const promise = axios.post<ResCreateMulti<T>>(reqUrl, data, await getOptions())
		.then(response => {
			return {
				data: response.data.data,
				successful: true,
				wasNetworkError: false
			};
		})
		.catch(error => {
			return handleAxiosError(error);
		});

	return promise;
}
