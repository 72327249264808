import { AgentAvatar } from "@/Components/Avatar/AgentAvatar";
import { FormParent } from "@shared/Components/Form/FormParent";
import { FormSection } from "@shared/Components/Form/FormSection";
import { FormReadBox } from "@shared/Components/Form/FormReadBox";
import { FormReadPair } from "@shared/Components/Form/FormReadPair";
import { Agent } from "@shared/Entities/EntityTypes";
import { datetimeToWhenString } from "@shared/Helpers/DateFunctions";

export function getAgentTitle(agent: Agent) {
	return (agent.name ?? "") + (agent.deleted ? " (Deleted)" : agent.disabled ? " (Disabled)" : "");
}

interface AgentViewerDisplayProps {
	agent: Agent;
}

export function AgentViewerDisplay(props: AgentViewerDisplayProps) {

	return (
		<FormParent title={getAgentTitle(props.agent)}>
			<FormSection title="Details">
				<FormReadBox>
					<FormReadPair
						name="Name"
						value={props.agent.name}
					/>
					<FormReadPair
						name="Email"
						value={props.agent.email}
					/>
					<FormReadPair
						name="Job Title"
						value={props.agent.jobTitle}
					/>
					<FormReadPair
						name="Phone Number"
						value={props.agent.phoneNumber}
					/>
				</FormReadBox>
			</FormSection>

			<FormSection title="Avatar">
				<AgentAvatar agent={props.agent} widthPx={70} hideAgentStatus />
			</FormSection>

			<FormSection title="Permissions">
				<FormReadBox>
					<FormReadPair
						name="Administrator"
						value={props.agent.isAdmin ? "True" : "False"}
					/>
				</FormReadBox>
			</FormSection>

			<FormSection
				title="Greeting"
				titleTooltip={<span>This will form the first line of your email responses. Set to blank to skip this option. You can also use '$name' to pull in the user's name.</span>}
			>
				<FormReadBox>
					<FormReadPair
						name="Greeting"
						value={props.agent.prefs.emailGreeting === undefined ? "Hi $name," : props.agent.prefs.emailGreeting === "" ? "-" : props.agent.prefs.emailGreeting}
					/>
				</FormReadBox>
			</FormSection>

			<FormSection title="Email Signature">
				{props.agent.signature ?
					<div
						className="text-base p-3 bg-white text-gray-700 rounded border border-gray-200"
						dangerouslySetInnerHTML={{ __html: props.agent.signature }}
					>
					</div>
					:
					<div className="font-semibold text-slate-500">
						-
					</div>}
			</FormSection>

			<FormSection>
				<FormReadBox>
					<FormReadPair
						name="Updated"
						value={datetimeToWhenString(props.agent.modified)}
					/>
					<FormReadPair
						name="Created"
						value={datetimeToWhenString(props.agent.created)}
					/>
				</FormReadBox>
			</FormSection>
		</FormParent>
	);
}
