import { ReactNode, useEffect, useState } from "react";
import { BiChevronDown } from "react-icons/bi";
import { AnimatePresence, motion } from "framer-motion";
import { IoFileTrayFullOutline, IoFileTrayOutline } from "react-icons/io5";
import { ClosedViewID, OpenViewID, WithUserViewID } from "@/Store/Reducers/cache/HardCodedViews";
import { useCache } from "@/Hooks/useCache";
import { TicketCounts } from "@/Api/genApi.schemas";
import ViewButton from "../ViewButton/ViewButton";
import { AgentAvatar } from "../../../../../Components/Avatar/AgentAvatar";
import OtherFunctions from "../../../../../Helpers/OtherFunctions";
import { View } from "@shared/Models/View";

interface ViewDropdownProps {
	name: string;
	views: View[];
	ticketCounts?: TicketCounts;
	icon?: ReactNode;
	activeViewId: number | string;
	setActiveView(view: View): void;
	groupId?: string;
}

export default function ViewDropdown(props: ViewDropdownProps) {
	const localStorageKey = "tickets-" + props.name + "-view-dropdown";

	const [isOpen, setIsOpen] = useState(getInitialState());

	const { cache } = useCache();

	function getInitialState() {
		if (localStorage.getItem(localStorageKey) == "true") {
			return true;
		} else {
			return false;
		}
	}

	useEffect(() => {
		props.views.forEach((view) => {
			// If any of the views are selected, make sure this dropdown is opened. (Unless it is the header).
			if (props.activeViewId == view.id && props.activeViewId != props.groupId) {
				setIsOpen(true);
				localStorage.setItem(localStorageKey, String(true));
				return;
			}
		});
	}, []); // Only run on mount.

	function toggleOpen() {
		setIsOpen((prevState) => {
			if (!prevState) {
				localStorage.setItem(localStorageKey, String(!prevState));
			} else {
				localStorage.removeItem(localStorageKey);
			}
			return !prevState;
		});
	}

	function onClick() {
		if (props.groupId == null || props.groupId == undefined) {
			return;
		}

		// Wierd workaround to fetch this group's header view.
		const view = cache.getView(props.groupId);

		if (view != null) {
			props.setActiveView(view);
		}
	}

	function getViewCount(groupName?: string, id?: string | number): number | undefined {
		if (groupName == "Teams" && typeof id == "string") {
			const teamId = parseInt(id.split(",")[0].split(":")[1]);
			const agentId = parseInt(id.split(",")[1].split(":")[1]);

			if (agentId == -2) { // Team header.
				return props.ticketCounts?.teams.find(teamTicketCounts => teamTicketCounts.teamId == teamId)?.total;
			} else if (agentId == -1) { // Unassigned.
				return props.ticketCounts?.teams.find(teamTicketCounts => teamTicketCounts.teamId == teamId)?.unassigned;
			} else { // Agent.
				const agentCounts = props.ticketCounts?.teams.find(teamTicketCounts => teamTicketCounts.teamId == teamId)?.agentCounts;

				return agentCounts != null ? agentCounts[agentId] : undefined;
			}
		} else if (props.name == "Statuses") {
			if (id == OpenViewID) {
				return props.ticketCounts?.statusOpen;
			} else if (id == WithUserViewID) {
				return props.ticketCounts?.statusUser;
			} else if (id == ClosedViewID) {
				return props.ticketCounts?.statusClosed;
			}
		} else if (groupName == "Agents" && typeof id == "string") {
			const agentId = parseInt(id.split(":")[1]);

			return props.ticketCounts?.agentCounts[agentId];
		} else if (typeof id == "string") {
			// Team dropdown header.
			const teamId = parseInt(id.split(":")[1]);
			return props.ticketCounts?.teams.find(teamTicketCounts => teamTicketCounts.teamId == teamId)?.total;
		}

		return undefined;
	}

	function getViewIcon(groupName?: string, id?: string | number, ticketCount?: number): JSX.Element | undefined {
		if (groupName == "Teams" && typeof id == "string") {
			const agentId = parseInt(id.split(",")[1].split(":")[1]);

			if (agentId == -1) { // Unassigned.
				return ticketCount != null && ticketCount > 0 ? <IoFileTrayFullOutline size={20} /> : <IoFileTrayOutline size={20} />;
			} else { // Agent.
				const agent = cache.getAgent(agentId);
				return <AgentAvatar agent={agent} widthPx={20} hideAgentStatus />;
			}
		} else if (groupName == "Agents" && typeof id == "string") {
			const agentId = parseInt(id.split(":")[1]);

			const agent = cache.getAgent(agentId);
			return <AgentAvatar agent={agent} widthPx={20} hideAgentStatus />;
		}

		return undefined;
	}

	const ticketCount = getViewCount(props.name, props.groupId);
	const ticketCountFormatted = OtherFunctions.ticketCountFormat(ticketCount);
	const isActive = props.groupId == props.activeViewId;

	return (
		<motion.div
			layout
			className={
				" overflow-hidden rounded-md"
				// +	(isOpen && " outline outline-1 outline-gray-200 ")
			}
		>
			<div
				className={
					"z-1 flex h-[34px] select-none items-center border rounded-md overflow-hidden dropdown " +
					(isOpen && " ") +
					(isActive && " drop-shadow-sm bg-gray-100 ") +
					(isActive ? " border-gray-200 " : " border-transparent ")
				}
			>
				<div
					className={
						"flex h-full cursor-pointer items-center hover:bg-gray-100 pr-1 "
					}
					onClick={toggleOpen}
				>
					<motion.div
						className="flex"
						initial={isOpen ? "open" : "collapsed"}
						animate={isOpen ? "open" : "collapsed"}
						variants={{
							open: { rotate: 0 },
							collapsed: { rotate: "-90deg" },
						}}
						transition={{ duration: 0.2 }}
					>
						<BiChevronDown size={18} />
					</motion.div>
				</div>
				<div
					className={
						"flex h-10 w-full items-center rounded-md rounded-l-none px-1 py-1 dropdownElement " +
						(props.groupId != undefined &&
							props.groupId != props.activeViewId &&
							" cursor-pointer hover:bg-gray-100 ")
					}
					onClick={onClick}
				>
					{props.icon != null &&
						<div className="w-5 mr-2 flex items-center justify-center">
							{props.icon}
						</div>}

					<div className="line-clamp-2 leading-5">
						{props.name}
					</div>
					<AnimatePresence>
						{ticketCountFormatted != undefined &&
							<motion.div
								className={
									"ml-auto flex text-right leading-4 tabular-nums rounded-full text-[#121619] bg-[#F2F4F8] py-[5px] px-2 border border-gray-200 " +
									(isActive ? " bg-white " : " bg-gray-100 ")
								}
							>
								{ticketCountFormatted}
							</motion.div>}
					</AnimatePresence>
				</div>
			</div>
			<AnimatePresence initial={false}>
				{isOpen && (
					<motion.div
						key={props.name + "-content"}
						initial="collapsed"
						animate="open"
						exit="collapsed"
						variants={{
							open: { height: "auto" },
							collapsed: { height: 0 },
						}}
						transition={{ duration: 0.2 }}
					>
						<div className="ml-2.5 py-1.5 flex flex-col gap-1">
							{props.views.length > 0 &&
								props.views.map((view) => {
									// Don't show the header view in the list.
									if (view.id == props.groupId) { return null; }

									return (
										<motion.div key={view.id} className="pl-3">
											<ViewButton
												active={view.id == props.activeViewId}
												view={view}
												count={getViewCount(view.groupName, view.id)}
												icon={getViewIcon(view.groupName, view.id, getViewCount(view.groupName, view.id))}
												setActiveView={props.setActiveView}
											/>
										</motion.div>
									);
								})}
						</div>
					</motion.div>
				)}
			</AnimatePresence>
		</motion.div>
	);
}
