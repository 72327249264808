import { ViewsTopHalf } from "@/Pages/Tickets/ViewList/ViewsTopHalf";
import { ViewsBottomHalf } from "@/Pages/Tickets/ViewList/ViewBottomHalf";
import { useGetTicketCounts } from "@/Api/genApi";

export function TicketsViewList() {
	const ticketCountsReq = useGetTicketCounts({ query: {
		refetchInterval: 15 * 1000
	} });

	return (
		<div className="flex w-72 flex-shrink-0 flex-col border-r border-gray-300 bg-white" >
			<div className="flex flex-col pt-4 px-3">
				<div className="text-sm font-medium pb-2">
					Views
				</div>

				<ViewsTopHalf ticketCounts={ticketCountsReq.data} />
			</div>

			<ViewsBottomHalf ticketCounts={ticketCountsReq.data} />
		</div>
	);
}
