import { Email, Business } from "@mui/icons-material";
import { Box, Typography, Stack, Skeleton } from "@mui/material";

interface UserSearchResultSkeletonProps {
	text?: string;
}

export function UserSearchResultSkeleton({ text }: UserSearchResultSkeletonProps) {
	return (

		<div className="flex flex-col w-full">

			{text && (
				<Box mt={1} width="100%">
					<Typography variant="body2" fontWeight="700" fontSize="20px" align="center">
						{text}
					</Typography>
				</Box>
			)}
			<Box
				border={1}
				borderRadius={2}
				padding={2}
				marginX={2}
				marginY={1}
				display="flex"
				alignItems="center"
				justifyContent="space-between"
				height={95}
			>
				<Stack direction="row" spacing={2} alignItems="center">
					<Skeleton variant="circular" width={40} height={40} />
					<Box>
						<Skeleton variant="text" width={150} height={20} />

						<Stack direction="row" alignItems="center" spacing={1}>
							<Email fontSize="small" />
							<Skeleton variant="text" width={120} height={20} />
						</Stack>

						<Stack direction="row" alignItems="center" spacing={1}>
							<Business fontSize="small" />
							<Skeleton variant="text" width={120} height={20} />
						</Stack>
					</Box>
				</Stack>
				<Box display="flex" alignItems="center">
					<Skeleton variant="rectangular" width={60} height={30} />
				</Box>
			</Box>
		</div>
	);
}
