import { Entities } from "../Entities/Entities";

export function getEndpoint(entityType: Entities): string {
	switch (entityType) {
		case Entities.ACTION:
			return "actions";
		case Entities.AGENT:
			return "agents";
		case Entities.ATTACHMENT:
			return "attachments";
		case Entities.AUTOMATION:
			return "automations";
		case Entities.CANNEDREPLY:
			return "cannedreplies";
		case Entities.CATEGORY:
			return "categories";
		case Entities.CHANNELSEMAIL:
			return "channels_email";
		case Entities.COMPANY:
			return "companies";
		case Entities.COMPANY_NOTE:
			return "company_notes";
		case Entities.EMAILNOTIFICATION:
			return "emailnotifications";
		case Entities.KB:
			return "kb";
		case Entities.KBARTICLE:
			return "kbarticles";
		case Entities.KBFOLDER:
			return "kbsections";
		case Entities.SLA:
			return "slas";
		case Entities.TEAM:
			return "teams";
		case Entities.TICKET:
			return "tickets";
		case Entities.USER:
			return "users";
		case Entities.VIEW:
			return "views";


		// Plugins

	}
}
