import { Button } from "@/Components/Button/Button";
import { useCache } from "@/Hooks/useCache";
import Modal from "@shared/Components/Modal/Modal";
import ModalContent from "@shared/Components/Modal/ModalContent";
import ModalButtons from "@shared/Components/Modal/ModalButtons";

interface Props {
	isOpen: boolean;
	closeModal(): void;
}

export function GodeskPurchasedModal(props: Props) {
	const { refreshCache } = useCache();

	const modalStyle = {
		padding: "4px",
		maxWidth: "350px",
		minWidth: "450px",
		maxHeight: "650px",
		border: "none",
		borderRadius: "10px",
		boxShadow: "0 1px 6px -3px rgba(0,0,0, 0.5)",
	};

	function close() {
		// Refresh the cache to make sure the agent limit is updated.
		refreshCache();
		props.closeModal();
	}

	return (
		<Modal
			isOpen={props.isOpen}
			onClose={close}
			modalStyle={modalStyle}
			title="Purchase successful"
		>

			<ModalContent>
				Thank you for purchasing GoDesk!
			</ModalContent>

			<ModalButtons>
				<Button
					btnClass="btn-blue"
					onClick={close}
					label="Continue"
				/>
			</ModalButtons>
		</Modal>
	);
}
