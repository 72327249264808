import { Alert } from "@mui/material";

interface GptUserAnswerProps {
	/** The question in plaintext. */
	question: string;
	/** The answer in HTML */
	answerHtml: string;
}

export function GptUserAnswer(props: GptUserAnswerProps) {
	return (
		<Alert severity="info">
			<div><span className="font-medium">Extra info:</span> <span className="font-semibold1">{props.question}</span></div>
			<hr />
			<span className="font-medium">Answer:</span>

			<div dangerouslySetInnerHTML={{ __html: props.answerHtml }} />
		</Alert>
	);
}
