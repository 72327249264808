import LoadingButton from "@mui/lab/LoadingButton";
import { Alert } from "@mui/material";
import { usePushNotifications } from "@/Hooks/usePushNotifications";

export function PushSubscriptionManager() {
	const { status, subscribe, subscribePending, unsubscribe, unsubscribePending } = usePushNotifications();

	if (status == "denied") {
		return (
			<Alert severity="warning">
				<span className="font-semibold">You have blocked notifications from GoDesk.</span>
				<br /><br />
				Manually enable them in your browser, then refresh the page to enable push notifications.
			</Alert>
		);
	}

	if (status == "active") {
		return (
			<LoadingButton onClick={unsubscribe} loading={unsubscribePending} variant="contained" >
				Unsubscribe
			</LoadingButton>
		);
	}

	return (
		<LoadingButton onClick={subscribe} loading={subscribePending} variant="contained">
			Subscribe
		</LoadingButton>
	);

}
