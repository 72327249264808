import { Button, ButtonProps } from "@mui/material";
import LoadingButton, { LoadingButtonProps } from "@mui/lab/LoadingButton";

interface PrimaryButtonProps extends ButtonProps {}

export function PrimaryButton(props: PrimaryButtonProps) {
	return (
		<Button
			variant="contained"
			size="small"
			color="primary"
			{...props}
		/>
	);
}

interface LoadingPrimaryButtonProps extends LoadingButtonProps {}

export function LoadingPrimaryButton(props: LoadingPrimaryButtonProps) {
	return (
		<LoadingButton
			variant="contained"
			size="small"
			color="primary"
			{...props}
		/>
	);
}
