import ModalButtons from "@shared/Components/Modal/ModalButtons";
import ModalContent from "@shared/Components/Modal/ModalContent";
import Modal from "@shared/Components/Modal/Modal"; // Circular dep.

interface CloseConfirmationModalProps {
	isOpen: boolean;
	onClose(): void;
	onCancel(): void;
}

export default function CloseConfirmationModal(props: CloseConfirmationModalProps) {
	return (
		<Modal
			showCloseWarning={false} // This will be a modal on top of another modal. Make sure we don't have problems with this.
			isOpen={props.isOpen}
			onClose={props.onCancel}
			title="Confirmation"
			type="warning"
		>
			<ModalContent>
				Are you sure you want to close? Any progress will be lost.
			</ModalContent>

			<ModalButtons>
				<button className="btn-grey" onClick={props.onCancel}>Cancel</button>
				<button className="btn-red" onClick={props.onClose}>Close</button>
			</ModalButtons>
		</Modal>
	);
}
