import { ReactNode } from "react";
import TimePicker from "react-time-picker";
import { Value } from "react-time-picker/dist/cjs/shared/types";
import "../../../../node_modules/react-time-picker/dist/TimePicker.css";

interface TimerangeProps {
	value: string;
	valueName: string;
	label?: ReactNode;
	minTime?: string;
	maxTime?: string;
	disabled?: boolean;
	onChange(key: string, newValue: string | undefined): void;
	inline?: boolean;
}

export default function Timerange(props: TimerangeProps) {
	function onChange(value: Value) {
		if (value != props.value) {
			props.onChange(props.valueName, value == null ? undefined : value.toString());
		}
	}

	const styles = props.inline ? "inline" : "Form_Component";

	return (
		<div className={styles}>
			<label className="ml-1 select-none">{props.label}</label>

			<TimePicker
				onChange={onChange}
				clearIcon={null}
				value={props.value}
				disabled={props.disabled}
				minTime={props.minTime}
				maxTime={props.maxTime}
				format="HH:mm"
				disableClock
			/>
		</div>
	);
}
