import { useLocation, useNavigate } from "react-router-dom";
import { AiOutlineFolder } from "react-icons/ai";
import { RiArticleFill, RiArticleLine } from "react-icons/ri";
import TreeView from "react-treeview";
import { KbArticle, KbFolder } from "@shared/Entities/EntityTypes";
import { alphabeticalSort } from "@shared/Helpers/SortFunctions";

interface KbTreeViewProps {
	folders: KbFolder[];
}

export function KbTreeView(props: KbTreeViewProps) {
	return (
		props.folders.map(folder =>
			<TreeViewSection key={folder.id} folder={folder} />
		)
	);
}

interface TreeViewSectionProps {
	folder: KbFolder;
}

function TreeViewSection(props: TreeViewSectionProps) {
	return (
		<TreeView
			nodeLabel={
				<span className="inline-flex items-center gap-1">
					<AiOutlineFolder />
					<span className="truncate">{props.folder.name}</span>
				</span>}
			itemClassName={"mt-2 pt-1.5 pb-0.5 rounded hover:bg-gray-200 active:bg-gray-300 flex overflow-hidden"}
		>

			{props.folder.childFolders?.sort((a, b) => alphabeticalSort(a.name, b.name)).map(folder => (
				<TreeViewSection key={folder.id} folder={folder} />
			))}

			{props.folder.articles?.sort(kbArticleSort).map(article => (
				<TreeViewArticle key={article.id} article={article} />
			))}
		</TreeView>
	);
}

interface TreeViewArticleProps {
	article: KbArticle;
}

function TreeViewArticle(props: TreeViewArticleProps) {
	const navigate = useNavigate();
	const location = useLocation();

	const active = location.pathname == "/kb/article/" + props.article.id;
	const bgStyles = active ? " bg-blue-200" : " hover:bg-gray-200 active:bg-gray-300";

	const articleContentA = props.article.articleContent?.find(article => article.id.language == "en_GB");

	return (
		<div
			onClick={() => navigate("/kb/article/" + props.article.id)}
			className={"cursor-pointer px-2 py-1 my-1 ml-3 rounded flex items-start" + bgStyles}
		>
			<span className={props.article.published ? "mr-1 text-blue-600" : "mr-1 text-yellow-500"}>
				{props.article.published ? <RiArticleLine /> : <RiArticleFill />}
			</span>
			<div className="text-ellipsis overflow-hidden whitespace-nowrap">
				{articleContentA?.title}
			</div>
		</div>
	);
}

function kbArticleSort(a: KbArticle, b: KbArticle): number {
	if (a.published && !b.published) {
		return -1;
	} else if (b.published && !a.published) {
		return 1;
	}

	const articleContentA = a.articleContent?.find(article => article.id.language == "en_GB");
	const articleContentB = b.articleContent?.find(article => article.id.language == "en_GB");

	if (articleContentA?.title != null && articleContentB?.title != null) {
		return articleContentA.title.localeCompare(articleContentB.title);
	}

	return 1;
}
