import { Control, Controller } from "react-hook-form";
import type { Except } from "type-fest";
import { Radio, RadioProps } from "@/Components/FormComponents/Radio/Radio";
import { ControlError } from "@shared/Components/FormComponents/ControlError";

interface W_RadioProps<T> extends Except<RadioProps<T>, "value" | "onChange"> {
	control: Control<any>;
	dataname: string;
}

/**
 * @see W_TextInput
 */
export default function W_Radio<T>(props: W_RadioProps<T>) {
	const rules: any = {}; // https://react-hook-form.com/api/useform/register

	return (
		<Controller
			control={props.control}
			name={props.dataname}
			rules={rules}
			render={({
				field: { onChange, value },
				fieldState: { error }
			}) => (
				<>
					<Radio<T>
						value={value}
						onChange={(key: string, newValue: T) => {onChange(newValue);} }
						{...props}
					/>

					<ControlError error={error?.message} />
				</>
			)}
		/>
	);
}
