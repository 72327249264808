import { Entities } from "@shared/Entities/Entities";

export function getAvailableColumnIds(entity: Entities): string[] {
	// GOTCHA: Column IDs must be added to the BE's allowedTicketColumns list.

	switch (entity) {
		case Entities.TICKET:
			return ["id", "description", "priorityId", "statusId", "responseDue", "userId", "agentId", "customCreated", "companyId"];
	}

	return [];
}

export function getMandatoryColumnIds(entity: Entities): string[] {
	switch (entity) {
		case Entities.TICKET:
			return ["id", "description", "userId"];
	}

	return [];
}

export function getDefaultColumnIds(entity: Entities): string[] {
	switch (entity) {
		case Entities.TICKET:
			return ["id", "description", "priorityId", "statusId", "responseDue", "userId", "agentId", "customCreated"];
	}

	return [];
}

export function getColumnFriendlyName(columnId: string): string {
	switch (columnId) {
		case "id": return "ID";
		case "description": return "Subject";
		case "priorityId": return "Priority";
		case "statusId": return "Status";
		case "responseDue": return "SLA Status";
		case "userId": return "User";
		case "agentId": return "Agent";
		case "customCreated": return "Created";
		case "companyId": return "Company";
		default: return "UNKNOWN COLUMN";
	}
}
