import { useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { Button, ButtonBlue } from "@/Components/Button/Button";
import { useCache } from "@/Hooks/useCache";
import DeleteWarningModal from "@/Components/Utility/Modals/DeleteWarningModal";
import { useActivateCname, useGetCnameChangeStatus, useRemoveCname } from "@/Api/genApi";
import { FormParent } from "@shared/Components/Form/FormParent";
import { FormSection } from "@shared/Components/Form/FormSection";
import { QuestionTooltip } from "@shared/Components/QuestionTooltip/QuestionTooltip";
import W_TextInput from "@shared/Components/FormComponents/TextInput/W_TextInput";
import { InputTypes } from "@shared/Components/FormComponents/TextInput/InputTypes";
import { AlertBox } from "@shared/Components/AlertBox/AlertBox";
import { SettingString } from "@shared/Enums/SettingEnums";

interface CnameForm {
	domain: string;
}

export function Cname() {
	const { cache, refreshCache } = useCache();
	const defaultDomain = cache.getSettingString(SettingString.HELPDESK_DEFAULT_DOMAIN);
	const cnameIsActive = defaultDomain != null && !defaultDomain.includes("localhost") && !defaultDomain.endsWith(".getgodesk.com");

	const activateCnameReq = useActivateCname();
	const removeCnameReq = useRemoveCname();

	const cnameStatusReq = useGetCnameChangeStatus({ query: {
		refetchInterval: 5000
	} });

	const tenantId = cache.getTenantId();

	const tenantRealDomain = tenantId + ".getgodesk.com";

	const [deleteModalIsOpen, setDeleteModalIsOpen] = useState<boolean>(false);

	const { handleSubmit, control, getValues } = useForm<CnameForm>();

	async function onSubmit(form: CnameForm) {
		activateCnameReq.mutate({ params: { domain: form.domain } }, {
			onSuccess: async () => {
				toast.success("CNAME request sent.");

				cnameStatusReq.refetch();
			},
			onError: error => {
				toast.error("Could not activate CNAME: " + error.errorMsg);
			}
		});
	}

	async function removeCname() {
		removeCnameReq.mutate(undefined, {
			onSuccess: async () => {
				toast.success("CNAME removed.");
			},
			onError: error => {
				toast.error("Could not remove CNAME: " + error.errorMsg);
			}
		});
	}

	function redirectToCname() {
		const domain = getValues("domain");
		window.location.href = `https://${domain}?cnamerequest`;
	}

	function redirectToRealDomain() {
		window.location.href = `https://${tenantRealDomain}`;
	}

	const addCnameFormDisabled = cnameStatusReq.data == "ADDING" || cnameStatusReq.data == "ADD_COMPLETED";

	return (
		<>
			<form onSubmit={handleSubmit(onSubmit)}>
				<FormParent
					title="CNAME"
					description="Set up a custom URL for GoDesk on your website."
				>
					<FormSection className="w-[750px]">
						{!cnameIsActive ?
							<>
								<div className="p-4">
									<CnameInfo domain={tenantRealDomain} />

									<div>
										<div className="text-2xl my-4">Activate</div>

										<div className="pl-4">
											<div>Once you have created your CNAME record, activate it in GoDesk below.</div>

											<W_TextInput
												control={control}
												dataname="domain"
												label="Your CNAME domain"
												placeholder="helpdesk.getgodesk.com"
												urlUseFQDN
												mandatory
												disabled={addCnameFormDisabled}
												type={InputTypes.Url}
											/>
										</div>
									</div>
								</div>

								<div className="pl-8">
									<ButtonBlue
										label="Verify and Activate"
										loading={activateCnameReq.isPending}
										disabled={activateCnameReq.isPending || addCnameFormDisabled || cnameStatusReq.data == "ADDING"}
										onClick={() => handleSubmit(onSubmit)()}
									/>
									<div className="mt-2">This can take a few minutes to complete.</div>
								</div>

								{cnameStatusReq.data == "ADDING" &&
									<AlertBox alertType="info">
										Waiting for CNAME to activate. This may take a few minutes. This box will update when this is complete.
									</AlertBox>
								}

								{cnameStatusReq.data == "ADD_COMPLETED" &&
									<AlertBox alertType="info">
										CNAME activated. Click here to go to your new URL.
										<br />
										<Button
											label="Go to new URL"
											btnClass="btn-blue"
											onClick={async () => {
												toast.loading("Redirecting...");

												await refreshCache();
												redirectToCname();
											}}
										/>
									</AlertBox>
								}

								{cnameStatusReq.data == "ADD_FAILED" &&
									<AlertBox alertType="error">Could not add CNAME. Please contact support.</AlertBox>
								}

								{activateCnameReq.error != null && activateCnameReq.error.errorMsg != null &&
									<AlertBox alertType="error">{activateCnameReq.error.errorMsg}</AlertBox>
								}
							</>
							:
							<>
								<div>
									<div className="mb-4 text-lg">
										Currently using: {cache.getSettingString(SettingString.HELPDESK_DEFAULT_DOMAIN)}
									</div>
									<div>
										<Button
											label="Remove CNAME"
											loading={removeCnameReq.isPending}
											disabled={removeCnameReq.isPending}
											tooltip="This may take up to a minute to complete."
											onClick={() => setDeleteModalIsOpen(true)}
										/>
									</div>
								</div>

								{cnameStatusReq.data == "REMOVE_COMPLETED" &&
									<AlertBox alertType="info">
										CNAME removed. Click here to go to your new URL.
										<br />
										<Button
											label="Go to new URL"
											btnClass="btn-blue"
											onClick={async () => {
												toast.loading("Redirecting...");

												await refreshCache();
												redirectToRealDomain();
											}}
										/>
									</AlertBox>
								}

								{cnameStatusReq.data == "REMOVE_FAILED" &&
									<AlertBox alertType="error">Could not remove CNAME. Please contact support.</AlertBox>
								}

								{removeCnameReq.error != null && removeCnameReq.error.errorMsg != null &&
									<AlertBox alertType="error">{removeCnameReq.error.errorMsg}</AlertBox>
								}
							</>
						}
					</FormSection>
				</FormParent>
			</form>
			<DeleteWarningModal
				isOpen={deleteModalIsOpen}
				onDelete={() => {
					removeCname();
					setDeleteModalIsOpen(false);
				}}
				onCancel={() => setDeleteModalIsOpen(false)}
				message="Removing your CNAME will logout all of your agents. You will need to let them know the new URL."
			/>
		</>
	);
}

interface CnameInfoProps {
	domain: string;
}

function CnameInfo(props: CnameInfoProps) {
	const stepStyles = "rounded-full w-8 h-8 bg-blue-200 font-semibold border border-blue-400 px-3 mr-4 flex justify-center items-center ";

	return (
		<div className="pl-4">
			<div className="flex flex-row items-center">
				<div className={stepStyles + "shrink"}>1</div>
				<div className="grow">Sign in to your account with your domain registrar. (GoDaddy/123-reg/IONOS/Namecheap)</div>
			</div>

			<div className="flex flex-row items-center mt-8">
				<div className={stepStyles}>2</div>
				<span>Find the option to change your DNS records.
					This might be called something like "DNS Management", "Name Server Management", or "Advanced Settings".</span>
			</div>

			<div className="flex flex-row items-center mt-8">
				<div className={stepStyles}>3</div>
				<span>Locate the CNAME records for your domain. (CNAME records are used to make a subdomain an alias of an external website).</span>
			</div>

			<div className="flex flex-row items-center mt-8">
				<div className={stepStyles}>4</div>
				<span>Do one of the following:

					If you don't have a CNAME record for your subdomain yet, look for an option to add a new record.
					If you already have a CNAME record for your subdomain, look for an option to edit the record.</span>
			</div>

			<div className="flex flex-row items-center mt-8 mb-4">
				<div className={stepStyles}>5</div>
				<span>Point the CNAME record from your subdomain to your GoDesk subdomain. Use the values below</span>
			</div>

			<div className="grid grid-cols-2 grid-rows-4 gap-4 ml-12 p-4 border border-gray-200 bg-white rounded-lg shadow-sm">
				<div className="font-semibold">Field</div>
				<div className="font-semibold">Value</div>

				<div>Hostname/Record</div>
				<div>
					<span className="font-medium">Desired subdomain. E.g. help, support</span>
					<QuestionTooltip tip="This will be your subdomain. A value of 'helpdesk' in here will give you the URL: 'helpdesk.your-site.com'." />
				</div>

				<div>Destination/Target/Content</div>
				<div>{props.domain}</div>

				<div>TTL</div>
				<div>Default / 1 hour</div>
			</div>
		</div>
	);
}
