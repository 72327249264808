import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Agent } from "@shared/Entities/EntityTypes";

interface LoggedInAgentState {
	agent?: Agent;
}

const initialState: LoggedInAgentState = {
	agent: undefined
};

export const loggedInAgentSlice = createSlice({
	name: "loggedInAgent",
	initialState,
	reducers: {
		agentLoggedIn: (state, action: PayloadAction<Agent>) => {
			state.agent = action.payload;
		},
		agentLoggedOut: state => {
			state.agent = undefined;
		},
		agentDetailsChanged: (state, action: PayloadAction<Agent>) => {
			state.agent = action.payload;
		},
	}
});

export const { agentLoggedIn, agentLoggedOut, agentDetailsChanged } = loggedInAgentSlice.actions;

export default loggedInAgentSlice.reducer;
