import React, { useState, ChangeEvent, ReactNode, useEffect, useId } from "react";
import { ControlLabel } from "@shared/Components/FormComponents/ControlLabel";

export interface TextAreaProps {
	value?: string;
	dataname: string;
	label?: ReactNode;
	placeholder?: string;
	mandatory?: boolean;
	isWrapped?: boolean; // Disables browser mandatory validation. Leaves label.
	autoFocus?: boolean;
	inline?: boolean;
	ref?: React.MutableRefObject<any>;
	handleSubmit(key: string, newValue: string | undefined): void;
	onKeyDown?(event: any): any;
	invalid?: boolean;
	disabled?: boolean;
	rows?: number;
	maxLength?: number;
	toolTip?: ReactNode;
	disableResizable?: boolean;
}

function TextArea(props: TextAreaProps) {
	const controlId = useId();
	const [value, setValue] = useState<string>(props.value ?? "");

	useEffect(() => {
		setValue(props.value ?? "");
	}, [props.value]);

	function handleChange(event: ChangeEvent<HTMLTextAreaElement>) {
		setValue(event.target.value);
	}

	function handleBlur(event: React.FocusEvent<HTMLTextAreaElement>) {
		if (value != props.value) {
			props.handleSubmit(props.dataname, value);
		}
	}

	const displayCssContainer = props.inline ? "inline" : "block";
	const displayCssInput = props.inline ? "" : "w-full";
	const inputCssColors = props.invalid ? " border-red-300 focus:border-red-400" : " border-gray-300 focus:border-blue-500";
	const resizable = props.disableResizable == true ? "resize-none" : "";

	return (
		<div className={displayCssContainer + " Form_Component"}>
			{!props.inline &&
				<ControlLabel
					label={props.label}
					mandatory={props.mandatory}
					invalid={props.invalid}
					controlId={controlId}
					helperText={props.toolTip}
				/>}

			<textarea
				className={displayCssInput + inputCssColors + " shadow-sm rounded-md py-2 px-3 border " + resizable}
				required={props.mandatory && !props.isWrapped}
				id={controlId}
				value={value}
				ref={props.ref}
				onChange={handleChange}
				onBlur={handleBlur}
				onKeyDown={props.onKeyDown}
				placeholder={props.placeholder}
				autoFocus={props.autoFocus}
				disabled={props.disabled}
				rows={props.rows}
				maxLength={props.maxLength}
			/>
			{props.maxLength != null &&
				<div className="text-gray-600 text-sm flex justify-end">{value.length}/{props.maxLength}</div>
			}
		</div>
	);
}

export default TextArea;
