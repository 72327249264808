import { BiEraser } from "react-icons/bi";
import { IoCloseOutline } from "react-icons/io5";
import { IconButton, Tooltip } from "@mui/material";
import { FaArrowRotateRight } from "react-icons/fa6";
import { useCache } from "@/Hooks/useCache";
import { useAppDispatch, useAppSelector } from "../../../Store/hooks";
import { customFilterApplied, customFilterOpened, customFilterPanelCleared } from "../../../Store/Reducers/ticketsPageSlice";

export function FilterPanelTopButtons() {
	const dispatch = useAppDispatch();
	const { cache } = useCache();

	const viewId = useAppSelector(state => state.ticketsPage.viewId);

	function resetFilters() {
		// Get current view's filters. Default to blank.
		const filters = cache.getView(viewId)?.filters ?? {};

		dispatch(customFilterOpened(filters));
	}

	function clearFilters() {
		dispatch(customFilterApplied({}));
	}

	function toggleFilters() {
		dispatch(customFilterPanelCleared());
	}

	return (
		<>
			<Tooltip title="Reset Filters" placement="bottom">
				<IconButton onClick={resetFilters}>
					<FaArrowRotateRight className="text-lg" onClick={resetFilters} />
				</IconButton>
			</Tooltip>

			<Tooltip title="Clear Filters" placement="bottom">
				<IconButton onClick={clearFilters}>
					<BiEraser className="text-2xl" onClick={clearFilters} />
				</IconButton>
			</Tooltip>

			<Tooltip title="Close Filters" placement="bottom">
				<IconButton onClick={toggleFilters}>
					<IoCloseOutline className="text-3xl" />
				</IconButton>
			</Tooltip>
		</>
	);
}
