import { useState } from "react";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { IoPersonAddOutline } from "react-icons/io5";
import { UserMassUpdateReq } from "@/legacy/ApiResponse";
import { ApiMassUpdateUsers } from "@/legacy/ApiCallerOld";
import { HeaderTabEntities } from "@/Components/Header/GlobalTab";
import { globalTabAdded } from "../../../Store/Reducers/globalTabsSlice";
import EntityTable from "../EntityTable";
import { useAppDispatch } from "../../../Store/hooks";
import AddUserModal from "../../Utility/Modals/AddUserModal";
import { View } from "@shared/Models/View";
import { User } from "@shared/Entities/EntityTypes";
import { Entities } from "@shared/Entities/Entities";

interface UserTableProps {
	view?: View;
	/** @deprecated See EntityTable. */
	defaultNewData?: User;
}

export function UserTable(props: UserTableProps) {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();

	const [addUserModal, setAddUserModal] = useState(false);

	function onRowDoubleClick(id: number, rowData: User) {
		const tabTitle = rowData.name != null ? rowData.name : "NO TAB TITLE";

		dispatch(globalTabAdded({ id: id, title: tabTitle, entity: HeaderTabEntities.USERS }));
		navigate("/users/" + id);
	}

	async function onMultiDelete(ids: number[]) {
		const req: UserMassUpdateReq = {
			ids: [...ids],
			disabled: true
		};
		const deleteResponse = await ApiMassUpdateUsers(req);

		if (deleteResponse != null && deleteResponse.errorMsg) {
			toast.error(deleteResponse.errorMsg);
		}
	}

	return (
		<>
			<EntityTable
				entity={Entities.USER}
				view={props.view}
				onRowDoubleClickOverride={onRowDoubleClick}
				editEntityUrl="/users/"
				defaultNewData={props.defaultNewData}
				multiDeleteOverride={onMultiDelete}
				newButtonReplacement={
					{
						label: "Invite users",
						icon: <IoPersonAddOutline />,
						onClick: async () => setAddUserModal(true)
					}
				}
			/>
			<AddUserModal closeModal={() => setAddUserModal(false)} isOpen={addUserModal} />
		</>
	);
}
