import { ReactNode } from "react";

interface LoginParentProps {
	children: ReactNode;
}

export function LoginParent(props: LoginParentProps) {
	return (
		<div className="h-screen flex items-center justify-center" style={{ backgroundColor: "#E1F2FE" }}>
			<div className="max-w-md w-full mx-auto bg-gray-50 py-8 px-5 rounded-md shadow-md md:-translate-y-1/3">
				<div className="w-2/3 mx-auto mb-6">
					<img src="/logowithtext.png" alt="GoDesk Logo" />
				</div>

				{props.children}
			</div>
		</div>
	);
}
