import { ReactNode } from "react";

interface ViewGroupProps {
	name: string;
	children?: ReactNode;
}

export default function ViewGroup(props: ViewGroupProps) {
	return (
		<div>
			<h2 className="text-sm font-semibold pt-3 pb-1">
				{props.name}
			</h2>
			<div className="flex flex-col gap-1">
				{props.children}
			</div>
		</div>
	);
}
