
interface ModalContentProps {
	children: React.ReactNode;
	widthFull?: boolean;
	disableScroll?: boolean;
}

export default function ModalContent(props: ModalContentProps) {
	return (
		<div className={` overflow-y-auto ${props.widthFull ? "py-6" : "px-4 py-6"} ${props.disableScroll ? " !px-0 !py-0 " : "overflow-y-auto"}`}>
			{props.children}
		</div>
	);
}
