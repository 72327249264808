import { useEffect, useState } from "react";
import { AiOutlineUser } from "react-icons/ai";
import { GiTicket } from "react-icons/gi";
import { BiTrash } from "react-icons/bi";
import { ApiUpdateEntity } from "@/legacy/ApiCallerOld";
import api from "@/Api/Api";
import CacheDropdown from "@/Components/FormComponents/CacheDropdown/CacheDropdown";
import { CacheSlices } from "@/Store/Reducers/cache/CacheTypes";
import { ButtonRed } from "@/Components/Button/Button";
import OtherFunctions from "../../../Helpers/OtherFunctions";
import { CompanyAvatar } from "../../../Components/Avatar/CompanyAvatar";
import InlineText from "../../../Components/FormComponents/InlineText/InlineText";
import ApiDropdown from "../../../Components/FormComponents/ApiDropdown/ApiDropdown";
import { QuestionTooltip } from "@shared/Components/QuestionTooltip/QuestionTooltip";
import { Entities } from "@shared/Entities/Entities";
import TextInput from "@shared/Components/FormComponents/TextInput/TextInput";
import { InputTypes } from "@shared/Components/FormComponents/TextInput/InputTypes";
import { Company } from "@shared/Entities/EntityTypes";
import TextArea from "@shared/Components/FormComponents/TextArea/TextArea";

interface CompanyDetailsProps {
	companyId: number;
	deleteCompany(): void;
	refreshCompanyList(): void;
	refreshCache(): Promise<void>;
}

export function CompanyDetailsPane(props: CompanyDetailsProps) {
	const [company, setCompany] = useState<Company>();

	useEffect(() => {
		doFetch();

		async function doFetch() {
			if (props.companyId != -1) {
				const response = await api.getEntity<Company>(Entities.COMPANY, props.companyId);

				if (response != null && response.data != null) {
					setCompany(response.data);
				}
			}
		}
	}, [props.companyId]);

	async function handleSubmit(key: string, newValue: unknown) {
		const dataCopy = Object.assign({}, company);
		const newData = OtherFunctions.SafeChangeProperty(dataCopy, key, newValue);

		if (props.companyId != -1) {
			const req = await ApiUpdateEntity<Company>(Entities.COMPANY, props.companyId, newData);

			if (req != null && req.data != null) {
				setCompany(req.data);

				// If name has been changed refresh the company list.
				if (company?.name != newData?.name) {
					props.refreshCompanyList();
				}
			}

			await props.refreshCache();
		}
	}

	async function newAvatarCallback(dataBase64: string, mimeType: string) {
		const newData = Object.assign({}, company);

		newData.profilePictureData = dataBase64;
		newData.profilePictureMimetype = mimeType;

		const req = await api.updateEntity<Company>(Entities.COMPANY, props.companyId, newData);

		if (req.successful && req.data != null) {
			setCompany(req.data);
		}

		// Update the company list with the new pic.
		props.refreshCompanyList();
	}

	const emailDomainTooltip = <QuestionTooltip tip={"New users whose email addresses match this domain will be created at this company."} />;
	const accountManagerTooltip = <QuestionTooltip tip={"The agent who is responsible for this company."} />;

	return (
		<div className="flex justify-between gap-16">
			{company != null && props.companyId != -1 ?
				<>
					<div className="grow flex flex-row">
						<div>
							<div className="flex max-h-[50px]">
								<div>
									<CompanyAvatar company={company} widthPx={50} updateAvatar={newAvatarCallback} />
								</div>
								<div style={{ margin: "auto 0 auto 5px" }}>
									<InlineText value={company.name} maxLength={255} dataname="name" handleSubmit={handleSubmit} />
								</div>
							</div>

							<div className="grid grid-cols-2 grid-rows-2 gap-x-10 w-[500px] mb-4">
								<div >
									<ApiDropdown
										label="Primary contact"
										value={company.primaryUserId}
										dataname="primaryUserId"
										onChange={handleSubmit}
										entity={Entities.USER}
										view={{ filters: {
											companyId: { type: "number", comparison: "equals", value: company.id }
										} }}
										searchableField={"name"}
									/>
								</div>

								<div className="col-start-1 row-start-2">
									<TextInput
										label={<>Email domain {emailDomainTooltip}</>}
										value={company.emailDomain}
										dataname="emailDomain"
										onChangeDebounced={(_key, newValue) => handleSubmit("emailDomain", newValue)}
										type={InputTypes.Url}
										placeholder={"their-domain.com"}
										maxLength={255}
									/>
								</div>

								<div className="col-start-2 row-start-1">
									<CacheDropdown
										dataname="accountManagerId"
										onChange={handleSubmit}
										label={<>Account manager {accountManagerTooltip}</>}
										value={company.accountManagerId}
										cacheSlice={CacheSlices.Agents}
									/>
								</div>
							</div>
						</div>
						<div className=" w-[300px] pl-10 self-center mt-6 ">
							<TextArea
								dataname="description"
								handleSubmit={handleSubmit}
								rows={4}
								maxLength={150}
								label="Description"
								value={company.description}
								disableResizable
							/>
						</div>
					</div>
					<div>
						<div>
							<b>Statistics</b>
						</div>
						<div>
							<GiTicket /> {company.ticketCount} Open Tickets
							<br /><AiOutlineUser /> {company.userCount} Users
						</div>
					</div>
					<div>
						<div style={{ textAlign: "right" }}>
							<ButtonRed
								onClick={props.deleteCompany}
								icon={<BiTrash />}
								label="Delete"
							/>
						</div>
					</div>
				</>
				:
				<></>
			}
		</div>
	);
}
