import { BillingPlanCurrency, BillingPlanPaymentTerm } from "@/Api/genApi.schemas";

export function getCurrencySymbol(currency: BillingPlanCurrency): string {
	switch (currency) {
		case BillingPlanCurrency.USD: return "$";
		case BillingPlanCurrency.GBP: return "£";
		case BillingPlanCurrency.EUR: return "€";
	}
}

export function getPlanPrice(currency: BillingPlanCurrency, term: BillingPlanPaymentTerm): number {
	if (currency == "GBP") {
		if (term == "MONTHLY") {
			return 20;
		} else {
			return 18;
		}
	}

	if (currency == "EUR") {
		if (term == "MONTHLY") {
			return 20;
		} else {
			return 18;
		}
	}

	if (currency == "USD") {
		if (term == "MONTHLY") {
			return 20;
		} else {
			return 18;
		}
	}

	return 0;
}
