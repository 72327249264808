import { useNavigate, useParams } from "react-router-dom";
import { AiOutlineSearch } from "react-icons/ai";
import { SearchPageResults } from "@/Pages/SearchPage/SearchPageResults";
import Searchbox from "../../Components/SearchBox/Searchbox";

export default function SearchPage() {
	document.title = "Search | GoDesk";

	const params = useParams();
	const query = params.query;

	const navigate = useNavigate();

	function searchOnChange(term: string) {
		navigate("/search/" + term);
	}

	return (
		<div className="mx-auto p-4 max-w-6xl">
			<div>
				<div className="text-2xl my-1">Search</div>
				<div className="my-3">
					Search tickets, users, companies, agents and KB articles.
				</div>
			</div>

			<div className="flex justify-between">
				<div className="flex items-center">
					<Searchbox searchCallback={searchOnChange} />
					<button className="btn-grey"><AiOutlineSearch /></button>
				</div>
			</div>

			{query &&
				<SearchPageResults query={query} />}
		</div>
	);
}
