import { DateTime } from "luxon";

/*

To do a secondary sort, chain sorts with ||.
Equal values will return 0, which is falsy. So the next one is run.

*/

export function alphabeticalSort(a?: string, b?: string) {
	// First, check nulls.
	const nullSortRes = nullSort(a, b);

	if (nullSortRes != null) {
		return nullSortRes;
	}

	if (a == null || b == null) { // Should never fire. Just here for TS.
		return 0;
	}

	// Then, do alphabetical sort.
	return a.localeCompare(b);
}

export function dateSort(a?: string, b?: string) {
	// First, check nulls.
	const nullSortRes = nullSort(a, b);

	if (nullSortRes != null) {
		return nullSortRes;
	}

	if (a == null || b == null) { // Should never fire. Just here for TS.
		return 0;
	}

	const dateA = DateTime.fromISO(a);
	const dateB = DateTime.fromISO(b);

	// Then, do date sort.
	if (dateA < dateB) {
		return -1;
	} else if (dateA == dateB) {
		return 0;
	} else {
		return 1;
	}

}

export function numberSort(a?: number, b?: number) {
	// First, check nulls.
	const nullSortRes = nullSort(a, b);

	if (nullSortRes != null) {
		return nullSortRes;
	}

	if (a == null || b == null) { // Should never fire. Just here for TS.
		return 0;
	}

	// Then, do number sort.
	if (a < b) {
		return -1;
	} else if (a == b) {
		return 0;
	} else {
		return 1;
	}
}

function nullSort(a?: unknown, b?: unknown): number | undefined {
	if (a == null && b != null) {
		return -1;
	} else if (a != null && b == null) {
		return 1;
	} else if (a == null && b == null) {
		return 0;
	}

	return undefined;
}
