import Box from "@mui/material/Box";
import { ImShift } from "react-icons/im";

interface KeyProps {
	/** Special case for 'shift'.*/
	children: string;
}

export default function Key(props: KeyProps) {
	let key;
	let extraStyles;

	if (props.children == "shift") {
		key = <ImShift className="" />;
		extraStyles = "";
	} else {
		key = props.children;
		extraStyles = "px-1";
	}

	return (
		<Box
			className={"border inline-flex justify-center rounded border-b-2 leading-4 min-w-[1.25rem] py-0.5 " + extraStyles}
			sx={{
				backgroundColor: "neutral.100",
				borderColor: "neutral.300",
				color: "neutral.800"
			}}
		>
			{key}
		</Box>
	);
}
