import { useMemo } from "react";
import { Box, Typography, CircularProgress, List } from "@mui/material";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { useDebounce } from "use-debounce";
import InfiniteScroll from "react-infinite-scroller";
import { getAllUsers, getGetAllUsersQueryKey } from "@/Api/genApi";
import { User } from "@/Api/genApi.schemas";
import UserSearchResult from "@/Components/UserSearch/UserSearchResult";
import { generateFilterParam } from "@/Components/UserSearch/generateFilterParam";
import { useGodeskInfiniteQuery } from "@/Hooks/useGodeskInfinateQuery";
import { UserSearchResultSkeleton } from "@/Components/UserSearch/UserSearchResultSkeleton";
import { useCache } from "@/Hooks/useCache";
import { GD_SUPPORT_AGENT_ID } from "@shared/Enums/Enums";

export interface UserSearchCriteria {
	name?: string;
	email?: string;
	companyId?: string;
	isPrimaryContact?: boolean;
	entity: "user" | "agent";
}

interface UserSearchResultsProps {
	criteria: UserSearchCriteria;
	onUserSelect(user: User): void;
}

export default function UserSearchResults(props: UserSearchResultsProps) {
	const { cache } = useCache();
	// Debounce the criteria so the useQuery hook doesn't run with every keystroke.
	const [criteriaD] = useDebounce(props.criteria, 500);

	// Memoised because generateFilterParam will create a new obj every render.
	const filterParam = useMemo(() => generateFilterParam(criteriaD), [criteriaD]);

	const criteriaNotSet =
		(criteriaD.companyId == null || criteriaD.companyId == "") &&
		(criteriaD.email == null || criteriaD.email == "") &&
		(criteriaD.name == null || criteriaD.name == "") &&
		(criteriaD.isPrimaryContact == null || criteriaD.isPrimaryContact == false);

	const { req, data, total } = useGodeskInfiniteQuery({
		queryKey: getGetAllUsersQueryKey({ filter: filterParam }),
		queryFn: params => getAllUsers({
			filter: filterParam,
			offset: params?.offset,
			limit: params?.limit
		}),
		enabled: !criteriaNotSet,
		keepPreviousData: true
	});

	if (req.error) {
		return (
			<Box sx={{ width: 480, display: "flex", flexDirection: "column", justifyContent: "start", alignItems: "center", height: 400, paddingTop: 2 }}>
				<ErrorOutlineIcon sx={{ fontSize: 48, color: "red" }} />
				<Typography color="error" gutterBottom>
					An error occurred while fetching users.
				</Typography>
			</Box>
		);
	}

	const entityCache = props.criteria.entity == "user" ? cache.Users : cache.Agents;
	const validEntityCount = entityCache.filter(user => !user.disabled && !user.deleted && user.id != GD_SUPPORT_AGENT_ID).length;

	if (criteriaNotSet) {
		return (
			<Box sx={{ width: 480, height: 400, px: 2, display: "flex", justifyContent: "center", paddingTop: "100px" }}>
				<Typography sx={{ fontSize: "18px", color: "#595959" }}>
					Search {validEntityCount} {getEntityString(props.criteria.entity, validEntityCount) } {req.isLoading && <CircularProgress color="info" size={15} />}
				</Typography>
			</Box>
		);
	}

	// Is initial load.
	if (req.isFetching && req.isLoading) {
		return (
			<Box sx={{ width: 480, display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "start", height: 400, paddingTop: 3, maxHeight: 400, overflowY: "auto" }}>
				<div className="mt-[110x]"></div>{/* Hidden div to stop flickering */}
				<Typography gutterBottom sx={{ fontSize: "18px", width: 433, marginTop: 10.5, paddingLeft: 2 }}>
					Fetching users {req.isFetching && <CircularProgress color="info" size={15} />}
				</Typography>
				<UserSearchResultSkeleton />
				<UserSearchResultSkeleton />
				<UserSearchResultSkeleton />
				<UserSearchResultSkeleton />
			</Box>
		);
	}

	const users = data || [];

	return (
		<Box sx={{ width: "100%", pl: 2, maxHeight: 400, overflowY: "auto" }}>
			<Typography gutterBottom sx={{ fontSize: "18px", marginTop: 2, width: 433 }}>
				<span>Found {total} {getEntityString(props.criteria.entity, total)}</span> {req.isRefetching && <CircularProgress color="info" size={15} />}
			</Typography>

			<List className="overflow-auto h-[350px]">
				{users.length > 0 &&
					<InfiniteScroll
						loadMore={() => req.fetchNextPage()}
						hasMore={req.hasNextPage}
						loader={<div className="loader" key={0}>Loading ...</div>}
						useWindow={false}
						threshold={1000}
					>
						{users.map((user: User) => (
							<UserSearchResult
								key={user.id}
								user={user}
								onUserSelect={props.onUserSelect}
							/>
						))}
					</InfiniteScroll>}
			</List>
		</Box>
	);
}

function getEntityString(entity: "user" | "agent", count?: number): string {
	if (entity == "user") {
		return count == undefined || count == 1 ? "user" : "users";
	} else {
		return count == undefined || count == 1 ? "agent" : "agents";
	}
}
