import { ReactNode, useMemo } from "react";
import Dropdown from "@shared/Components/FormComponents/Dropdown/Dropdown";

interface TimezoneDropdownProps {
	value: string | undefined | null;
	dataname: string;
	onChange(key: string, value: string): void;
	label?: ReactNode;
	toolTip?: ReactNode;
	mandatory?: boolean;
	inline?: boolean;
	invalid?: boolean;
	disabled?: boolean;
	widthCss?: string;
}

export function TimezoneDropdown(props: TimezoneDropdownProps) {
	const options = useMemo(() => {
		return tzData.map(entry => {
			return {
				value: entry.id,
				label: <>{entry.name} <span className="text-gray-600 text-sm">{entry.offset}</span></>
			};
		});
	}, []);

	return (
		<Dropdown
			options={options}
			{...props}
		/>
	);
}

const tzData = [
	// Common
	{ offset: "(UTC +00:00)", name: "Dublin, Edinburgh, Lisbon, London", id: "Europe/London" },
	{ offset: "(UTC -08:00)", name: "Pacific Time (US and Canada)", id: "America/Los_Angeles" },
	{ offset: "(UTC -05:00)", name: "Eastern Time (US and Canada)", id: "America/New_York" },
	{ offset: "(UTC -07:00)", name: "Mountain Time (US and Canada)", id: "America/Denver" },

	// Rest (includes common ones again.)
	{ offset: "(UTC -12:00)", name: "International Date Line West", id: "Etc/GMT+12" },
	{ offset: "(UTC -11:00)", name: "Coordinated Universal Time-11", id: "Etc/GMT+11" },
	{ offset: "(UTC -10:00)", name: "Hawaii", id: "Pacific/Honolulu" },
	{ offset: "(UTC -09:00)", name: "Alaska", id: "America/Anchorage" },
	{ offset: "(UTC -08:00)", name: "Baja California", id: "America/Santa_Isabel" },
	{ offset: "(UTC -08:00)", name: "Pacific Time (US and Canada)", id: "America/Los_Angeles" },
	{ offset: "(UTC -07:00)", name: "Chihuahua, La Paz, Mazatlan", id: "America/Chihuahua" },
	{ offset: "(UTC -07:00)", name: "Arizona", id: "America/Phoenix" },
	{ offset: "(UTC -07:00)", name: "Mountain Time (US and Canada)", id: "America/Denver" },
	{ offset: "(UTC -06:00)", name: "Central America", id: "America/Guatemala" },
	{ offset: "(UTC -06:00)", name: "Central Time (US and Canada)", id: "America/Chicago" },
	{ offset: "(UTC -06:00)", name: "Saskatchewan", id: "America/Regina" },
	{ offset: "(UTC -06:00)", name: "Guadalajara, Mexico City, Monterey", id: "America/Mexico_City" },
	{ offset: "(UTC -05:00)", name: "Bogota, Lima, Quito", id: "America/Bogota" },
	{ offset: "(UTC -05:00)", name: "Indiana (East)", id: "America/Indiana/Indianapolis" },
	{ offset: "(UTC -05:00)", name: "Eastern Time (US and Canada)", id: "America/New_York" },
	{ offset: "(UTC -04:30)", name: "Caracas", id: "America/Caracas" },
	{ offset: "(UTC -04:00)", name: "Atlantic Time (Canada)", id: "America/Halifax" },
	{ offset: "(UTC -04:00)", name: "Asuncion", id: "America/Asuncion" },
	{ offset: "(UTC -04:00)", name: "Georgetown, La Paz, Manaus, San Juan", id: "America/La_Paz" },
	{ offset: "(UTC -04:00)", name: "Cuiaba", id: "America/Cuiaba" },
	{ offset: "(UTC -04:00)", name: "Santiago", id: "America/Santiago" },
	{ offset: "(UTC -03:30)", name: "Newfoundland", id: "America/St_Johns" },
	{ offset: "(UTC -03:00)", name: "Brasilia", id: "America/Sao_Paulo" },
	{ offset: "(UTC -03:00)", name: "Greenland", id: "America/Godthab" },
	{ offset: "(UTC -03:00)", name: "Cayenne, Fortaleza", id: "America/Cayenne" },
	{ offset: "(UTC -03:00)", name: "Buenos Aires", id: "America/Argentina/Buenos_Aires" },
	{ offset: "(UTC -03:00)", name: "Montevideo", id: "America/Montevideo" },
	{ offset: "(UTC -02:00)", name: "Coordinated Universal Time-2", id: "Etc/GMT+2" },
	{ offset: "(UTC -01:00)", name: "Cape Verde", id: "Atlantic/Cape_Verde" },
	{ offset: "(UTC -01:00)", name: "Azores", id: "Atlantic/Azores" },
	{ offset: "(UTC +00:00)", name: "Coordinated Universal Time", id: "Etc/UTC" },
	{ offset: "(UTC +00:00)", name: "Casablanca", id: "Africa/Casablanca" },
	{ offset: "(UTC +00:00)", name: "Monrovia, Reykjavik", id: "Atlantic/Reykjavik" },
	{ offset: "(UTC +00:00)", name: "Dublin, Edinburgh, Lisbon, London", id: "Europe/London" },
	{ offset: "(UTC +00:00)", name: "Coordinated Universal Time", id: "Etc/GMT" },
	{ offset: "(UTC +01:00)", name: "Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna", id: "Europe/Berlin" },
	{ offset: "(UTC +01:00)", name: "Brussels, Copenhagen, Madrid, Paris", id: "Europe/Paris" },
	{ offset: "(UTC +01:00)", name: "West Central Africa", id: "Africa/Lagos" },
	{ offset: "(UTC +01:00)", name: "Belgrade, Bratislava, Budapest, Ljubljana, Prague", id: "Europe/Budapest" },
	{ offset: "(UTC +01:00)", name: "Sarajevo, Skopje, Warsaw, Zagreb", id: "Europe/Warsaw" },
	{ offset: "(UTC +01:00)", name: "Windhoek", id: "Africa/Windhoek" },
	{ offset: "(UTC +02:00)", name: "Athens, Bucharest, Istanbul", id: "Europe/Istanbul" },
	{ offset: "(UTC +02:00)", name: "Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius", id: "Europe/Kiev" },
	{ offset: "(UTC +02:00)", name: "Cairo", id: "Africa/Cairo" },
	{ offset: "(UTC +02:00)", name: "Damascus", id: "Asia/Damascus" },
	{ offset: "(UTC +02:00)", name: "Amman", id: "Asia/Amman" },
	{ offset: "(UTC +02:00)", name: "Harare, Pretoria", id: "Africa/Johannesburg" },
	{ offset: "(UTC +02:00)", name: "Jerusalem", id: "Asia/Jerusalem" },
	{ offset: "(UTC +02:00)", name: "Beirut", id: "Asia/Beirut" },
	{ offset: "(UTC +03:00)", name: "Baghdad", id: "Asia/Baghdad" },
	{ offset: "(UTC +03:00)", name: "Minsk", id: "Europe/Minsk" },
	{ offset: "(UTC +03:00)", name: "Kuwait, Riyadh", id: "Asia/Riyadh" },
	{ offset: "(UTC +03:00)", name: "Nairobi", id: "Africa/Nairobi" },
	{ offset: "(UTC +03:30)", name: "Tehran", id: "Asia/Tehran" },
	{ offset: "(UTC +04:00)", name: "Moscow, St. Petersburg, Volgograd", id: "Europe/Moscow" },
	{ offset: "(UTC +04:00)", name: "Tbilisi", id: "Asia/Tbilisi" },
	{ offset: "(UTC +04:00)", name: "Yerevan", id: "Asia/Yerevan" },
	{ offset: "(UTC +04:00)", name: "Abu Dhabi, Muscat", id: "Asia/Dubai" },
	{ offset: "(UTC +04:00)", name: "Baku", id: "Asia/Baku" },
	{ offset: "(UTC +04:00)", name: "Port Louis", id: "Indian/Mauritius" },
	{ offset: "(UTC +04:30)", name: "Kabul", id: "Asia/Kabul" },
	{ offset: "(UTC +05:00)", name: "Tashkent", id: "Asia/Tashkent" },
	{ offset: "(UTC +05:00)", name: "Islamabad, Karachi", id: "Asia/Karachi" },
	{ offset: "(UTC +05:30)", name: "Sri Jayewardenepura Kotte", id: "Asia/Colombo" },
	{ offset: "(UTC +05:30)", name: "Chennai, Kolkata, Mumbai, New Delhi", id: "Asia/Kolkata" },
	{ offset: "(UTC +05:45)", name: "Kathmandu", id: "Asia/Kathmandu" },
	{ offset: "(UTC +06:00)", name: "Astana", id: "Asia/Almaty" },
	{ offset: "(UTC +06:00)", name: "Dhaka", id: "Asia/Dhaka" },
	{ offset: "(UTC +06:00)", name: "Yekaterinburg", id: "Asia/Yekaterinburg" },
	{ offset: "(UTC +06:30)", name: "Yangon", id: "Asia/Yangon" },
	{ offset: "(UTC +07:00)", name: "Bangkok, Hanoi, Jakarta", id: "Asia/Bangkok" },
	{ offset: "(UTC +07:00)", name: "Novosibirsk", id: "Asia/Novosibirsk" },
	{ offset: "(UTC +08:00)", name: "Krasnoyarsk", id: "Asia/Krasnoyarsk" },
	{ offset: "(UTC +08:00)", name: "Ulaanbaatar", id: "Asia/Ulaanbaatar" },
	{ offset: "(UTC +08:00)", name: "Beijing, Chongqing, Hong Kong, Urumqi", id: "Asia/Shanghai" },
	{ offset: "(UTC +08:00)", name: "Perth", id: "Australia/Perth" },
	{ offset: "(UTC +08:00)", name: "Kuala Lumpur, Singapore", id: "Asia/Singapore" },
	{ offset: "(UTC +08:00)", name: "Taipei", id: "Asia/Taipei" },
	{ offset: "(UTC +09:00)", name: "Irkutsk", id: "Asia/Irkutsk" },
	{ offset: "(UTC +09:00)", name: "Seoul", id: "Asia/Seoul" },
	{ offset: "(UTC +09:00)", name: "Osaka, Sapporo, Tokyo", id: "Asia/Tokyo" },
	{ offset: "(UTC +09:30)", name: "Darwin", id: "Australia/Darwin" },
	{ offset: "(UTC +09:30)", name: "Adelaide", id: "Australia/Adelaide" },
	{ offset: "(UTC +10:00)", name: "Hobart", id: "Australia/Hobart" },
	{ offset: "(UTC +10:00)", name: "Yakutsk", id: "Asia/Yakutsk" },
	{ offset: "(UTC +10:00)", name: "Brisbane", id: "Australia/Brisbane" },
	{ offset: "(UTC +10:00)", name: "Guam, Port Moresby", id: "Pacific/Port_Moresby" },
	{ offset: "(UTC +10:00)", name: "Canberra, Melbourne, Sydney", id: "Australia/Sydney" },
	{ offset: "(UTC +11:00)", name: "Vladivostok", id: "Asia/Vladivostok" },
	{ offset: "(UTC +11:00)", name: "Solomon Islands, New Caledonia", id: "Pacific/Guadalcanal" },
	{ offset: "(UTC +12:00)", name: "Coordinated Universal Time+12", id: "Etc/GMT-12" },
	{ offset: "(UTC +12:00)", name: "Fiji, Marshall Islands", id: "Pacific/Fiji" },
	{ offset: "(UTC +12:00)", name: "Magadan", id: "Asia/Magadan" },
	{ offset: "(UTC +12:00)", name: "Auckland, Wellington", id: "Pacific/Auckland" },
	{ offset: "(UTC +13:00)", name: "Nuku'alofa", id: "Pacific/Tongatapu" },
	{ offset: "(UTC +13:00)", name: "Samoa", id: "Pacific/Apia" }
];
