import { DateTime } from "luxon";
import { EnrichedCache } from "@/Store/Reducers/cache/CacheTypes";
import { Ticket } from "@shared/Entities/EntityTypes";

export function getDummyData(cache: EnrichedCache): Ticket[] {
	const now = DateTime.now();

	const activeUsers = cache.Users.filter(user => !user.deleted && !user.disabled && !user.isAgent);
	const activeCompanies = cache.Companies.filter(company => !company.disabled);
	const activeAgents = cache.Agents.filter(agent => !agent.disabled && !agent.deleted);

	function getRandomElement<T>(array: T[]): T {
		return array[Math.floor(Math.random() * array.length)];
	}

	return [
		{
			id: 1,
			description: "Software Bug",
			priorityId: 1,
			statusId: 1,
			responseDue: now.plus({ hours: 1, minutes: 5 }).toISO(),
			slaId: 1,
			customCreated: now.minus({ hours: 5 }).toISO(),
			userId: getRandomElement(activeUsers).id,
			agentId: getRandomElement(activeAgents).id,
			companyId: getRandomElement(activeCompanies).id,
			ticketread: true
		},
		{
			id: 2,
			description: "Marketing Campaign",
			priorityId: 4,
			statusId: 2,
			responseDue: now.minus({ minutes: 5 }).toISO(),
			slaId: 1,
			customCreated: now.minus({ days: 5 }).toISO(),
			userId: getRandomElement(activeUsers).id,
			agentId: getRandomElement(activeAgents).id,
			companyId: getRandomElement(activeCompanies).id,
			ticketread: true
		},
		{
			id: 3,
			description: "Invoice Issue",
			priorityId: 3,
			statusId: 1,
			responseDue: now.plus({ minutes: 5 }).toISO(),
			slaId: 1,
			customCreated: now.minus({ hours: 5 }).toISO(),
			userId: getRandomElement(activeUsers).id,
			agentId: getRandomElement(activeAgents).id,
			companyId: getRandomElement(activeCompanies).id,
			ticketread: true
		},
		{
			id: 4,
			description: "Sales Report",
			priorityId: 4,
			statusId: 1,
			responseDue: now.plus({ hours: 2, minutes: 5 }).toISO(),
			slaId: 1,
			customCreated: now.minus({ hours: 5 }).toISO(),
			userId: getRandomElement(activeUsers).id,
			agentId: getRandomElement(activeAgents).id,
			ticketread: true
		},
		{
			id: 5,
			description: "Research Data",
			priorityId: 5,
			statusId: 3,
			responseDue: now.plus({ hours: 1, minutes: 5 }).toISO(),
			slaId: 1,
			customCreated: now.minus({ hours: 5 }).toISO(),
			userId: getRandomElement(activeUsers).id,
			agentId: getRandomElement(activeAgents).id,
			companyId: getRandomElement(activeCompanies).id,
			ticketread: true
		},
		{
			id: 6,
			description: "Employee Onboarding",
			priorityId: 2,
			statusId: 1,
			responseDue: now.plus({ hours: 1, minutes: 5 }).toISO(),
			slaId: 1,
			customCreated: now.minus({ hours: 5 }).toISO(),
			userId: getRandomElement(activeUsers).id,
			agentId: getRandomElement(activeAgents).id,
			ticketread: true
		},
		{
			id: 7,
			description: "Project Update",
			priorityId: 2,
			statusId: 2,
			responseDue: now.plus({ hours: 1, minutes: 5 }).toISO(),
			slaId: 1,
			customCreated: now.minus({ hours: 5 }).toISO(),
			userId: getRandomElement(activeUsers).id,
			agentId: getRandomElement(activeAgents).id,
			ticketread: true
		},
		{
			id: 8,
			description: "Technical Issue",
			priorityId: 4,
			statusId: 2,
			responseDue: now.plus({ hours: 1, minutes: 5 }).toISO(),
			slaId: 1,
			customCreated: now.minus({ hours: 5 }).toISO(),
			userId: getRandomElement(activeUsers).id,
			agentId: getRandomElement(activeAgents).id,
			companyId: getRandomElement(activeCompanies).id,
			ticketread: true
		},
		{
			id: 9,
			description: "Contract Review",
			priorityId: 4,
			statusId: 1,
			responseDue: now.minus({ minutes: 6 }).toISO(),
			slaId: 1,
			customCreated: now.minus({ hours: 5 }).toISO(),
			userId: getRandomElement(activeUsers).id,
			agentId: getRandomElement(activeAgents).id,
			companyId: getRandomElement(activeCompanies).id,
			ticketread: true
		},
		{
			id: 10,
			description: "System Upgrade",
			priorityId: 4,
			statusId: 1,
			responseDue: now.plus({ hours: 1, minutes: 5 }).toISO(),
			slaId: 1,
			customCreated: now.minus({ hours: 5 }).toISO(),
			userId: getRandomElement(activeUsers).id,
			agentId: getRandomElement(activeAgents).id,
			companyId: getRandomElement(activeCompanies).id,
			ticketread: true
		},
		{
			id: 11,
			description: "Inventory Management",
			priorityId: 3,
			statusId: 3,
			responseDue: now.plus({ hours: 1, minutes: 5 }).toISO(),
			slaId: 1,
			customCreated: now.minus({ hours: 5 }).toISO(),
			userId: getRandomElement(activeUsers).id,
			agentId: getRandomElement(activeAgents).id,
			companyId: getRandomElement(activeCompanies).id,
			ticketread: true
		},
		{
			id: 12,
			description: "Supplier Contract",
			priorityId: 5,
			statusId: 1,
			responseDue: now.plus({ hours: 1, minutes: 5 }).toISO(),
			slaId: 1,
			customCreated: now.minus({ hours: 5 }).toISO(),
			userId: getRandomElement(activeUsers).id,
			agentId: getRandomElement(activeAgents).id,
			ticketread: true
		},
		{
			id: 13,
			description: "Shipment Delay",
			priorityId: 5,
			statusId: 1,
			responseDue: now.plus({ days: 2, minutes: 5 }).toISO(),
			slaId: 1,
			customCreated: now.minus({ hours: 5 }).toISO(),
			userId: getRandomElement(activeUsers).id,
			agentId: getRandomElement(activeAgents).id,
			companyId: getRandomElement(activeCompanies).id,
			ticketread: true
		},
		{
			id: 14,
			description: "Policy Update",
			priorityId: 2,
			statusId: 1,
			responseDue: now.plus({ minutes: 25 }).toISO(),
			slaId: 1,
			customCreated: now.minus({ hours: 5 }).toISO(),
			userId: getRandomElement(activeUsers).id,
			agentId: getRandomElement(activeAgents).id,
			ticketread: true
		},
		{
			id: 15,
			description: "Data Breach",
			priorityId: 3,
			statusId: 1,
			responseDue: now.plus({ hours: 4, minutes: 5 }).toISO(),
			slaId: 1,
			customCreated: now.minus({ hours: 5 }).toISO(),
			userId: getRandomElement(activeUsers).id,
			agentId: getRandomElement(activeAgents).id,
			ticketread: true
		}
	];
}
