import { Control, Controller, FieldPath, FieldValues } from "react-hook-form";
import { Except } from "type-fest";
import { FormControlLabel, Radio, RadioGroup, RadioGroupProps } from "@mui/material";

interface Option {
	label: string;
	value: string;
}

type W_RadioGroupProps<T extends FieldValues> = Except<RadioGroupProps, "name"> & {
	control: Control<T>;
	name: FieldPath<T>;
	options: Option[];
};

export function W_RadioGroup<T extends FieldValues>(props: W_RadioGroupProps<T>) {

	return (
		<Controller
			control={props.control}
			name={props.name}
			render={({
				field: { onChange, value }
			}) =>
				<RadioGroup
					{...props}
					value={value}
					onChange={onChange}
				>
					{props.options.map((option, index) =>
						<FormControlLabel
							key={index}
							value={option.value}
							label={option.label}
							control={<Radio />}
						/>
					)}
				</RadioGroup>
			}
		/>
	);
}
