import { useRef } from "react";
import toast from "react-hot-toast";
import { useGetApiVersion } from "@/Api/genApi";

/**
 * Version we expect to see back from the BE
 * Make sure you change this when the BE one is changed!!!
 */
const apiVersionFE = 2;

/**
 * Polls the BE to see if we need to refresh.
 * Useful for major breaking API changes.
 */
export function useVersionUpdater() {
	const apiVersionBE = useGetApiVersion({ query: {
		refetchInterval: 60 * 1000,
		refetchIntervalInBackground: true,
		refetchOnMount: true,
		refetchOnWindowFocus: false
	} });

	const toastDisplayed = useRef(false);

	if (!toastDisplayed.current && apiVersionBE.data != null && apiVersionBE.data > apiVersionFE) {
		toast(
			<div className="flex">
				<div className="grow">
					A new version of GoDesk is available. Please click <b>Refresh</b> to upgrade.
				</div>

				<button
					className="btn-grey ml-1 shrink-0"
					onClick={() => {
						location.reload();
					}}
				>REFRESH</button>
			</div>
			, { duration: 9999999 });

		toastDisplayed.current = true;
	}
}
