import { createSlice } from "@reduxjs/toolkit";

interface AppState {
	darkmode: boolean;
}

const userPrefersDarkmode = window.matchMedia && window.matchMedia("(prefers-color-scheme: dark)").matches;

const initialState: AppState = {
	darkmode: userPrefersDarkmode
};

/** Global state for the whole app. */
export const appSlice = createSlice({
	name: "app",
	initialState,
	reducers: {
		darkModeToggled: state => {
			state.darkmode = !state.darkmode;
		}
	}
});

export const { darkModeToggled } = appSlice.actions;

export default appSlice.reducer;
