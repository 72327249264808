import { useGetLoggedInAgent } from "@/Api/genApi";
import { AgentNotificationsForm } from "@/Pages/Config/Notifications/AgentNotificationsForm";
import { FormParent } from "@shared/Components/Form/FormParent";

export function AgentNotifications() {
	document.title = "Notification Settings | GoDesk";

	const getMeReq = useGetLoggedInAgent({ query: {
		staleTime: Infinity
	} });

	if (getMeReq.isPending || getMeReq.isError || getMeReq.data == null) {
		return;
	}

	return (
		<FormParent
			title="Notification Settings"
			description="Choose which notifications you want to receive."
		>
			<AgentNotificationsForm
				defaultData={getMeReq.data}
			/>
		</FormParent>
	);
}
