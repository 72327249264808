import { Link } from "react-router-dom";

interface NotFound404Props {
	link: string;
	linkText?: string;
}

export default function NotFound404({ link, linkText = "Return home." }: NotFound404Props) {
	return (
		<div
			className="flex flex-col items-center sm:pt-12 px-3 "
			style={{ textAlign: "center" }}
		>
			<img src="/404.png" alt="" style={{ maxHeight: "400px" }} />
			<h1 className="text-2xl">Sorry, this page isn't available.</h1>
			<p>
				The link you followed may be broken, or the page has been removed.{" "}
				<Link
					style={{ textDecoration: "underline", color: "#3b3b3b" }}
					to={link}
				>
					{linkText}
				</Link>
			</p>
		</div>
	);
}
