import { ReactNode } from "react";
import { Divider, Stack } from "@mui/material";
import { QuestionTooltip } from "@shared/Components/QuestionTooltip/QuestionTooltip";

interface FormSectionProps {
	title?: string;
	/** Adds a tooltip to the title. */
	titleTooltip?: ReactNode;
	children: ReactNode;
	/** Custom classes. Do not overuse this! We want form layout components as standard as possible. */
	className?: string;
	/** Gives the section a fixed width. Useful when one section should be full screen and another shouldn't. */
	limitWidth?: boolean;
}

/** Use this to group form controls. */
export function FormSection(props: FormSectionProps) {
	return (
		<div className={`${props.limitWidth ? "max-w-[35rem]" : ""} ${props.className ?? ""}`}>
			<Divider />

			{props.title != null &&
				<div className="text-lg px-4 pt-4 flex items-center">
					{props.title}

					{props.titleTooltip &&
						<QuestionTooltip tip={props.titleTooltip} />}
				</div>}

			<Stack className="p-4">
				{props.children}
			</Stack>
		</div>
	);
}
