import { ReactNode } from "react";
import Tooltip from "@mui/material/Tooltip";
import { AiOutlineQuestionCircle } from "react-icons/ai";

interface QuestionTooltipProps {
	tip: ReactNode;
}

export function QuestionTooltip(props: QuestionTooltipProps) {
	return (
		<Tooltip title={props.tip} enterDelay={100} placement="bottom">
			<div className="inline-block">
				<AiOutlineQuestionCircle className="text-gray-800 ml-1 cursor-pointer block" />
			</div>
		</Tooltip>
	);
}
