import { useParams } from "react-router-dom";

type ParamsNumber = { [k: string]: number | undefined };

export function useNumberParams(): Readonly<ParamsNumber> {
	const params = useParams();

	const numObj: ParamsNumber = {};

	for (const [key, value] of Object.entries(params)) {
		if (value == null) {
			continue;
		}

		const numValue = parseInt(value);

		if (!isNaN(numValue)) {
			numObj[key] = numValue;
		}
	}

	return numObj;
}
