import { useNavigate } from "react-router-dom";
import { TeamTable } from "@/Components/EntityTable/Tables/TeamTable/TeamTable";
import { BasicTable } from "../../Components/EntityTable/Tables/BasicTable";
import { AgentTable } from "../../Components/EntityTable/Tables/AgentTable/AgentTable";
import { ChannelsEmailTable } from "../../Components/EntityTable/Tables/ChannelsEmailTable";
import { useAppSelector } from "../../Store/hooks";
import { Entities } from "@shared/Entities/Entities";
import LoadingSpinner from "@shared/Components/LoadingSpinner";
import { Hotkeys } from "@shared/lib/Hotkeys";

interface GenericListPageProps {
	urlTitle: string;
	title: string;
	description: string;
	entityType: Entities;
}

export default function GenericListPage(props: GenericListPageProps) {
	document.title = props.urlTitle + " | GoDesk";

	const agentsCache = useAppSelector(state => state.cache.Agents);
	const navigate = useNavigate();

	// Using a switch statement for some of the custom config tables. Easier than spinning them out into their own config components for now.
	let table;
	switch (props.entityType) {
		case Entities.AGENT:
			table = <AgentTable />;
			break;
		case Entities.CHANNELSEMAIL:
			table = <ChannelsEmailTable />;
			break;
		case Entities.TEAM:
			table = <TeamTable />;
			break;
		default:
			table = <BasicTable	entity={props.entityType} />;
			break;
	}

	return (
		<div className="h-full">
			<Hotkeys hotkeys="Escape" callback={() => navigate("/config")} />

			<div className="p-4 flex flex-col gap-3">
				<div className="text-2xl font-medium">
					{props.title}
				</div>
				<div>
					{props.description}
				</div>
			</div>

			<div className="px-1" style={{ height: "calc(100% - 88px)" }}>
				{agentsCache != null && agentsCache.length != 0 ?
					table
					: <LoadingSpinner />}
			</div>
		</div>
	);
}
