import { useState } from "react";
import { FiLock } from "react-icons/fi";
import { FaRegEye } from "react-icons/fa";
import { TfiLayout } from "react-icons/tfi";
import { bindMenu, bindTrigger, usePopupState } from "material-ui-popup-state/hooks";
import { Button, Popover } from "@mui/material";
import { useCache } from "@/Hooks/useCache";
import { Layout, LayoutEntity, LayoutVisibility } from "@/Api/genApi.schemas";
import { LayoutModalCreate } from "@/Components/Layouts/LayoutModal/LayoutModalCreate";
import { LayoutModalEdit } from "@/Components/Layouts/LayoutModal/LayoutModalEdit";
import { LayoutListItem } from "@/Components/Layouts/SelectLayoutButton/SelectLayoutListItem";
import { getDefaultColumnIds } from "@/Components/Layouts/LayoutModal/LayoutModalFunctions";
import { NeutralButton } from "@/Components/Button/NeutralButton";
import TextInput from "@shared/Components/FormComponents/TextInput/TextInput";
import { Entities } from "@shared/Entities/Entities";

enum LayoutFilter {
	ALL, PRIVATE, PUBLIC
}

interface SelectLayoutButtonProps {
	entity: LayoutEntity;
	onSelected(layout: Layout): void;
	activeLayout?: Layout | null;
}

export function SelectLayoutButton(props: SelectLayoutButtonProps) {
	const [filter, setFilter] = useState<LayoutFilter>(LayoutFilter.ALL);
	const [searchTerm, setSearchTerm] = useState("");
	const [addNewModalOpen, setAddNewModalOpen] = useState(false);
	const [editLayoutId, setEditLayoutId] = useState<number | null>(null);
	const popupState = usePopupState({ variant: "popover" });

	const { cache } = useCache();

	const filterStylesActive = "text-blue-600 border-blue-600";
	const filterStylesInactive = "text-gray-600 border-transparent hover:border-blue-200";

	let layoutsToShow: Layout[] = cache.Layouts;

	// Apply entity filter.
	layoutsToShow = layoutsToShow.filter(layout => layout.entity == props.entity);

	// Apply visibility filter.
	switch (filter) {
		case LayoutFilter.PUBLIC:
			layoutsToShow = layoutsToShow.filter(layout => layout.visibility == LayoutVisibility.GLOBAL);
			break;
		case LayoutFilter.PRIVATE:
			layoutsToShow = layoutsToShow.filter(layout => layout.visibility == LayoutVisibility.PRIVATE);
			break;
	}

	// Apply search term filter.
	layoutsToShow = layoutsToShow.filter(layout => layout.name.toLowerCase().includes(searchTerm.toLowerCase()));

	// Add default layout.
	layoutsToShow.unshift({
		name: "Default",
		entity: "TICKET",
		columns: getDefaultColumnIds(Entities.TICKET),
		visibility: "GLOBAL",
		creatorAgentId: -1,
		id: -1
	});

	return (
		<>
			<NeutralButton
				startIcon={<TfiLayout />}
				{...bindTrigger(popupState)}
			>
				Layouts
			</NeutralButton>

			<Popover
				{...bindMenu(popupState)}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "center",
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "center",
				}}
			>
				<div className="w-80 h-[20rem] flex flex-col">
					<div className="border-b border-gray-200 px-4 py-1">
						<TextInput
							dataname=""
							autoFocus
							className="w-full px-3 border border-gray-300 rounded-md"
							placeholder="Search layouts..."
							onChange={setSearchTerm} // Update searchTerm state
						/>
					</div>

					<div className="grid grid-cols-3 justify-around border-b border-gray-200 py-2 mb-2 px-4">
						<button
							className={`px-3 py-1 text-sm border-b-2 transition-colors ${filter == LayoutFilter.ALL ? filterStylesActive : filterStylesInactive}`}
							onClick={() => setFilter(LayoutFilter.ALL)}
						>
							All
						</button>

						<button
							className={`px-3 py-1 text-sm border-b-2 transition-colors ${filter === LayoutFilter.PUBLIC ? filterStylesActive : filterStylesInactive}`}
							onClick={() => setFilter(LayoutFilter.PUBLIC)}
						>
							<span className="flex items-center gap-1 justify-center">Public <FaRegEye className="shrink-0" /></span>
						</button>

						<button
							className={`px-3 py-1 text-sm border-b-2 transition-colors ${filter == LayoutFilter.PRIVATE ? filterStylesActive : filterStylesInactive}`}
							onClick={() => setFilter(LayoutFilter.PRIVATE)}
						>
							<span className="flex items-center gap-1 justify-center">Private <FiLock className="shrink-0" /></span>
						</button>
					</div>

					<div className="gap-y-2 overflow-y-auto mb-2 grow">
						{searchTerm != null && searchTerm != "" && layoutsToShow.length == 0 &&
							<div className="p-4">No layouts found matching: {searchTerm}</div>
						}

						{layoutsToShow.map((layout) => (
							<LayoutListItem
								key={layout.id}
								layout={layout}
								onSelect={layout => props.onSelected(layout)}
								editLayout={() => {
									setEditLayoutId(layout.id ?? null);
									popupState.close();
								}}
								isActive={props.activeLayout != null && props.activeLayout.id == layout.id}
								disableContextMenu={layout.id == -1}
							/>
						))}
					</div>

					<div className="my-2 px-4">
						<Button
							variant="contained"
							size="small"
							onClick={() => {
								setAddNewModalOpen(true);
								popupState.close();
							}}
							className="w-full"
						>
							+ New layout
						</Button>
					</div>
				</div>
			</Popover>

			{addNewModalOpen &&
				<LayoutModalCreate
					close={() => setAddNewModalOpen(false)}
				/>
			}

			{editLayoutId != null &&
				<LayoutModalEdit
					id={editLayoutId}
					close={() => setEditLayoutId(null)}
				/>
			}
		</>
	);
}
