"use client";

import React, { DragEvent, ChangeEvent, useState, useEffect } from "react";
import { toast } from "react-hot-toast";
import { AiOutlinePaperClip } from "react-icons/ai";
import { AnimatePresence, motion } from "framer-motion";
import { ApiCallerBaseForApi } from "@shared/Api/ApiCallerBaseForApi";
import { Entities } from "@shared/Entities/Entities";
import { File } from "@shared/Entities/EntityTypes";
import { bytesToFormattedString, getBase64 } from "@shared/Helpers/OtherFunctions";
import { ActionAttachment } from "@shared/Attachments/ActionAttachment";

interface AttachmentDTO {
	dataBase64: string;
	originalFileName: string;
}

interface AttachmentsProps {
	files: File[];
	setFiles(newValue: File[]): void;
	apiCaller: ApiCallerBaseForApi;
	attachmentUrl: string;
	/** USE IN PORTAL. This is to suppress call to the api to delete attachments. We do not want anon users to delete things so they are left in place. */
	softDelete?: boolean;

	/** Use in the canned reply viewer. This is here so cannedreply attachments don't get deleted when we're adding them to an action. */
	deleteCannedReplyAttachments?: boolean;
}

/**
 * BUG: If mouse cursor touches the html iframe on entry the overlay doesn't get activated.
 */
export function Attachments(props: AttachmentsProps) {
	const [dragActive, setDragActive] = useState(false);
	const [fileSize, setFileSize] = useState<number>(0);
	const [showTotalSize, setShowTotalSize] = useState<boolean>(false);
	const hiddenFileInput = React.useRef<HTMLInputElement>(null);

	useEffect(() => {
		if (!dragActive) {
			document.addEventListener("dragenter", (e) => {
				if (!hasImageType(e)) {setDragActive(true);}
			});
		} else {
			document.removeEventListener("dragenter", (e) => setDragActive(false));
		}
	}, [dragActive]);

	useEffect(() => {
		if (props.files) {
			setFileSize(
			// Adds up all of the fileSizeBytes in the array
				props.files?.reduce(function (a, b) {
					const adder = b.fileSizeBytes ? b.fileSizeBytes : 0;
					return a + adder;
				}, 0)
			);
		}
	}, [props.files]);

	const handleClick = () => {
		if (hiddenFileInput.current != null) {hiddenFileInput.current.click();}
	};

	const handleInputChange = function (e: ChangeEvent<HTMLInputElement>) {
		e.preventDefault();
		if (e.target.files && e.target.files[0]) {
			uploadFiles(e.target.files);
		}
	};

	const handleDrag = function (e: DragEvent<HTMLDivElement>) {
		e.preventDefault();
		e.stopPropagation();
		let hasImage = false;

		if (e.type === "dragenter") {
			hasImage = hasImageType(e);
		}
		if (!hasImage) {
			if (e.type === "dragenter" || e.type === "dragover") {
				setDragActive(true);
			} else if (e.type === "dragleave") {
				setDragActive(false);
			}
		}
	};

	/* Boolean check if dragged files have image type */
	function hasImageType(e: any) {
		let hasImage = false;
		Array.from(e.dataTransfer.items).forEach((i: any) => {
			const fileType = i.type.split("/")[0];
			if (fileType == "image") {
				hasImage = true;
			}
		});
		return hasImage;
	}

	const handleDrop = async function (e: DragEvent<HTMLDivElement>) {
		e.preventDefault();
		e.stopPropagation();
		setDragActive(false);
		if (e.dataTransfer.files && e.dataTransfer.files[0]) {
			uploadFiles(e.dataTransfer.files);
		}
	};

	async function uploadFiles(files: FileList) {
		const postedFiles: File[] = [];

		const toastId = toast.loading("Uploading...");

		for (let index = 0; index < files.length; index++) {

			const fileName = files[index].name;

			const base64File = await getBase64(files[index]);

			if (base64File != null && fileName != null) {

				const base64FormatedFile = base64File.substring(base64File.indexOf("base64,") + 7);
				const attachment: AttachmentDTO = {
					dataBase64: base64FormatedFile,
					originalFileName: fileName,
				};

				const response = await props.apiCaller.createEntity<File>(Entities.ATTACHMENT, attachment);

				if (response.data != null && response.successful) {
					postedFiles.push(response.data);
				} else {
					toast.error("Upload failed. " + response.errorCode + ": " + response.errorMsg, { id: toastId });
					return;
				}
			}
		}

		toast.success("Upload successful.", { id: toastId });

		props.setFiles(props.files.concat(postedFiles));
	}

	async function removeAttachment(attachment: File) {
		props.setFiles(props.files.filter((person) => person.id !== attachment.id));

		if (!props.softDelete) {
			// Don't delete canned reply attachments unless we're on the canned reply viewer.
			if (attachment.cannedReplyId != null && !props.deleteCannedReplyAttachments) {
				return;
			}

			await props.apiCaller.deleteEntity(Entities.ATTACHMENT, attachment.id);
		}
	}

	return (
		<>
			<button
				className="btn-grey p-2 flex shadow-none my-1.5"
				onClick={handleClick}
				type="button"
			>
				<AiOutlinePaperClip size="20px" />Attach file
			</button>
			{showTotalSize && (
				<>
					<p>
						Attachments (Max size: <span className="font-semibold">25MB</span>)
					</p>
					{fileSize != 0 && (
						<p>
							Total size:{" "}
							<span className="font-semibold">
								{bytesToFormattedString(fileSize)}
							</span>
						</p>
					)}
				</>
			)}
			{props.files && (
				<>
					<div className="flex flex-wrap gap-x-4">
						{props.files.map((attachment) => {
							return (
								<ActionAttachment
									attachment={attachment}
									key={attachment.id}
									removeAttachment={removeAttachment}
									attachmentsUrl={props.attachmentUrl}
								/>
							);
						})}
					</div>
				</>
			)}
			<AnimatePresence>
				{dragActive && (
					<motion.div
						className={"absolute top-0 left-0 bg-blue-200 bg-opacity-50 p-5 box-border outline-blue-400 outline-4 outline-dashed h-full w-full"}
						style={{ zIndex: 1400, outlineOffset: "-10px" }}
						initial={{ opacity: 0 }}
						animate={{ opacity: 1 }}
						exit={{ opacity: 0 }}
						transition={{ ease: "easeOut", duration: 0.2 }}
						onDragEnter={handleDrag}
						onDragLeave={handleDrag}
						onDragOver={handleDrag}
						onDrop={handleDrop}
					>
						<p
							className={"m-0 absolute left-1/2 top-1/2 font-semibold text-gray-600 text-center bg-white w-fit p-5 text-xl rounded-md pointer-events-none -translate-x-1/2 -translate-y-1/2 shadow-md"}
						>
							Drop your file(s) anywhere
						</p>
					</motion.div>
				)}
			</AnimatePresence>

			{/* WARNING: Adding a file larger than 2MB to the /public dir will trigger a refresh in dev mode. Do not be alarmed! */}
			<input
				id="file-upload"
				type="file"
				className="hidden"
				multiple={true}
				onChange={handleInputChange}
				ref={hiddenFileInput}
			/>
		</>
	);
}
