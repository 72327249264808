import { useEffect, useRef, useState } from "react";
import Markdown from "react-markdown";
import { Button } from "@/Components/Button/Button";
import { draftKbArticle } from "@/Api/GptCaller";
import { CreateKbArticleModal } from "@/Components/Utility/Modals/CreateKbArticleModal";
import Modal from "@shared/Components/Modal/Modal";
import ModalButtons from "@shared/Components/Modal/ModalButtons";
import { Action } from "@shared/Entities/EntityTypes";
import ModalContent from "@shared/Components/Modal/ModalContent";

interface KbDraftArticleModalProps {
	onClose(): void;
	action: Action;
}

export default function KbDraftArticleModal(props: KbDraftArticleModalProps) {
	const textEndRef = useRef<HTMLDivElement>(null);
	const [streamStarted, setSteamStarted] = useState(false);
	const [streamComplete, setStreamComplete] = useState(false);
	const [createKbArticleModalIsOpen, setCreateKbArticleModalIsOpen] = useState(false);

	const [text, setText] = useState("");
	const [htmlText, setHtmlText] = useState("");

	useEffect(() => {
		function recieveText(text: string) {
			setText(currentText => currentText + text);
		}

		async function doDraft() {
			draftKbArticle(
				props.action,
				recieveText,
				() => setSteamStarted(true),
				() => setStreamComplete(true)
			);
		}

		doDraft();

		return () => {
			setSteamStarted(false);
			setStreamComplete(false);

			props.onClose();
		};
	}, [props]);

	useEffect(() => {
		// Scroll to bottom when the text changes.
		textEndRef.current?.scrollIntoView({ behavior: "smooth" });
	}, [text]);

	function close() {
		setSteamStarted(false);
		setStreamComplete(false);

		props.onClose();
	}

	function onSubmit() {
		setHtmlFromMarkdown();

		setCreateKbArticleModalIsOpen(true);
	}

	function setHtmlFromMarkdown() {
		const draftHtml = document.getElementById("markdown_container")?.innerHTML;

		if (draftHtml != null) {
			setHtmlText(draftHtml);
		}
	}

	return (
		<>
			<Modal
				className="w-[700px]"
				isOpen={true}
				onClose={close}
				showCloseWarning={true}
				title="AI KB Article Draft"
			>
				<ModalContent>
					<div id="markdown_container">
						<Markdown>
							{text}
						</Markdown>
					</div>

					<span ref={textEndRef}></span>

					{streamStarted && !streamComplete &&
						<div className="bg-gray-500 rounded-full h-4 w-4"></div>}
				</ModalContent>

				<ModalButtons>
					<Button
						btnClass="btn-grey"
						label="Discard"
						onClick={close}
					/>

					<Button
						btnClass="btn-blue"
						label="Create as draft"
						disabled={!streamComplete}
						onClick={onSubmit}
					/>
				</ModalButtons>
			</Modal>

			<CreateKbArticleModal
				isOpen={createKbArticleModalIsOpen}
				onClose={() => setCreateKbArticleModalIsOpen(false)}
				articleDraft={text}
				articleDraftHtml={htmlText}
			/>
		</>
	);
}
