import { useState } from "react";
import { MdCheck } from "react-icons/md";

export default function GetStarted() {
	document.title = "Get Started | GoDesk";

	const [page, setPage] = useState(1);

	function setPageCallback(number: number) {
		setPage(number);
	}

	let activePage = null;

	switch (page) {
		case 1: activePage = <PageDetails setPage={setPage} />; break;
		// case 2: activePage = <PageAgents setPage={setPage} />; break;
		// case 3: activePage = <PageEmail setPage={setPage} />; break;
		// case 4: activePage = <PageTour setPage={setPage} />; break;
	}

	return (
		<div className="w-full h-full p-2 flex">
			<div className="bg-white rounded w-72 mr-2">
				<div className="p-5">
					<img className="h-20" src="/progress/progress-25.png" />
					<div className="relative">25%</div>
				</div>
				<div className="border-t border-gray-200 p-2">
					<PageButton
						title="Help desk details"
						time="3 mins"
						done={false}
						page={1}
						setPage={setPageCallback}
					/>
					<PageButton
						title="Invite your team"
						time="3 mins"
						done={false}
						page={2}
						setPage={setPageCallback}
					/>
					<PageButton
						title="Setup your support email"
						time="5 mins"
						done={false}
						page={3}
						setPage={setPageCallback}
					/>
				</div>
				<div className="border-t border-gray-200 p-2">
					<PageButton
						title="Ticket tour"
						time="2 mins"
						done={false}
						page={4}
						setPage={setPageCallback}
					/>
				</div>
			</div>
			<div className="bg-white rounded flex-grow">
				{activePage}
			</div>
		</div>
	);
}

interface PageButtonProps {
	title: string;
	time: string;
	done: boolean;
	page: number;
	setPage(number: number): void;
}

function PageButton(props: PageButtonProps) {
	let rightSide;
	let buttonStyles;

	if (props.done) {
		rightSide = <div className="text-green-600"><MdCheck /></div>;
		buttonStyles = "bg-green-100";
	} else {
		rightSide = <div className="text-gray-500">{props.time}</div>;
		buttonStyles = "bg-gray-100";
	}

	return (
		<button onClick={() => props.setPage(props.page)} className={"rounded w-full px-4 py-2 my-2 flex justify-between " + buttonStyles}>
			<div>{props.title}</div>
			{rightSide}
		</button>
	);
}

interface PageDetailsProps {
	setPage(id: number): void;
}

function PageDetails(props: PageDetailsProps) {
	function nextPage() {
		props.setPage(2);
	}

	return (
		<>
			<div className="">
				Details page
			</div>
			<div className="">
				<button onClick={nextPage} className="btn-blue">Continue</button>
			</div>
		</>
	);
}
