import { Button } from "@/Components/Button/Button";
import { ConditionSelector } from "@/Pages/Config/Automations/AutomationViewerComponents/ConditionSelector";
import { AutomationCondition } from "@shared/Entities/EntityTypes";

interface ConditionManagerProps {
	data: AutomationCondition[];
	updateData(data: AutomationCondition[]): void;
}

export default function ConditionManager(props: ConditionManagerProps) {
	function addCondition() {
		const dataCopy = [...props.data];
		let minId = -1; // New Id's are random minus numbers. Later set to undefined in AutomationViewer.formSubmit().

		dataCopy.forEach(condition => {
			if (condition.id != null && condition.id <= minId) {
				minId = condition.id - 1;
			}
		});

		dataCopy.push({ id: minId });
		props.updateData(dataCopy);
	}

	function updateCondition(data: AutomationCondition) {
		const dataCopy = [...props.data];

		const index = dataCopy.findIndex(condition => condition.id != null && condition.id == data.id);
		if (index > -1) {
			dataCopy[index] = data;
		}

		props.updateData(dataCopy);
	}

	function deleteCondition(data: AutomationCondition) {
		const dataCopy = [...props.data];

		const index = dataCopy.findIndex(condition => condition.id != null && condition.id == data.id);
		if (index > -1) {
			dataCopy.splice(index, 1);
		}

		props.updateData(dataCopy);
	}

	return (
		<>
			{props.data.map((condition, index, array) => (
				<>
					<ConditionSelector data={condition} deletable={props.data.length != null && props.data.length > 1} updateData={updateCondition} delete={deleteCondition} />
					{index + 1 < array.length ? <>Or:</> : null}
				</>
			))}
			<br />
			<Button className="" onClick={addCondition} label="Add Condition" />
		</>
	);
}
