import { GD_SUPPORT_AGENT_ID, TicketStatuses } from "@shared/Enums/Enums";
import { Agent, Team } from "@shared/Entities/EntityTypes";
import { View } from "@shared/Models/View";

export const AllTicketViewID = -1;
export const InboxViewID = -2;
export const UnresolvedViewID = -3;
export const DeletedViewID = -4;

export const OpenViewID = -10;
export const WithUserViewID = -11;
export const ClosedViewID = -12;

/**
 * @param userId The ID of the user we are generating the views for.
 */
export function generateHardCodedViews(teamCache: Team[], agentCache: Agent[], userId: number | null): View[] {

	const views: View[] = [
		{
			id: InboxViewID,
			name: "Inbox",
			filters: {
				deleted: { type: "bool", comparison: "equals", value: false },
				statusId: { type: "number", comparison: "equals", value: 1 },
				agentId: { type: "number", comparison: "equals", value: userId ?? -1 }
			},
		},
		{
			id: UnresolvedViewID,
			name: "Unresolved", // TODO: Make this an overdue view.
			filters: {
				deleted: { type: "bool", comparison: "equals", value: false },
				statusId: { type: "set", comparison: "contains", values: [TicketStatuses.OPEN, TicketStatuses.WITHUSER] }
			},
		},
		{
			id: DeletedViewID,
			name: "Deleted",
			filters: {
				deleted: { type: "bool", comparison: "equals", value: true }
			},
		},

		// Statuses.
		{
			id: OpenViewID,
			name: "Open",
			filters: {
				deleted: { type: "bool", comparison: "equals", value: false },
				statusId: { type: "number", comparison: "equals", value: TicketStatuses.OPEN }
			}
		},
		{
			id: WithUserViewID,
			name: "With User",
			filters: {
				deleted: { type: "bool", comparison: "equals", value: false },
				statusId: { type: "number", comparison: "equals", value: TicketStatuses.WITHUSER }
			}
		},
		{
			id: ClosedViewID,
			name: "Closed",
			filters: {
				deleted: { type: "bool", comparison: "equals", value: false },
				statusId: { type: "number", comparison: "equals", value: TicketStatuses.CLOSED }
			}
		}
	];

	// Add agents grouped by team views.
	teamCache.forEach(team => {
		// Add 'Team' view.
		views.push({
			id: "teamId:" + team.id + ",agentId:-2",
			name: team.name,
			filters: {
				deleted: { type: "bool", comparison: "equals", value: false },
				statusId: { type: "number", comparison: "equals", value: TicketStatuses.OPEN },
				teamId: { type: "number", comparison: "equals", value: team.id }
			},
			groupName: "Teams",
			subGroupId: team.id
		});

		// Add 'Unassigned' view.
		views.push({
			id: "teamId:" + team.id + ",agentId:-1",
			name: "Unassigned",
			filters: {
				deleted: { type: "bool", comparison: "equals", value: false },
				statusId: { type: "number", comparison: "equals", value: TicketStatuses.OPEN },
				teamId: { type: "number", comparison: "equals", value: team.id },
				agentId: { type: "set", comparison: "contains", values: [-1] },
			},
			groupName: "Teams",
			subGroupId: team.id
		});

		team.agents?.forEach(agentTeam => {
			const agent = agentCache.find((agent) => agentTeam.agentId == agent.id);

			if (agent != null && !agent.disabled && !agent.deleted && agent.id != GD_SUPPORT_AGENT_ID) {
				views.push({
					id: "teamId:" + team.id + ",agentId:" + agent.id,
					name: agent.name,
					filters: {
						deleted: { type: "bool", comparison: "equals", value: false },
						statusId: { type: "number", comparison: "equals", value: TicketStatuses.OPEN },
						teamId: { type: "number", comparison: "equals", value: team.id },
						agentId: { type: "number", comparison: "equals", value: agent.id },
					},
					groupName: "Teams",
					subGroupId: team.id
				});
			}
		});
	});

	// Add agent views.
	agentCache.forEach(agent => {
		if (agent != null && !agent.disabled && !agent.deleted && agent.id != GD_SUPPORT_AGENT_ID) {
			views.push({
				id: "agentId:" + agent.id,
				name: agent.name,
				filters: {
					deleted: { type: "bool", comparison: "equals", value: false },
					statusId: { type: "number", comparison: "equals", value: TicketStatuses.OPEN },
					agentId: { type: "number", comparison: "equals", value: agent.id }
				},
				groupName: "Agents"
			});
		}
	});

	return views;
}
