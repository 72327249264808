import { Control } from "react-hook-form";
import W_CacheDropdown from "@/Components/FormComponents/CacheDropdown/W_CacheDropdown";
import W_MultiSelectUserEmail from "@/Components/FormComponents/MultiSelectUserEmail/W_MultiSelectUserEmail";
import { CacheSlices } from "@/Store/Reducers/cache/CacheTypes";
import { TicketFormField } from "@/Components/Utility/Modals/NewTicketModal/ticketFieldEnum";

interface NewTicketFieldProps {
	field: TicketFormField;
	control: Control<any>;
}

export default function NewTicketField(props: NewTicketFieldProps): JSX.Element {
	switch (props.field) {
		case TicketFormField.STATUS:
			return <W_CacheDropdown
				cacheSlice={CacheSlices.Statuses}
				control={props.control}
				dataname="statusId"
				label="Status"
			       />;
		case TicketFormField.PRIORITY:
			return <W_CacheDropdown
				cacheSlice={CacheSlices.Priorities}
				control={props.control}
				dataname="priorityId"
				label="Priority"
			       />;
		case TicketFormField.CATEGORY:
			return <W_CacheDropdown
				cacheSlice={CacheSlices.Categories}
				control={props.control}
				dataname="categoryId"
				label="Category"
			       />;
		case TicketFormField.CC:
			 return <W_MultiSelectUserEmail
				control={props.control}
				dataname="cc"
				label="CC"
			        />;
		case TicketFormField.BCC:
			return <W_MultiSelectUserEmail
				control={props.control}
				dataname="bcc"
				label="BCC"
			       />;
	}
}
