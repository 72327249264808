import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AgGridReact } from "@ag-grid-community/react";
import { CellDoubleClickedEvent, ColDef, GetRowIdParams, NavigateToNextCellParams, SelectionChangedEvent, SortChangedEvent, SortModelItem, SuppressKeyboardEventParams, CellKeyDownEvent, IRowNode, CellPosition, FirstDataRenderedEvent } from "@ag-grid-community/core";
import { AiOutlineDelete, AiOutlineExport, AiOutlineStop } from "react-icons/ai";
import toast from "react-hot-toast";
import { HiOutlineRefresh } from "react-icons/hi";
import { MdOutlineCallMerge } from "react-icons/md";
import { AnimatePresence } from "framer-motion";
import { IoCreateOutline } from "react-icons/io5";
import { PiPlusBold } from "react-icons/pi";
import { ResGetSome } from "@/legacy/ApiResponse";
import { useCache } from "@/Hooks/useCache";
import { ApiDeleteEntities } from "@/legacy/ApiCallerOld";
import api from "@/Api/Api";
import { Layout } from "@/Api/genApi.schemas";
import { DeleteDemoDataButton } from "@/Components/EntityTable/DeleteDemoDataButton";
import { useAppDispatch, useAppSelector } from "../../Store/hooks";
import { sortCleared, sortUpdated } from "../../Store/Reducers/entityTableSlice";
import { FilterPanel } from "./FilterPanel/FilterPanel";
import DeleteWarningModal from "../Utility/Modals/DeleteWarningModal";
import MergeTicketsModal from "../Utility/Modals/MergeTicketsModal/MergeTicketsModal";
import NewEntityModal from "../Utility/Modals/NewEntityModal";
import { Button, ButtonBlue } from "../Button/Button";
import ViewsDropdown, { getDefaultDropdownView } from "./ViewsDropdown";
import PaginationDisplay from "./PaginationDisplay";
import { generateColumnDefs } from "./EntityTableFunctions";
import { CollisionNote } from "../../Api/genApi.schemas";
import { TableSort } from "@shared/Enums/Enums";
import { View } from "@shared/Models/View";
import { InfoToast } from "@shared/Components/Utils/Toasts";
import { Entities } from "@shared/Entities/Entities";
import { Hotkeys } from "@shared/lib/Hotkeys";
import { Ticket } from "shared/src/Entities/EntityTypes";
import "@ag-grid-community/styles/ag-grid.css";
import "@ag-grid-community/styles/ag-theme-alpine.css";

export interface EntityTableButton {
	icon: JSX.Element;
	label: string;
	onClick(ids: number[]): Promise<void>;
}

export interface EntityTableLimit {
	limit: number;
	errorMsg: string;
}

interface EntityTableProps {
	entity: Entities;
	viewId?: number | string;

	/**
	 * @deprecated Use viewId.
	 */
	view?: View;
	/** Allows limiting the amount of an entity that can be created. Useful for things like agent licensing. */
	entityLimit?: EntityTableLimit;

	/** The URL where an entity can be added. Enables the 'new' button. 'New' will just use the default modal if undefined. */
	newEntityUrl?: string;
	/** The URL where an entity can be edited. Enables row doubleclick and the edit button. Do not include the ID. E.g. /config/agents/ */
	editEntityUrl?: string;
	/** Disables the edit button. */
	disableEditButton?: boolean;

	/** Overriding callback for when a row is double clicked. Provides the id and the entity clicked (as any). */
	onRowDoubleClickOverride?(id: number, rowData: any): void;
	/** Override what happens when multiple rows are deleted at once. You might want to make the BE just set a deleted flag = true instead of deleting. */
	multiDeleteOverride?(ids: number[]): Promise<void>;
	/** Callback for when multiple rows are deleted at once. */
	onMultiDelete?(ids: number[]): void;
	/** Enables a delete confirmation modal with the provided message. */
	multiDeleteWarningMsg?: string;
	/** Buttons to add to the list of multiselect options. */
	multiSelectButtons?: EntityTableButton[];
	/** Buttons to add to the right group of buttons. */
	customButtons?: EntityTableButton[];
	/** Button to replace the default 'new' button. */
	newButtonReplacement?: EntityTableButton;
	/** @deprecated This is used to pass an obj to the NewEntityModal. How a new entity is added should probably be set through composition. */
	defaultNewData?: any;
	/** Enables a button that allows an agent to download the table's data as a CSV. */
	exportCsv?(data: any[]): void;

	/** Displays the filters panel. Just in use for tickets page currently. */
	showFiltersPanel?: boolean;

	/** Optional prop to take over control of EntityTable's page. */
	page?: number;
	/** Callback for page changes. */
	onPageChange?(newPage: number): void;

	/** Overrides the sort state in EntityTableSlice. */
	sort?: TableSort;
	/** Callback for sort changes. */
	onSortChange?(newSort?: TableSort): void;

	/** Optional collision notes to enrich the collision note renderer if it is used. */
	collisionNotes?: CollisionNote[];

	layout?: Layout | null;
}

export default function EntityTable(props: EntityTableProps) {
	const dispatch = useAppDispatch();
	const darkmode = useAppSelector(state => state.app.darkmode);

	const theme = process.env.NODE_ENV == "development" && darkmode ? "ag-theme-alpine-dark" : "ag-theme-alpine";

	// This is a temp local state for storing the sort. In the future each composition of EntityTable will pass down its
	// own state, stored in its own reducer (like tickets does).
	// NOTE: This applies the state's sort on reload, but AG Grid currently does not show it in the UI (with arrows on the col header).
	const state = useAppSelector(state => state.entityTable);
	const navigate = useNavigate();
	const { cache, refreshCache } = useCache();
	const gridRef = useRef<AgGridReact>(null);

	const [data, setData] = useState<any[]>();
	const [dataTotal, setDataTotal] = useState<number | undefined>();
	const [dropdownView, setDropdownView] = useState<View | undefined>(getDefaultDropdownView(props.entity));

	const [localpage, setLocalPage] = useState(0);
	const page = props.page ?? localpage;

	const [newModalOpen, setNewModalOpen] = useState(false);
	const [mergeModalOpen, setMergeModalOpen] = useState(false);
	const [deleteModalOpen, setDeleteModalOpen] = useState(false);

	const [selectedIds, setSelectedIds] = useState<number[]>([]);
	const [selectedData, setSelectedData] = useState<any[]>([]); // Data of rows currently selected. Just used in merge modal currently.

	// Used to give onCellKeyDown() access to the last selected row's index.
	const [lastShiftRowIndex, setLastShiftRowIndex] = useState<number>();

	const dataLimit = 30;

	/** Just fetches data from the API. Use refreshData() to fetch AND update our state. */
	const fetchData = useCallback(async (options: { fetchAll?: boolean } = {}): Promise<ResGetSome<any>> => {
		const viewToUse = cache.getView(props.viewId);

		// TODO: View + dropdownView OR dropdownView + filters.
		// TODO: Add the user filters back to this.
		// Merge the filters. The order determines what overrides what.
		const filters = { ...viewToUse?.filters, ...props.view?.filters, ...dropdownView?.filters };

		const sortToUse = props.sort ? props.sort : state.sort;

		let sortModel: SortModelItem[] | undefined = undefined;
		if (sortToUse != null && sortToUse.sortField != null && sortToUse.sortType != null) {
			sortModel = [{
				colId: sortToUse.sortField,
				sort: sortToUse.sortType
			}];
		}

		const dataOffset = options.fetchAll ? 0 : page * dataLimit;
		const finalLimit = options.fetchAll ? 999999 : dataLimit;

		console.debug("EntityTable Fetch");
		console.debug("Entity: " + props.entity);
		console.debug("Limit: " + finalLimit);
		console.debug("Offset: " + dataOffset);
		console.debug("Sort:", sortModel);
		console.debug("Filter:", filters);

		return await api.getEntities(props.entity, finalLimit, dataOffset, sortModel, filters);
	}, [cache, props.viewId, props.view?.filters, props.entity, dropdownView?.filters, page, props.sort, state.sort]);

	const refreshData = useCallback(async () => {
		const response = await fetchData();

		if (response && response.data) {
			// Set results for Ag Grid.
			setData(response.data);

			// Set total for pagination.
			setDataTotal(response.pagination?.total);
		} else {
			toast.error("Could not fetch data. " + response.errorCode + ": " + response.errorMsg);

			if (response && response.errorCode == 401) { // 401 = UNAUTHENTICATED.
				navigate("/logout");
			}
		}
	}, [fetchData, navigate]);

	// This clears the sorted column on unload.
	useEffect(() => {
		return function cleanup() {
			dispatch(sortCleared());
		};
	}, [dispatch]);

	// Fetch when things change.
	useEffect(() => {
		refreshData();
	}, [cache, dropdownView, props.entity, props.view, refreshData]);

	// Fetch every 30 secs.
	useEffect(() => {
		const interval = setInterval(() => {
			refreshData();
		}, 30000);

		return () => clearInterval(interval); // Clean up.
	}, [refreshData]);

	// Destructure prop so linter doesn't moan.
	const destructured_onRowDoubleClick = props.onRowDoubleClickOverride;

	const rowDoubleClick = useCallback((id: number, rowData: any) => {
		if (destructured_onRowDoubleClick) { // Prioritise override callback.
			destructured_onRowDoubleClick(id, rowData);
		} else if (props.editEntityUrl != null) {
			navigate(props.editEntityUrl + id);
		}
	}, [destructured_onRowDoubleClick, navigate, props.editEntityUrl]);

	const onCellDoubleClicked = useCallback((event: CellDoubleClickedEvent) => {
		rowDoubleClick(
			event.data != null ? event.data.id : null,
			event.data
		);
	}, [rowDoubleClick]);

	function prevPage() {
		const offset = page * dataLimit;

		if (offset <= 0) { // Don't allow paging past 0.
			return;
		}

		// Preserve other parameters by only using set on "page" param
		const newPageNum = page - 1;

		setLocalPage(newPageNum);

		if (props.onPageChange) {
			props.onPageChange(newPageNum);
		}
	}

	function nextPage() {
		const offset = page * dataLimit;
		const allowNext = offset + dataLimit < (dataTotal ?? 0);

		if (!allowNext) { // Don't allow paging past the total.
			return;
		}

		const newPageNum = page + 1;

		setLocalPage(newPageNum);

		if (props.onPageChange) {
			props.onPageChange(newPageNum);
		}
	}

	const onSelectionChanged = useCallback((event: SelectionChangedEvent) => {
		const selectedRows = event.api.getSelectedRows();
		const selectedIds: number[] = [];
		const selectedData: any[] = [];

		selectedRows.forEach(function (selectedRow) {
			selectedIds.push(selectedRow.id);
			selectedData.push(selectedRow);
		});

		setSelectedData(selectedData);
		setSelectedIds(selectedIds);
	}, []);

	const onCellKeyDown = useCallback((e: CellKeyDownEvent) => {
		const api = gridRef.current?.api;

		const key = (e.event as KeyboardEvent).key;
		const shift = (e.event as KeyboardEvent).shiftKey;
		const ctrl = (e.event as KeyboardEvent).ctrlKey;

		if (key == "Shift") {
			// Store the current row index so it can be used for Shift+[Home/End/PageUp/PageDown].
			setLastShiftRowIndex(api?.getFocusedCell()?.rowIndex);
		}

		// Default behaviour skips to first/last COLUMN as well as row. We just want to move the row.
		if (key == "Home" || key == "End") {
			const focusedCell = api?.getFocusedCell();

			const newIndex = key == "Home" ? 0 : api?.getLastDisplayedRow();
			console.log(newIndex);

			if (newIndex != null && focusedCell != null) {
				api?.setFocusedCell(newIndex, focusedCell.column);
			}
		}

		if (key == "PageDown" || key == "PageUp" || key == "Home" || key == "End") {
			if (!shift) {
				api?.deselectAll();

				const focusedCell = api?.getFocusedCell();

				if (focusedCell != null) {
					api?.getDisplayedRowAtIndex(focusedCell.rowIndex)?.setSelected(true);
				}
			} else {
				const newRowIndex = api?.getFocusedCell()?.rowIndex;

				if (newRowIndex != null && lastShiftRowIndex != null) {
					const smallerIndex = Math.min(newRowIndex, lastShiftRowIndex);
					const largerIndex = Math.max(newRowIndex, lastShiftRowIndex);

					// Select all in the range. Deselect any that are not.
					api?.forEachNode((rowNode: IRowNode, index: number) => {
						rowNode.setSelected(index >= smallerIndex && index <= largerIndex);
					});
				}
			}
		}

		if (ctrl && key == "a") {
			api?.selectAll();
		}

		if (key == "Enter" && e.data != null) { // Double click row.
			rowDoubleClick(e.data.id, e.data);
		}

		if (key == "Escape") {
			api?.deselectAll();
		}
	}, [lastShiftRowIndex, rowDoubleClick]);

	const navigateToNextCell = useCallback((params: NavigateToNextCellParams): CellPosition | null => {
		const prevCellRow = gridRef.current?.api.getDisplayedRowAtIndex(params.previousCellPosition.rowIndex);
		const nextCellRow = params.nextCellPosition != null ? gridRef.current?.api.getDisplayedRowAtIndex(params.nextCellPosition.rowIndex) : undefined;
		const shiftKey = params.event?.shiftKey;

		// Copy Windows Explorer multiselect behaviour.
		if ((params.key == "ArrowUp" || params.key == "ArrowDown") && prevCellRow != null && nextCellRow != null) {

			if (!shiftKey) {
				gridRef.current?.api.deselectAll();
				prevCellRow.setSelected(false);
			}

			if (shiftKey && prevCellRow.isSelected() && nextCellRow.isSelected()) {
				prevCellRow.setSelected(false);
			}

			nextCellRow.setSelected(true);
		}

		// Do not allow navigating to the grid header..
		if (params.nextCellPosition == null || params.nextCellPosition.rowIndex == -1) {
			return null;
		}

		return params.nextCellPosition;
	}, []);

	const onFirstDataRendered = useCallback((event: FirstDataRenderedEvent) => {
		// Focus top left cell.
		const columns = event.columnApi.getColumns();

		if (columns != null && columns.length > 0) {
			event.api.setFocusedCell(0, columns[0]);
		}

		// Apply the initial sort from props. In the future, this could be done via columnDefs.
		if (props.sort && props.sort.sortField != null && props.sort.sortType != null) {
			gridRef.current?.columnApi?.applyColumnState({
				state: [
					{ colId: props.sort.sortField, sort: props.sort.sortType, sortIndex: 0 },
				],
				defaultState: { sort: null },
			});
		}
	}, [props.sort]);

	/** Resets the focused cell when up/down are pressed. This ensures the grid is always focused. */
	function globalArrowKeyUpDown(keyEvent?: KeyboardEvent) {
		if (keyEvent == null) {
			return;
		}

		if (keyEvent?.target instanceof HTMLElement && keyEvent.target.closest(".ag-root-wrapper")) {
			// Only do stuff if AG Grid is not the current target.
			return;
		}

		const api = gridRef.current?.api;
		const key = keyEvent.key;

		if (api != null) {
			const focusedCell = api.getFocusedCell();
			const topRow = api.getDisplayedRowAtIndex(0);

			if (focusedCell != null) {
				// Refocus and select new row.
				let newRowIndex = key == "ArrowUp" ? focusedCell.rowIndex - 1 : focusedCell.rowIndex + 1;

				// Make sure we do not focus the header.
				if (newRowIndex < 0) {
					newRowIndex = 0;
				}

				let rowToSelect = api.getDisplayedRowAtIndex(newRowIndex);

				// If there isn't a 'next row', try the current one.
				if (rowToSelect == null) {
					rowToSelect = api.getDisplayedRowAtIndex(focusedCell.rowIndex);
				}

				if (rowToSelect != null && rowToSelect.rowIndex != null) {
					api.deselectAll();
					rowToSelect.setSelected(true);
					api.setFocusedCell(rowToSelect.rowIndex, focusedCell.column);
				}
			} else if (topRow != null) {
				// Focus top-left cell.
				const columns = gridRef.current?.columnApi.getColumns();

				if (columns != null && columns.length > 0 && columns[0] != null) {
					// Select row.
					topRow.setSelected(true);

					// Focus first cell so navigateToNextCell() works.
					api.setFocusedCell(0, columns[0]);
				}
			}

			// Prevent up/down key from scrolling the grid.
			keyEvent?.preventDefault();
		}
	}

	function openNewModal() {
		setNewModalOpen(true);
	}

	function closeNewModal() {
		setNewModalOpen(false);
	}

	async function newCreated() {
		await refreshData();
		setNewModalOpen(false);

		// Refresh the cache unless this is a ticket table.
		if (props.entity != Entities.TICKET) {
			await refreshCache();
		}
	}

	function bulkDeleteClick() {
		if (props.multiDeleteWarningMsg != null) {
			setDeleteModalOpen(true);
		} else {
			deleteSelectedEntities();
		}
	}

	async function deleteSelectedEntities() {
		setDeleteModalOpen(false);

		if (selectedIds.length > 0) {
			// Send req.
			if (props.multiDeleteOverride != null) {
				await props.multiDeleteOverride(selectedIds);
			} else {
				const deleteResponse = await ApiDeleteEntities(props.entity, [...selectedIds]);

				// Display any errors.
				if (deleteResponse != null && deleteResponse.errorMsg) {
					toast.error(deleteResponse.errorMsg);
				}
			}

			if (props.onMultiDelete) {
				props.onMultiDelete(selectedIds);
			}

			// Refresh and clear selection.
			await refreshData();
			setSelectedIds([]);
			gridRef.current?.api.deselectAll();

			// Refresh the cache unless this is a ticket table.
			if (props.entity != Entities.TICKET) {
				await refreshCache();
			}
		}
	}

	function setViewCallback(view: View) {
		setDropdownView(view);
	}

	const columnDefsGen: ColDef[] = useMemo(() =>
		generateColumnDefs(props.entity, cache, dispatch, props.layout?.columns)
	, [props.entity, cache, dispatch, props.layout]);

	const defaultColDef: ColDef = useMemo(() => {

		/** Stops the default AG Grid behaviour for these keys. */
		function suppressKeyboardEvent(params: SuppressKeyboardEventParams) {
			const key = params.event.key;

			// Home + End are handled in onCellKeyDown().
			// ArrowLeft + ArrowRight are used to change the page.
			// Tabbing is suppressed until we can work out how to effectively use it.
			return key == "Home" || key == "End" || key == "ArrowLeft" || key == "ArrowRight" || key == "Tab";
		}

		return {
			resizable: true,
			sortable: true,
			suppressMovable: true,
			flex: 1,
			minWidth: 60,
			comparator: () => 0, // Disables the effects of client side sorting but keeps the visuals.
			suppressKeyboardEvent: suppressKeyboardEvent
		};
	}, []);

	function newButtonClick() {
		// Check the entityLimit is not exeeded first.
		if (props.entityLimit != null && dataTotal != null && dataTotal >= props.entityLimit.limit) {
			toast(props.entityLimit.errorMsg, InfoToast);
			return;
		} else if (props.newEntityUrl != null) {
			// Navigate to new entity screen.
			navigate(props.newEntityUrl);
		}
	}

	let newButton = null;
	if (props.newButtonReplacement != null) {
		newButton = <ButtonBlue
			onClick={() => props.newButtonReplacement?.onClick([])}
			icon={props.newButtonReplacement.icon}
			label={props.newButtonReplacement.label}
		            />;
	} else if (props.newEntityUrl != null) {
		newButton = <ButtonBlue icon={<PiPlusBold />} onClick={newButtonClick} label="New" />;
	} else if (props.entity == Entities.KBARTICLE) {
		// Hide the new button for KB articles.
		newButton = null;
	} else {
		newButton = <ButtonBlue onClick={openNewModal} icon={<PiPlusBold />} label="New" />;
	}

	const customButtons = props.customButtons?.map(btn =>
		<Button onClick={() => doCustomMultiSelectFunction(btn.onClick)} icon={btn.icon} label={btn.label} />
	) ?? [];

	async function doCustomMultiSelectFunction(func: (ids: number[]) => Promise<void>) {
		await func(selectedIds);

		// Refresh and clear selection.
		await refreshData();
		setSelectedIds([]);
		gridRef.current?.api.deselectAll();
	}

	const singleSelectButtons: JSX.Element[] = [];

	const editEntityUrl = props.editEntityUrl; // TS didn't like using the prop directly for some reason...
	if (props.disableEditButton != true && editEntityUrl != null) {
		// Add the edit button.
		singleSelectButtons.push(
			<Button
				onClick={() => {
					if (selectedIds.length == 1) {
						navigate(editEntityUrl + selectedIds[0]);
					}
				}}
				icon={<IoCreateOutline />}
				label={"Edit"}
			/>
		);
	}

	const multiselectButtons = props.multiSelectButtons?.map(btn =>
		<Button
			key={Math.random()} // Temp until we add custom multiselect buttons.
			onClick={() => doCustomMultiSelectFunction(btn.onClick)}
			icon={btn.icon}
			label={btn.label}
		/>
	) ?? [];

	async function exportCsv() {
		if (props.exportCsv == null) {
			return;
		}

		const res = await fetchData({ fetchAll: true });

		if (res.successful && res.data != null) {
			props.exportCsv(res.data);
		} else {
			toast.error("Could not fetch data: " + res.errorMsg);
		}
	}

	// TODO: Remove this switch statement. Merge modal should be in TicketTable. Bulk delete could be exported as an EntityTableButton so tables can pass it back.
	switch (props.entity) {
		case Entities.TICKET:
			multiselectButtons.push(
				<Button onClick={() => setMergeModalOpen(true)} icon={<MdOutlineCallMerge />} label="Merge" />,
				<Button onClick={bulkDeleteClick} icon={<AiOutlineDelete />} label="Delete" />
			);
			break;

		case Entities.AGENT:
		case Entities.USER:
			multiselectButtons.push(<Button onClick={bulkDeleteClick} icon={<AiOutlineStop />} label="Disable" />);
			break;

		default:
			multiselectButtons.push(<Button onClick={bulkDeleteClick} icon={<AiOutlineDelete />} label="Delete" />);
			break;
	}

	const getRowId = useCallback((params: GetRowIdParams) => {
		return params.data.id;
	}, []);

	const onSortChanged = useCallback((event: SortChangedEvent) => {
		// Only run this code if the sort was changed by the user. (Not us setting it initially via the API).
		if (event.source != "uiColumnSorted") {
			return;
		}

		const sortedColumns = event.columnApi.getColumnState().filter(col => col.sort != null);

		if (sortedColumns.length > 0) {
			const sort = sortedColumns[0];

			if (sort.sort != null) {
				const newSort = {
					sortField: sort.colId,
					sortType: sort.sort
				};

				dispatch(sortUpdated(newSort));

				if (props.onSortChange != null) {
					props.onSortChange(newSort);
				}
			}
		} else {
			dispatch(sortCleared());

			if (props.onSortChange != null) {
				props.onSortChange(undefined);
			}
		}
	}, [dispatch]);

	if (props.entity == Entities.TICKET && props.collisionNotes != null) {
		data?.forEach((ticket: Ticket) => {
			ticket.collisionNotes = props.collisionNotes?.filter(note => note.entityId == ticket.id);
		});
	}

	return (
		<div className="h-full flex flex-col overflow-x-hidden" >
			{/* Arrow key shortcuts. */}
			<Hotkeys hotkeys={["ArrowUp", "ArrowDown"]} callback={globalArrowKeyUpDown} />
			<Hotkeys hotkeys="ArrowLeft" callback={prevPage} />
			<Hotkeys hotkeys="ArrowRight" callback={nextPage} />

			<div className="flex h-full">
				<div className="flex flex-col w-full p-3 pt-0">
					<div className="flex justify-between items-center py-2" style={{ height: "50px" }}>
						<div className="flex space-x-2">
							{singleSelectButtons != null && selectedIds.length == 1 && singleSelectButtons}

							{multiselectButtons != null && selectedIds.length > 0 ?
								multiselectButtons
								:
								<>
									<ViewsDropdown use={props.entity} view={dropdownView} setViewCallback={setViewCallback} />

									{/* Removed for now. */}
									{/* <Searchbox searchCallback={searchCallback} />*/}
								</>
							}
						</div>
						<div className="flex items-center gap-2">
							{customButtons}
							{props.exportCsv &&
								<Button icon={<AiOutlineExport />} onClick={exportCsv} label="Export" />
							}
							<DeleteDemoDataButton />
							<Button icon={<HiOutlineRefresh />} onClick={refreshData} />
							<PaginationDisplay
								pagination={{ total: dataTotal, offset: page * dataLimit, limit: dataLimit }}
								prevPage={prevPage}
								nextPage={nextPage}
							/>
							{newButton}
						</div>
					</div>
					<div className={theme} style={ { height: "100%", width: "100%" } }>

						<AgGridReact
							ref={gridRef}
							rowData={data}
							rowSelection="multiple"
							animateRows
							suppressMultiSort
							alwaysShowVerticalScroll
							suppressRowVirtualisation // Disable virtualisation. Unnecessary and required to make 'Home' and 'End' keys work. Without this, rows out of view are virtualised so 'End' has nothing to jump to.

							// These objects are wrapped in useMemo so AgGrid won't rerender every time EntityTable does.
							columnDefs={columnDefsGen}
							defaultColDef={defaultColDef}

							// These callbacks are wrapped in useCallback so AgGrid won't rerender every time EntityTable does.
							getRowId={getRowId}
							onCellDoubleClicked={onCellDoubleClicked}
							onSelectionChanged={onSelectionChanged}
							navigateToNextCell={navigateToNextCell}
							onCellKeyDown={onCellKeyDown}
							onSortChanged={onSortChanged}
							onFirstDataRendered={onFirstDataRendered}
						/>
					</div>
				</div>
				<AnimatePresence initial={false}>
					{ props.showFiltersPanel && <FilterPanel /> }
				</AnimatePresence>
			</div>

			<NewEntityModal
				newCreated={newCreated}
				isOpen={newModalOpen}
				entityType={props.entity}
				closeModal={closeNewModal}
				defaultNewData={props.defaultNewData}
			/>
			<DeleteWarningModal isOpen={deleteModalOpen} onDelete={deleteSelectedEntities} onCancel={() => setDeleteModalOpen(false)} message={props.multiDeleteWarningMsg} />

			<MergeTicketsModal
				isOpen={mergeModalOpen}
				onClose={() => setMergeModalOpen(false)}
				selectedTickets={selectedData}
			/>
		</div>
	);
}
