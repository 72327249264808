import { ReactNode } from "react";
import toast from "react-hot-toast";
import { InboxViewID } from "@/Store/Reducers/cache/HardCodedViews";
import api from "@/Api/Api";
import { ViewButtonContextMenu } from "@/Pages/Tickets/ViewList/ViewGroup/ViewButton/ViewButtonContextMenu";
import { useAppDispatch } from "@/Store/hooks";
import { customFilterOpened, customFilterPanelCleared, customViewUpdated, viewChanged } from "@/Store/Reducers/ticketsPageSlice";
import OtherFunctions from "@/Helpers/OtherFunctions";
import { useCache } from "@/Hooks/useCache";
import { Entities } from "@shared/Entities/Entities";
import { View } from "@shared/Models/View";

interface ViewButtonProps {
	view: View;
	icon?: ReactNode;
	active?: boolean;
	count?: number;
	hasContextMenu?: boolean;
	setActiveView(view: View): void;
	className?: string;
}

export default function ViewButton(props: ViewButtonProps) {
	const dispatch = useAppDispatch();
	const { refreshCache } = useCache();

	function viewButtonOnClick() {
		props.setActiveView(props.view);
	}

	async function editView() {
		const viewFilters = props.view.filters ?? {};

		// Set the editing custom view.
		dispatch(customViewUpdated(props.view));

		// Open filters panel.
		dispatch(customFilterOpened(viewFilters));
	}

	async function duplicateView() {
		const objCopy = structuredClone(props.view);

		objCopy.name += " (copy)";

		const duplicateRes = await api.createEntity<View>(Entities.VIEW, objCopy);

		if (duplicateRes.successful && duplicateRes.data != null) {
			toast.success("View duplicated.");
			await refreshCache();

			// Switch to new view.
			dispatch(viewChanged(duplicateRes.data));
		} else {
			toast.error("Duplicate failed. " + duplicateRes.errorCode + ": " + duplicateRes.errorMsg);
		}
	}

	async function deleteView() {
		if (props.view.id == null || typeof props.view.id == "string" || props.view.id < 1) {
			return;
		}

		const deleteRes = await api.deleteEntity(Entities.VIEW, props.view.id);

		if (deleteRes.successful) {
			toast.success("View deleted.");
			await refreshCache();
		} else {
			toast.error("Delete failed: " + deleteRes.errorMsg);
		}

		dispatch(customFilterPanelCleared());

		// Switch to Inbox view.
		dispatch(viewChanged({ id: InboxViewID, filters: {} }));
	}

	const ticketCount = OtherFunctions.ticketCountFormat(props.count);

	const activeStyle = props.active ? " bg-blue-50 border-blue-200 text-[#121619] "
									 : " text-gray-700 border-transparent hover:bg-gray-100 ";

	return (
		<button
			className={
				"view-button border relative z-[1] flex h-[34px] w-full select-none items-center rounded-md px-1 py-0.5 text-left leading-4 " +
				activeStyle + " " + props.className
			}
			onClick={viewButtonOnClick}
		>
			{props.icon != null &&
				<div className="w-5 mr-2 flex items-center justify-center">
					{props.icon}
				</div>}

			<div className="grow line-clamp-2 leading-5">
				{props.view.name}
			</div>

			{ticketCount != null &&
				<div
					className={
						"tabular-nums rounded-full text-[#121619] py-[5px] px-2 border border-gray-200 " +
						(props.hasContextMenu ? " view-button-hideonhover " : "") +
						(props.active ? " bg-white " : " bg-gray-100 ")
					}
				>
					{ticketCount}
				</div>}

			{props.hasContextMenu &&
				<ViewButtonContextMenu
					onEdit={editView}
					onDuplicate={duplicateView}
					onDelete={deleteView}
				/>}
		</button>
	);
}
