import Modal from "@shared/Components/Modal/Modal";
import ModalContent from "@shared/Components/Modal/ModalContent";
import ModalButtons from "@shared/Components/Modal/ModalButtons";

interface DeleteWarningModalProps {
	isOpen: boolean;
	message?: string;
	onDelete(): void;
	onCancel(): void;
}

export default function DeleteWarningModal(props: DeleteWarningModalProps) {

	return (
		<Modal
			isOpen={props.isOpen}
			onClose={props.onCancel}
			title="Confirm Deletion"
		>
			<ModalContent>
				{props.message}
			</ModalContent>

			<ModalButtons>
				<button className="btn-grey" onClick={props.onCancel}>Cancel</button>
				<button className="btn-red" onClick={props.onDelete}>Delete</button>
			</ModalButtons>
		</Modal>
	);
}
