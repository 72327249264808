import { CompanyListItem } from "@/Pages/Companies/UsersCompaniesPanel/CompanyList/CompanyListItem";
import { Company } from "@shared/Entities/EntityTypes";

interface CompanyListProps {
	companies?: Company[];
	onSelect(id: number): void;
	selectedId?: number;
}

export function CompanyList(props: CompanyListProps) {
	return (
		props.companies?.filter(company => company.id != -1).map((company) =>
			<CompanyListItem
				key={company.id}
				company={company}
				onSelect={props.onSelect}
				active={company.id == props.selectedId}
			/>
		)
	);
}
