import { useEffect, useState } from "react";

interface WindowSize {
	windowWidth: number;
	windowHeight: number;
}

export function useWindowSize(): WindowSize {
	const [windowSize, setWindowSize] = useState<WindowSize>({ windowWidth: window.innerWidth, windowHeight: window.innerHeight });

	useEffect(() => {
		function onResize() {
			setWindowSize({ windowWidth: window.innerWidth, windowHeight: window.innerHeight });
		}

		window.addEventListener("resize", onResize);

		return () => window.removeEventListener("resize", onResize);
	}, []);

	return windowSize;
}
