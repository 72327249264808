import { motion } from "framer-motion";
import { FilterPanelFilters } from "@/Components/EntityTable/FilterPanel/FilterPanelFilters";
import { CustomViewPanel } from "@/Components/EntityTable/FilterPanel/CustomViewPanel";
import { FilterPanelTopButtons } from "@/Components/EntityTable/FilterPanel/FilterPanelTopButtons";
import { useAppDispatch, useAppSelector } from "../../../Store/hooks";
import { customViewCreated } from "../../../Store/Reducers/ticketsPageSlice";
import { Button } from "../../Button/Button";

/**
 * This currently just works for the tickets page. Could be generalised to work for EntityTable though.
 */
export function FilterPanel() {
	const dispatch = useAppDispatch();

	const customView = useAppSelector(state => state.ticketsPage.customView);

	const panelTitle = customView != null && customView.id == undefined ? "New Custom View" :
		customView != null && customView.id != null ? "Editing View"
			: "Filters";

	return (
		<motion.div
			initial={{ width: 0 }}
			animate={{ width: 320 }}
			exit={{ width: 0 }}
			transition={{ duration: 0.2 }}
		>
			<div className="bg-white border-l border-gray-300 w-[320px] flex flex-col h-full">
				<div className="flex justify-between items-center px-7 py-5 w-full">
					<div className="text-xl font-medium text-gray-600 line-clamp-1 overflow-clip">{panelTitle}</div>
					<div className="flex items-center shrink-0 gap-1">
						<FilterPanelTopButtons />
					</div>
				</div>

				<div className="grow overflow-auto px-7 py-5 ">
					<FilterPanelFilters />
				</div>

				{!customView &&
					<div className="px-7 py-5 border-t">
						<Button label="Add custom view" btnClass="btn-blue" onClick={() => dispatch(customViewCreated())} /></div>}

				{customView &&
					<CustomViewPanel customView={customView} />}
			</div>
		</motion.div>
	);
}
