import { useCache } from "@/Hooks/useCache";
import TextArea from "@shared/Components/FormComponents/TextArea/TextArea";
import Modal from "@shared/Components/Modal/Modal";
import ModalContent from "@shared/Components/Modal/ModalContent";

interface DisplayWidgetModalProps {
	isOpen: boolean;
	close(): void;
	agentAppUrl: string;
	kbId: number;
}

export function DisplayWidgetModal(props: DisplayWidgetModalProps) {
	const { cache } = useCache();
	const portalUrl = cache.getPortalURL();

	const embedCode =
`<script>
window.gdConfig = {
kbUrl: "${portalUrl}${props.kbId}/en_GB"
}
</script>

<script src="${props.agentAppUrl}/widget/widget.js"></script>
<link rel="stylesheet" href="${props.agentAppUrl}/widget/widget.css">`;

	return (
		<Modal isOpen={props.isOpen} onClose={props.close} title="Widget code">
			<ModalContent>
				<div>Copy and paste the code below into your website</div>
				<div className="text-base w-[560px] text-gray-700">
					<TextArea
						value={embedCode}
						disabled
						label="Embed code"
						dataname=""
						handleSubmit={() => {}}
						rows={8}
					/>
				</div>
			</ModalContent>
		</Modal>
	);
}
