import { Link } from "react-router-dom";
import { useAppDispatch } from "@/Store/hooks";
import { darkModeToggled } from "@/Store/Reducers/appSlice";

export function DesignMenu() {
	const dispatch = useAppDispatch();

	return (
		<div className="text-lg mb-4 flex flex-col gap-1">
			<div className="font-medium">GoDesk Design System</div>

			<Link to="/" className="text-blue-700">Home</Link>
			<Link to="/design" className="text-blue-700">Design</Link>
			<Link to="/design/form-components" className="text-blue-700">Form components</Link>

			<span className="text-blue-700 cursor-pointer" onClick={() => dispatch(darkModeToggled())}>Darkmode</span>
		</div>
	);
}
