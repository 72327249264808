import { Link } from "react-router-dom";
import LoadingButton from "@mui/lab/LoadingButton";

interface LoginFormProps {
	disabled: boolean;
}

export function LoginForm(props: LoginFormProps) {
	return (
		<>
			<label htmlFor="usernameInput">Work email</label>
			<input
				id="usernameInput"
				type="text"
				placeholder="me@company.com"
				disabled={props.disabled}
				autoComplete="username"
				className="mt-1 block w-full shadow-sm border-gray-300 rounded-md py-2 px-3 border focus:border-blue-500 mb-4"
			/>

			<label htmlFor="passwordInput">Password</label>
			<input
				id="passwordInput"
				type="password"
				placeholder="&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;"
				disabled={props.disabled}
				autoComplete="current-password"
				className="mt-1 block w-full shadow-sm border-gray-300 rounded-md py-2 px-3 border focus:border-blue-500"
			/>

			<div className="flex items-center">
				<div className="h-10" />
				<Link to="/password-reset" className="ml-auto text-blue-600 underline">Forgot password?</Link>
			</div>

			<div className="w-full flex justify-center">
				<LoadingButton
					variant="contained"
					type="submit"
					loading={props.disabled}
				>
					Login
				</LoadingButton>
			</div>
		</>
	);
}
