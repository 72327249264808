import { useNavigate } from "react-router-dom";
import { FaRegBell } from "react-icons/fa";
import { Divider, IconButton, Tooltip } from "@mui/material";
import InfiniteScroll from "react-infinite-scroller";
import { DeleteOutlined, SettingsOutlined } from "@mui/icons-material";
import { Notification } from "@/Api/genApi.schemas";
import { NotificationCard } from "@/Components/Header/NotificationCenter/NotificationCard";
import { getGetMyNotificationsQueryKey, useDeleteAllMyNotifications } from "@/Api/genApi";
import { queryClient } from "@/Api/UseQueryProvider";

interface NotificationCenterProps {
	notifications: Notification[];
	hasNextPage: boolean;
	fetchNextPage(): void;
	close(): void;
}

export function NotificationCenter(props: NotificationCenterProps) {
	const navigate = useNavigate();

	const deleteAllMyNotificationsReq = useDeleteAllMyNotifications({ mutation: {
		onSettled: () => queryClient.invalidateQueries({ queryKey: getGetMyNotificationsQueryKey() }),
	} });

	return (
		<div className="w-[24rem]">
			<div className="py-2 px-2.5 flex justify-between items-center">
				<Tooltip title="Settings">
					<IconButton
						onClick={() => {
							navigate("/config/notifications");
							props.close();
						}}
					>
						<SettingsOutlined />
					</IconButton>
				</Tooltip>

				<div className="text-lg font-medium">Notifications</div>

				<Tooltip title="Clear all">
					<span>
						<IconButton
							disabled={props.notifications.length == 0}
							onClick={() => deleteAllMyNotificationsReq.mutate()}
						>
							<DeleteOutlined />
						</IconButton>
					</span>
				</Tooltip>
			</div>

			<Divider />

			<div className="overflow-y-auto pt-3 pb-5 max-h-[25rem]">
				<InfiniteScroll
					loadMore={props.fetchNextPage}
					hasMore={props.hasNextPage}
					loader={<div className="loader" key={0}>Loading ...</div>}
					useWindow={false}
					threshold={1000}
				>
					{props.notifications.map((notification) => (
						<NotificationCard
							key={notification.id}
							notification={notification}
							closeNotifications={props.close}
						/>
					))}
				</InfiniteScroll>

				{props.notifications.length === 0 &&
					<NoNotifications />}
			</div>
		</div>
	);
}

function NoNotifications() {
	return (
		<div className="flex flex-col gap-3 items-center justify-center h-40 p-6">
			<FaRegBell fontSize="3rem" className="opacity-30" />

			<div>No Notifications</div>
		</div>
	);
}
