import GenericListPage from "@/Pages/Config/_GenericListPage";
import { Entities } from "@shared/Entities/Entities";

export default function ManageCannedReplies() {
	return (
		<GenericListPage
			urlTitle="Canned Replies | Config"
			title="Canned Replies"
			description="Use canned replies to answer common questions quicker. To insert one in an action simply type '/' or click the 'Insert Canned Reply' button above."
			entityType={Entities.CANNEDREPLY}
		/>
	);
}
