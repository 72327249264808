import { ViewVisibility } from "shared/src/Enums/Enums";

export interface View {
	/**
	 * This is a tricky ID. It identifies 3 types of view.
	 * 1) Custom agent views. ID: 0+.
	 * 2) Hardcoded views. ID < 0.
	 * 3) Generated views. ID: string. These are for views generated from other entity IDs which may change such as teams/agents.
	 * We can't have the view IDs changing if an agent is removed so we just check them in here with a key. E.g. team:1,agent:3.
	 */
	id?: number | string;

	name?: string;
	count?: number;
	filters: ViewFilters;
	icon?: string;
	creatorAgentId?: number;
	visibility?: ViewVisibility;
	visibilityAgentsIds?: number[];
	visibilityTeamsIds?: number[];

	/** The name of the view group this view should appear in. */
	groupName?: string;
	/** The ID of the subgroup this view should appear in. E.g. team.id for team views.*/
	subGroupId?: number;
}

export type ViewFilters = { [index: string]: ViewFilter | undefined };

/**
 * This is a union type to typescript can give you intellisense when it's used as a param.
 * Our filter system is based on AG Grid's one.
 */
type ViewFilter = ViewTextFilter | ViewNumberFilter | ViewDateFilter | ViewBoolFilter | ViewSetFilter;

export interface ViewSimpleFilter {
	type: string;
	comparison: "empty" | "equals" | "notEqual" | "lessThan" | "lessThanOrEqual" | "greaterThan" | "greaterThanOrEqual" | "inRange" | "contains" | "notContains" | "startsWith" | "endsWith" | "blank" | "notBlank";
}

export interface ViewTextFilter extends ViewSimpleFilter {
	type: "text";
	comparison: "contains" | "notContains" | "equals" | "notEqual" | "startsWith" | "endsWith" | "blank" | "notBlank";
	value: string;
}

export interface ViewNumberFilter extends ViewSimpleFilter {
	type: "number";
	comparison: "equals" | "notEqual" | "greaterThan" | "greaterThanOrEqual" | "lessThan" | "lessThanOrEqual" | "inRange" | "blank" | "notBlank";
	value: number;
	valueTo?: number;
}

export interface ViewDateFilter extends ViewSimpleFilter {
	type: "date";
	comparison: "equals" | "notEqual" | "greaterThan" | "greaterThanOrEqual" | "lessThan" | "lessThanOrEqual" | "inRange" | "blank" | "notBlank";
	value: number; // TODO: This doesn't look right!
	valueTo?: number;
}

export interface ViewBoolFilter extends ViewSimpleFilter {
	type: "bool";
	comparison: "equals" | "notEqual" | "blank" | "notBlank";
	value: boolean;
}

/** Mirrors the Filter Set Model (couldn't find it in AG Grid files...). Also has custom type prop for excluding + including. */
export interface ViewSetFilter {
	type: "set";
	comparison: "contains" | "notContains";
	values: number[];
}

export function isViewTextFilter(filter: ViewFilter): filter is ViewTextFilter {
	return filter.type == "text";
}

export function isViewNumberFilter(filter: ViewFilter): filter is ViewNumberFilter {
	return filter.type == "number";
}

export function isViewDateFilter(filter: ViewFilter): filter is ViewDateFilter {
	return filter.type == "date";
}

export function isViewBoolFilter(filter: ViewFilter): filter is ViewBoolFilter {
	return filter.type == "bool";
}

export function isViewSetFilter(filter: ViewFilter): filter is ViewSetFilter {
	return filter.type == "set";
}
