import { useState } from "react";
import axios, { AxiosError, AxiosResponse } from "axios";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useAgent } from "@/Hooks/useAgent";
import { Button, ButtonBlue } from "../../Button/Button";
import Modal from "@shared/Components/Modal/Modal";
import ModalButtons from "@shared/Components/Modal/ModalButtons";
import ModalContent from "@shared/Components/Modal/ModalContent";
import { InputTypes } from "@shared/Components/FormComponents/TextInput/InputTypes";
import W_TextArea from "@shared/Components/FormComponents/TextArea/W_TextArea";
import W_TextInput from "@shared/Components/FormComponents/TextInput/W_TextInput";

interface ContactSupportModalProps {
	isOpen: boolean;
	closeModal(): void;
}

export default function ContactSupportModal(props: ContactSupportModalProps) {
	const agent = useAgent();

	const [isLoading, setIsLoading] = useState(false);

	const defaultValues = {
		name: agent.name,
		email: agent.email,
		phone: agent.phoneNumber
	};

	const { handleSubmit, reset, control } = useForm({ defaultValues });

	const onSubmit = async (data: any) => {
		setIsLoading(true);

		const bodyFormData = new URLSearchParams({
			formName: "SENT FROM GODESK (Support) (" + window.location.href + ") " + data.name,
			formEmail: data.email,
			formTel: data.phone,
			formMessage: data.note,
			formEmailMe: "",
			formAgents: ""
		});

		await axios.post<any>("https://getgodesk.com/php/sendContactEmail.php", bodyFormData, { withCredentials: false, timeout: 10000 })
			.then((response: AxiosResponse<any>) => {
				console.log(response);

				if (response.data == "SENT") {
					toast.success("Email sent!");

					reset(defaultValues);
					props.closeModal();
				} else {
					toast.error("Error sending email.");

					reset(defaultValues);
					props.closeModal();
				}
			})
			.catch(function (error: AxiosError) {
				console.log(error);

				toast.error("Error sending email.");

				reset(defaultValues);
				props.closeModal();
			});

		setIsLoading(false);
	};

	function cancel() {
		// Careful of deep copy properties!
		reset(defaultValues);
		props.closeModal();
	}

	return (
		<Modal
			isOpen={props.isOpen}
			onClose={props.closeModal}
			title="Contact Support"
		>
			<ModalContent>
				<div className="w-[30rem]">
					<div className="mb-7">
						Use the form below if you need any help or have a suggestion for how GoDesk could better suit your needs.
					</div>

					<div className="my-5">
						<W_TextInput
							control={control}
							dataname="name"
							label="Your name"
							placeholder="John Smith"
							mandatory
						/>
					</div>

					<div className="my-5">
						<W_TextInput
							control={control}
							dataname="email"
							label="Email address"
							placeholder="me@company.com"
							type={InputTypes.Email}
							mandatory
						/>
					</div>

					<div className="my-5">
						<W_TextInput
							control={control}
							dataname="phone"
							label="Phone number (optional)"
							placeholder="+44 1234 123456"
							type={InputTypes.Tel}
						/>
					</div>

					<div className="mt-5">
						<W_TextArea control={control} dataname="note" label="How can we help you?" rows={4} />
					</div>
				</div>
			</ModalContent>

			<ModalButtons>
				<Button onClick={cancel} label="Cancel" />
				<ButtonBlue onClick={() => handleSubmit(onSubmit)()} label="Get in touch" disabled={isLoading} />
			</ModalButtons>
		</Modal>
	);
}
