import { ReactNode, useEffect, useState } from "react";
import { NavigateFunction, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { useForm } from "react-hook-form";
import pDebounce from "p-debounce";
import W_CacheDropdown from "@/Components/FormComponents/CacheDropdown/W_CacheDropdown";
import { ApiCreateEntity } from "@/legacy/ApiCallerOld";
import { CacheSlices, EnrichedCache } from "@/Store/Reducers/cache/CacheTypes";
import { useCache } from "@/Hooks/useCache";
import api from "@/Api/Api";
import { Button } from "../../../../Components/Button/Button";
import { EmailServer } from "./EmailServer";
import OtherFunctions from "../../../../Helpers/OtherFunctions";
import { EmailSecurity, FieldErrorCode } from "@shared/Enums/Enums";
import { InputTypes } from "@shared/Components/FormComponents/TextInput/InputTypes";
import { ChannelsEmail } from "@shared/Entities/EntityTypes";
import W_TextInput from "@shared/Components/FormComponents/TextInput/W_TextInput";
import { Entities } from "@shared/Entities/Entities";
import { SpecialControlErrorMessage } from "@shared/Components/FormComponents/SpecialControlErrorMessage";

export default function CreateEmailChannel() {
	const { control, handleSubmit, trigger, getValues, setValue, watch, reset } = useForm<ChannelsEmail>({
		defaultValues: {
			isGodeskManaged: false,
			disabled: false,
			imapPort: 993,
			imapSecurity: EmailSecurity.STARTTLS,
			smtpPort: 587,
			smtpSecurity: EmailSecurity.STARTTLS
		}
	});

	const navigate = useNavigate();
	const [page, setPage] = useState<1 | 2 | 3>(1);
	const [serverPage, setServerPage] = useState<1 | 2 | 3>(1);
	const { cache } = useCache();

	useEffect(() => {
		api.metricEvent("new_channel");
	}, []);

	async function finishPage1() {
		// Check field is a valid email.
		if (!await trigger("address")) {
			return;
		}

		const address = getValues("address");

		if (address == null) {
			return;
		}

		// Check email is not in use.
		const res = await api.BasicPost<void>("/channels_email/check_email_free", { email: address });

		if (res.successful) {
			// Check is email is outlook/gmail.
			const capsAddress = address?.trim().toUpperCase();
			const isGmailAddress = capsAddress?.endsWith("@GMAIL.COM");
			const isMsAddress = capsAddress?.endsWith("@HOTMAIL.COM") || capsAddress?.endsWith("@LIVE.COM") || capsAddress?.endsWith("@OUTLOOK.COM");

			if (isGmailAddress) {
				setValue("isGmail", true);
			}

			if (isMsAddress) {
				setValue("isMicrosoft", true);
			}

			// Copy address to imap and smtp user fields.
			setValue("imapUser", address);
			setValue("smtpUser", address);

			api.metricEvent("new_email_step_complete", { step: "email", address: address });

			// Advance the wizard.
			setPage(2);
		} else if (res.formFieldErrorCode != undefined && res.fieldErrors != undefined) {
			OtherFunctions.displayEmailTakenError(res, cache, navigate);
		} else {
			toast.error("Unsuccessful.");
		}
	}

	async function finishPage2() {
		// Check field is a valid email.
		if (!await trigger("name") || !await trigger("teamId")) {
			return;
		}

		api.metricEvent("new_email_step_complete", { step: "name_team", address: getValues("address") });

		// Advance the wizard.
		setPage(3);
	}

	async function formSubmit(data: ChannelsEmail) {
		if (page == 1) {
			finishPage1();
			return;
		}

		const res = await api.createEntity<ChannelsEmail>(Entities.CHANNELSEMAIL, data);

		if (res.successful && res.data != null) {
			finalStepMetric(res.data);
			navigate("/config/manage/channels/email/" + res.data.id);
		} else {
			toast.error("ERROR: " + res.errorMsg);
		}
	}

	async function formSubmitOauth(data: ChannelsEmail) {
		const res = await ApiCreateEntity<ChannelsEmail>(Entities.CHANNELSEMAIL, data);

		if (res.successful && res.data != null) {
			finalStepMetric(res.data);
			reset(res.data);

			return res.data; // Redirected in EmailServer.
		} else {
			toast.error("ERROR: " + res.errorMsg);
			return undefined;
		}
	}

	function advanceServerPage() {
		setServerPage(currentPage => {
			// Typed out so TS doesn't get confused. number -> 1,2,3.
			if (currentPage == 1) {
				return 2;
			} else if (currentPage == 2) {
				return 3;
			} else {
				return 3;
			}
		});
	}

	/** Determines what metric gets sent to mixpanel. */
	function finalStepMetric(data: ChannelsEmail) {
		if (data.isGmail) {
			api.metricEvent("new_email_step", { step: "complete", type: "Gmail", address: getValues("address") });
		} else if (data.isMicrosoft) {
			api.metricEvent("new_email_step", { step: "complete", type: "Microsoft", address: getValues("address") });
		} else if (data.isGodeskManaged) {
			api.metricEvent("new_email_step", { step: "complete", type: "GoDesk managed", address: getValues("address") });
		} else {
			api.metricEvent("new_email_step", { step: "complete", type: "Direct connect", address: getValues("address") });
		}
	}

	return (
		<div className="flex gap-16">
			<form onSubmit={handleSubmit(formSubmit)} style={{ width: "550px" }}>
				<div className="flex flex-col justify-center my-2 border-b border-b-gray-300 ">
					<div className="text-2xl self-center pb-2">New Mailbox</div>
				</div>

				<div className="flex flex-col px-4">
					<W_TextInput
						control={control}
						type={InputTypes.Email}
						dataname="address"
						label="Email address"
						mandatory
						toolTip="Enter the email address where customers currently send you support emails."
						placeholder="support@mycompany.com"
						asyncValidation={debouncedInputValidation}
						errorTransformer={(error) => emailInUseErrorTransformer(navigate, cache, error)}
					/>

					{page == 1 &&
						<div className="self-center mt-4">
							<Button
								className="text-base"
								btnClass="btn-blue"
								label="Next"
								onClick={finishPage1}
							/>
						</div>}

					{page > 1 &&
						<div className="my-5">
							<W_TextInput
								control={control}
								dataname="name"
								label="Name"
								toolTip="Customer-friendly name for this mailbox"
								placeholder="E.g. Apple Support, Microsoft Billing"
								mandatory
							/>
							<W_CacheDropdown
								control={control}
								dataname="teamId"
								label="Team"
								toolTip="Assign new incoming tickets from this channel to this team."
								cacheSlice={CacheSlices.Teams}
								mandatory
							/>
						</div>}

					{page == 2 &&
						<div className="self-center mt-4">
							<Button className="text-base" btnClass="btn-blue" label="Next" onClick={finishPage2} />
						</div>}

					{page == 3 &&
						<>
							<EmailServer
								control={control}
								getValues={getValues}
								setValue={setValue}
								watch={watch}
								page={serverPage}
								advancePage={advanceServerPage}
								save={() => formSubmitOauth(getValues())}
							/>
						</>}

					{(serverPage == 3 || getValues("isGodeskManaged")) &&
						<div className="py-5">
							<input type="submit" className="btn-blue" value={"Complete"} />
						</div>
					}
				</div>
			</form>

			<EmailChannelTutorialVideos />
		</div>
	);
}

const debouncedInputValidation = pDebounce(inputValidation, 300);

/** Serialise a custom string if the email is already in use.  */
async function inputValidation(value?: string): Promise<string | boolean> {
	// If value is blank then return an true, This should never happen.
	if (value === "" || value == undefined) {
		return true;
	}

	// Check email is not in use.
	const res = await api.BasicPost<void>("/channels_email/check_email_free", { email: value });

	if (res.successful) {
		return true;
	} else if (res.formFieldErrorCode != null && res.dataNumber1) {
		return new SpecialControlErrorMessage(res.formFieldErrorCode, res.dataNumber1).serialise();
	}

	return true;
}

/** Transforms email in use errors to have a link to the entity using the email. */
function emailInUseErrorTransformer(navigate: NavigateFunction, cache: EnrichedCache, error?: string): ReactNode {
	if (error != null && SpecialControlErrorMessage.isSpecialError(error)) {
		const specialError = SpecialControlErrorMessage.fromString(error);

		if (specialError?.errorCode == FieldErrorCode.EMAIL_TAKEN_BY_USER) {
			const user = cache.getUser(specialError.errorData);

			if (user != undefined) {
				return <div>
					This email is in use by a user: <span className="underline cursor-pointer text-blue-500" onClick={() => navigate("/users/" + user.id)}>{user.name}</span>
				</div>;

			}
		} else if (specialError?.errorCode == FieldErrorCode.EMAIL_TAKEN_BY_EMAIL_CHANNEL) {
			const channel = cache.getChannel(specialError.errorData);

			if (channel != undefined) {
				return <div>
					This email is already in use by a mailbox: <span className="underline cursor-pointer text-blue-500" onClick={() => navigate("/config/manage/channels/email/" + channel.id)}>{channel.name}</span>
				</div>;

			}
		} else if (specialError?.errorCode == FieldErrorCode.EMAIL_TAKEN_BY_AGENT) {
			const agent = cache.getAgent(specialError.errorData);

			if (agent != undefined) {
				return <div>
					This email is in use by a agent: <span className="underline cursor-pointer text-blue-500" onClick={() => navigate("/config/manage/agents/" + agent.id)}>{agent.name}</span>
				</div>;

			}
		}
	}

	return error;
}

function EmailChannelTutorialVideos() {
	return (
		<div className="flex flex-col gap-5">
			<div className="text-lg font-semibold">Guides</div>

			<iframe
				width="426"
				height="200"
				src="https://www.youtube-nocookie.com/embed/2zAXN-6Ndrw?si=HSxfQdOw_ci_kQw8"
				title="YouTube video player"
				frameBorder="0"
				allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
				referrerPolicy="strict-origin-when-cross-origin"
				allowFullScreen
			/>

			<iframe
				width="426"
				height="200"
				src="https://www.youtube-nocookie.com/embed/wcOMLALfRjw?si=9il5TiCFBEmmvaO2"
				title="YouTube video player"
				frameBorder="0"
				allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
				referrerPolicy="strict-origin-when-cross-origin"
				allowFullScreen
			/>

			<iframe
				width="426"
				height="200"
				src="https://www.youtube-nocookie.com/embed/ACOOBpo8Nlg?si=UZIIQEejE5LyA5vu"
				title="YouTube video player"
				frameBorder="0"
				allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
				referrerPolicy="strict-origin-when-cross-origin"
				allowFullScreen
			/>
		</div>
	);
}
