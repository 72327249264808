import { AiOutlineDelete } from "react-icons/ai";
import { CacheSlices } from "@/Store/Reducers/cache/CacheTypes";
import CacheDropdown from "../../../../Components/FormComponents/CacheDropdown/CacheDropdown";
import { AutomationAction, AutomationActionType } from "@shared/Entities/EntityTypes";

interface ActionSelectorProps {
	data: AutomationAction;
	deletable: boolean;
	updateData(data: AutomationAction): void;
	delete(data: AutomationAction): void;
}

export function ActionSelector(props: ActionSelectorProps) {
	let valueInput = null;

	function updateAction(field: string) {
		const dataCopy = Object.assign({}, props.data);

		let newValue: AutomationActionType | undefined = undefined;

		if (Object.values(AutomationActionType).indexOf(field as AutomationActionType) > -1) { // String can be cast correctly as this enum.
			newValue = AutomationActionType[field as AutomationActionType];
		}

		if (newValue != undefined) {
			dataCopy.action = newValue;

			dataCopy.dataInt = undefined;
			dataCopy.dataString = undefined;

			props.updateData(dataCopy);
		}
	}

	function updateDataInt(value: number | undefined) {
		const dataCopy = Object.assign({}, props.data);

		dataCopy.dataInt = value;
		props.updateData(dataCopy);
	}

	function updateDataString(value: string | undefined) {
		const dataCopy = Object.assign({}, props.data);

		dataCopy.dataString = value;
		props.updateData(dataCopy);
	}

	switch (props.data.action) {
		case AutomationActionType.SET_AGENT:
			valueInput = <CacheDropdown
				dataname=""
				widthCss="250px"
				value={props.data.dataInt}
				onChange={(key, value) => {updateDataInt(value);}}
				cacheSlice={CacheSlices.Agents}
				mandatory
				inline
			             />;
			break;

		case AutomationActionType.SET_PRIORITY:
			valueInput = <CacheDropdown
				dataname=""
				widthCss="250px"
				value={props.data.dataInt}
				onChange={(key, value) => {updateDataInt(value);}}
				cacheSlice={CacheSlices.Priorities}
				mandatory
				inline
			             />;
			break;

		case AutomationActionType.SET_STATUS:
			valueInput = <CacheDropdown
				dataname=""
				widthCss="250px"
				value={props.data.dataInt}
				onChange={(key, value) => {updateDataInt(value);}}
				cacheSlice={CacheSlices.Statuses}
				mandatory
				inline
			             />;
			break;

		case AutomationActionType.SET_TEAM:
			valueInput = <CacheDropdown
				dataname=""
				widthCss="250px"
				value={props.data.dataInt}
				onChange={(key, value) => {updateDataInt(value);}}
				cacheSlice={CacheSlices.Teams}
				mandatory
				inline
			             />;
			break;
	}

	return (
		<div className="my-3 w-full flex items-center">
			<div className="flex-grow pr-3 flex items-center gap-2">
				<select className="py-2 px-1 shadow-sm border-gray-300 border rounded-md" value={props.data.action} onChange={(event) => { updateAction(event.target.value); }} required>
					<option disabled selected value="">Select Action</option>
					<option value={AutomationActionType.SET_AGENT}>Set agent</option>
					<option value={AutomationActionType.SET_PRIORITY}>Set priority</option>
					<option value={AutomationActionType.SET_STATUS}>Set status</option>
					<option value={AutomationActionType.SET_TEAM}>Set team</option>
				</select>

				{ props.data.action ?
					<>
						{valueInput}
					</>
					: null}
			</div>

			<div className="w-6">
				{props.deletable ?
					<div className="h-6 w-6 rounded-full bg-gray-200 items-center text-center inline-flex cursor-pointer hover:bg-gray-300" onClick={() => {props.delete(props.data);}}>
						<AiOutlineDelete className="ml-1.5" />
					</div>
					: null}
			</div>

		</div>
	);
}
