import { CSSProperties, ReactNode, useEffect, useRef, useState } from "react";
import DraggableLib from "react-draggable";
import { Resizable } from "re-resizable";
import { Direction } from "re-resizable/lib/resizer";
import { useWindowSize } from "@/Hooks/useWindowSize";

interface DraggableResizableProps {
	children: ReactNode;
	initialPositionPx: { x: number; y: number };
	initialSizePx: { width: number; height: number };
	minSizePx: { width: number; height: number };
	maxSizePx: { width: number; height: number };
	draggableHandle: string;
}

interface Position {
	/** Which side of the screen we are offsetting from. */
	directionX: "left" | "right";
	x: number;
	directionY: "top" | "bottom";
	y: number;
}

const bufferZone = 30;
const softBuffer = 3;

/** React-draggable does not keep the element on the screen if you resize the window, This fixes the problem. */
export default function DraggableResizable(props: DraggableResizableProps) {
	const [position, setPosition] = useState<Position>({ directionX: "left", x: props.initialPositionPx.x, directionY: "top", y: props.initialPositionPx.y });
	const { windowWidth, windowHeight } = useWindowSize();

	const lastWindowWidth = useRef(windowWidth);
	const lastWindowHeight = useRef(windowHeight);
	const boundsRef = useRef<HTMLDivElement>(null);
	const elementRef = useRef<HTMLDivElement>(null);

	// Keeps the element onscreen if they resize the window.
	useEffect(() => {
		const resizeActionX = lastWindowWidth.current == windowWidth ? null : lastWindowWidth.current > windowWidth ? "thinner" : "wider";
		const windowDeltaX = windowWidth - lastWindowWidth.current;
		lastWindowWidth.current = windowWidth;

		const resizeActionY = lastWindowHeight.current == windowHeight ? null : lastWindowHeight.current > windowHeight ? "shorter" : "taller";
		const windowDeltaY = windowHeight - lastWindowHeight.current;
		lastWindowHeight.current = windowHeight;

		const elementBox = elementRef.current?.getBoundingClientRect();

		if (elementBox == null) {
			return;
		}

		const newPosition: Position = {
			directionX: position.directionX,
			x: position.x,
			directionY: position.directionY,
			y: position.y
		};

		if (resizeActionX == "thinner") {

			if (elementBox.width + bufferZone + bufferZone > windowWidth || elementBox.left < bufferZone) {
				// If the window has breached the bufferZone on the left we flip the x position to be fixed to the left.
				newPosition.directionX = "left";
				newPosition.x = bufferZone;
			} else if (elementBox.right + bufferZone + softBuffer > windowWidth) {
				// If the window has breached the bufferZone on the right we flip the x position to be fixed to the right.
				newPosition.directionX = "right";
				newPosition.x = bufferZone;
			} else {
				// This stops a jitter if you are fixed to one side then move that same side towards the element.
				const xAdjustment = position.directionX == "right" ? windowDeltaX : 0;

				// This fixes the x position to the left while the element is not breached from the right side.
				newPosition.directionX = "left";
				newPosition.x = elementBox.left - xAdjustment;
			}
		}

		if (resizeActionX == "wider") {

			const xAdjustment = position.directionX == "right" ? windowDeltaX : 0;

			// This is a special case to stop you being able to grab the element if you breach the bufferZone on both sides.
			newPosition.directionX = "left";
			newPosition.x = elementBox.left - xAdjustment;
		}

		if (resizeActionY == "shorter") {

			if (elementBox.height + bufferZone + bufferZone > windowHeight || elementBox.top < bufferZone) {
				newPosition.directionY = "top";
				newPosition.y = bufferZone;
			} else if (elementBox.bottom + bufferZone + softBuffer > windowHeight) {
				newPosition.directionY = "bottom";
				newPosition.y = bufferZone;
			} else {
				const yAdjustment = position.directionY == "bottom" ? windowDeltaY : 0;

				newPosition.directionY = "top";
				newPosition.y = elementBox.top - yAdjustment;
			}
		}

		if (resizeActionY == "taller") {

			const yAdjustment = position.directionY == "bottom" ? windowDeltaY : 0;

			newPosition.directionY = "top";
			newPosition.y = elementBox.top - yAdjustment;
		}

		setPosition(newPosition);

		// Not changing on position change.
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [windowWidth, windowHeight]);

	/**
	 * Flips the bound side of the element of the box if needed when it is resized.
	 */
	function resizeStart(dir: Direction) {
		const elementBox = elementRef.current?.getBoundingClientRect();

		if (elementBox == null) {
			return;
		}

		let newDirX = position.directionX;
		let newX = position.x;
		let newDirY = position.directionY;
		let newY = position.y;

		if (dir == "topLeft" || dir == "top" || dir == "topRight") {
			newDirY = "bottom";
			newY = windowHeight - elementBox.bottom;
		}

		if (dir == "bottomLeft" || dir == "bottom" || dir == "bottomRight") {
			newDirY = "top";
			newY = elementBox.top;
		}

		if (dir == "topLeft" || dir == "left" || dir == "bottomLeft") {
			newDirX = "right";
			newX = windowWidth - elementBox.right;
		}

		if (dir == "topRight" || dir == "right" || dir == "bottomRight") {
			newDirX = "left";
			newX = elementBox.left;
		}

		setPosition({
			directionX: newDirX,
			x: newX,
			directionY: newDirY,
			y: newY
		});
	}

	return (
		<>
			<div className="relative">
				{/* Bounding element for draggable (Needs padding) */}
				<div
					id="draggableBounds"
					className="h-screen w-screen fixed left-0 top-0 invisible block"
					style={{ padding: bufferZone }}
				/>

				{/* Bounding element for re-resizable (Needs margin) */}
				<div
					className="left-0 top-0 fixed invisible block"
					style={{
						height: `calc(100vh - ${bufferZone}px - ${bufferZone}px)`,
						width: `calc(100vw - ${bufferZone}px - ${bufferZone}px)`,
						margin: bufferZone
					}}
					ref={boundsRef}
				/>
			</div>
			<DraggableLib
				handle={props.draggableHandle}
				bounds="#draggableBounds"
				position={{ x: 0, y: 0 }} // This is correct. The actual position is applied with getPositionStyles().
				onStop={(_e, data) => {
					const deltaX = data.x;
					const deltaY = data.y;

					setPosition({
						directionX: position.directionX,
						x: position.directionX == "left" ? position.x + deltaX : position.x - deltaX,
						directionY: position.directionY,
						y: position.directionY == "top" ? position.y + deltaY : position.y - deltaY });
				} }
			>
				<div
					ref={elementRef}
					style={getPositionStyles(position)}
					className="z-50 touch-none"
				>
					<Resizable
						boundsByDirection
						onResizeStart={(_e, dir) => resizeStart(dir)}
						bounds={boundsRef.current ?? undefined}
						defaultSize={props.initialSizePx}
						minWidth={props.minSizePx.width}
						minHeight={props.minSizePx.height}
						maxWidth={props.maxSizePx.width}
						maxHeight={props.maxSizePx.height}
					>
						<div className="h-full w-full">
							{props.children}
						</div>
					</Resizable>
				</div>

			</DraggableLib>

		</>
	);
}

function getPositionStyles(pos: Position): CSSProperties {
	return {
		position: "fixed",
		left: pos.directionX == "left" ? pos.x : undefined,
		right: pos.directionX == "right" ? pos.x : undefined,
		top: pos.directionY == "top" ? pos.y : undefined,
		bottom: pos.directionY == "bottom" ? pos.y : undefined
	};
}
