import { useState } from "react";
import { Button, Card, Snackbar, Stack } from "@mui/material";
import { FieldValues, FormState } from "react-hook-form";
import LoadingButton from "@mui/lab/LoadingButton";

interface SaveBarProps<T extends FieldValues> {
	formState: FormState<T>;
	onSave(): Promise<void>;
	onCancel(): void;
}

/**
 * Snackbar that opens at the bottom of the screen.
 * Prompts the user to save their changes.
 * Useful for always-edit config pages.
 */
export function SaveBar<T extends FieldValues>(props: SaveBarProps<T>) {
	const [isLoading, setIsLoading] = useState<boolean>(false);

	// Dirty field count including nested objects.
	let dirtyFieldCount = countValues(props.formState.dirtyFields);

	if (dirtyFieldCount == 0) {
		dirtyFieldCount = 1; // Stops the value flickering to 0 if we 'unchange' our last change.
	}

	async function save() {
		setIsLoading(true);
		await props.onSave();
		setIsLoading(false);
	}

	return (
		<Snackbar
			open={props.formState.isDirty}
			anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
		>
			<Card className="p-5">
				<Stack direction="row" spacing={2} className="items-center tabular-nums">
					<div>You've changed {dirtyFieldCount} {dirtyFieldCount > 1 ? "properties" : "property"}</div>

					<Button
						variant="outlined"
						onClick={() => props.onCancel()} // Must be called as lambda to stop weird reccursion error.
					>
						Cancel
					</Button>

					<LoadingButton
						variant="contained"
						onClick={save}
						loading={isLoading}
					>
						Save
					</LoadingButton>
				</Stack>
			</Card>
		</Snackbar>
	);
}

// Bit of GPT!
function countValues(obj: object) {
	let count = 0;

	function countRecursively(o: any) {
		for (const key in o) {
			if (typeof o[key] === "object" && o[key] !== null) {
				countRecursively(o[key]); // Recurse if the value is an object
			} else {
				count++; // Increment count if the value is not an object
			}
		}
	}

	countRecursively(obj);
	return count;
}
