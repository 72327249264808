import { AiOutlineUser } from "react-icons/ai";
import { GiTicket } from "react-icons/gi";
import { MdBusiness } from "react-icons/md";
import { HiOutlineBookOpen } from "react-icons/hi";
import { SearchPageTabs } from "@shared/Enums/Enums";

interface SearchPageTabProps {
	tabType: SearchPageTabs;
	activeTab?: SearchPageTabs;
	ticketCount: number;
	onClick(tabType: SearchPageTabs | undefined): void;
}

export function SearchPageTab(props: SearchPageTabProps) {
	let tabIcon = <></>;
	let tabTitle = "";
	let tabStyles = {};

	switch (props.tabType) {
		case SearchPageTabs.AGENT:
			tabIcon = <AiOutlineUser />;
			tabTitle = "Agents";
			break;
		case SearchPageTabs.COMPANY:
			tabIcon = <MdBusiness />;
			tabTitle = "Company";
			break;
		case SearchPageTabs.TICKET:
			tabIcon = <GiTicket />;
			tabTitle = "Tickets";
			break;
		case SearchPageTabs.USER:
			tabIcon = <AiOutlineUser />;
			tabTitle = "Users";
			break;
		case SearchPageTabs.KBARTICLE:
			tabIcon = <HiOutlineBookOpen />;
			tabTitle = "KB Articles";
			break;
	}

	tabStyles = props.tabType == props.activeTab ? { backgroundColor: "#ddd" } : {};

	function onClick() {
		if (props.activeTab == props.tabType) {
			props.onClick(undefined);
		} else {
			props.onClick(props.tabType);
		}
	}

	return (
		<button className="btn-grey rounded-b-none border-b-0" title={tabTitle} style={tabStyles} onClick={onClick} >
			{tabIcon}
			{props.ticketCount}
		</button>
	);
}
