import { DateTime } from "luxon";
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from "chart.js";
import { Alert } from "@mui/material";
import { TicketPriority, TicketStatus, TicketsByDay, TicketsByHour } from "@/Pages/Reporting/Dashboard/DashboardCharts";
import { useGetDashboardReport } from "@/Api/genApi";
import { StatBox } from "../StatBox";

ChartJS.register(
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend
);

interface DashboardProps {
	dateRange: [DateTime, DateTime];
}

export function Dashboard(props: DashboardProps) {
	const dashboardReq = useGetDashboardReport({ dateFrom: props.dateRange[0].toISODate(), dateTo: props.dateRange[1].toISODate() });

	if (dashboardReq.isLoading) {
		return null;
	}

	if (dashboardReq.error || dashboardReq.data == null) {
		return <Alert severity="error">
			Could not load dashboard.
		</Alert>;
	}

	return (<>
		<div className="text-2xl my-5 text-center">
			Reporting Dashboard
		</div>

		<div className="flex flex-wrap my-10">
			<StatBox name="Created Tickets" value={dashboardReq.data.createdTickets} />
			<StatBox name="Resolved Tickets" value={dashboardReq.data.resolvedTickets} />
			<StatBox name="Unresolved Tickets" value={dashboardReq.data.unresolvedTickets} />
			<StatBox name="Average Response Time" valueTimeMins={dashboardReq.data.avgResponseTimeRawMins} />
			<StatBox name="Avg. First Response Time" valueTimeMins={dashboardReq.data.avgFirstResponseTimeRawMins} />
		</div>

		<div className="grid gap-5 grid-cols-1 lg:grid-cols-2">
			<TicketsByHour homeStats={dashboardReq.data} />
			<TicketsByDay homeStats={dashboardReq.data} />
			<TicketStatus homeStats={dashboardReq.data} />
			<TicketPriority homeStats={dashboardReq.data} />
		</div>
	</>);
}
