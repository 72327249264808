import { ChangeEvent } from "react";
import { SlaPriorityResolutionUnit } from "@/Api/genApi.schemas";

interface SlaTimeAmountProps {
	value: number;
	unit: SlaPriorityResolutionUnit;
	onChangeValue(newValue: number): void;
	onChangeUnit(newValue: SlaPriorityResolutionUnit): void;
}

export default function SlaTimeAmount(props: SlaTimeAmountProps) {
	function handleValueChange(event: ChangeEvent<HTMLInputElement>) {
		if (event.target.value != null) {
			let valueMins: number | undefined = parseInt(event.target.value);

			if (isNaN(valueMins)) {
				valueMins = undefined;
			}

			props.onChangeValue(valueMins ?? 0);
		}
	}

	function changeUnit(newUnit: string) {
		if (newUnit == SlaPriorityResolutionUnit.MINUTE) {
			props.onChangeUnit(SlaPriorityResolutionUnit.MINUTE);
		} else if (newUnit == SlaPriorityResolutionUnit.HOUR) {
			props.onChangeUnit(SlaPriorityResolutionUnit.HOUR);
		} else {
			props.onChangeUnit(SlaPriorityResolutionUnit.DAY);
		}
	}

	return (
		<div>
			<input
				value={props.value}
				className="p-2 w-14 h-[37px] border-gray-300 focus:border-blue-500 border rounded-l-md"
				type="text"
				inputMode="numeric"
				pattern="[0-9]*"
				min="1"
				step="1"
				onChange={handleValueChange}
			/>

			<select
				className="py-2 px-1 h-[37px] shadow-sm border-gray-300 focus:border-blue-500 border-r border-t border-b rounded-r-md"
				value={props.unit}
				onChange={(event) => { changeUnit(event.target.value); }}
				required
			>
				<option value={SlaPriorityResolutionUnit.MINUTE}>Minutes</option>
				<option value={SlaPriorityResolutionUnit.HOUR}>Hours</option>
				<option value={SlaPriorityResolutionUnit.DAY}>Days</option>
			</select>
		</div>
	);
}
