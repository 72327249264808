import { useSearchParams } from "react-router-dom";
import { PasswordResetRedeem } from "@/Pages/Auth/PasswordReset/PasswordResetRedeem";
import PasswordResetRequest from "@/Pages/Auth/PasswordReset/PasswordResetRequest";

export default function PasswordReset() {
	document.title = "Password Reset | GoDesk";

	const [searchParams] = useSearchParams();
	const token = searchParams.get("token");

	if (token != null) {
		return <PasswordResetRedeem token={token} />;
	} else {
		return <PasswordResetRequest />;
	}
}
