import { ReactNode, useId } from "react";
import { AiOutlinePlusCircle } from "react-icons/ai";
import Select, { StylesConfig } from "react-select";
import Tooltip from "@mui/material/Tooltip";
import { closeMenuOnScrollFix } from "../../../Helpers/OtherFunctions";
import { ControlLabel } from "@shared/Components/FormComponents/ControlLabel";

export type DropdownOption<T> = {
	label: ReactNode;
	value?: T;
	isDisabled?: boolean;
};

export interface DropdownProps<T> {
	value?: T;
	dataname: string;
	options: DropdownOption<T | undefined>[];
	onChange?(key: string, value?: T): void;
	label?: ReactNode;
	toolTip?: ReactNode;
	mandatory?: boolean;
	inline?: boolean;
	invalid?: boolean;
	disabled?: boolean;
	widthCss?: string;

	/** This callback enables a 'new' button to the right of the dropdown. */
	newButtonOnClick?(): void;
	newButtonTooltip?: string;
}

/**
 * Composable dropdown component. T is the datatype (usually a number ID).
 */
export default function Dropdown<T>(props: DropdownProps<T>) {
	const controlId = useId();

	function handleChange(newValue: unknown) {
		const value = (newValue as DropdownOption<T>).value;

		if (props.onChange != null) {
			props.onChange(props.dataname, value);
		}
	}

	const customStyles: StylesConfig = {
		dropdownIndicator: (provided) => ({
			...provided,
			padding: "5px 7px",
		}) as any,
		indicatorSeparator: (provided) => ({
			...provided,
			display: "none",
		}) as any,
		menu: (provided) => ({
			...provided,
			margin: "1px",
			zIndex: "100"
		}) as any,
		menuPortal: (provided) => ({
			...provided,
			zIndex: "2"
		}) as any,
	};

	const options = [...props.options];

	if (props.mandatory != true) {
		options.push({ value: undefined, label: "--" });
	}

	const styles = { width: props.widthCss };

	const dropdownValueLabel = props.value != null ? props.options.find(option => option.value == props.value)?.label : "--";
	const dropdownValue = { value: props.value, label: dropdownValueLabel };

	const displayCssContainer = props.inline ? "inline-block" : "block Form_Component";

	return (
		<div className={displayCssContainer} style={styles}>
			{!props.inline &&
				<ControlLabel
					label={props.label}
					mandatory={props.mandatory}
					helperText={props.toolTip}
					controlId={controlId}
					invalid={props.invalid}
				/>}

			<div className="flex w-full items-center">
				<Select
					inputId={controlId}
					value={dropdownValue}
					onChange={handleChange}
					options={props.options}
					styles={customStyles}
					isDisabled={props.disabled}
					menuPlacement="auto"
					// This adds fixed position to Dropdown so options will display over the top of the modal.
					menuPosition="fixed"
					className="grow select-parent"
					closeMenuOnScroll={closeMenuOnScrollFix}
				/>

				{props.newButtonOnClick && props.newButtonTooltip &&
					<Tooltip title={props.newButtonTooltip}>
						<span>
							<AiOutlinePlusCircle
								className="mx-3 cursor-pointer w-5 h-5"
								onClick={props.newButtonOnClick}
							/>
						</span>
					</Tooltip>
				}
			</div>
		</div>
	);
}
