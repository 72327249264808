import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Button } from "../../Components/Button/Button";
import Timerange from "../../Components/FormComponents/Timerange/Timerange";
import { TimezoneDropdown } from "../../Components/FormComponents/TimezoneDropdown/TimezoneDropdown";
import { Tickbox } from "@shared/Components/FormComponents/Tickbox/Tickbox";
import { Setting } from "@shared/Entities/EntityTypes";

interface SettingsWorkdayProps {
	data?: Setting[];
	updateData(settingsData: Setting[]): void;
}

// TODO: Loads of crap duplicate code in this class could go with a better data structure. (day objs with a start and finish prop.)

export default function SettingsWorkday(props: SettingsWorkdayProps) {
	const [data, setData] = useState<Map<string, string | undefined | null> | undefined>();
	const [mondayEnabled, setMondayEnabled] = useState<boolean>(false);
	const [tuesdayEnabled, setTuesdayEnabled] = useState<boolean>(false);
	const [wednesdayEnabled, setWednesdayEnabled] = useState<boolean>(false);
	const [thursdayEnabled, setThursdayEnabled] = useState<boolean>(false);
	const [fridayEnabled, setFridayEnabled] = useState<boolean>(false);
	const [saturdayEnabled, setSaturdayEnabled] = useState<boolean>(false);
	const [sundayEnabled, setSundayEnabled] = useState<boolean>(false);

	const [monday24hr, setMonday24hr] = useState<boolean>(false);
	const [tuesday24hr, setTuesday24hr] = useState<boolean>(false);
	const [wednesday24hr, setWednesday24hr] = useState<boolean>(false);
	const [thursday24hr, setThursday24hr] = useState<boolean>(false);
	const [friday24hr, setFriday24hr] = useState<boolean>(false);
	const [saturday24hr, setSaturday24hr] = useState<boolean>(false);
	const [sunday24hr, setSunday24hr] = useState<boolean>(false);

	const { handleSubmit } = useForm();
	const navigate = useNavigate();

	useEffect(() => {
		const settingsMap = new Map();

		if (props.data != null) {
			props.data.forEach((setting) => {
				settingsMap.set(setting.key, setting.value);
			});
		}

		setMondayEnabled(settingsMap.get("workday.mondayStart") && settingsMap.get("workday.mondayFinish"));
		setTuesdayEnabled(settingsMap.get("workday.tuesdayStart") && settingsMap.get("workday.tuesdayFinish"));
		setWednesdayEnabled(settingsMap.get("workday.wednesdayStart") && settingsMap.get("workday.wednesdayFinish"));
		setThursdayEnabled(settingsMap.get("workday.thursdayStart") && settingsMap.get("workday.thursdayFinish"));
		setFridayEnabled(settingsMap.get("workday.fridayStart") && settingsMap.get("workday.fridayFinish"));
		setSaturdayEnabled(settingsMap.get("workday.saturdayStart") && settingsMap.get("workday.saturdayFinish"));
		setSundayEnabled(settingsMap.get("workday.sundayStart") && settingsMap.get("workday.sundayFinish"));

		setMonday24hr(settingsMap.get("workday.monday24hr") == "1");
		setTuesday24hr(settingsMap.get("workday.tuesday24hr") == "1");
		setWednesday24hr(settingsMap.get("workday.wednesday24hr") == "1");
		setThursday24hr(settingsMap.get("workday.thursday24hr") == "1");
		setFriday24hr(settingsMap.get("workday.friday24hr") == "1");
		setSaturday24hr(settingsMap.get("workday.saturday24hr") == "1");
		setSunday24hr(settingsMap.get("workday.sunday24hr") == "1");

		setData(settingsMap);
	}, [props.data]);

	function dayToggle(dayEnabled: boolean, setDay: (value: boolean) => void, dayStartKey: string, dayFinishKey: string) {
		if (!dayEnabled && data?.get(dayStartKey) == undefined && data?.get(dayFinishKey) == undefined) {
			const dataCopy = new Map(data ?? []);

			dataCopy.set(dayStartKey, "09:00");
			dataCopy.set(dayFinishKey, "17:00");

			setData(dataCopy);
		}

		setDay(!dayEnabled);
	}

	function formSubmit() {
		if (data != null) {
			const dataCopy = new Map(data);

			if (!mondayEnabled || dataCopy.get("workday.mondayStart") == undefined || dataCopy.get("workday.mondayFinish") == undefined) {
				dataCopy.set("workday.mondayStart", undefined);
				dataCopy.set("workday.mondayFinish", undefined);
			}

			if (!tuesdayEnabled || dataCopy.get("workday.tuesdayStart") == undefined || dataCopy.get("workday.tuesdayFinish") == undefined) {
				dataCopy.set("workday.tuesdayStart", undefined);
				dataCopy.set("workday.tuesdayFinish", undefined);
			}

			if (!wednesdayEnabled || dataCopy.get("workday.wednesdayStart") == undefined || dataCopy.get("workday.wednesdayFinish") == undefined) {
				dataCopy.set("workday.wednesdayStart", undefined);
				dataCopy.set("workday.wednesdayFinish", undefined);
			}

			if (!thursdayEnabled || dataCopy.get("workday.thursdayStart") == undefined || dataCopy.get("workday.thursdayFinish") == undefined) {
				dataCopy.set("workday.thursdayStart", undefined);
				dataCopy.set("workday.thursdayFinish", undefined);
			}

			if (!fridayEnabled || dataCopy.get("workday.fridayStart") == undefined || dataCopy.get("workday.fridayFinish") == undefined) {
				dataCopy.set("workday.fridayStart", undefined);
				dataCopy.set("workday.fridayFinish", undefined);
			}

			if (!saturdayEnabled || dataCopy.get("workday.saturdayStart") == undefined || dataCopy.get("workday.saturdayFinish") == undefined) {
				dataCopy.set("workday.saturdayStart", undefined);
				dataCopy.set("workday.saturdayFinish", undefined);
			}

			if (!sundayEnabled || dataCopy.get("workday.sundayStart") == undefined || dataCopy.get("workday.sundayFinish") == undefined) {
				dataCopy.set("workday.sundayStart", undefined);
				dataCopy.set("workday.sundayFinish", undefined);
			}

			dataCopy.set("workday.monday24hr", monday24hr ? "1" : "0");
			dataCopy.set("workday.tuesday24hr", tuesday24hr ? "1" : "0");
			dataCopy.set("workday.wednesday24hr", wednesday24hr ? "1" : "0");
			dataCopy.set("workday.thursday24hr", thursday24hr ? "1" : "0");
			dataCopy.set("workday.friday24hr", friday24hr ? "1" : "0");
			dataCopy.set("workday.saturday24hr", saturday24hr ? "1" : "0");
			dataCopy.set("workday.sunday24hr", sunday24hr ? "1" : "0");

			const settings: Setting[] = [];

			dataCopy.forEach((value, key) => {
				settings.push({ key: key, value: value });
			});

			props.updateData(settings);
			navigate("/config/");
		}
	}

	function handleChange(key: string, newValue: string | undefined) {
		const dataCopy = new Map(data ?? []);

		if (newValue != null) {
			dataCopy.set(key, newValue);
		} else {
			dataCopy.set(key, undefined);
		}

		setData(dataCopy);
	}

	function cancelButtonClick() {
		navigate("/config");
	}

	return (
		props.data != null ?
			<div className="m-4">
				<div className="mb-3">
					<div style={{ fontSize: "24px", margin: "10px 0" }}>
						Workdays
					</div>
					<div>
						The hours set here define when the clock is running for SLAs.
					</div>
				</div>

				<form onSubmit={handleSubmit(formSubmit)} style={{ width: "40em" }} >
					<div className="flex items-center my-5">
						<div className="font-bold pt-1 w-28">
							<Tickbox
								label="Monday"
								value={mondayEnabled}
								valueName=""
								onChange={() => {dayToggle(mondayEnabled, setMondayEnabled, "workday.mondayStart", "workday.mondayFinish");}}
								inline
							/>
						</div>
						<div className="ml-10">
							<Timerange
								value={data?.get("workday.mondayStart") ?? ""}
								maxTime={data?.get("workday.mondayFinish") ?? ""}
								valueName="workday.mondayStart"
								disabled={!mondayEnabled || monday24hr}
								onChange={handleChange}
								inline
							/>
							<div className="inline mx-2">to</div>
							<Timerange
								value={data?.get("workday.mondayFinish") ?? ""}
								minTime={data?.get("workday.mondayStart") ?? ""}
								valueName="workday.mondayFinish"
								disabled={!mondayEnabled || monday24hr}
								onChange={handleChange}
								inline
							/>
						</div>
						<div className="inline mr-4 ml-10">or</div>
						<Tickbox
							label="24 hour"
							valueName=""
							onChange={() => setMonday24hr(!monday24hr)}
							value={monday24hr}
							disabled={!mondayEnabled}
							inline
						/>
					</div>

					<div className="flex items-center my-5">
						<div className="font-bold pt-1 w-28">
							<Tickbox
								label="Tuesday"
								value={tuesdayEnabled}
								valueName=""
								onChange={() => {dayToggle(tuesdayEnabled, setTuesdayEnabled, "workday.tuesdayStart", "workday.tuesdayFinish");}}
								inline
							/>
						</div>
						<div className="ml-10">
							<Timerange
								value={data?.get("workday.tuesdayStart") ?? ""}
								valueName="workday.tuesdayStart"
								disabled={!tuesdayEnabled || tuesday24hr}
								onChange={handleChange}
								inline
							/>
							<div className="inline mx-2">to</div>
							<Timerange
								value={data?.get("workday.tuesdayFinish") ?? ""}
								valueName="workday.tuesdayFinish"
								disabled={!tuesdayEnabled || tuesday24hr}
								onChange={handleChange}
								inline
							/>
						</div>
						<div className="inline mr-4 ml-10">or</div>
						<Tickbox
							label="24 hour"
							valueName=""
							onChange={() => setTuesday24hr(!tuesday24hr)}
							value={tuesday24hr}
							disabled={!tuesdayEnabled}
							inline
						/>
					</div>

					<div className="flex items-center my-5">
						<div className="font-bold pt-1 w-28">
							<Tickbox
								label="Wednesday"
								value={wednesdayEnabled}
								valueName=""
								onChange={() => {dayToggle(wednesdayEnabled, setWednesdayEnabled, "workday.wednesdayStart", "workday.wednesdayFinish");}}
								inline
							/>
						</div>
						<div className="ml-10">
							<Timerange
								value={data?.get("workday.wednesdayStart") ?? ""}
								valueName="workday.wednesdayStart"
								disabled={!wednesdayEnabled || wednesday24hr}
								onChange={handleChange}
								inline
							/>
							<div className="inline mx-2">to</div>
							<Timerange
								value={data?.get("workday.wednesdayFinish") ?? ""}
								valueName="workday.wednesdayFinish"
								disabled={!wednesdayEnabled || wednesday24hr}
								onChange={handleChange}
								inline
							/>
						</div>
						<div className="inline mr-4 ml-10">or</div>
						<Tickbox
							label="24 hour"
							valueName=""
							onChange={() => setWednesday24hr(!wednesday24hr)}
							value={wednesday24hr}
							disabled={!wednesdayEnabled}
							inline
						/>
					</div>

					<div className="flex items-center my-5">
						<div className="font-bold pt-1 w-28">
							<Tickbox
								label="Thursday"
								value={thursdayEnabled}
								valueName=""
								onChange={() => {dayToggle(thursdayEnabled, setThursdayEnabled, "workday.thursdayStart", "workday.thursdayFinish");}}
								inline
							/>
						</div>
						<div className="ml-10">
							<Timerange
								value={data?.get("workday.thursdayStart") ?? ""}
								valueName="workday.thursdayStart"
								disabled={!thursdayEnabled || thursday24hr}
								onChange={handleChange}
								inline
							/>
							<div className="inline mx-2">to</div>
							<Timerange
								value={data?.get("workday.thursdayFinish") ?? ""}
								valueName="workday.thursdayFinish"
								disabled={!thursdayEnabled || thursday24hr}
								onChange={handleChange}
								inline
							/>
						</div>
						<div className="inline mr-4 ml-10">or</div>
						<Tickbox
							label="24 hour"
							valueName=""
							onChange={() => setThursday24hr(!thursday24hr)}
							value={thursday24hr}
							disabled={!thursdayEnabled}
							inline
						/>
					</div>

					<div className="flex items-center my-5">
						<div className="font-bold pt-1 w-28">
							<Tickbox
								label="Friday"
								value={fridayEnabled}
								valueName=""
								onChange={() => {dayToggle(fridayEnabled, setFridayEnabled, "workday.fridayStart", "workday.fridayFinish");}}
								inline
							/>
						</div>
						<div className="ml-10">
							<Timerange
								value={data?.get("workday.fridayStart") ?? ""}
								valueName="workday.fridayStart"
								disabled={!fridayEnabled || friday24hr}
								onChange={handleChange}
								inline
							/>
							<div className="inline mx-2">to</div>
							<Timerange
								value={data?.get("workday.fridayFinish") ?? ""}
								valueName="workday.fridayFinish"
								disabled={!fridayEnabled || friday24hr}
								onChange={handleChange}
								inline
							/>
						</div>
						<div className="inline mr-4 ml-10">or</div>
						<Tickbox
							label="24 hour"
							valueName=""
							onChange={() => setFriday24hr(!friday24hr)}
							value={friday24hr}
							disabled={!fridayEnabled}
							inline
						/>
					</div>

					<div className="flex items-center my-5">
						<div className="font-bold pt-1 w-28">
							<Tickbox
								label="Saturday"
								value={saturdayEnabled}
								valueName=""
								onChange={() => {dayToggle(saturdayEnabled, setSaturdayEnabled, "workday.saturdayStart", "workday.saturdayFinish");}}
								inline
							/>
						</div>
						<div className="ml-10">
							<Timerange
								value={data?.get("workday.saturdayStart") ?? ""}
								valueName="workday.saturdayStart"
								disabled={!saturdayEnabled || saturday24hr}
								onChange={handleChange}
								inline
							/>
							<div className="inline mx-2">to</div>
							<Timerange
								value={data?.get("workday.saturdayFinish") ?? ""}
								valueName="workday.saturdayFinish"
								disabled={!saturdayEnabled || saturday24hr}
								onChange={handleChange}
								inline
							/>
						</div>
						<div className="inline mr-4 ml-10">or</div>
						<Tickbox
							label="24 hour"
							valueName=""
							onChange={() => setSaturday24hr(!saturday24hr)}
							value={saturday24hr}
							disabled={!saturdayEnabled}
							inline
						/>
					</div>

					<div className="flex items-center my-5">
						<div className="font-bold pt-1 w-28">
							<Tickbox
								label="Sunday"
								value={sundayEnabled}
								valueName=""
								onChange={() => {dayToggle(sundayEnabled, setSundayEnabled, "workday.sundayStart", "workday.sundayFinish");}}
								inline
							/>
						</div>
						<div className="ml-10">
							<Timerange
								value={data?.get("workday.sundayStart") ?? ""}
								valueName="workday.sundayStart"
								disabled={!sundayEnabled || sunday24hr}
								onChange={handleChange}
								inline
							/>
							<div className="inline mx-2">to</div>
							<Timerange
								value={data?.get("workday.sundayFinish") ?? ""}
								valueName="workday.sundayFinish"
								disabled={!sundayEnabled || sunday24hr}
								onChange={handleChange}
								inline
							/>
						</div>
						<div className="inline mr-4 ml-10">or</div>
						<Tickbox
							label="24 hour"
							valueName=""
							onChange={() => setSunday24hr(!sunday24hr)}
							value={sunday24hr}
							disabled={!sundayEnabled}
							inline
						/>
					</div>

					<TimezoneDropdown
						dataname="workday.timezone"
						value={data?.get("workday.timezone")}
						onChange={handleChange}
						label="Time zone"
						widthCss="420px"
						toolTip="The time zone your team is based in - SLAs won't be accurate without it."
					/>

					<div className="flex items-center">
						<Button className="" onClick={cancelButtonClick} label="Cancel" />
						<input type="submit" className="ml-2 btn-blue" value="Update" />
					</div>
				</form>
			</div>
			:
			null
	);
}
