import { ReactNode } from "react";
import { FormControl, FormHelperText, InputLabel, MenuItem, Select, SelectChangeEvent, SelectProps } from "@mui/material";
import { Except } from "type-fest";

export type DropdownOption<T extends number | string> = {
	label: ReactNode;
	value: T;
	disabled?: boolean;
};

export type NewDropdownProps<T extends number | string> = Except<SelectProps, "onChange"> & {
	value?: T;
	options: DropdownOption<T>[];
	onSelect?(value?: T): void;
	label?: ReactNode;
	helperText?: ReactNode;
};

/**
 * Composable dropdown component. T is the datatype (usually a number ID).
 */
export function NewDropdown<T extends number | string>(props: NewDropdownProps<T>) {

	function handleChange(newValue: SelectChangeEvent<unknown>) {
		if (props.onSelect != null) {
			props.onSelect(newValue.target.value as (T | undefined));
		}
	}

	const options: JSX.Element[] = [];

	if (!props.required) {
		options.push(
			<MenuItem value={undefined}>--</MenuItem>
		);
	}

	props.options.forEach(option => {
		options.push(
			<MenuItem
				key={option.value}
				value={option.value}
				disabled={option.disabled}
			>
				{option.label}
			</MenuItem>
		);
	});

	// Remove uneeded props.
	const propsCopy = { ...props };
	delete propsCopy.helperText;

	return (
		<FormControl
			fullWidth={props.fullWidth}
			sx={{
				minWidth: 300
			}}
		>
			<InputLabel>{props.label}</InputLabel>

			<Select
				{...propsCopy}
				value={props.value}
				label={props.label}
				onChange={handleChange}
				MenuProps={{
					anchorOrigin: { vertical: "bottom", horizontal: "left" },
					transformOrigin: { vertical: "top", horizontal: "left" },
				}}
			>
				{options}
			</Select>

			{props.helperText != null &&
				<FormHelperText>{props.helperText}</FormHelperText>}
		</FormControl>
	);
}
