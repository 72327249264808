import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { GlobalTab, GlobalTabId, HeaderTabEntities } from "@/Components/Header/GlobalTab";

interface GlobalTabsState {
	tabs: GlobalTab[];
}

interface TabReplacedPayload {
	currentTab: { id: number; entity: HeaderTabEntities };
	replaceTab: GlobalTab;
}

const initialState: GlobalTabsState = {
	tabs: []
};

export const globalTabsSlice = createSlice({
	name: "globalTabs",
	initialState,
	reducers: {
		globalTabAdded: (state, action: PayloadAction<GlobalTab>) => {
			// Check if tab is already here.
			const tabIndex = state.tabs.findIndex(tab => tab.id == action.payload.id && tab.entity == action.payload.entity);

			if (tabIndex == -1) {
				// We assume that new tabs are unpinned. So remove any other unpinned tabs when we add one.
				state.tabs = state.tabs.filter(tab => tab.pinned);

				state.tabs.push(action.payload);
			}
		},
		globalTabRemoved: (state, action: PayloadAction<{ id: number; entity: HeaderTabEntities }>) => {
			state.tabs = state.tabs.filter(tab => tab.id != action.payload.id || tab.entity != action.payload.entity);
		},

		globalTabUpdated: (state, action: PayloadAction<GlobalTab>) => {
			const tabIndex = state.tabs.findIndex(tab => tab.id == action.payload.id && tab.entity == action.payload.entity);

			if (tabIndex != -1) {
				// Merged with the existing one so we don't lose the pinned property.
				state.tabs[tabIndex] = { ...state.tabs[tabIndex], ...action.payload };
			} else {
				state.tabs.push(action.payload);
			}
		},

		globalTabReplaced: (state, action: PayloadAction<TabReplacedPayload>) => {
			const currentTab = action.payload.currentTab;

			const tabIndex = state.tabs.findIndex(tab => tab.id == currentTab.id && tab.entity == currentTab.entity);

			if (tabIndex != -1) {
				// Merged with the existing one so we don't lose the pinned property.
				state.tabs[tabIndex] = { ...state.tabs[tabIndex], ...action.payload.replaceTab };
			} else {
				state.tabs.push(action.payload.replaceTab);
			}
		},
		allGlobalTabsReplaced: (state, action: PayloadAction<GlobalTab[]>) => {
			state.tabs = action.payload;
		},

		globalTabPinned: (state, action: PayloadAction<GlobalTabId>) => {
			const tab = state.tabs.find(tab => tab.id == action.payload.id && tab.entity == action.payload.entity);

			if (tab != null) {
				tab.pinned = true;
			}
		},
		globalTabUnpinned: (state, action: PayloadAction<GlobalTabId>) => {
			const tab = state.tabs.find(tab => tab.id == action.payload.id && tab.entity == action.payload.entity);

			if (tab != null) {
				tab.pinned = false;
			}
		}
	}
});

export const { globalTabAdded, globalTabRemoved, globalTabUpdated, globalTabReplaced, allGlobalTabsReplaced, globalTabPinned, globalTabUnpinned } = globalTabsSlice.actions;

export default globalTabsSlice.reducer;
