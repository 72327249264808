import { ReactNode } from "react";

interface FormParentProps {
	title?: string;
	description?: string;
	children: ReactNode;
	/** Makes the parent expand to fill its container. */
	fullWidth?: boolean;
	/** Custom classes. Do not overuse this! We want form layout components as standard as possible. */
	className?: string;
}

/** Use this as the wrapper for a form. */
export function FormParent(props: FormParentProps) {
	const displayTitleDiv = props.title != null || props.description != null;

	const defaultWidth = "min-w-[25rem] max-w-[35rem]";

	return (
		<div className={`${props.fullWidth ? "w-full" : props.className != null ? props.className : defaultWidth}`}>
			{displayTitleDiv &&
				<div className="p-4 flex flex-col gap-3">
					{props.title &&
						<div className="text-2xl font-medium">
							{props.title}
						</div>}
					{props.description &&
						<div>
							{props.description}
						</div>}
				</div>}
			<div>
				{props.children}
			</div>
		</div>
	);
}
