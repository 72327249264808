import { Badge, IconButton, Popover } from "@mui/material";
import { bindMenu, bindTrigger, usePopupState } from "material-ui-popup-state/hooks";
import { NotificationsOutlined } from "@mui/icons-material";
import { getGetMyNotificationsQueryKey, getMyNotifications } from "@/Api/genApi";
import { NotificationCenter } from "@/Components/Header/NotificationCenter/NotificationCenter";
import { Notification } from "@/Api/genApi.schemas";
import { useGodeskInfiniteQuery } from "@/Hooks/useGodeskInfinateQuery";

export function NotificationCenterButton() {
	const popupState = usePopupState({ variant: "popover" });

	const { data, req, total } = useGodeskInfiniteQuery({
		queryKey: getGetMyNotificationsQueryKey(),
		queryFn: getMyNotifications
	});

	const notifications = data as Notification[] | undefined;

	return (
		<>
			<Badge
				badgeContent={total}
				color="primary"
				max={99}
				overlap="circular"
			>
				<IconButton
					{...bindTrigger(popupState)}
					disabled={!req.isSuccess}
				>
					<NotificationsOutlined />
				</IconButton>
			</Badge>

			{notifications != null &&
				<Popover
					{...bindMenu(popupState)}
					anchorOrigin={{
						vertical: "bottom",
						horizontal: "right",
					}}
					transformOrigin={{
						vertical: "top",
						horizontal: "right",
					}}
				>
					<NotificationCenter
						notifications={notifications}
						close={popupState.close}
						fetchNextPage={req.fetchNextPage}
						hasNextPage={req.hasNextPage}
					/>
				</Popover>}
		</>
	);
}
