import { CacheSlices } from "@/Store/Reducers/cache/CacheTypes";
import { useAgent } from "@/Hooks/useAgent";
import CacheDropdown from "../../../../Components/FormComponents/CacheDropdown/CacheDropdown";
import { Ticket } from "@shared/Entities/EntityTypes";

interface TicketAgentSectionProps {
	ticket: Ticket;
	handleSubmit(key: string, newValue: number | undefined): void;
}

export function TicketAgentSection(props: TicketAgentSectionProps) {
	const agent = useAgent();

	function setAgentToMe() {
		props.handleSubmit("agentId", agent.id);
	}

	const agentLabel = <div className="w-full flex justify-between">
		Agent
		<button onClick={setAgentToMe} className="blue-link underline">Me</button>
	</div>;

	return (
		<div className="border-t border-gray-300 p-3">
			<CacheDropdown
				label={agentLabel}
				value={props.ticket.agentId}
				dataname={"agentId"}
				onChange={props.handleSubmit}
				cacheSlice={CacheSlices.Agents}
			/>
			<CacheDropdown
				label="Team"
				mandatory
				value={props.ticket.teamId}
				dataname={"teamId"}
				onChange={props.handleSubmit}
				cacheSlice={CacheSlices.Teams}
			/>
		</div>
	);
}
