import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ConnectionStatuses } from "@shared/Enums/Enums";

interface ConnectionStatusState {
	status: ConnectionStatuses;
}

const initialState: ConnectionStatusState = {
	status: ConnectionStatuses.GREEN
};

export const connectionStatusSlice = createSlice({
	name: "connectionStatus",
	initialState,
	reducers: {
		statusUpdated: (state, action: PayloadAction<ConnectionStatuses>) => {
			state.status = action.payload;
		}
	}
});

export const { statusUpdated } = connectionStatusSlice.actions;

export default connectionStatusSlice.reducer;
