import { ReactNode } from "react";
import { useNumberParams } from "@/Hooks/useNumberParams";
import { Error404 } from "@/Components/Errors/Error404";

interface IdProvider {
	render(id: number): ReactNode;
}

/**
 * Makes sure a component always has a valid ID.
 * Displays a 404 error if not.
 */
export function IdProvider(props: IdProvider) {
	const { id } = useNumberParams();

	if (id == null) {
		return (
			<Error404 />
		);
	} else {
		return props.render(id);
	}
}
