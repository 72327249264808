import GenericListPage from "@/Pages/Config/_GenericListPage";
import { Entities } from "@shared/Entities/Entities";

export default function ManageChannelsEmail() {
	return (
		<GenericListPage
			urlTitle="Mailboxes | Config"
			title="Mailboxes"
			description="Add an email address here to start importing emails as tickets."
			entityType={Entities.CHANNELSEMAIL}
		/>
	);
}
