import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface AuthState {
	accessToken?: string;
	refreshToken?: string;
}

interface AuthPayload {
	accessToken: string;
	refreshToken: string;
}

function getAndDeleteCookie(key: string): string | undefined {
	// Find the cookie.
	const cookie = document.cookie
		.split("; ")
		.find((row) => row.startsWith(key + "="));

	// Delete the cookie.
	document.cookie = key + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";

	// Return the cookie or undefined.
	return cookie?.split("=")[1];
}

// Attempts to load auth from the cookies the new demo sets first. Then from local storage.
const initialState: AuthState = {
	accessToken: getAndDeleteCookie("DEMO_AUTH_ACCESS") ?? undefined,
	refreshToken: getAndDeleteCookie("DEMO_AUTH_REFRESH") ?? localStorage.getItem("refreshToken") ?? undefined
};

export const authSlice = createSlice({
	name: "auth",
	initialState,
	reducers: {
		authUpdated: (state, action: PayloadAction<AuthPayload>) => {
			return action.payload;
		},
		authCleared: state => {
			return { accessToken: undefined, refreshToken: undefined };
		}
	}
});

export const { authCleared, authUpdated } = authSlice.actions;

export default authSlice.reducer;
