import { useNavigate } from "react-router-dom";
import { AiOutlineDelete } from "react-icons/ai";
import { MdArrowBack } from "react-icons/md";
import { Button } from "../../Components/Button/Button";

interface EntityViewerToolbarProps {
	editmode: boolean;
	onEdit?(): void;
	onCancel(): void;
	onDelete?(): void;
	isNew: boolean;
	backUrl?: string;
	extraReadButtons?: JSX.Element;
	extraEditButtons?: JSX.Element;
	widthCss?: string;
	hideNewButton?: boolean;

	/** Changes the width: 35em to use maxWidth instead. */
	useMaxWidth?: boolean;
}

/**
 * This component had proven to be annoying to work with. Could explore alternatives...
 */
export function EntityViewerToolbar(props: EntityViewerToolbarProps) {
	const navigate = useNavigate();

	function backOnClick() {
		if (props.backUrl != null) {
			navigate(props.backUrl);
		}
	}

	const width = props.useMaxWidth != null ? undefined : props.widthCss != null ? props.widthCss : "35em";
	const maxWidth = props.useMaxWidth != null ? "35em" : undefined;

	const newButton = !props.hideNewButton && props.isNew &&
		<input type="submit" className="btn-blue" value="Create" />;

	const saveButton = !props.isNew &&
		<input type="submit" className="btn-blue" value="Save" />;

	return (
		<div className="py-2 px-4 bg-gray-100 flex justify-between border-b border-gray-300" style={{ width: width, maxWidth: maxWidth }}>
			<div className="grow">
				{props.backUrl != null ?
					<Button onClick={backOnClick} icon={<MdArrowBack />} label="Back" />
					: null}
			</div>
			<div className="flex items-center space-x-2">
				{props.editmode ?
					<>
						{props.extraEditButtons}
						<Button label="Cancel" className="" onClick={props.onCancel} />
						{saveButton}
						{newButton}
					</>
					:
					<>
						{props.extraReadButtons}
						{props.onDelete ?
							<Button className="" label="Delete" icon={<AiOutlineDelete />} onClick={props.onDelete} />
							: null}

						{props.onEdit ?
							<Button label="Edit" className="" onClick={props.onEdit} />
							: null}
					</>
				}
			</div>
		</div>
	);
}
