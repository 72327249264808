import { useEffect } from "react";
import { useForm } from "react-hook-form";
import W_CacheDropdown from "@/Components/FormComponents/CacheDropdown/W_CacheDropdown";
import { CacheSlices } from "@/Store/Reducers/cache/CacheTypes";
import { useCache } from "@/Hooks/useCache";
import { EmailServer } from "@/Pages/Config/Channels/ChannelsEmailViewer/EmailServer";
import { EntityViewerToolbar } from "@/Fragments/EntityViewer/EntityViewerToolbar";
import { FormError } from "@shared/Components/Form/FormError";
import { ChannelsEmail } from "@shared/Entities/EntityTypes";
import W_TextInput from "@shared/Components/FormComponents/TextInput/W_TextInput";
import { InputTypes } from "@shared/Components/FormComponents/TextInput/InputTypes";
import { FormParent } from "@shared/Components/Form/FormParent";
import { FormSection } from "@shared/Components/Form/FormSection";
import { FormHotkeys } from "@shared/Components/Utils/FormHotkeys";

interface EditEmailChannelProps {
	id: number;
	cancelEdit(): void;
	data?: ChannelsEmail;
	updateEntity(data: ChannelsEmail): Promise<boolean>;
	formError?: string;
}

export function EditEmailChannel(props: EditEmailChannelProps) {
	const { cache } = useCache();

	const { control, handleSubmit, reset, getValues, setValue, watch } = useForm<ChannelsEmail>({ defaultValues: props.data });

	useEffect(() => {
		// Override form data when data changes.
		reset(props.data);
	}, [props.data]);

	async function formSubmit(data: ChannelsEmail) {
		const updateSuccess = await props.updateEntity(data);

		if (updateSuccess) {
			props.cancelEdit();
		}
	}

	const name = watch("name");
	const address = watch("address");

	const tenantId = cache.getTenantId();

	let goDeskEmail = "support@" + tenantId + ".getgodesk.com";

	if (address != goDeskEmail) {
		goDeskEmail = address?.replaceAll("@", "").replaceAll(".", "") + "@" + tenantId + ".getgodesk.com";
	}

	return (
		<form onSubmit={handleSubmit(formSubmit)} style={{ width: "550px" }}>
			<FormHotkeys onSubmit={handleSubmit(formSubmit)} onCancel={props.cancelEdit} />

			<EntityViewerToolbar
				editmode
				isNew={false}
				backUrl="/config/manage/channels/email"
				onEdit={() => {}}
				onCancel={() => props.cancelEdit()}
				onDelete={() => {}}
				hideNewButton
				widthCss="550px"
			/>

			<FormParent title={name} className="w-[550px]">

				<FormError error={props.formError} />

				<FormSection>
					<W_TextInput
						control={control}
						dataname="address"
						label="Email"
						toolTip="The email address where customers send you support emails."
						placeholder="support@mycompany.com"
						type={InputTypes.Email}
						mandatory
						autocomplete="off"
					/>

					<W_TextInput
						control={control}
						dataname="name"
						label="Name"
						toolTip="Customer-friendly name for this mailbox"
						placeholder="E.g. Apple Support, Microsoft Billing"
						mandatory
						autoFocus
					/>

					<W_CacheDropdown
						control={control}
						dataname="teamId"
						label="Team"
						toolTip="Assign new incoming tickets from this channel to this team."
						cacheSlice={CacheSlices.Teams}
						mandatory
					/>

					<br />

					<EmailServer
						control={control}
						getValues={getValues}
						setValue={setValue}
						watch={watch}
						page={3}
						advancePage={() => {}}
						save={async () => {
							formSubmit(getValues());

							return undefined; // Just return undefined. Shouldn't matter anyway.
						}}
					/>
				</FormSection>
			</FormParent>
		</form>
	);
}
