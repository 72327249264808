import sanitizeHtmlLib from "sanitize-html";

export function sanitizeHtml(html: string | undefined): string {
	const attributes = { ...sanitizeHtmlLib.defaults.allowedAttributes };
	attributes["img"] = ["src", "alt", "width", "height"];
	attributes["a"] = attributes["a"].concat(["rel"]);

	const transforms = {
		"a": sanitizeHtmlLib.simpleTransform("a", { target: "_blank", rel: "noreferrer noopener" })
	};

	return sanitizeHtmlLib(html ?? "", {
		allowedTags: sanitizeHtmlLib.defaults.allowedTags.concat(["img"]),
		allowedAttributes: attributes,
		transformTags: transforms,
		exclusiveFilter: (frame: sanitizeHtmlLib.IFrame) => {
			// Removes 1x1 px tracking pixels.
			return frame.tag === "img" && (frame.attribs.width === "1" || frame.attribs.width === "1px") && (frame.attribs.height === "1" || frame.attribs.height === "1px");
		}
	});
}

export function removeAllHtml(html: string | undefined): string {
	return sanitizeHtmlLib(html ?? "", { allowedTags: [], allowedAttributes: {} });
}
