import { useState } from "react";
import saveAs from "file-saver";
import { Alert, Checkbox, FormControlLabel } from "@mui/material";
import { useCache } from "@/Hooks/useCache";
import { Button, ButtonBlue } from "@/Components/Button/Button";
import { useExportTickets } from "@/Api/genApi";
import { EnrichedCache } from "@/Store/Reducers/cache/CacheTypes";
import { TicketList } from "@/Api/genApi.schemas";
import { View } from "@shared/Models/View";
import { removeAllHtml } from "@shared/Helpers/HtmlFunctions";
import Modal from "@shared/Components/Modal/Modal";
import ModalContent from "@shared/Components/Modal/ModalContent";
import ModalButtons from "@shared/Components/Modal/ModalButtons";

interface ExportTicketsModalProps {
	isOpen: boolean;
	closeModal(): void;
	viewId?: string | number | undefined;
	view?: View;
}

export function ExportTicketsModal(props: ExportTicketsModalProps) {
	const [includeFirstUserAction, setIncludeFirstUserAction] = useState(false);
	const [errorMsg, setErrorMsg] = useState<string>();

	const { cache } = useCache();

	const exportTicketsReq = useExportTickets();

	async function runExport() {
		const viewFilters = cache.getView(props.viewId)?.filters ?? props.view?.filters;

		const exportName = cache.getView(props.viewId)?.name ?? props.view?.name ?? "Custom";

		if (viewFilters == null) {
			setErrorMsg("Could not find view.");
			return;
		}

		exportTicketsReq.mutate({ data: {
			includeFirstUserAction: includeFirstUserAction,
			filters: viewFilters as any
		} }, {
			onSuccess: data => {
				exportCsv(exportName, data, cache, includeFirstUserAction);
			},
			onError: error => {
				setErrorMsg(error.errorMsg);
			}
		});

	}

	return (
		<Modal isOpen={props.isOpen} onClose={props.closeModal} title="Ticket Export">
			<ModalContent>
				Run a ticket export to generate a CSV file of this ticket view's data.

				<Alert severity="warning">Only the first 5,000 tickets will be exported.</Alert>

				{errorMsg &&
					<Alert severity="error">{errorMsg}</Alert>}

				<div className="pt-5">
					<div className="font-medium">
						Settings
					</div>

					<FormControlLabel
						control={<Checkbox
							checked={includeFirstUserAction}
							onChange={event => setIncludeFirstUserAction(event.target.checked)}
						         />}
						label="Include first user message"
					/>
				</div>
			</ModalContent>

			<ModalButtons>
				<Button onClick={props.closeModal} label="Cancel" />
				<ButtonBlue loading={exportTicketsReq.isPending} onClick={runExport} label="Export" />
			</ModalButtons>
		</Modal>
	);
}

function exportCsv(exportName: string, data: TicketList[], cache: EnrichedCache, includeFirstUserAction: boolean) {
	let csv = "ID,Details,User name,User email,Status,Priority,SLA,Agent,Team,Category,Created (UTC)";

	if (includeFirstUserAction) {
		csv += ",First user action";
	}

	csv += "\n";

	data.forEach(ticket => {
		// Sanitise HTML.
		const firstUserActionNote = ticket.firstUserActionNote != null ? removeAllHtml(ticket.firstUserActionNote) : null;

		let ticketRow = [
			ticket.id,
			ticket.description,
			cache.getUser(ticket.userId)?.name,
			cache.getUser(ticket.userId)?.email,
			cache.getStatus(ticket.statusId)?.name,
			cache.getPriority(ticket.priorityId)?.name,
			cache.getSla(ticket.slaId)?.name,
			cache.getAgent(ticket.agentId)?.name,
			cache.getTeam(ticket.teamId)?.name,
			cache.getCategory(ticket.categoryId)?.name,
			ticket.customCreated,
			firstUserActionNote
		];

		// https://en.wikipedia.org/wiki/Comma-separated_values#Basic_rules

		ticketRow = ticketRow.map(value => {
			if (typeof value == "string") {
				// Remove new lines.
				value = value.replace(/(\r\n|\n|\r)/gm, " ");

				// Escape quotes and commas.
				if (value.includes("\"") || value.includes(",")) {
					return "\"" + value.replaceAll("\"", "\"\"") + "\"";
				}
			}

			return value;
		});

		csv += ticketRow.join(",") + "\n";
	});

	const blob = new Blob([csv], { type: "text/plain;charset=utf-8" });
	saveAs(blob, "GoDesk Tickets - " + exportName + ".csv");
}
