import { Checkbox, CheckboxProps } from "@mui/material";
import { Control, Controller, FieldPath, FieldValues } from "react-hook-form";
import { ControlError } from "@shared/Components/FormComponents/ControlError";

interface W_MUI_TickBoxProps<T extends FieldValues> extends CheckboxProps {
	control: Control<T>;
	dataname: FieldPath<T>;
}

export function W_MUI_TickBox<T extends FieldValues>(props: W_MUI_TickBoxProps<T>) {
	const rules = {}; // https://react-hook-form.com/api/useform/register

	return (
		<Controller
			control={props.control}
			name={props.dataname}
			rules={rules}
			render={({
				field: { onChange, value },
				fieldState: { error }
			}) => (
				<>
					<Checkbox
						checked={value}
						onChange={(_event, checked) => onChange(checked)}
						{...props}
					/>

					<ControlError error={error?.message} />
				</>
			)}
		/>
	);
}
