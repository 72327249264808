import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { InboxViewID } from "@/Store/Reducers/cache/HardCodedViews";
import { Layout } from "@/Api/genApi.schemas";
import { ViewVisibility } from "@shared/Enums/Enums";
import { TableSort } from "@shared/Enums/Enums";
import { View, ViewFilters } from "@shared/Models/View";

interface TicketsPageState {
	page: number;
	/** The ID of the currently active view on the ticket screen. */
	viewId: number | string;
	/**
	 * The filters of the currently active view on the ticket screen.
	 * These are stored separately so the agent can edit the filters for a view without us losing track of the active view.
	 */
	customFilters: ViewFilters | null;
	/** Tracks if a user has touched the customFilters. Used to display a "(custom)" label next to the view. */
	customFiltersDirty: boolean;

	sort?: TableSort;
	/**
	 * The custom view we are currently creating / editing. Can contain either a new view or an existing one (check the ID).
	 * It DOES NOT contain filters. These are stored in customFilters.
	 */
	customView: View | null;

	layout: Layout | null;
}

const initialState: TicketsPageState = {
	page: 0,
	viewId: InboxViewID,
	customFiltersDirty: false,
	customFilters: null,
	sort: {
		sortField: "created",
		sortType: "desc"
	},
	customView: null,
	layout: null
};

export const ticketsPageSlice = createSlice({
	name: "ticketsPage",
	initialState,
	reducers: {
		ticketsSortUpdated: (state, action: PayloadAction<TableSort>) => {
			state.sort = action.payload;
		},
		ticketsSortCleared: state => {
			state.sort = undefined;
		},
		pageChanged: (state, action: PayloadAction<number>) => {
			state.page = action.payload;
		},
		viewChanged: (state, action: PayloadAction<View>) => {
			if (action.payload.id != null) {
				state.viewId = action.payload.id;

				// Reset page.
				state.page = 0;

				// Reset custom filters if they exist.
				if (state.customFilters != null) {
					state.customFilters = action.payload.filters;
					state.customFiltersDirty = false;
				}
			}
		},

		customViewCreated: (state, action: PayloadAction<void>) => {
			state.customView = {
				filters: {},
				visibility: ViewVisibility.PRIVATE
			};
		},

		customViewUpdated: (state, action: PayloadAction<View>) => {
			state.customView = action.payload;
		},

		customViewCleared: (state, action: PayloadAction<void>) => {
			state.customView = null;
		},

		// For when the filter panel is opened or a new view is selected.
		customFilterOpened: (state, action: PayloadAction<ViewFilters>) => {
			state.customFilters = action.payload;
			state.customFiltersDirty = false;
		},

		// For when the user edits the filters.
		customFilterApplied: (state, action: PayloadAction<ViewFilters>) => {
			state.customFilters = action.payload;
			state.customFiltersDirty = true;
		},

		customFilterCleared: (state) => {
			state.customFilters = null;
			state.customFiltersDirty = false;
		},

		/** Clears all the custom stuff. */
		customFilterPanelCleared: (state) => {
			state.customFilters = null;
			state.customFiltersDirty = false;

			state.customView = null;
		},

		layoutApplied: (state, action: PayloadAction<Layout>) => {
			state.layout = action.payload;
		},

		layoutCleared: (state) => {
			state.layout = null;
		},
	}
});

export const {
	pageChanged, viewChanged, customFilterOpened, customFilterApplied, customFilterCleared, customViewCreated,
	customViewCleared, customViewUpdated, ticketsSortUpdated, ticketsSortCleared, customFilterPanelCleared, layoutApplied,
	layoutCleared
} = ticketsPageSlice.actions;

export default ticketsPageSlice.reducer;
