import { BoolFilter, NumberFilter, TextFilter } from "@/Api/genApi.schemas";
import { UserSearchCriteria } from "@/Components/UserSearch/UserSearchResults";
import { GD_SUPPORT_AGENT_ID } from "@shared/Enums/Enums";

type FilterParam = { [key: string]: BoolFilter | NumberFilter | TextFilter };

export function generateFilterParam(criteria: UserSearchCriteria): FilterParam {
	const filters: FilterParam = {};

	if (criteria.name != null && criteria.name != "") {
		filters["name"] = {
			type: "text",
			comparison: "contains",
			value: criteria.name
		};
	}

	if (criteria.email != null && criteria.email != "") {
		filters["email"] = {
			type: "text",
			comparison: "contains",
			value: criteria.email
		};
	}

	if (criteria.companyId != null) {
		filters["companyId"] = {
			type: "number",
			comparison: "equals",
			value: criteria.companyId
		};
	}

	if (criteria.entity == "agent") {
		filters["isAgent"] = {
			type: "bool",
			comparison: "equals",
			value: true
		};
	}

	if (criteria.isPrimaryContact == true) {
		filters["isPrimaryContact"] = {
			type: "bool",
			comparison: "equals",
			value: true
		};
	}

	filters["disabled"] = {
		type: "bool",
		comparison: "equals",
		value: false
	};

	filters["deleted"] = {
		type: "bool",
		comparison: "equals",
		value: false
	};

	filters["id"] = {
		type: "number",
		comparison: "notEqual",
		value: GD_SUPPORT_AGENT_ID
	};

	return filters;
}
