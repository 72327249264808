import { ReactNode } from "react";
import { BusinessOutlined } from "@mui/icons-material";
import { useCache } from "@/Hooks/useCache";
import { Ticket } from "@shared/Entities/EntityTypes";
import { CustomFieldEntity, CustomFieldInputType } from "@shared/Enums/Enums";

interface TicketCustomFieldSectionProps {
	ticket: Ticket;
}

export function TicketCustomFieldSection(props: TicketCustomFieldSectionProps) {
	const { cache } = useCache();

	const ticketCompany = cache.getCompany(props.ticket.companyId);

	const customFields = cache.CustomFields.filter(cf => cf.entity == CustomFieldEntity.COMPANY);

	if (ticketCompany == null || ticketCompany.customValues == null || Object.keys(ticketCompany.customValues).length == 0) {
		return;
	}

	const cfs: ReactNode[] = [];

	for (const field in ticketCompany.customValues) {
		const value = ticketCompany.customValues[field];

		const fieldDef = customFields.find(cf => cf.name == field);

		if (fieldDef == null) {
			continue;
		} else if (fieldDef.type == CustomFieldInputType.TEXT_INPUT) {
			cfs.push(
				<div className="text-slate-700">
					{fieldDef.label}:
				</div>
			);

			cfs.push(
				<div className="text-slate-700">
					{value}
				</div>
			);
		} else if (fieldDef.type == CustomFieldInputType.DROPDOWN) {
			cfs.push(
				<div className="text-slate-700">
					{fieldDef.label}:
				</div>
			);

			const asNumber = Number(value);

			const valueLabel = fieldDef.options?.find(option => option.id == asNumber)?.label;

			cfs.push(
				<div className="text-slate-700">
					{valueLabel}
				</div>
			);
		}
	}

	return (
		<>
			<div className="border-t border-gray-300 text-center py-3">CUSTOM FIELDS</div>

			<div className="border-t border-gray-300 p-3">
				<div className="flex gap-1">
					<BusinessOutlined fontSize="small" className="text-slate-700" />
					<div className="font-medium mb-2">Company</div>
				</div>

				<div className="grid grid-cols-2 gap-2">
					{cfs}
				</div>
			</div>
		</>
	);
}
