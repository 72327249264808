import { AiOutlineClose } from "react-icons/ai";
import { IoIosWarning, IoIosCloseCircle } from "react-icons/io";
import Tooltip from "@mui/material/Tooltip";
import ModalBase, { ModalBaseProps } from "@shared/Components/Modal/ModalBase";
import Keys from "@shared/Components/Key/Keys";
import Key from "@shared/Components/Key/Key";

interface ModalProps extends ModalBaseProps {
	title: React.ReactNode;
	titleIcon?: React.ReactNode;
	/** Positions this modal at the top of the screen. */
	positionAtTop?: boolean;
	/** Styles the header and adds an icon */
	type?: "warning" | "error";
}

/**
 * Modal with a title and buttons. Use this to create most other modals.
 * Use this with the ModalContent and ModalButtons components.
 */
export default function Modal(props: ModalProps) {
	let modalStylesOverrides = props.modalStyle;

	if (props.positionAtTop) {
		modalStylesOverrides = { ...modalStylesOverrides, position: "absolute", top: "10%" };
	}

	const icon = props.titleIcon ?? (props.type == "warning" ? <IoIosWarning className="text-[#ffc800]" /> : props.type == "error" ? <IoIosCloseCircle className="text-[#d1322e]" /> : undefined);

	const borderTopClassName = props.type == "warning" ? "bg-[#ffc800] rounded-t-md" : props.type == "error" ? "bg-[#d1322e] rounded-t-md" : undefined;

	return (
		<ModalBase
			modalStyle={modalStylesOverrides}
			{...props}
		>
			{ borderTopClassName != null &&
				<div className={`h-1 w-full ${borderTopClassName}`} />}

			{ props.title != null &&
				<div className={"flex justify-between p-4 border-b bg-gray-50 rounded-t-md"}>
					<div className="text-xl font-medium flex items-center gap-2 grow">
						{icon}

						{props.title}
					</div>

					<Tooltip title={<Keys label="Close" keys={[<Key>Esc</Key>]} />} placement="right">
						<div onClick={props.onClose} className="hover:bg-gray-100 rounded-md p-2 flex self-center cursor-pointer">
							<AiOutlineClose size={21} />
						</div>
					</Tooltip>
				</div>}

			{props.children}
		</ModalBase>
	);
}
