import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { MdOutlineCallMerge } from "react-icons/md";
import toast from "react-hot-toast";
import { HeaderTabEntities } from "@/Components/Header/GlobalTab";
import { useMergeTickets } from "@/Api/genApi";
import { globalTabAdded } from "../../../../Store/Reducers/globalTabsSlice";
import TicketList from "./TicketList";
import { useAppDispatch } from "../../../../Store/hooks";
import Modal from "@shared/Components/Modal/Modal";
import ModalContent from "@shared/Components/Modal/ModalContent";
import ModalButtons from "@shared/Components/Modal/ModalButtons";
import { Ticket } from "@shared/Entities/EntityTypes";

interface MergeTicketsModalProps {
	isOpen: boolean;
	onClose(): void;
	selectedTickets: Ticket[];
}

export default function MergeTicketsModal(props: MergeTicketsModalProps) {
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const [ticketList, setTicketsList] = useState<Ticket[]>([]);

	const mergeTicketsReq = useMergeTickets({ mutation: {
		// TODO: Invalidate ticket cache.
	} });

	useEffect(() => {
		/*
		Creating a deep-copy of selected tickets using JSON func,
		so that we can mutate the list while preserving the originally selected list.
		*/
		setTicketsList(JSON.parse(JSON.stringify(props.selectedTickets)));
	}, [props.isOpen, props.selectedTickets]);

	async function submitMerge() {
		if (ticketList != null) {
			const toastId = toast.loading("Merging tickets...");

			const primaryTicket = ticketList[0];
			const mergedTicketIds: number[] = ticketList.slice(1).map(ticket => ticket.id);

			mergeTicketsReq.mutate({ data: { ownerTicketId: primaryTicket.id, mergedTicketIds: mergedTicketIds } }, {
				onSuccess: () => {
					toast.success("Tickets merged!", { id: toastId });

					dispatch(
						globalTabAdded({
							id: primaryTicket.id,
							title: primaryTicket.description ? primaryTicket.description : "",
							entity: HeaderTabEntities.TICKETS,
						})
					);

					navigate("/tickets/" + primaryTicket.id);

					props.onClose();
				},
				onError: error => {
					toast.error("Could not merge tickets: " + error.errorMsg, { id: toastId });
				}
			});
		}
	}

	return (
		<Modal
			isOpen={props.isOpen}
			titleIcon={<MdOutlineCallMerge />}
			title="Merge tickets"
			onClose={props.onClose}
			suppressAnimation // Animation currently makes list items slide in from the right
		>
			<ModalContent>
				<div className="mb-6">
					<span className="font-semibold">{ticketList.length} tickets</span> selected.
					<br /><br />
					Actions from secondary tickets will be added to the primary ticket.
					<br /><br />
					CC and BCC lists will be merged. Users on the merged tickets will be added to the CC list.
				</div>

				<TicketList ticketList={ticketList} setTicketList={setTicketsList} />
			</ModalContent>
			<ModalButtons>
				<button onClick={props.onClose} className="btn-grey">Cancel</button>
				<button className="btn-blue" onClick={submitMerge}>Merge</button>
			</ModalButtons>
		</Modal>
	);
}
