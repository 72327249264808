import { Box } from "@mui/material";
import { useForm } from "react-hook-form";
import { SearchOutlined } from "@mui/icons-material";
import { CacheSlices } from "@/Store/Reducers/cache/CacheTypes";
import UserSearchResults, { UserSearchCriteria } from "@/Components/UserSearch/UserSearchResults";
import { User } from "@/Api/genApi.schemas";
import W_CacheDropdown from "@/Components/FormComponents/CacheDropdown/W_CacheDropdown";
import { W_TextField } from "@shared/Components/FormComponents/W_TextField/W_TextField";
import Modal from "@shared/Components/Modal/Modal";
import { W_RadioGroup } from "@shared/Components/FormComponents/W_RadioGroup/W_RadioGroup";
import { W_Tickbox } from "@shared/Components/FormComponents/W_Tickbox/W_Tickbox";

interface FindUserModalProps {
	onUserSelect(user: User): void;
	onClose(): void;
}

/**
 * Modal to help agents find users.
 */
export default function FindUserModal(props: FindUserModalProps) {
	const { control, watch } = useForm<UserSearchCriteria>({ defaultValues: {
		entity: "user"
	} });

	const criteria = watch();

	return (
		<Modal
			isOpen={true}
			onClose={props.onClose}
			title="Find User"
			className="min-h-[480px]"
			titleIcon={<SearchOutlined />}
		>
			<Box sx={{ display: "flex", maxWidth: "800px" }}>
				<Box sx={{ width: "40%", borderRight: "1px solid #ddd", px: 2, mt: 1 }}>
					<W_RadioGroup
						control={control}
						name="entity"
						row
						options={[
							{ label: "Users", value: "user" },
							{ label: "Agents", value: "agent" }
						]}
					/>

					<Box sx={{ mt: 2 }}>
						<W_TextField
							control={control}
							name="name"
							variant="outlined"
							size="small"
							sx={{ mb: 2, width: 287 }}
							label="Name"
						/>

						<W_TextField
							control={control}
							name="email"
							variant="outlined"
							size="small"
							sx={{ width: 287 }}
							label="Email"
						/>

						<W_CacheDropdown
							control={control}
							cacheSlice={CacheSlices.Companies}
							dataname="companyId"
							label="Company"
						/>

						<W_Tickbox
							control={control}
							name="isPrimaryContact"
							label="Primary contact"
							className="mt-2"
						/>
					</Box>
				</Box>

				<UserSearchResults
					criteria={criteria}
					onUserSelect={props.onUserSelect}
				/>
			</Box>
		</Modal>
	);
}
