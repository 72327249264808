import { ReactNode, useId, useMemo } from "react";

export type RadioOption<T> = {
	label: ReactNode;
	value: T;
};

export interface RadioProps<T> {
	value: T;
	dataname: string;
	options: RadioOption<T>[];
	onChange(key: string, value: T): void;
	// label?: ReactNode;
	disabled?: boolean;
}

export function Radio<T>(props: RadioProps<T>) {
	const controlId = useId();

	const options = useMemo(() => props.options.map((option, index) => {
		function onChangeValue(event: any) {
			const optionArrayPos = event.target.value;

			const optionValue = props.options[optionArrayPos].value;

			props.onChange(props.dataname, optionValue);
		}

		const label = <label htmlFor={controlId}>
			{option.label}
		</label>;

		return <div key={index}>
			<input
				id={controlId}
				type="radio"
				value={index}
				name={props.dataname}
				checked={props.value == option.value}
				onChange={onChangeValue}
				className="mr-2"
			/>

			{label}
		</div>;
	}), [props]);

	return (
		<div className="flex flex-wrap gap-5">
			{options}
		</div>
	);
}
