import { Link } from "react-router-dom";
import { Button } from "@/Components/Button/Button";

export function TicketAiSection() {
	return (
		<div className="border-t border-gray-300 p-3">
			<div className="bg-[#EEECEC] p-4 rounded-lg">
				<div className="flex items-center font-semibold text-xl">
					<img className="h-7 mr-3" src="/robot-emoji.png" />
					Knowledge Base assistant
				</div>
				<div className="my-3">
					AI Powered articles drafted for you. Save time and supercharge your ticket deflection.
				</div>
				<div className="flex justify-center">

					<Link to="/config/ai/assistant" className="w-full">
						<Button label="Get started" btnClass="btn-purple" className="w-full place-content-center" />
					</Link>
				</div>
			</div>
		</div>
	);
}
