import { ApiUploadImageAttachment } from "@/legacy/ApiCallerOld";

/**
 * Using the images_upload_url method caused spring security problems. This function posts the blob as done in other image uploads in the program.
 *
 * This may cause issues with webpack dev server. Will pickup new files and cause a refresh (dev env only) if static.watch: false is not set in webpackDevServer.config.js.
 */
export function getImageUploadHandler(tenantId: string) {
	return async function imageUploadHandler(blobInfo: any, success: (url: string) => void, failure: (err: string, options?: any) => void) {
		const res = await ApiUploadImageAttachment(blobInfo.base64(), blobInfo.blob().type);

		if (res.errorMsg != null || res.data == null) {
			failure(res.errorMsg ?? "");
		} else {
			let url = "";

			let apiUrl = "https://" + window.location.hostname;

			if (process.env.NODE_ENV == "development" || window.location.hostname == "localhost") {
				apiUrl = ""; // Just use relative paths for local dev.
			}

			// TODO: These shouldn't really be in the embedded dir. They're just sent to the user as <img src="X" />.
			// Maybe I should be embedding them!
			if (tenantId != null) {
				url = apiUrl + "/attachments/" + tenantId + "/embedded/" + res.data;
			} else {
				url = apiUrl + "/attachments/default/embedded/" + res.data;
			}

			success(url);
		}
	};
}
