import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TableSort } from "@shared/Enums/Enums";

interface EntityTableState {
	sort?: TableSort;
}

const initialState: EntityTableState = {

};

export const entityTableSlice = createSlice({
	name: "entityTable",
	initialState,
	reducers: {
		sortUpdated: (state, action: PayloadAction<TableSort>) => {
			state.sort = action.payload;
		},
		sortCleared: state => {
			state.sort = undefined;
		}
	}
});

export const { sortUpdated, sortCleared } = entityTableSlice.actions;

export default entityTableSlice.reducer;
