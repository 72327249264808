import LoadingButton from "@mui/lab/LoadingButton";
import toast from "react-hot-toast";
import { useResendAgentActions } from "@/Api/genApi";
import { FormParent } from "@shared/Components/Form/FormParent";
import { FormSection } from "@shared/Components/Form/FormSection";

export function GoDeskSupportPage() {

	const resendAgentActionsReq = useResendAgentActions({ mutation: {
		onSuccess: () => toast.success("Resent!"),
		onError: error => toast.error("Could not resend: " + error.message)
	} });

	return (
		<FormParent
			title="GoDesk Support"
			description="Custom actions for our team to use."
		>
			<FormSection>

				<div>
					<LoadingButton
						loading={resendAgentActionsReq.isPending}
						variant="contained"
						onClick={() => resendAgentActionsReq.mutate()}
					>
						Resend agent actions
					</LoadingButton>
				</div>
			</FormSection>
		</FormParent>
	);
}
