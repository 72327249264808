import Dropdown, { DropdownProps } from "@shared/Components/FormComponents/Dropdown/Dropdown";

interface StatusDropdownProps extends Omit<DropdownProps<number>, "options"> {
}

export function StatusDropdown(props: StatusDropdownProps) {
	const HardCodedOptions = [
		{ label: "Open", value: 1 },
		{ label: "With User", value: 2 },
		{ label: "Closed", value: 3 }
	];

	return (
		<Dropdown<number>
			options={HardCodedOptions}
			{...props}
		/>
	);
}
