import { DateTime } from "luxon";
import { ChannelsEmail } from "@shared/Entities/EntityTypes";

/**
 * @returns Returns if a Microsoft channel appears to be all good. Or null if it is not Microsoft.
 */
export function getMicrosoftStatus(channel: ChannelsEmail): boolean | null {
	if (!channel.isMicrosoft) {
		return null;
	}

	return getMicrosoftError(channel) == null;
}

/**
 * @returns Any errors with a Microsoft channel. Or null if there are none.
 */
export function getMicrosoftError(channel: ChannelsEmail): string | null {
	if (!channel.isMicrosoft) {
		return null;
	}

	if (!channel.hasMsTokens || channel.msSubscriptionId == null) {
		return "Invalid. Please reconfigure or contact support.";
	}

	// Check sub expiry.
	const nowUTC = DateTime.now();

	if (channel.msSubscriptionExpires != null && DateTime.fromISO(channel.msSubscriptionExpires) > nowUTC) {
		return null;
	} else {
		return "Subscription has expired. Please sign in with Microsoft again.";
	}
}
