import { Control, Controller } from "react-hook-form";
import CacheDropdown, { CacheDropdownProps } from "./CacheDropdown";
import { ControlError } from "@shared/Components/FormComponents/ControlError";

interface W_CacheDropdownProps extends Omit<CacheDropdownProps, "value"> {
	control: Control<any>;
}

/**
 * @see W_TextInput
 */
export default function W_CacheDropdown(props: W_CacheDropdownProps) {
	const rules: any = {}; // https://react-hook-form.com/api/useform/register

	if (props.mandatory) {
		rules.required = { value: true, message: "This is a required field." };
	}

	return (
		<Controller
			control={props.control}
			name={props.dataname}
			rules={rules}
			render={({
				field: { onChange, value },
				fieldState: { error },
			}) => (
				<>
					<CacheDropdown
						{...props}
						onChange={(_key, value) => {
							onChange(value);

							if (props.onChange != null) {
								props.onChange(value as any);
							}
						} }
						value={value}
						invalid={error != null}
					/>

					<ControlError error={error?.message} />
				</>
			)}
		/>
	);
}
