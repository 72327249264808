import { Alert } from "@mui/material";
import { DateTime } from "luxon";
import { useGetDashboardReport } from "@/Api/genApi";
import { ReportDashboard } from "@/Api/genApi.schemas";
import { StatBox } from "@/Pages/Reporting/StatBox";

interface ResponseTimesProps {
	dateRange: [DateTime, DateTime];
}

export function ResponseTimes(props: ResponseTimesProps) {
	const dashboardReq = useGetDashboardReport({ dateFrom: props.dateRange[0].toISODate(), dateTo: props.dateRange[1].toISODate() });

	if (dashboardReq.isLoading) {
		return null;
	}

	if (dashboardReq.error || dashboardReq.data == null) {
		return <Alert severity="error">
			Could not load dashboard.
		</Alert>;
	}

	return (<>
		<div className="text-2xl my-5 text-center">
			Response Times
		</div>

		<div className="flex flex-wrap my-10">
			<ResponseStatBoxes homeStats={dashboardReq.data} />
		</div>

		<div className="flex flex-wrap my-10">
			<AgentResponseStatBoxes homeStats={dashboardReq.data} />
		</div>
	</>);
}

interface StatBoxesProps {
	homeStats: ReportDashboard;
}

function ResponseStatBoxes(props: StatBoxesProps) {
	return (
		<>
			<StatBox
				name="Avg. First Response Time"
				tooltip={<div>The <span className="font-semibold">median</span> time it took for the first agent to reply for tickets logged within the period.<br /><br /><span className="italic">Use this to help evaluate your overall support experience.</span></div>}
				valueTimeMins={props.homeStats?.avgResponseTimeRawMins}
			/>
			<StatBox
				name="Average Response Time"
				tooltip={<div>The <span className="font-semibold">median</span> agent response time on tickets logged within the period.<br /><br /><span className="italic">Use this to help evaluate your overall support experience.</span></div>}
				valueTimeMins={props.homeStats?.avgResponseTimeRawMins}
			/>
			<StatBox
				name="Avg. First Response Time (Working Hours)"
				tooltip={<div>The <span className="font-semibold">median</span> time it took for the first agent to reply for tickets logged within the period. Only counts time elapsed during working hours.<br /><br /><span className="italic">Use this to help evaluate your agent's performance.</span></div>}
				valueTimeMins={props.homeStats?.avgFirstResponseTimeWorkingMins}
			/>
			<StatBox
				name="Average Response Time (Working Hours)"
				tooltip={<div>The <span className="font-semibold">median</span> agent response time on tickets logged within the period. Only counts time elapsed during working hours.<br /><br /><span className="italic">Use this to help evaluate your agent's performance.</span></div>}
				valueTimeMins={props.homeStats?.avgResponseTimeWorkingMins}
			/>
		</>
	);
}

function AgentResponseStatBoxes(props: StatBoxesProps) {
	return (
		<>
			<StatBox
				name="Avg. Agent Responses per Ticket"
				tooltip={<div>The <span className="font-semibold">mean</span> agent responses per closed ticket.<br /><br /><span className="italic">Use this to help evaluate your agent's performance.</span></div>}
				value={props.homeStats?.avgAgentResponsesPerClosedTicket}
			/>

			<StatBox
				name="Percentage of One Touch Closes"
				tooltip={<div>The percentage of tickets that were closed after only one agent response.<br />Calculations do not include tickets closed with 0 agent responses.<br /><br /><span className="italic">Use this to help evaluate your agent's performance.</span></div>}
				valuePerc={props.homeStats?.oneTouchClosedTicketsPercent}
			/>
		</>
	);
}
