import { ReactNode } from "react";

interface ControlLabelProps {
	label?: ReactNode;
	mandatory?: boolean;
	controlId?: string;
	helperText?: ReactNode;
	invalid?: boolean;
}

export function ControlLabel(props: ControlLabelProps) {
	if (props.label == null && props.helperText == null) {
		return;
	}

	const labelMandatory = props.mandatory == true ? <span className="text-red-600 ml-1">*</span> : null;
	const labelCssInvalid = props.invalid ? "text-red-700" : "";
	
	return (
		<label htmlFor={props.controlId} className={"block text-sm mb-1 " + labelCssInvalid}>
			<div>{props.label}{labelMandatory}</div>

			{props.helperText &&
				<div className="text-sm text-slate-600 mb-0">
					{props.helperText}
				</div>}
		</label>
	);
}
