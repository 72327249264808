import { Layout, Sla } from "@/Api/genApi.schemas";
import { CustomField } from "../../../../../shared/src/Entities/EntityTypes";
import { SettingString, SettingInt, SettingBool } from "@shared/Enums/SettingEnums";
import { View } from "@shared/Models/View";
import { Agent, CannedReply, Category, ChannelsEmail, Company, Kb, KbFolder, Priority, Setting, Status, Team, User } from "@shared/Entities/EntityTypes";

/**
 * TODO: This is basically a copy of entities. Should probably merge them.
 */
export enum CacheSlices {
	Agents,
	Categories,
	Channels,
	Companies,
	CustomFields,
	Kbs,
	KbFolders,
	Layouts,
	Priorities,
	Settings,
	Slas,
	Statuses,
	Teams,
	Users,
	Views,
}

export interface AppCache {
	hash: string;

	/** The ID of the user that requested the cache. This is a workaround so that generateHardCodedViews can have access to the 'Me' agent. */
	userId: number | null;

	Agents: Agent[];
	CannedReplies: CannedReply[];
	Categories: Category[];
	Channels: ChannelsEmail[]; // May need to change to superclass when we add more channel types.
	Companies: Company[];
	CustomFields: CustomField[];
	Kbs: Kb[];
	KbSections: KbFolder[];
	Layouts: Layout[];
	Priorities: Priority[];
	Settings: Setting[];
	Slas: Sla[];
	Statuses: Status[];
	Teams: Team[];
	Users: User[];
	Views: View[];

	// Plugins

}

/** An extention of {@link AppCache} with lots of helper functions. */
export interface EnrichedCache extends AppCache {
	getAgent(id?: number): Agent | undefined;
	getCategory(id?: number): Category | undefined;
	getChannel(id?: number): ChannelsEmail | undefined;
	getCompany(id?: number): Company | undefined;
	getCustomField(id?: number): CustomField | undefined;
	getKb(id?: number): Kb | undefined;
	getKbFolder(id?: number): KbFolder | undefined;
	getLayout(id?: number): Layout | undefined;
	getPriority(id?: number): Priority | undefined;
	getSla(id?: number): Sla | undefined;
	getStatus(id?: number): Status | undefined;
	getTeam(id?: number): Team | undefined;
	getUser(id?: number): User | undefined;
	getView(id?: string | number): View | undefined;

	/**  Do not use this directly! Use an enum one. */
	getSettingValue(key: string): string | undefined | null;
	getSettingString(key: SettingString): string | null;
	getSettingInt(key: SettingInt): number | null;
	getSettingBool(key: SettingBool): boolean | null;

	/** Helper method to get the tenant ID. */
	getTenantId(): string;
	/** Helper method to get the tenant's pictures dir. (With trailing slash.) */
	getAttachmentsDir(): string;
	/** Helper method to get the tenant's pictures dir. (With trailing slash.) */
	getPicturesDir(): string;
	/**
	 * Returns the full domain and path of the portal. E.g. help.customer.com/support/
	 * Use this for links to the portal within the agent app.
	 */
	getPortalURL(): string;

	/** Checks if the given email is currently in use by a Channel. */
	emailUsedByChannel(email: string): boolean;
}
