import { SlaForm } from "@/Pages/Config/SLA/SlaForm";
import { useGetOneSLAs } from "@/Api/genApi";

interface SlaViewerProps {
	id: number;
}

export default function SlaViewer(props: SlaViewerProps) {
	const getSlaReq = useGetOneSLAs(props.id, { query: {
		staleTime: Infinity
	} });

	if (getSlaReq.isPending || getSlaReq.isError || getSlaReq.data == null) {
		return;
	}

	return (
		<SlaForm
			id={props.id}
			defaultData={getSlaReq.data}
		/>
	);
}
