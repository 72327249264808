import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { generateHardCodedViews } from "@/Store/Reducers/cache/HardCodedViews";
import { AppCache } from "@/Store/Reducers/cache/CacheTypes";

// Reducer

const initialState: AppCache = {
	hash: "",

	userId: null,

	Agents: [],
	CannedReplies: [],
	Categories: [],
	Channels:	[],
	Companies: [],
	CustomFields: [],
	Kbs:	[],
	KbSections: [],
	Layouts: [],
	Priorities: [],
	Settings: [],
	Slas: [],
	Statuses: [],
	Teams: [],
	Users: [],
	Views: [],

	// Plugins

};

export const cacheSlice = createSlice({
	name: "cache",
	initialState,
	reducers: {
		cacheUpdated: (state, action: PayloadAction<AppCache>) => {
			if (state.hash == action.payload.hash) {
				return state;
			} else {
				// Inject hard coded views.
				const hardCodedViews = generateHardCodedViews(action.payload.Teams, action.payload.Agents, action.payload.userId);
				action.payload.Views = (action.payload.Views ?? []).concat(hardCodedViews);

				return action.payload;
			}
		}
	}
});

export const { cacheUpdated } = cacheSlice.actions;

export default cacheSlice.reducer;
