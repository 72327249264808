import { Route, Routes } from "react-router-dom";
import EntityViewer from "@/legacy/EntityViewer";
import { AiAssistantPage } from "@/Pages/Config/AiAssistantPage";
import { Cname } from "@/Pages/Config/Cname";
import { AgentViewer } from "@/Pages/Config/Agents/AgentViewer";
import NotFound404 from "@/Pages/Auth/NotFound404";
import SettingsPage, { SettingPages } from "@/Pages/Config/_SettingsPage";
import ConfigTreeView from "@/Pages/Config/ConfigTreeView";
import ManageCategories from "@/Pages/Config/ManageCategories";
import ManageSlas from "@/Pages/Config/SLA/ManageSlas";
import ManageViews from "@/Pages/Config/ManageViews";
import { AgentViewerNew } from "@/Pages/Config/Agents/AgentViewerNew";
import { IdProvider } from "@/Routes/IdProvider";
import { AgentNotifications } from "@/Pages/Config/Notifications/AgentNotifications";
import Account from "@/Pages/Config/Account/AccountPage";
import ManageAgents from "@/Pages/Config/Agents/ManageAgents";
import ManageTeams from "@/Pages/Config/Teams/ManageTeams";
import { TeamViewer } from "@/Pages/Config/Teams/TeamViewer";
import ManageChannelsEmail from "@/Pages/Config/Channels/ManageChannelsEmail";
import { ChannelsEmailViewer } from "@/Pages/Config/Channels/ChannelsEmailViewer/ChannelsEmailViewer";
import ManageCannedReplies from "@/Pages/Config/CannedReplies/ManageCannedReplies";
import { CannedReplyViewer } from "@/Pages/Config/CannedReplies/CannedReplyViewer";
import ManageAutomations from "@/Pages/Config/Automations/ManageAutomations";
import { NewSla } from "@/Pages/Config/SLA/NewSla";
import SlaViewer from "@/Pages/Config/SLA/SlaViewer";
import { Sheet } from "@/Components/Sheet";
import { GoDeskSupportPage } from "@/Pages/Config/GoDeskSupportPage";
import { Entities } from "@shared/Entities/Entities";

export default function ConfigParent() {

	return (
		<div className="flex" style={{ height: "100%" }}>
			<div className="w-72 border-r border-gray-300 shrink-0">
				<ConfigTreeView />
			</div>
			<Sheet className="h-full w-full overflow-auto">
				<Routes>
					<Route path="/manage/agents" element={<ManageAgents />} />
					<Route path="/manage/agents/:id" element={<AgentViewer />} />
					<Route
						path="/manage/agentsnew/:id"
						element={
							<IdProvider render={id => <AgentViewerNew id={id} />} />
						}
					/>

					<Route path="/manage/cannedreplies" element={<ManageCannedReplies />} />
					<Route path="/manage/cannedreplies/:id" element={<CannedReplyViewer />} />

					<Route path="/manage/slas" element={<ManageSlas />} />
					<Route path="/manage/slas/new" element={<NewSla />} />
					<Route path="/manage/slas/:id" element={<IdProvider render={id => <SlaViewer id={id} />} />} />

					<Route path="/manage/categories" element={<ManageCategories />} />

					<Route path="/manage/channels/email" element={<ManageChannelsEmail />} />
					<Route path="/manage/channels/email/:id" element={<ChannelsEmailViewer />} />

					<Route path="/manage/teams" element={<ManageTeams />} />
					<Route path="/manage/teams/:id" element={<TeamViewer />} />

					<Route path="/manage/account" element={<Account />} />

					<Route path="/manage/views" element={<ManageViews />} />
					<Route path="/manage/views/:id" element={<EntityViewer entityType={Entities.VIEW} />} />

					<Route path="/automations" element={<ManageAutomations />} />
					<Route path="/automations/:id" element={<EntityViewer entityType={Entities.AUTOMATION} />} />

					<Route path="/email/notifications" element={<SettingsPage page={SettingPages.EMAILNOTIFICATIONS} />} />
					<Route path="/helpdesk" element={<SettingsPage page={SettingPages.HELPDESK} />} />
					<Route path="/workday" element={<SettingsPage page={SettingPages.WORKDAY} />} />
					<Route path="/ai/assistant" element={<AiAssistantPage />} />
					<Route path="/cname" element={<Cname />} />
					<Route path="/branding" element={<SettingsPage page={SettingPages.BRANDING} />} />
					<Route path="/notifications" element={<AgentNotifications />} />
					<Route path="/godesk-support" element={<GoDeskSupportPage />} />

					{/* 404 */}
					<Route path="*" element={<NotFound404 link="/" />} />
				</Routes>
			</Sheet>
		</div>
	);
}
