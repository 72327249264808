import { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";
import { BiTrash } from "react-icons/bi";
import { BsPencil } from "react-icons/bs";
import toast from "react-hot-toast";
import { PiPlusBold } from "react-icons/pi";
import api from "@/Api/Api";
import { useCache } from "@/Hooks/useCache";
import { AgentAvatar } from "@/Components/Avatar/AgentAvatar";
import { useAgent } from "@/Hooks/useAgent";
import { Button } from "@/Components/Button/Button";
import DeleteWarningModal from "../../../../Components/Utility/Modals/DeleteWarningModal";
import { Entities } from "@shared/Entities/Entities";
import { CompanyNote } from "@shared/Entities/EntityTypes";
import { datetimeToLocaleString, datetimeToWhenString } from "@shared/Helpers/DateFunctions";
import HtmlEditor from "@shared/Components/FormComponents/HtmlEditor/HtmlEditor";
import Key from "@shared/Components/Key/Key";
import Keys from "@shared/Components/Key/Keys";
import { FormHotkeys } from "@shared/Components/Utils/FormHotkeys";


interface NotesTabProps {
	companyId: number;
}

export function NotesTab(props: NotesTabProps) {

	const [companyNotes, setCompanyNotes] = useState<CompanyNote[]>();

	const [newCompanyNote, setNewCompanyNote] = useState(false);
	const [companyNoteContent, setCompanyNoteContent] = useState<string>();
	const [newCompanyNoteLoading, setNewCompanyNoteLoading] = useState<boolean>(false);

	const agent = useAgent();

	const doFetch = useCallback(async () => {
		const res = await api.getEntities<CompanyNote>(Entities.COMPANY_NOTE,
			1000,
			0,
			[{ colId: "created", sort: "desc" }],
			{ companyId: { comparison: "equals", type: "number", value: props.companyId } });

		if (res.successful && res.data != null) {
			setCompanyNotes(res.data);
		} else {
			setCompanyNotes(undefined);
		}
	}, [props.companyId]);

	useEffect(() => {
		doFetch();
	}, [doFetch]);

	async function createCompanyNote(note?: string) {
		const noteToUse = note ?? companyNoteContent;

		if (noteToUse != null) {
			const companyNote: CompanyNote = { companyId: props.companyId, note: noteToUse, creatorId: agent.id };

			setNewCompanyNoteLoading(true);

			const res = await api.createEntity<CompanyNote>(Entities.COMPANY_NOTE, companyNote);

			setNewCompanyNoteLoading(false);

			if (res.successful) {
				toast.success("Created note");
				setNewCompanyNote(false);
				setCompanyNoteContent(undefined);
				doFetch();
			} else {
				toast.error("Could not create note");
			}
		}
	}

	function onCancel() {
		setNewCompanyNote(false);
		setCompanyNoteContent("");
	}

	return (
		<div className="mb-3 mx-3 flex flex-col h-full ">
			<div className="py-2 flex flex-row-reverse">
				<Button
					btnClass="btn-blue"
					label="New"
					tooltip="Create a new note"
					icon={<PiPlusBold />}
					onClick={() => setNewCompanyNote(true)}
				/>
			</div>
			<div className={`shadow-sm border rounded-md p-3 gap-y-3 flex flex-col items-center grow h-0 overflow-y-auto ${companyNotes == undefined && "h-full"} `}>
				{newCompanyNote &&
					<>
						<span className="text-xl font-normal">Create a new company note</span>
						<FormHotkeys onSubmit={createCompanyNote} onCancel={onCancel} />
						<HtmlEditor
							dataname="note"
							handleCancel={onCancel}
							handleChange={(_value, newValue) => setCompanyNoteContent(newValue)}
							handleSubmit={createCompanyNote}
							value={companyNoteContent ?? ""}
							resizable
						/>
						<div>
							<Tooltip placement="top" title={<Keys label="Cancel" keys={[<Key>Esc</Key>]} />}>
								<button onClick={onCancel} className="btn-grey mr-2">Cancel</button>
							</Tooltip>

							<Tooltip placement="top" title={<Keys label="Create" keys={[<Key>Ctrl</Key>, <Key>Enter</Key>]} />}>
								<button onClick={() => createCompanyNote()} disabled={newCompanyNoteLoading} className="btn-blue">Create</button>
							</Tooltip>
						</div>
					</>
				}

				{companyNotes?.map(companyNote =>
					<Note
						companyNote={companyNote}
						doFetch={doFetch}
						key={companyNote.id}
					/>)}
			</div>
		</div>
	);
}

interface NoteProps {
	companyNote: CompanyNote;
	doFetch(): Promise<void>;
}

export function Note(props: NoteProps) {
	const [editNote, setEditNote] = useState(false);
	const [newNoteContent, setNewNoteContent] = useState<string>();
	const [newNoteContentLoading, setNewNoteContentLoading] = useState(false);
	const [deleteWarningModal, setDeleteWarningModal] = useState(false);

	const { cache } = useCache();
	const noteCreator = cache.getAgent(props.companyNote.creatorId);

	const agent = useAgent();

	async function onDelete() {
		if (props.companyNote.id != null) {
			const res = await api.deleteEntity(Entities.COMPANY_NOTE, props.companyNote.id);

			if (res.successful) {
				toast.success("Deleted company note");
				props.doFetch();
			} else {
				toast.error("Could not delete company note");
				setDeleteWarningModal(false);
			}
		}

	}

	async function updateCompanyNote(note?: string) {
		const noteToUse = note ?? newNoteContent;

		if (noteToUse != null) {
			const companyNote: CompanyNote = {
				id: props.companyNote.id,
				companyId: props.companyNote.companyId,
				note: noteToUse,
				creatorId: props.companyNote.creatorId };

			if (props.companyNote.id != null) {

				setNewNoteContentLoading(true);

				const res = await api.updateEntity(Entities.COMPANY_NOTE, props.companyNote.id, companyNote);

				setNewNoteContentLoading(false);
				if (res.successful) {
					toast.success("Updated company note");
					setEditNote(false);
					setNewNoteContent(undefined);
					props.doFetch();
				} else {
					toast.error("Could not update company note");
					setEditNote(false);
					setNewNoteContent(undefined);
				}
			}
		}
	}

	function onCancel() {
		setNewNoteContent(props.companyNote.note);
		setEditNote(false);
	}

	return (
		<>
			<div className="bg-[#FFF7ED] w-[600px] p-4">
				<div className="flex">
					<div className="mr-2">
						<AgentAvatar agent={noteCreator} widthPx={35} />
					</div>
					<div className="flex-grow">
						<div className="font-semibold text-gray-700" style={{ width: "fit-content" }}>
							<Link to={`/config/manage/agents/${props.companyNote.creatorId}`} className="blue-link">
								{noteCreator?.name}
							</Link>
						</div>
						<div>
							<div className="text-gray-500">
								<span className="italic" title={datetimeToLocaleString(props.companyNote.created)}>{datetimeToWhenString(props.companyNote.created)}</span>
							</div>
						</div>
					</div>
					<div>
						{!editNote &&
							<Tooltip title="Edit" placement="bottom">
								<button onClick={() => setEditNote(!editNote)} className="border border-gray-300 bg-white rounded p-1 w-7 h-7 mr-1"><BsPencil /></button>
							</Tooltip>

						}

						{noteCreator?.id == agent.id || agent.isAdmin ?
							<Tooltip title="Delete" placement="bottom">
								<button onClick={() => setDeleteWarningModal(true)} className="border border-gray-300 bg-white rounded p-1 w-7 h-7 mr-1"><BiTrash /></button>
							</Tooltip>
							: null}
					</div>
				</div>

				{editNote ?
					<>
						<FormHotkeys onSubmit={updateCompanyNote} onCancel={onCancel} />
						<HtmlEditor
							dataname="note"
							handleCancel={onCancel}
							handleChange={(_value, newValue) => setNewNoteContent(newValue)}
							handleSubmit={updateCompanyNote}
							value={newNoteContent ?? props.companyNote.note}
						/>
						<div className="flex justify-center">
							<Tooltip placement="top" title={<Keys label="Cancel" keys={[<Key>Esc</Key>]} />}>
								<button onClick={onCancel} className="btn-grey mr-2">Cancel</button>
							</Tooltip>

							<Tooltip placement="top" title={<Keys label="Update" keys={[<Key>Ctrl</Key>, <Key>Enter</Key>]} />}>
								<button onClick={() => updateCompanyNote()} disabled={newNoteContentLoading} className="btn-blue">Update</button>
							</Tooltip>
						</div>
					</>
					:
					<div dangerouslySetInnerHTML={{ __html: props.companyNote.note }}></div>
				}
			</div>

			<DeleteWarningModal message="Are you sure you want to delete this company note." isOpen={deleteWarningModal} onCancel={() => setDeleteWarningModal(false)} onDelete={onDelete} />
		</>
	);
}
