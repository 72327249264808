import { useState, useEffect, ReactNode, useId } from "react";
import AsyncSelect from "react-select/async";
import { Options, OptionsOrGroups, StylesConfig } from "react-select";
import api from "@/Api/Api";
import { Entities } from "@shared/Entities/Entities";
import { View } from "@shared/Models/View";
import { ControlLabel } from "@shared/Components/FormComponents/ControlLabel";

type ValueOption = {
	label: string;
	value: number | undefined;
};

interface ApiDropdownProps {
	value?: number | null;
	label?: ReactNode;
	toolTip?: ReactNode;
	dataname: string;
	onChange(key: string, newValue: number | undefined | null): void;
	entity: Entities;
	view?: View;
	/** The field to allow users to search on. Must be written like the obj prop. E.g. agentName */
	searchableField: string;
	mandatory?: boolean;
	widthCss?: string;
	disabled?: boolean;
}

/**
 * A dropdown that makes API calls. Can be passed a view to filter results.
 */
export default function ApiDropdown(props: ApiDropdownProps) {
	const controlId = useId();
	const [options, setOptions] = useState<Options<ValueOption> | undefined>();
	const [value, setValue] = useState<number | undefined | null >(props.value);

	// ValueLabel is needed as a state because the number of records may be too high to find the label in the options array.
	const [valueLabel, setValueLabel] = useState("");

	useEffect(() => {
		setValue(props.value);
	}, [props.value]);

	useEffect(() => {
		fetchData();

		async function fetchData() {
			const labelProperty = "name";

			const defaultView: View = { filters: {
				disabled: { type: "bool", comparison: "equals", value: false },
				deleted: { type: "bool", comparison: "equals", value: false }
			} };

			if (props.entity == Entities.AGENT) {
				defaultView.filters.id = { type: "number", comparison: "notEqual", value: 3 }; // GoDesk support agent
			}

			// Override defaultView filters with props view filters.
			const filters = { ...defaultView.filters, ...props.view?.filters };

			const response = await api.getEntities(props.entity, 100, 0, undefined, filters);

			// Create options array.
			const options: ValueOption[] = [];

			if (props.mandatory != true) {
				options.push({ value: undefined, label: "--" });
			}

			if (response != null && response.data != null) {
				response.data.forEach((element: any) => {
					options.push({ value: element.id, label: element[labelProperty] });
				});
			}

			setOptions(options);
		}
	}, [props.entity, props.view, props.mandatory]);

	useEffect(() => {
		fetchValue();

		async function fetchValue() {
			if (props.value != null) {
				const labelProperty = "name";

				const response = await api.getEntity<any>(props.entity, props.value);

				if (response != null && response.data != null) {
					setValueLabel(response.data[labelProperty]);
				}
			} else {
				setValueLabel("--");
			}
		}
	}, [props.value, props.entity, props.mandatory]);

	function onChange(data: any) {
		setValue(data.value);
		props.onChange(props.dataname, data.value);
	}

	function searchOptions(inputValue: string): Promise<OptionsOrGroups<typeof Option, any>> {
		return new Promise(async resolve => {
			const labelProperty = "name";

			const searchFilters: View = { filters: {
				disabled: { type: "bool", comparison: "equals", value: false },
				deleted: { type: "bool", comparison: "equals", value: false }
			} };

			if (props.entity == Entities.AGENT) {
				searchFilters.filters.id = { type: "number", comparison: "notEqual", value: 3 }; // GoDesk support agent
			}

			searchFilters.filters[props.searchableField] = { type: "text", comparison: "contains", value: inputValue };

			// Override props view filters with search filters.
			const filters = { ...props.view?.filters, ...searchFilters.filters };

			const response = await api.getEntities(props.entity, 100, 0, undefined, filters);

			// Create options array.
			const options: { value: number | undefined; label: string }[] = [];

			if (props.mandatory != true) {
				options.push({ value: undefined, label: "--" });
			}

			if (response != null && response.data != null) {
				response.data.forEach((element: any) => {
					options.push({ value: element.id, label: element[labelProperty] });
				});
			}

			resolve(options);
		});
	}

	const customStyles: StylesConfig = {
		dropdownIndicator: (provided) => ({
			...provided,
			padding: "5px 7px",
		}),
		indicatorSeparator: (provided) => ({
			...provided,
			display: "none",
		}),
		menu: (provided) => ({
			...provided,
			margin: "1px",
		}),
	};

	const dropdownValue = { value: value, label: valueLabel };

	const styles = { width: props.widthCss };

	return (
		<div className="Form_Component" style={styles}>
			<ControlLabel
				label={props.label}
				mandatory={props.mandatory}
				helperText={props.toolTip}
				controlId={controlId}
			/>

			<AsyncSelect
				inputId={controlId}
				value={dropdownValue}
				onChange={onChange}
				defaultOptions={options}
				loadOptions={searchOptions}
				isSearchable={props.searchableField != null}
				styles={customStyles}
				isDisabled={props.disabled}
			/>
		</div>
	);
}
