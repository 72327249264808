import { BiCopy, BiDotsVerticalRounded, BiPencil } from "react-icons/bi";
import { AiOutlineDelete } from "react-icons/ai";
import { ListItemIcon, ListItemText, Menu, MenuItem } from "@mui/material";
import { usePopupState, bindTrigger, bindMenu } from "material-ui-popup-state/hooks";

interface ViewButtonContextMenuProps {
	onEdit(): void;
	onDuplicate(): void;
	onDelete(): void;
}

export function ViewButtonContextMenu(props: ViewButtonContextMenuProps) {
	const popupState = usePopupState({ variant: "popover" });

	return (
		<>
			<div
				className="absolute right-3 flex items-center rounded ellipsis bg-gray-100 hover:bg-gray-300 view-button-menu-button"
				{...bindTrigger(popupState)}
			>
				<BiDotsVerticalRounded size={22} />
			</div>

			<Menu {...bindMenu(popupState)}>
				<MenuItem onClick={() => {
					popupState.close();
					props.onEdit();
				}}
				>
					<ListItemIcon>
						<BiPencil />
					</ListItemIcon>
					<ListItemText>Edit</ListItemText>
				</MenuItem>

				<MenuItem onClick={() => {
					popupState.close();
					props.onDuplicate();
				}}
				>
					<ListItemIcon>
						<BiCopy />
					</ListItemIcon>
					<ListItemText>Duplicate</ListItemText>
				</MenuItem>

				<MenuItem onClick={() => {
					popupState.close();
					props.onDelete();
				}}
				>
					<ListItemIcon>
						<AiOutlineDelete />
					</ListItemIcon>
					<ListItemText>Delete</ListItemText>
				</MenuItem>
			</Menu>
		</>
	);
}
