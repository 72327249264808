import { ErrorBoundary as SentryErrorBoundary } from "@sentry/react";

interface ErrorBoundaryProps {
	children?: React.ReactNode;
}

export function ErrorBoundary(props: ErrorBoundaryProps) {

	return (
		<SentryErrorBoundary
			fallback={
				<div className="w-full h-full pt-[10%] flex justify-center">
					<div className="p-5">
						An error has occurred. Please <a href="/home" className="cursor-pointer underline text-blue-400">refresh</a> the app or contact support at: <a href="mailto:support@getgodesk.com" className="underline">support@getgodesk.com</a>.
					</div>
				</div>
			}
		>
			{props.children}
		</SentryErrorBoundary>
	);
}
