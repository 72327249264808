import axios from "axios";
import toast from "react-hot-toast";
import { authCleared, authUpdated } from "@/Store/Reducers/authSlice";
import { isJwtExpired, parseJwt } from "@/Store/jwtParser";
import store from "@/Store/store";
import { getApiUrl } from "@/Api/ApiUrl";
import { ResCreate, ResGetOne } from "@/legacy/ApiResponse";

const apiUrl = getApiUrl();

// Payloads
interface TokenResponse {
	accessToken: string;
	refreshToken: string;
}

interface TokenRefreshResponse {
	accessToken: string;
}

/**
 * @param authUsername Note this is the AUTH's username: the user/agent's email address.
 */
export async function AuthGetTokens(authUsername: string, authPassword: string): Promise<ResCreate<TokenResponse>> {
	const auth = {
		auth: {
			username: authUsername,
			password: authPassword
		}
	};

	return axios.post<TokenResponse>(apiUrl + "token", null, auth)
		.then(response => {
			const jwt = parseJwt(response.data.accessToken);

			if (jwt != null && jwt.sub == authUsername.toUpperCase()) {
				return {
					data: response.data,
					successful: true,
					wasNetworkError: false
				};
			} else {
				return {
					successful: false,
					wasNetworkError: false,
					errorMsg: "Unspecified login error."
				};
			}
		})
		.catch(error => {
			if (error.response) {
			// client received an error response (5xx, 4xx)
				return {
					successful: false,
					wasNetworkError: false,
					errorCode: error.response.status,
					errorMsg: error.response.data?.message ?? ""
				};
			} else {
			// anything else
				return {
					successful: false,
					wasNetworkError: true,
					errorMsg: "Unspecified login error."
				};
			}
		});
}

export async function AuthRefreshAccessToken(refreshToken: string): Promise<ResCreate<TokenRefreshResponse>> {
	const config = {
		headers: {
			Authorization: "Bearer " + refreshToken
		}
	};

	return axios.post<TokenResponse>(apiUrl + "refresh-token", null, config)
		.then(response => {
			const jwt = parseJwt(response.data.accessToken);

			if (response.data != null && jwt != null) {
				return {
					data: response.data,
					successful: true,
					wasNetworkError: false,
				};
			} else {
				return {
					successful: false,
					wasNetworkError: false,
					errorMsg: "Unspecified login error."
				};
			}
		})
		.catch(error => {
			if (error.response) {
			// client received an error response (5xx, 4xx)
				return {
					successful: false,
					wasNetworkError: false,
					errorCode: error.response.status,
					errorMsg: error.response.data?.message ?? ""
				};
			} else {
			// anything else
				return {
					successful: false,
					wasNetworkError: true,
					errorMsg: "Unspecified login error."
				};
			}
		});
}

export async function AuthLogout(): Promise<ResGetOne<null>> {
	const authToken = getAccessTokenFromStore();

	const options = {
		headers: {
			Authorization: "Bearer " + authToken
		}
	};

	store.dispatch(authCleared());

	return axios.post(apiUrl + "clear-tokens", null, options)
		.then(function () {
			return {
				successful: true,
				wasNetworkError: false
			};
		})
		.catch(error => {
			if (error.response) {
			// client received an error response (5xx, 4xx)
				return {
					successful: false,
					wasNetworkError: false,
					errorCode: error.response.status,
					errorMsg: error.response.data?.message ?? ""
				};
			} else {
			// anything else
				return {
					successful: false,
					wasNetworkError: true,
					errorMsg: "Unspecified log out error."
				};
			}
		});
}

/**
 * Convenience function to get the access token from Redux.
 */
export function getAccessTokenFromStore(): string | undefined {
	return store.getState()?.auth.accessToken;
}

/**
 * Convenience function to get the refresh token from Redux.
 */
export function getRefreshTokenFromStore(): string | undefined {
	return store.getState()?.auth.refreshToken;
}

/**
 * Tries to return a valid access token for an API call to use. Will request new tokens from the API or trigger a logout if necessary.
 */
export async function getAccessToken(): Promise<string | undefined> {
	const accessToken = getAccessTokenFromStore();

	// Check if access token is valid.
	if (accessToken != null && !isJwtExpired(accessToken)) {
		return accessToken;
	} else {
		console.info("Access token doesn't exist or is expired. Using refresh token.");

		// Attempt to get a new token.
		const refreshToken = getRefreshTokenFromStore();

		// Check refresh token is valid.
		if (refreshToken != null && !isJwtExpired(refreshToken)) {
			console.info("Refresh token good. Using it to fetch access token.");

			// Refresh access token.
			const refreshRes = await AuthRefreshAccessToken(refreshToken);

			if (refreshRes.successful && refreshRes.data?.accessToken != null) {
				const newAccessToken = refreshRes.data.accessToken;

				console.info("Good refresh token response.");
				store.dispatch(authUpdated({ accessToken: newAccessToken, refreshToken: refreshToken }));

				return newAccessToken;
			} else if (refreshRes.errorCode == 403) {
				// Logout.
				console.info("Bad refresh token response: 403.");
				store.dispatch(authCleared());
				return;
			} else if (refreshRes.wasNetworkError) {
				console.info("Network error.");
				toast.error("Network error.");
				return;
			} else {
				console.info("Unspecified network error.");
				toast.error("Unspecified network error.");
				return;
			}
		} else {
			// Logout.
			console.info("Refresh token bad. Logging out.");
			store.dispatch(authCleared());
			return;
		}
	}
}
