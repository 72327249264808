import { useGetOneLayouts, useUpdateLayouts } from "@/Api/genApi";
import { Layout } from "@/Api/genApi.schemas";
import { LayoutModal } from "@/Components/Layouts/LayoutModal/LayoutModal";
import { useCache } from "@/Hooks/useCache";
import { AlertBox } from "@shared/Components/AlertBox/AlertBox";
import Modal from "@shared/Components/Modal/Modal";
import { Entities } from "@shared/Entities/Entities";

interface LayoutModalEditProps {
	id: number;
	close(): void;
}

export function LayoutModalEdit(props: LayoutModalEditProps) {
	const { refreshCache } = useCache();

	const { data, isLoading, error } = useGetOneLayouts(props.id, { query: {
		staleTime: Infinity
	} });

	const updateLayout = useUpdateLayouts();

	function onSubmit(layout: Layout) {
		updateLayout.mutate(
			{ id: props.id, data: layout },
			{
				onSuccess: async () => {
					await refreshCache();
					props.close();
				}
			}
		);
	}

	if (isLoading) {
		return (
			<Modal isOpen={true} onClose={props.close} title="Layout" >
				Loading...
			</Modal>
		);
	}

	if (error != null) {
		return (
			<Modal isOpen={true} onClose={props.close} title="Layout" >
				<AlertBox alertType={"error"}>
					Could not edit layout: {error.errorMsg}
				</AlertBox>
			</Modal>
		);
	}

	return (
		<LayoutModal
			entity={Entities.TICKET}
			close={props.close}
			data={data}
			onSubmit={onSubmit}
			isSubmitting={updateLayout.isPending}
			error={updateLayout.error}
		/>
	);
}
