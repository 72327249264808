import { FaCircle, FaMinusCircle, FaDotCircle } from "react-icons/fa";
import { Menu, MenuItem } from "@mui/material";
import { IoMdArrowDropdown } from "react-icons/io";
import { usePopupState, bindTrigger, bindMenu } from "material-ui-popup-state/hooks";
import { useAgent } from "@/Hooks/useAgent";
import { agentDetailsChanged } from "@/Store/Reducers/loggedInAgentSlice";
import { useAppDispatch } from "@/Store/hooks";
import { ApiUpdateEntity } from "@/legacy/ApiCallerOld";
import { Entities } from "@shared/Entities/Entities";
import { AgentStatus } from "@shared/Enums/Enums";
import { Agent } from "@shared/Entities/EntityTypes";

const labelClass = "flex items-center gap-1";

const label_offline = <div className={labelClass}><FaCircle color="green" /> OFFLINE</div>;
const label_available = <div className={labelClass}><FaCircle color="green" /> Available</div>;
const label_idle = <div className={labelClass}><FaCircle color="#FAA61A" /> Idle</div>;
const label_dnd = <div className={labelClass}><FaMinusCircle color="red" /> Do Not Disturb</div>;
const label_ooo = <div className={labelClass}><FaDotCircle color="grey" /> Out Of Office</div>;

export function AgentStatusSelector() {
	const dispatch = useAppDispatch();
	const agent = useAgent();

	const popupState = usePopupState({ variant: "popover" });

	let toggleLabel = null;

	switch (agent.status) {
		case AgentStatus.OFFLINE: toggleLabel = label_offline; break;
		case AgentStatus.AVAILABLE: toggleLabel = label_available; break;
		case AgentStatus.IDLE: toggleLabel = label_idle; break;
		case AgentStatus.DND: toggleLabel = label_dnd; break;
		case AgentStatus.OOO: toggleLabel = label_ooo; break;
		default: toggleLabel = label_offline; break;
	}

	async function onSelect(statusId: string) {
		// Post updated agent.
		const agentCopy = { ...agent };
		agentCopy.status = parseInt(statusId);

		const ApiResponse = await ApiUpdateEntity<Agent>(Entities.AGENT, agent.id, agentCopy);

		if (ApiResponse.data != null) {
			// Update LoggedInAgent
			dispatch(agentDetailsChanged(ApiResponse.data));
		}

		popupState.close();
	}

	return (
		<>
			<button
				{...bindTrigger(popupState)}
				className="flex items-center gap-1"
			>
				{toggleLabel} <IoMdArrowDropdown />
			</button>

			<Menu {...bindMenu(popupState)}>
				<MenuItem onClick={() => onSelect(AgentStatus.AVAILABLE.toString())}>
					{label_available}
				</MenuItem>

				<MenuItem onClick={() => onSelect(AgentStatus.IDLE.toString())}>
					{label_idle}
				</MenuItem>

				<MenuItem onClick={() => onSelect(AgentStatus.DND.toString())}>
					{label_dnd}
				</MenuItem>

				<MenuItem onClick={() => onSelect(AgentStatus.OOO.toString())}>
					{label_ooo}
				</MenuItem>
			</Menu>
		</>
	);
}
