
import { Route, Routes as ReactRoutes } from "react-router-dom";
import { DesignFormComponents } from "@/Pages/Design/DesignFormComponents";
import { ApiDocs } from "@/Pages/ApiDocs";
import { AgentRegister } from "@/Pages/Auth/Register/AgentRegister";
import ParentPage from "./ParentPage";
import Login from "../Pages/Auth/Login/Login";
import Logout from "../Pages/Auth/Logout";
import Design from "../Pages/Design/Design";
import { RequireAgentAuth } from "./RequireAgentAuth";
import PasswordReset from "../Pages/Auth/PasswordReset/PasswordReset";

export default function Routes() {
	return (
		<ReactRoutes>
			{/* Auth pages */}
			<Route path="/login" element={<Login />} />
			<Route path="/logout" element={<Logout />} />
			<Route path="/password-reset" element={<PasswordReset />} />
			<Route path="/register" element={<AgentRegister />} />

			{/* Design system pages */}
			<Route path="/design" element={<Design />} />
			<Route path="/design/form-components" element={<DesignFormComponents />} />

			{/* API documentation */}
			<Route path="/api-docs" element={<ApiDocs />} />

			{/* Fall-through: Render the ParentPage then continue routing in that. */}
			<Route path="*" element={<RequireAgentAuth><ParentPage /></RequireAgentAuth>} />
		</ReactRoutes>
	);
}
