import { Link } from "react-router-dom";
import toast from "react-hot-toast";
import { DateTime } from "luxon";
import { MdOutlineMail } from "react-icons/md";
import Grid from "@mui/material/Grid2";
import { Alert, Box, Grid2, Stack } from "@mui/material";
import { BadgeColor } from "@/Components/Badge/BadgeColor";
import { LoadingPrimaryButton, PrimaryButton } from "@/Components/Button/PrimaryButton";
import { LoadingNeutralButton, NeutralButton } from "@/Components/Button/NeutralButton";
import { DangerButton, LoadingDangerButton } from "@/Components/Button/DangerButton";
import { SlaBadge } from "@/Components/EntityTable/SlaBadge";
import { Badge } from "@/Components/Badge/Badge";
import PaginationDisplay from "@/Components/EntityTable/PaginationDisplay";
import { DesignMenu } from "@/Pages/Design/DesignMenu";
import { Sheet } from "@/Components/Sheet";
import { InfoToast } from "@shared/Components/Utils/Toasts";

export default function Design() {
	document.title = "Design | GoDesk";

	const dateIn2Hrs = DateTime.now().plus({ hours: 2 }).toISO();
	const dateIn24Mins = DateTime.now().plus({ minutes: 24 }).toISO();
	const date53MinsAgo = DateTime.now().minus({ minutes: 53 }).toISO();

	return (
		<Sheet className="container mx-auto p-8">
			<div className="grid grid-cols-2 gap-10">
				<div>
					<DesignMenu />

					<div className="mb-8">
						<h2 className="text-xl mb-2">Colours</h2>
						<Colours />
					</div>

					<div className="mb-8">
						<h2 className="text-2xl mb-2">MUI Buttons</h2>
						<Buttons />
					</div>

					<div className="mb-8">
						<h2 className="text-2xl mb-2">Badges</h2>

						<Badge color={BadgeColor.GREY} label="Grey" />
						<Badge color={BadgeColor.GREEN} label="Green" />
					</div>

					<div className="mb-8">
						<h2 className="text-2xl mb-2">SLA Badges</h2>


						<div className="space-x-4">
							<SlaBadge responseDueISO={dateIn2Hrs} />
							<SlaBadge responseDueISO={dateIn24Mins} />
							<SlaBadge paused />
							<SlaBadge responseDueISO={date53MinsAgo} />
						</div>
					</div>

					<div className="mb-8">
						<h2 className="text-2xl mb-2">Pagination</h2>
						<Pagination />
					</div>
				</div>

				<div>
					<div className="mb-8">
						<h2 className="text-2xl">Alerts</h2>

						<Stack spacing={2}>
							<Alert severity="info">
								Lorem ipsum dolor sit amet, consectetur adipiscing elit.
							</Alert>
							<Alert severity="warning">
								Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex.
							</Alert>
							<Alert severity="error">
								Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
							</Alert>
						</Stack>
					</div>

					<div className="mb-8">
						<h2 className="text-2xl">Toasts</h2>

						<Stack direction="row" spacing={2}>
							<NeutralButton onClick={() => toast("This is an info msg.", InfoToast)}>
								Info
							</NeutralButton>
							<NeutralButton onClick={() => toast.success("This is a success msg!")}>
								Success
							</NeutralButton>
							<NeutralButton onClick={() => toast.error("This is an error.")}>
								Error
							</NeutralButton>
						</Stack>
					</div>

					<div className="mb-8">
						<h2 className="text-2xl">Icons</h2>

						<p>Prefer outlined to filled.</p>
					</div>

					<div>
						<h2 className="text-2xl">Guidelines</h2>
						<div>
							<Guidelines />
						</div>
					</div>
				</div>
			</div>
		</Sheet>
	);
}

function Buttons() {
	return (
		<Grid container spacing={3}>
			<Grid size={3}>
				<PrimaryButton>
					Button
				</PrimaryButton>
			</Grid>
			<Grid size={3}>
				<PrimaryButton disabled>
					Disabled
				</PrimaryButton>
			</Grid>
			<Grid size={3}>
				<PrimaryButton
					startIcon={<MdOutlineMail />}
				>
					Icon
				</PrimaryButton>
			</Grid>
			<Grid size={3}>
				<LoadingPrimaryButton
					startIcon={<MdOutlineMail />}
					loading={true}
				>
					Loading
				</LoadingPrimaryButton>
			</Grid>

			<Grid size={3}>
				<NeutralButton>
					Button
				</NeutralButton>
			</Grid>
			<Grid size={3}>
				<NeutralButton disabled>
					Disabled
				</NeutralButton>
			</Grid>
			<Grid size={3}>
				<NeutralButton
					startIcon={<MdOutlineMail />}
				>
					Icon
				</NeutralButton>
			</Grid>
			<Grid size={3}>
				<LoadingNeutralButton
					startIcon={<MdOutlineMail />}
					loading={true}
				>
					Loading
				</LoadingNeutralButton>
			</Grid>

			<Grid size={3}>
				<DangerButton>
					Button
				</DangerButton>
			</Grid>
			<Grid size={3}>
				<DangerButton disabled>
					Disabled
				</DangerButton>
			</Grid>
			<Grid size={3}>
				<DangerButton
					startIcon={<MdOutlineMail />}
				>
					Icon
				</DangerButton>
			</Grid>
			<Grid size={3}>
				<LoadingDangerButton
					startIcon={<MdOutlineMail />}
					loading={true}
				>
					Loading
				</LoadingDangerButton>
			</Grid>
		</Grid>
	);
}

function Colours() {
	return (
		<>
			<Grid2 container columnSpacing={2} rowSpacing={3}>
				<Grid2 size={12}>Default (MUI)</Grid2>

				<ColourSquare colour="primary.dark" />
				<ColourSquare colour="primary.main" />
				<ColourSquare colour="primary.light" />

				<ColourSquare colour="secondary.dark" />
				<ColourSquare colour="secondary.main" />
				<ColourSquare colour="secondary.light" />

				<ColourSquare colour="error.dark" />
				<ColourSquare colour="error.main" />
				<ColourSquare colour="error.light" />

				<ColourSquare colour="warning.dark" />
				<ColourSquare colour="warning.main" />
				<ColourSquare colour="warning.light" />

				<ColourSquare colour="info.dark" />
				<ColourSquare colour="info.main" />
				<ColourSquare colour="info.light" />

				<ColourSquare colour="success.dark" />
				<ColourSquare colour="success.main" />
				<ColourSquare colour="success.light" />

				<Grid2 size={12}>Background</Grid2>

				<ColourSquare colour="background.paper" />
				<ColourSquare colour="background.default" />

				<Grid2 size={12}>Custom</Grid2>

				<ColourSquare colour="neutral.main" />
			</Grid2>
		</>
	);
}

interface ColourSquareProps {
	colour: string;
}

function ColourSquare(props: ColourSquareProps) {
	return (
		<Grid2 size={4}>
			<Stack direction="row" spacing={1}>
				<Box sx={{ bgcolor: props.colour }} className="rounded w-12 h-12" />

				<div>{props.colour}</div>
			</Stack>
		</Grid2>
	);
}

function Pagination() {
	function nope() {}

	return (
		<PaginationDisplay pagination={{ total: 95, offset: 0, limit: 30 }} nextPage={nope} prevPage={nope} />
	);
}

function Guidelines() {
	return (
		<>
			<p>
				<div className="text-lg font-medium">Copy</div>
				<ul className="list-disc">
					<li>Tone should be polite and professional. Copy should be as simple and consise as possible.</li>
					<li>Do not use exclaimation marks!</li>
					<li>Do not capitalise entities in a sentence - 'ticket' not 'Ticket'</li>
					<li>Use 'example.com' and '[name]@example.com' for placeholders.</li>
					<li>Do not use fullstops at the end of tooltips 'Click here'.</li>
					<li>Do not use '(s)' for potential plurals where we could work it out properly.</li>
				</ul>
			</p>

			<p>
				<div className="text-lg font-medium">Capitals</div>
				<ul className="list-disc">
					<li>Page / modal titles - <Link to="https://titlecaseconverter.com/" target="_blank" className="text-blue-700">title case</Link>.</li>
					<li>Subtitles within a page - title case.</li>
					<li>Buttons - Just capitalise first word.</li>
					<li>Form control labels - Just capitalise first word.</li>
				</ul>
			</p>

			<p>
				<div className="text-lg font-medium">Alignment</div>
				<ul className="list-disc">
					<li>Put the primary button on the right.</li>
					<li>Align buttons to the right on a modal.</li>
				</ul>
			</p>
		</>
	);
}
