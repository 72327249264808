import Modal from "@shared/Components/Modal/Modal";
import ModalContent from "@shared/Components/Modal/ModalContent";
import ModalButtons from "@shared/Components/Modal/ModalButtons";
import { ChannelsEmail } from "@shared/Entities/EntityTypes";

interface BadChannelWarningModalProps {
	isOpen: boolean;
	channel?: ChannelsEmail;
	onClose(): void;
}

export default function BadChannelWarningModal(props: BadChannelWarningModalProps) {

	return (
		<Modal
			isOpen={props.isOpen}
			onClose={props.onClose}
			title="Invalid channel"
		>
			<ModalContent>
				The channel &#40;{props.channel?.name}&#41; is invalid. This is likely because a token has expired.
				<br /><br />
				An admin must reactivate the channel in the mailbox settings page.
			</ModalContent>

			<ModalButtons>
				<button className="btn-grey" onClick={props.onClose}>Close</button>
			</ModalButtons>
		</Modal>
	);
}
