import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Button } from "../../Components/Button/Button";
import ApiDropdown from "../../Components/FormComponents/ApiDropdown/ApiDropdown";
import { TicketClosePolicy, TicketRetentionPolicy } from "@shared/Enums/Enums";
import { FormParent } from "@shared/Components/Form/FormParent";
import { FormSection } from "@shared/Components/Form/FormSection";
import TextInput from "@shared/Components/FormComponents/TextInput/TextInput";
import { Entities } from "@shared/Entities/Entities";
import Dropdown from "@shared/Components/FormComponents/Dropdown/Dropdown";
import { Setting } from "@shared/Entities/EntityTypes";

interface SettingsHelpDeskProps {
	data?: Setting[];
	updateData(settingsData: Setting[]): void;
	refreshData?(): void;
}

export function SettingsHelpDesk(props: SettingsHelpDeskProps) {
	const [data, setData] = useState<Map<string, string | undefined | null> | undefined>();
	const { handleSubmit } = useForm();

	const navigate = useNavigate();

	useEffect(() => {
		const settingsMap = new Map();

		if (props.data != null) {
			props.data.forEach((setting) => {
				settingsMap.set(setting.key, setting.value);
			});
		}

		setData(settingsMap);
	}, [props.data]);

	async function formSubmit() {
		if (data != null) {
			const dataCopy = new Map(data);

			const settings: Setting[] = [];

			dataCopy.forEach((value, key) => {
				settings.push({ key: key, value: value });
			});


			props.updateData(settings);
			navigate("/config/");
		}
	}

	function handleChangeNumber(key: string, newValue: number | undefined) {
		if (newValue != null) {
			handleChange(key, String(newValue));
		} else {
			handleChange(key, null);
		}
	}

	function handleChange(key: string, newValue: string | null | undefined) {
		const dataCopy = new Map(data ?? []);

		if (newValue != null) {
			dataCopy.set(key, newValue);
		} else {
			dataCopy.set(key, undefined);
		}

		setData(dataCopy);
	}

	function cancelButtonClick() {
		navigate("/config");
	}

	const defaultEmailChannel = !isNaN(parseInt(data?.get("helpdesk.defaultemailchannel") ?? "")) ? parseInt(data?.get("helpdesk.defaultemailchannel") ?? "") : null;

	const closeOptions = [
		{ label: "Never", value: TicketClosePolicy.NEVER },
		{ label: "After 1 Week", value: TicketClosePolicy.ONE_WEEK },
		{ label: "After 1 Month", value: TicketClosePolicy.ONE_MONTH },
		{ label: "After 3 Months", value: TicketClosePolicy.THREE_MONTHS }
	];

	const retentionOptions = [
		{ label: "Never", value: TicketRetentionPolicy.NEVER },
		{ label: "After 1 Month", value: TicketRetentionPolicy.ONE_MONTH },
		{ label: "After 3 Months", value: TicketRetentionPolicy.THREE_MONTHS },
		{ label: "After 1 Year", value: TicketRetentionPolicy.ONE_YEAR }
	];

	return (
		props.data != null ?
			<form onSubmit={handleSubmit(formSubmit)} >
				<FormParent
					title="Help Desk"
					description="General settings to control your help desk."
				>
					<FormSection>
						<TextInput
							value={data?.get("helpdesk.name") ?? undefined}
							dataname="helpdesk.name"
							handleSubmit={handleChange}
							label="Help desk name"
						/>

						<ApiDropdown
							value={defaultEmailChannel}
							entity={Entities.CHANNELSEMAIL}
							searchableField="name"
							dataname="helpdesk.defaultemailchannel"
							onChange={handleChangeNumber}
							label="Default mailbox"
							mandatory
						/>
					</FormSection>

					<FormSection title="Data Retention">
						<Dropdown
							value={data?.get("tickets.closePolicy") ?? TicketClosePolicy.NEVER}
							mandatory
							dataname="tickets.closePolicy"
							options={closeOptions}
							onChange={handleChange}
							label="Delete closed tickets"
							toolTip="Closed tickets will be automatically deleted after this amount of time."
						/>

						<Dropdown
							value={data?.get("tickets.retentionPolicy") ?? TicketRetentionPolicy.NEVER}
							mandatory
							dataname="tickets.retentionPolicy"
							options={retentionOptions}
							onChange={handleChange}
							label="Permanently remove deleted tickets"
							toolTip="Tickets will be automatically removed after this amount of time."
						/>
					</FormSection>

					<div className="flex items-center ml-4 mb-4">
						<Button className="" onClick={cancelButtonClick} label="Cancel" />
						<input type="submit" className="ml-2 btn-blue" value="Update" />
					</div>
				</FormParent>
			</form>
			:
			null
	);
}
