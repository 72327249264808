
/** All we need to reference a global tab. */
export interface GlobalTabId {
	/** The ID of the entity this tab represents. */
	id: number;
	entity: HeaderTabEntities;
}

/** A full global tab obj. */
export interface GlobalTab extends GlobalTabId {
	title: string;

	/** Left unset if not pinned */
	pinned?: boolean;
}

export enum HeaderTabEntities {
	TICKETS = "TICKETS",
	USERS = "USERS"
}

export function getGlobalTabUrlPath(tab: GlobalTab) {
	switch (tab.entity) {
		case HeaderTabEntities.TICKETS:
			return "/tickets/" + tab.id;
		case HeaderTabEntities.USERS:
			return "/users/" + tab.id;
	}
}
