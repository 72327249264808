import { Step, StepLabel, Stepper } from "@mui/material";

interface StepperProgressProps {
	page: number;
}

export function ImportProgress(props: StepperProgressProps) {
	return (
		<Stepper className="w-full" alternativeLabel activeStep={props.page - 1}>
			<Step><StepLabel>Info</StepLabel></Step>
			<Step><StepLabel>Credentials</StepLabel></Step>
			<Step><StepLabel>Data selection</StepLabel></Step>
			<Step><StepLabel>Import</StepLabel></Step>
		</Stepper>
	);
}
