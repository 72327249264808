import { Alert } from "@mui/material";

export function Error404() {
	return (
		<div>
			<Alert severity="error">
				This resource does not exist or you do not have permission to access it.
			</Alert>
		</div>
	);
}
