import { Company as CompanyGen } from "@/Api/genApi.schemas";
import Avatar from "./Avatar";
import { Company } from "@shared/Entities/EntityTypes";

interface CompanyAvatarProps {
	company?: Company | CompanyGen;
	/** Width of the avatar in px */
	widthPx: number;
	/** Callback for uploading a new image. */
	updateAvatar?(dataBase64: string, mimeType: string): void;
}

export function CompanyAvatar(props: CompanyAvatarProps) {

	return (
		<Avatar
			name={props.company?.name}
			avatarHashString={"Company_" + props.company?.id}
			widthPx={props.widthPx}
			disabled={props.company?.disabled}
			updateAvatar={props.updateAvatar}
			avatarUrlFilename={props.company?.profilePictureFileName}
			avatarBase64={props.company?.profilePictureData}
			fallbackToInitials
		/>
	);
}
