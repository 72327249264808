import { User as UserGen } from "@/Api/genApi.schemas";
import { useCache } from "@/Hooks/useCache";
import Avatar from "./Avatar";
import { User } from "@shared/Entities/EntityTypes";

interface UserAvatarProps {
	user?: User | UserGen;
	/** Width of the avatar in px */
	widthPx: number;
	/** Callback for uploading a new image. */
	updateAvatar?(dataBase64: string, mimeType: string): void;
}

export function UserAvatar(props: UserAvatarProps) {

	const { cache } = useCache();

	let avatarUrlFilename = props.user?.profilePictureFileName;
	let avatarBase64 = props.user?.profilePictureData;


	if ((avatarUrlFilename == null || avatarUrlFilename == "") && (avatarBase64 == null || avatarBase64 == "")) {
		const company = cache.getCompany(props.user?.companyId);

		if (company != null) {
			avatarUrlFilename = company.profilePictureFileName;
			avatarBase64 = company.profilePictureData;
		}
	}

	return (
		<Avatar
			name={props.user?.name}
			avatarHashString={"User_" + props.user?.id}
			widthPx={props.widthPx}
			disabled={props.user?.disabled}
			deleted={props.user?.deleted}
			updateAvatar={props.updateAvatar}
			avatarUrlFilename={avatarUrlFilename}
			avatarBase64={avatarBase64}
		/>
	);
}
