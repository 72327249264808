import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { BiKey } from "react-icons/bi";
import { AiOutlineDelete, AiOutlineStop } from "react-icons/ai";
import { MdSettingsBackupRestore } from "react-icons/md";
import toast from "react-hot-toast";
import { ApiGetMe, ApiUpdateEntity } from "@/legacy/ApiCallerOld";
import { useEntity } from "@/legacy/useEntity";
import { useCache } from "@/Hooks/useCache";
import { useAgent } from "@/Hooks/useAgent";
import { AgentViewerDisplay, getAgentTitle } from "@/Pages/Config/Agents/AgentViewerDisplay";
import { useNumberParams } from "@/Hooks/useNumberParams";
import { useAppDispatch } from "@/Store/hooks";
import OtherFunctions from "@/Helpers/OtherFunctions";
import { agentDetailsChanged } from "@/Store/Reducers/loggedInAgentSlice";
import { EntityViewerToolbar } from "@/Fragments/EntityViewer/EntityViewerToolbar";
import { Button, ButtonRed } from "@/Components/Button/Button";
import { AgentAvatar } from "@/Components/Avatar/AgentAvatar";
import { getImageUploadHandler } from "@/Components/FormComponents/HtmlEditor/imageUploadHandler";
import { ChangePasswordModal } from "@/Components/Utility/Modals/ChangePasswordModal";
import { W_Tickbox } from "@shared/Components/FormComponents/Tickbox/W_Tickbox";
import { FormError } from "@shared/Components/Form/FormError";
import { FormParent } from "@shared/Components/Form/FormParent";
import { FormSection } from "@shared/Components/Form/FormSection";
import { Agent } from "@shared/Entities/EntityTypes";
import W_TextInput from "@shared/Components/FormComponents/TextInput/W_TextInput";
import W_HtmlEditor from "@shared/Components/FormComponents/HtmlEditor/W_HtmlEditor";
import { InputTypes } from "@shared/Components/FormComponents/TextInput/InputTypes";
import { Entities } from "@shared/Entities/Entities";
import TextInput from "@shared/Components/FormComponents/TextInput/TextInput";
import { FormHotkeys } from "@shared/Components/Utils/FormHotkeys";

export function AgentViewer() {
	const { id } = useNumberParams();
	const [passwordModalOpen, setPasswordModalOpen] = useState(false);
	const [editmode, setEditmode] = useState<boolean>(id == -1);
	const agent = useAgent();
	const { cache } = useCache();

	const dispatch = useAppDispatch();
	const navigate = useNavigate();

	const { data, formError, fieldErrors, updateEntity, createEntity, deleteEntity } = useEntity<Agent>(id, Entities.AGENT);
	const { control, handleSubmit, reset, setValue, getValues, setError, watch } = useForm<Agent>({ defaultValues: data });

	useEffect(() => {
		// Override form data when data changes.
		reset(data);
	}, [data]);

	useEffect(() => {
		// Override errors when they change in props.
		OtherFunctions.setFieldErrors(setError, fieldErrors);
	}, [fieldErrors]);

	async function disableAgent() {
		setValue("disabled", true);

		const resSuccessful = await updateEntity(getValues());

		if (resSuccessful) {
			toast.success("Agent disabled.");
		} else {
			toast.error("Could not disable agent.");
		}
	}

	async function restoreAgent() {
		setValue("disabled", false);

		const resSuccessful = await updateEntity(getValues());

		if (resSuccessful) {
			toast.success("Agent restored.");
		} else {
			toast.error("Could not restore agent.");
		}
	}

	async function deleteAgent() {
		const deleteSuccess = await deleteEntity();

		if (deleteSuccess) {
			navigate("/config/manage/agents");
		}
	}

	function cancelEdit() {
		setEditmode(false);
		reset(data);

		if (id == -1) {
			navigate("/config/manage/agents");
		}
	}

	async function formSubmit(data: Agent) {
		if (data.id > 0) {
			const updateSuccess = await updateEntity(data);

			if (updateSuccess) {
				setEditmode(false);
				refreshLoggedInAgent();
			}
		} else {
			const newId = await createEntity(data);

			if (newId != null && newId > -1) {
				setEditmode(false);
				navigate("/config/manage/agents/" + newId);
			}
		}
	}

	async function refreshLoggedInAgent() {
		const ApiResponse = await ApiGetMe();

		if (ApiResponse.data != null && ApiResponse.data.id != null && ApiResponse.data.id > 0) {
			dispatch(agentDetailsChanged(ApiResponse.data));
		}
	}

	function newAvatarCallback(dataBase64: string, mimeType: string) {
		let newData = Object.assign({}, getValues());
		newData = OtherFunctions.SafeChangeProperty(newData, "profilePictureData", dataBase64);
		newData = OtherFunctions.SafeChangeProperty(newData, "profilePictureMimetype", mimeType);

		if (newData.id > 0) {
			updateEntity(newData);
		}
	}

	async function submitNewPassword(password: string) {
		const toastId = toast.loading("Changing password...");

		const agent = getValues();
		agent.password = password;

		const response = await ApiUpdateEntity<Agent>(Entities.AGENT, agent.id, agent);

		if (response.successful) {
			toast.success("Password changed.", { id: toastId });
			reset(response.data);
		} else {
			toast.error("Could not change password. " + response.errorCode + ": " + response.errorMsg, { id: toastId });
		}

		setPasswordModalOpen(false);
	}

	const readButtons = data?.disabled ?
		<>
			<Button className="" onClick={restoreAgent} icon={<MdSettingsBackupRestore />} label="Restore" />
			<ButtonRed className="" label="Delete Forever" icon={<AiOutlineDelete />} onClick={deleteAgent} />
		</>
		:
		<>
			<Button icon={<BiKey />} label="Change password" onClick={() => setPasswordModalOpen(true)} />
			<Button className="" label="Disable" icon={<AiOutlineStop />} onClick={disableAgent} />
		</>;

	const isAdmin = agent?.isAdmin ?? false;
	const canEdit = isAdmin || agent?.id == id;

	if (id == null || data == null) {
		return null;
	}

	return (
		<>
			<FormHotkeys onSubmit={handleSubmit(formSubmit)} onCancel={cancelEdit} />

			<form onSubmit={handleSubmit(formSubmit)} className="h-full w-full flex flex-col">
				{!data.deleted &&
					<EntityViewerToolbar
						editmode={editmode}
						isNew={id == -1}
						backUrl="/config/manage/agents"
						onEdit={canEdit ? () => setEditmode(true) : undefined}
						onCancel={cancelEdit}
						extraEditButtons={canEdit ? <Button icon={<BiKey />} label="Change password" onClick={() => setPasswordModalOpen(true)} /> : undefined}
						extraReadButtons={isAdmin ? readButtons : undefined}
					/>}

				{editmode ?
					<div className="overflow-auto">
						<FormParent title={getAgentTitle(data)}>

							<FormError error={formError} />

							<FormSection title="Details">
								<W_TextInput
									control={control}
									dataname="name"
									label="Name"
									placeholder="John Smith"
									mandatory
									autoFocus={id < 0}
								/>
								<W_TextInput
									control={control}
									dataname="email"
									label="Email"
									placeholder="me@company.com"
									mandatory
									type={InputTypes.Email}
									autocomplete="off"
								/>

								{id == -1 ?
									<W_TextInput
										control={control}
										dataname="password"
										label="Password"
										type={InputTypes.Password}
										mandatory
										autocomplete="off"
									/>
									: null}

								<W_TextInput
									control={control}
									dataname="jobTitle"
									label="Job Title"
									placeholder="Support agent"
								/>

								<W_TextInput
									control={control}
									dataname="phoneNumber"
									label="Phone number"
									type={InputTypes.Tel}
									placeholder="+44 1234 123456"
								/>
							</FormSection>

							<FormSection title="Avatar">
								<AgentAvatar agent={data} widthPx={70} updateAvatar={newAvatarCallback} hideAgentStatus />
							</FormSection>

							{isAdmin &&
								<FormSection title="Permissions">
									<W_Tickbox control={control} dataname="isAdmin" valueName="isAdmin" label="Administrator" />
								</FormSection>}

							<FormSection
								title="Greeting"
								titleTooltip="This will form the first line of your email responses. Set to blank to skip this option. You can also use '$name' to pull in the user's name."
							>
								<TextInput
									dataname=""
									label="Greeting"
									placeholder="Hi $name,"
									value={watch("prefs.emailGreeting") === undefined ? "Hi $name," : watch("prefs.emailGreeting")}
									onChange={(value) => {setValue("prefs.emailGreeting", value);}}
								/>
							</FormSection>

							{id > 0 &&
								<FormSection
									title="Email Signature"
									className="w-[50rem]"
								>
									<W_HtmlEditor
										control={control}
										dataname="signature"
										agentFeatures
										handleCancel={cancelEdit}
										handleSubmit={(note) => {
											setValue("signature", note);
											handleSubmit(formSubmit)();
										}}
										cannedReplies={cache.CannedReplies}
										imageUploadHandler={getImageUploadHandler(cache.getTenantId())}
									/>
								</FormSection>}
						</FormParent>
					</div>
					:
					<div className="overflow-auto">
						<AgentViewerDisplay agent={data} />
					</div>}
			</form>

			<ChangePasswordModal updatePassword={submitNewPassword} isOpen={passwordModalOpen} closeModal={() => setPasswordModalOpen(false)} />
		</>
	);
}
