import { ChangeEvent } from "react";

interface SearchProps {
	searchCallback(searchTerm: string): void;
}

/**
 * @deprecated Remove and replace with standard input. Add a debounce prop. See for example:
 */
export default function Searchbox(props: SearchProps) {
	let timeout: NodeJS.Timeout;

	function handleChange(event: ChangeEvent<HTMLInputElement>) {
		clearTimeout(timeout);

		timeout = setTimeout(function () {
			props.searchCallback(event.target.value);
		}, 300);
	}

	return (
		<input
			type="text"
			placeholder="Search"
			onChange={handleChange}
			className="rounded border border-gray-300 px-3 py-2 w-52"
		/>
	);
}
