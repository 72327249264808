import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Button, CardContent, IconButton, Tooltip, Typography } from "@mui/material";
import { MdDeleteOutline } from "react-icons/md";
import { IoAddCircleOutline } from "react-icons/io5";
import { RiPencilLine } from "react-icons/ri";
import toast from "react-hot-toast";
import { useCache } from "@/Hooks/useCache";
import api from "@/Api/Api";
import DeleteWarningModal from "@/Components/Utility/Modals/DeleteWarningModal";
import KbFolderModal from "../../Components/Utility/Modals/KbfolderModal";
import { Entities } from "@shared/Entities/Entities";
import { KbArticle, KbFolder } from "@shared/Entities/EntityTypes";

interface KbFolderDisplayProps {
	data: KbFolder;
	refreshData(): void;
}

export default function KbFolderDisplay(props: KbFolderDisplayProps) {
	const navigate = useNavigate();
	const { cache } = useCache();
	const [newFolderModalOpen, setNewFolderModalOpen] = useState<boolean>(false);
	const [deleteModalOpen, setDeleteModalOpen] = useState(false);

	const url = props.data.pictureFileName != null ? cache.getPicturesDir() + props.data.pictureFileName : null;

	const parentFolderName = cache.getKbFolder(props.data.parentId)?.name;

	async function deleteFolder(id: number) {
		const res = await api.deleteEntity(Entities.KBFOLDER, id);

		if (res.successful) {
			toast.success("Folder deleted.");
		} else {
			toast.error("Could not delete folder.");
		}

		setDeleteModalOpen(false);
		props.refreshData();
	}

	const moreThan4Articles = props.data.articles.length > 4;

	return (
		<>
			<div className="shadow-md w-[360px] p-0 bg-white border relative rounded-lg">
				<div
					className="w-[358px] h-[179px] relative bg-gray-200"
				>
					{url &&
						<img
							src={url}
							className="w-[358px] h-[179px] rounded-t-lg"
							loading="lazy"
							alt=""
						/>}
				</div>

				{parentFolderName &&
					<Box className="absolute top-0 left-0 rounded-br-md rounded-tl-md bg-gray-300" >
						<Tooltip title="Parent folder">
							<div className="px-3 py-1">
								{parentFolderName}
							</div>
						</Tooltip>
					</Box>}

				<Box className="absolute top-0 right-0 pt-1 px-2 space-x-2 rounded-bl-md rounded-tr-md bg-gray-300" >
					<Tooltip title="Edit folder">
						<button onClick={() => setNewFolderModalOpen(true)}>
							<RiPencilLine size={20} />
						</button>
					</Tooltip>

					<Tooltip title="Delete folder">
						<button onClick={() => setDeleteModalOpen(true)}>
							<MdDeleteOutline size={20} />
						</button>
					</Tooltip>
				</Box>

				<div className="px-5 py-3 bg-[#f0f4f8] font-bold">
					<span className="line-clamp-2 h-full">{props.data.name}</span>
				</div>

				<CardContent
					sx={{
						rowGap: 0, padding: 0
					}}
				>
					<div className={moreThan4Articles ? "min-h-[50px] max-h-[150px] overflow-auto" : ""}>
						{props.data.articles?.filter(article => article.published == true).map(article =>
							<KbFolderArticle data={article} refreshData={props.refreshData} key={article.id} />
						)}
					</div>
				</CardContent>

				<Box sx={{ color: "primary.plainColor" }} className="flex justify-center items-center border-t py-2 ">
					<Button
						onClick={() => navigate("/kb/article/-1?kbFolderId=" + props.data.id)}
						startIcon={<IoAddCircleOutline size={20} />}
					>
						New article
					</Button>
				</Box>
			</div>

			<KbFolderModal
				data={props.data}
				refreshData={props.refreshData}
				closeModal={() => setNewFolderModalOpen(false)}
				isOpen={newFolderModalOpen}
			/>


			<DeleteWarningModal
				isOpen={deleteModalOpen}
				onCancel={() => setDeleteModalOpen(false)}
				onDelete={() => deleteFolder(props.data.id)}
				message="Are you sure you want to delete this folder?"
			/>
		</>
	);
}

interface articleSectionProps {
	data: KbArticle;
	refreshData(): void;
}

function KbFolderArticle(props: articleSectionProps) {
	const [deleteModalOpen, setDeleteModalOpen] = useState(false);
	const navigate = useNavigate();

	async function deleteButtonClick(id: number) {
		const res = await api.deleteEntity(Entities.KBARTICLE, id);

		if (res.successful) {
			toast.success("Article deleted.");
		} else {
			toast.error("Could not delete article.");
		}

		setDeleteModalOpen(false);
		props.refreshData();
	}

	const articleContent = props.data.articleContent?.find(article => article.id.language == "en_GB");

	return (
		<div className="flex w-full justify-between p-1">
			<Button
				sx={{
					justifyContent: "space-between",
					overflow: "hidden",
					borderRadius: 0,
					fontWeight: 400,
					flexGrow: 1,
					flexShrink: 1
				}}
				onClick={() => navigate("/kb/article/" + props.data.id)}
			>
				<Typography
					noWrap
				>
					{articleContent?.title}
				</Typography>


			</Button>

			<Tooltip title="Delete article">
				<IconButton aria-label="delete" onClick={ () => setDeleteModalOpen(true) } sx={{ fontSize: "1.5rem" }}>
					<MdDeleteOutline />
				</IconButton>
			</Tooltip>

			<DeleteWarningModal
				isOpen={deleteModalOpen}
				onCancel={() => setDeleteModalOpen(false)}
				onDelete={() => deleteButtonClick(props.data.id)}
				message="Are you sure you want to delete this article?"
			/>
		</div>
	);
}
