import { useEffect } from "react";
import { UseFormClearErrors, UseFormSetError } from "react-hook-form";
import OtherFunctions from "@/Helpers/OtherFunctions";
import { ErrorPayload } from "@shared/Api/ApiResponses";

/**
 * This is for keeping a react-hook-form in sync with useQuery errors.
 */
export function useFormErrorSync(
	setError: UseFormSetError<never>,
	clearErrors: UseFormClearErrors<never>,
	error?: ErrorPayload | null
) {
	return useEffect(() => {
		OtherFunctions.setFormErrors(setError, clearErrors, error);
	}, [setError, clearErrors, error]);
}
