import { ReactNode, useId } from "react";
import Select, { MultiValue, StylesConfig } from "react-select";
import { CacheSlices } from "@/Store/Reducers/cache/CacheTypes";
import { useCache } from "@/Hooks/useCache";
import { useAppDispatch } from "../../../Store/hooks";
import { CacheFunctions } from "../../../Helpers/CacheFunctions";
import { GD_SUPPORT_AGENT_ID } from "@shared/Enums/Enums";
import { closeMenuOnScrollFix } from "@shared/Helpers/OtherFunctions";
import { ControlLabel } from "@shared/Components/FormComponents/ControlLabel";
import { User } from "@shared/Entities/EntityTypes";

// Copied from react-select.
interface FilterOptionOption<Option> {
	readonly label: string;
	readonly value: string;
	readonly data: Option;
}

type Option = {
	value: number | undefined;
	label: string;
};

export interface MultiEntitySelectProps {
	value?: number[];
	label?: ReactNode;
	dataname: string;
	/** This will be the Id's of the entity. */
	handleSubmit(key: string, newValue: number[] | undefined): void;
	cacheSlice: CacheSlices;
	mandatory?: boolean;
	inline?: boolean;
	disabled?: boolean;
	invalid?: boolean;
	toolTip?: ReactNode;

	/**
	 * Extra options to include. Useful for representing unassigned using a value of '-1'.
	 * They are displayed before the action options.
	 */
	extraOptions?: Option[];
}

export default function MultiEntitySelect(props: MultiEntitySelectProps) {
	const controlId = useId();
	const dispatch = useAppDispatch();
	const { cache } = useCache();

	function handleChange(data: MultiValue<Option>) {
		const valuesToSubmit: number[] = [];

		if (data != null && data.length > 0) {
			data.forEach(option => {
				if (option.value != null) {
					valuesToSubmit.push(option.value);
				}
			});
		}

		props.handleSubmit(props.dataname, valuesToSubmit);
	}

	// Select the correct cacheSlice.
	const cacheToDisplay = CacheFunctions.getCacheSlice(cache, props.cacheSlice);
	const labelProperty = "name";

	// Create options array.
	let options: Option[] = [];

	if (cacheToDisplay != null) {
		// Add extra options from props.
		if (props.extraOptions != null) {
			options = options.concat(props.extraOptions);
		}

		cacheToDisplay.forEach((element: any) => {
			if (props.cacheSlice == CacheSlices.Companies && element.id == -1) {
				// Single users special company. Do not include in the list.
			} else {
				if (props.cacheSlice == CacheSlices.Agents || props.cacheSlice == CacheSlices.Users) {
					if (element.id == GD_SUPPORT_AGENT_ID) {
						// Ignore the GoDesk support agent
						return;
					}

					const user = element as User;

					if (user.disabled || user.deleted) {
						return;
					}
				}

				options.push({
					value: element.id,
					label: element[labelProperty]
				});
			}
		});
	}

	// Get current values
	const values: Option[] = [];
	props.value?.forEach((value) => {
		if (value != null && value > -1) {
			const valueLabel = CacheFunctions.getNameFromCache(value, cache, props.cacheSlice, dispatch) ?? "";
			values.push({ value: value, label: valueLabel });
		}

		// Handle special unassigned agent value.
		if (props.cacheSlice == CacheSlices.Agents && value == -1) {
			values.push({ value: -1, label: "Unassigned" });
		}
	});

	const customStyles: StylesConfig<Option, true> = {
		dropdownIndicator: (provided) => ({
			...provided,
			padding: "5px 7px",
		}),
		indicatorSeparator: (provided) => ({
			...provided,
			display: "none",
		}),
		menu: (provided) => ({
			...provided,
			margin: "1px",
		}),
	};

	function filterOption(option: FilterOptionOption<Option>, inputValue: string): boolean {
		return option.label.toUpperCase().includes(inputValue.toUpperCase());
	}

	const displayCssContainer = props.inline ? "inline-block" : "block Form_Component";

	return (
		<div className={displayCssContainer} style={{ minWidth: "200px" }}>
			{!props.inline &&
				<ControlLabel
					label={props.label}
					mandatory={props.mandatory}
					controlId={controlId}
					invalid={props.invalid}
					helperText={props.toolTip}
				/>}

			<Select<Option, true>
				className="select-parent"
				inputId={controlId}
				isMulti
				value={values}
				onChange={handleChange}
				options={options}
				styles={customStyles}
				isDisabled={props.disabled}
				menuPlacement="auto"
				// This adds fixed position to Dropdown so options will display over the top of the modal.
				menuPosition="fixed"
				filterOption={filterOption}
				closeMenuOnScroll={closeMenuOnScrollFix}
			/>
		</div>
	);
}
