import { useState, useEffect } from "react";
import toast from "react-hot-toast";
import { IoPersonAddOutline } from "react-icons/io5";
import { LiaUserClockSolid } from "react-icons/lia";
import { AgentMassUpdateReq } from "@/legacy/ApiResponse";
import { ApiMassUpdateAgents } from "@/legacy/ApiCallerOld";
import { useCache } from "@/Hooks/useCache";
import { useAgent } from "@/Hooks/useAgent";
import api from "@/Api/Api";
import { InviteAgentsModal } from "@/Components/EntityTable/Tables/AgentTable/InviteAgentsModal";
import { PendingAgentInvitesModal } from "@/Components/EntityTable/Tables/AgentTable/PendingAgentInvitesModal";
import EntityTable from "@/Components/EntityTable/EntityTable";
import { View } from "@shared/Models/View";
import { SettingInt } from "@shared/Enums/SettingEnums";
import { Entities } from "@shared/Entities/Entities";

interface AgentTableProps {
	view?: View;
}

export function AgentTable(props: AgentTableProps) {
	const { cache } = useCache();

	const agent = useAgent();
	const isAdmin = agent.isAdmin ?? false;

	const [addAgentModalOpen, setAddAgentModalOpen] = useState(false);
	const [pendingInvitesModalOpen, setPendingInvitesModalOpen] = useState(false);

	useEffect(() => {
		if (addAgentModalOpen) {
			api.metricEvent("add_agent_modal_open");
		}
	}, [addAgentModalOpen]);

	async function onMultiDelete(ids: number[]) {
		const req: AgentMassUpdateReq = {
			ids: [...ids],
			disabled: true
		};
		const deleteResponse = await ApiMassUpdateAgents(req);

		if (deleteResponse != null && deleteResponse.errorMsg) {
			toast.error(deleteResponse.errorMsg);
		}
	}

	let entityLimit = undefined;
	const agentLimit = cache.getSettingInt(SettingInt.LIMITS_AGENTS);
	if (agentLimit != null) {
		entityLimit = {
			limit: agentLimit,
			errorMsg: "Max agent count (" + agentLimit + ") exceeded. Increase your license count to add more agents."
		};
	}

	return (
		<>
			<EntityTable
				entity={Entities.AGENT}
				view={props.view}
				multiDeleteOverride={onMultiDelete}
				entityLimit={entityLimit}
				newEntityUrl="/config/manage/agents/-1"
				editEntityUrl="/config/manage/agents/"
				customButtons={[
					{
						label: "Pending agent invites",
						icon: <LiaUserClockSolid />,
						onClick: async () => setPendingInvitesModalOpen(true)
					}
				]}
				newButtonReplacement={
					isAdmin ?
						{
							label: "Invite agents",
							icon: <IoPersonAddOutline />,
							onClick: async () => setAddAgentModalOpen(true)
						}
						: undefined
				}
			/>

			<InviteAgentsModal isOpen={addAgentModalOpen} closeModal={() => setAddAgentModalOpen(false)} />
			<PendingAgentInvitesModal isOpen={pendingInvitesModalOpen} closeModal={() => setPendingInvitesModalOpen(false)} />
		</>
	);
}
