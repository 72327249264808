import { AiOutlineDelete } from "react-icons/ai";
import { CacheSlices } from "@/Store/Reducers/cache/CacheTypes";
import MultiEntitySelect from "../../../../Components/FormComponents/MultiEntitySelect/MultiEntitySelect";
import TextInput from "@shared/Components/FormComponents/TextInput/TextInput";
import { AutomationCondition, AutomationConditionType, AutomationOperator } from "@shared/Entities/EntityTypes";

interface ConditionSelectorProps {
	data: AutomationCondition;
	deletable: boolean;
	updateData(data: AutomationCondition): void;
	delete(data: AutomationCondition): void;
}

export function ConditionSelector(props: ConditionSelectorProps) {
	let valueInput = null;

	function updateField(field: string) {
		const dataCopy = Object.assign({}, props.data);

		let newValue: AutomationConditionType | undefined = undefined;

		if (Object.values(AutomationConditionType).indexOf(field as AutomationConditionType) > -1) { // String can be cast correctly as this enum.
			newValue = AutomationConditionType[field as AutomationConditionType];
		}

		if (newValue != undefined) {
			dataCopy.field = newValue;

			if (dataCopy.operator == null) {
				dataCopy.operator = "eql";
			}

			if ((dataCopy.operator == "con" || dataCopy.operator == "ncon") && dataCopy.field != AutomationConditionType.DESCRIPTION && dataCopy.field != AutomationConditionType.NOTE) {
				dataCopy.operator = "eql";
			}

			dataCopy.value = undefined;

			props.updateData(dataCopy);
		}
	}

	function updateOperator(operator: string) {
		const dataCopy = Object.assign({}, props.data);

		dataCopy.operator = operator as AutomationOperator;
		props.updateData(dataCopy);
	}

	function updateValue(value: string) {
		const dataCopy = Object.assign({}, props.data);

		dataCopy.value = value;
		props.updateData(dataCopy);
	}

	function joinIdList(list?: string): number[] | undefined {
		return list?.split(",").map(function(value) {
			return parseInt(value);
		});
	}

	switch (props.data.field) {
		case AutomationConditionType.DESCRIPTION:
			valueInput = <TextInput
				dataname=""
				value={props.data.value}
				handleSubmit={(_key, value) => {updateValue(value ?? "");}}
				mandatory
				inline
			             />;
			break;

		case AutomationConditionType.NOTE:
			valueInput = <TextInput
				dataname=""
				value={props.data.value}
				handleSubmit={(_key, value) => {updateValue(value ?? "");}}
				mandatory
				inline
			             />;
			break;

		case AutomationConditionType.USER:
			valueInput = <MultiEntitySelect
				dataname=""
				value={joinIdList(props.data.value)}
				handleSubmit={(_key, value) => {updateValue(value?.join(",") ?? "");}}
				cacheSlice={CacheSlices.Users}
				inline
			             />;
			break;

		case AutomationConditionType.AGENT:
			valueInput = <MultiEntitySelect
				dataname=""
				value={joinIdList(props.data.value)}
				handleSubmit={(_key, value) => {updateValue(value?.join(",") ?? "");}}
				cacheSlice={CacheSlices.Agents}
				inline
			             />;
			break;

		case AutomationConditionType.PRIORITY:
			valueInput = <MultiEntitySelect
				dataname=""
				value={joinIdList(props.data.value)}
				handleSubmit={(_key, value) => {updateValue(value?.join(",") ?? "");}}
				cacheSlice={CacheSlices.Priorities}
				inline
			             />;
			break;

		case AutomationConditionType.COMPANY:
			valueInput = <MultiEntitySelect
				dataname=""
				value={joinIdList(props.data.value)}
				handleSubmit={(_key, value) => {updateValue(value?.join(",") ?? "");}}
				cacheSlice={CacheSlices.Companies}
				inline
			             />;
			break;

		case AutomationConditionType.CATEGORY:
			valueInput = <MultiEntitySelect
				dataname=""
				value={joinIdList(props.data.value)}
				handleSubmit={(_key, value) => {updateValue(value?.join(",") ?? "");}}
				cacheSlice={CacheSlices.Categories}
				inline
			             />;
			break;
	}

	return (
		<div className="my-3 w-full flex items-center">
			<div className="flex-grow pr-3 flex items-center gap-2">
				If:

				<select className="py-2 px-1 shadow-sm border-gray-300 border rounded-md" value={props.data.field} onChange={(event) => { updateField(event.target.value); }} required>
					<option disabled selected value="">Select Condition</option>
					<option value={AutomationConditionType.DESCRIPTION}>Subject</option>
					<option value={AutomationConditionType.NOTE}>First message note</option>
					<option value={AutomationConditionType.USER}>User</option>
					<option value={AutomationConditionType.AGENT}>Agent</option>
					<option value={AutomationConditionType.COMPANY}>Company</option>
					<option value={AutomationConditionType.PRIORITY}>Priority</option>
					<option value={AutomationConditionType.CATEGORY}>Category</option>
				</select>

				{props.data.field ?
					<>
						<select className="shadow-sm border-gray-300 border rounded-md py-2 px-1 w-36" value={props.data.operator} onChange={(event) => { updateOperator(event.target.value); }} required>
							<option value="eql">Is</option>
							<option value="neql">Is not</option>

							{props.data.field == AutomationConditionType.DESCRIPTION || props.data.field == AutomationConditionType.NOTE ?
								<>
									<option value="con">Contains</option>
									<option value="ncon">Does not contain</option>
								</>
								: null}
						</select>

						{valueInput}
					</>
					: null}
			</div>

			<div className="w-6">
				{props.deletable ?
					<div className="h-6 w-6 rounded-full bg-gray-200 items-center text-center inline-flex cursor-pointer hover:bg-gray-300" onClick={() => {props.delete(props.data);}}>
						<AiOutlineDelete className="ml-1.5" />
					</div>
					: null}
			</div>

		</div>
	);
}
