import { useState } from "react";
import toast from "react-hot-toast";
import { useCreateGoogleStateCode } from "@/Api/genApi";
import { ChannelsEmail } from "@/Api/genApi.schemas";
import { ChannelsEmail as ChannelsEmailOld } from "@shared/Entities/EntityTypes";

export function getRedirectUri(): string {
	if (process.env.NODE_ENV == "development") {
		// TODO: Get this working for Gmail dev env.
		return "http://localhost:8080/oauth"; // Dev env.
	} else if (window.location.hostname == "support.getgodesk.com" || window.location.hostname == "emailtest.getgodesk.com") {
		return "https://oauth-stag.getgodesk.com"; // Staging.
	} else {
		return "https://oauth.getgodesk.com"; // Production.
	}
}

export interface OauthButtonProps {
	channel: ChannelsEmailOld;
	/** Calls and awaits this BEFORE sending the user off on the auth flow. Should return the channel as returned by the API */
	prefireCallback?(): Promise<ChannelsEmailOld | undefined>;
}

export function GmailOauthButton(props: OauthButtonProps) {
	const [awaitingCode, setAwaitingCode] = useState(false);

	const createStateCodeReq = useCreateGoogleStateCode();

	async function onClick() {
		if (awaitingCode) {
			return;
		}

		const channel = props.prefireCallback != null ? await props.prefireCallback() : props.channel;

		if (channel == null) {
			return;
		}

		setAwaitingCode(true);

		await createStateCodeReq.mutateAsync({ data: (channel as ChannelsEmail) }, {
			onSuccess: stateCode => {
				const googleAuthUrl = "https://accounts.google.com/o/oauth2/v2/auth";

				const redirectUri = getRedirectUri();

				const searchParams = new URLSearchParams({
					client_id: "955862405053-moarhh7ivfvr3cmajd8o153b9uv8dokf.apps.googleusercontent.com",
					redirect_uri: redirectUri,
					scope: "https://www.googleapis.com/auth/gmail.send",
					response_type: "code",
					access_type: "offline",
					prompt: "consent",
					state: stateCode
				});

				if (channel.address != null) {
					searchParams.append("login_hint", channel.address);
				}

				const authUrl = googleAuthUrl + "?" + searchParams.toString();

				window.location.href = authUrl;
			},
			onError: error => toast.error("Error. " + error.httpCode + ": " + error.errorMsg)
		});

		setAwaitingCode(false);
	}

	return (
		<img
			title="Activate Gmail"
			alt="Activate Gmail"
			className="cursor-pointer w-[191px]"
			onClick={onClick}
			src="/google-signin-light.png"
		/>
	);
}
