interface ImgWithFallbackProps {
	src: string;
	fallbackSrc?: string;
	className?: string;
	/** Only use if you need to use actual css instead of tailwind for specific use cases. */
	style?: React.CSSProperties;
	alt?: string;
}

/** Displays an image with a fallback image in case the main image fails to load. */
export function ImgWithFallback(props: ImgWithFallbackProps) {
	return (
		<picture>
			<source srcSet={props.src} className={props.className} style={props.style}></source>
			<img src={props.fallbackSrc ?? "/fallback.png"} alt={props.alt} className={props.className} style={props.style} />
		</picture>
	);
}
