import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import api from "@/Api/Api";
import { UsersCompaniesPanel } from "@/Pages/Companies/UsersCompaniesPanel/UsersCompaniesPanel";
import { CompanyViewer } from "@/Pages/Companies/CompanyViewer/CompanyViewer";
import { SingleUsersPanel } from "@/Pages/Companies/SingleUsersPanel";
import { useCache } from "@/Hooks/useCache";
import NewEntityModal from "../../Components/Utility/Modals/NewEntityModal";
import { NoCompanyPlaceholder } from "./NoCompanyPlaceholder";
import { useNumberParams } from "../../Hooks/useNumberParams";
import { Company } from "@shared/Entities/EntityTypes";
import { Entities } from "@shared/Entities/Entities";
import "react-tabs/style/react-tabs.scss";

export default function Companies() {
	const { id } = useNumberParams();
	const { refreshCache } = useCache();

	const navigate = useNavigate();
	const [companies, setCompanies] = useState<Company[]>();
	const [hasMoreCompanies, setHasMoreCompanies] = useState(false);

	const [newCompanyModalOpen, setNewCompanyModalOpen] = useState(false);
	const [newUserModalOpen, setNewUserModalOpen] = useState(false);

	const pageSize = 200;

	useEffect(() => {
		doFetch();
	}, []);

	if (id == null || id == -1) {
		document.title = "Companies | GoDesk";
	} else if (companies != null) {
		const company = companies.find(company => company.id == id);
		document.title = company?.name + " | Companies | GoDesk";
	}

	async function doFetch(page: number = 0) {
		const offset = page * pageSize;

		const response = await api.getEntities<Company>(Entities.COMPANY, pageSize, offset, [{ colId: "name", sort: "asc" }, { colId: "id", sort: "asc" }]);

		if (response.successful && response.data != null) {
			if (offset > 0) {
				// Append data.
				setCompanies(companies => companies != null && response.data != null ? [...companies, ...response.data] : response.data);
			} else {
				setCompanies(response.data);
			}

			setHasMoreCompanies(response.pagination?.total != null && response.pagination.total > offset + pageSize);
		} else {
			toast.error("Error. " + response.errorCode + ": " + response.errorMsg);
		}
	}

	async function newCompanyCreated(id: number) {
		setNewCompanyModalOpen(false);

		navigate("/companies/" + id);

		await doFetch();
		await refreshCache();
	}

	async function newUserCreated(id: number) {
		setNewUserModalOpen(false);

		navigate("/users/" + id);

		await refreshCache();
	}

	return (
		<div className="flex h-full">
			<UsersCompaniesPanel
				companies={companies}
				hasMoreCompanies={hasMoreCompanies}
				fetchMoreCompanies={doFetch}
				refreshCompanies={doFetch}
			/>

			<div className="h-full grow">
				{ id == undefined ?
					<NoCompanyPlaceholder openNewCompanyModal={() => setNewCompanyModalOpen(true)} openNewUserModal={() => setNewUserModalOpen(true)} />
					: id == -1 ?
						<SingleUsersPanel />
						:
						// TODO: Add company notes with an on hover effect when you hover on the company the description will show.
						<CompanyViewer
							id={id}
							onNameChange={doFetch}
						/>
				}
			</div>

			<NewEntityModal newCreated={newCompanyCreated} isOpen={newCompanyModalOpen} entityType={Entities.COMPANY} closeModal={() => setNewCompanyModalOpen(false)} />
			<NewEntityModal newCreated={newUserCreated} isOpen={newUserModalOpen} entityType={Entities.USER} closeModal={() => setNewUserModalOpen(false)} />
		</div>
	);
}
