import { BiLinkExternal } from "react-icons/bi";
import { useCache } from "@/Hooks/useCache";
import EntityTable from "../EntityTable";
import { Entities } from "@shared/Entities/Entities";
import { View } from "@shared/Models/View";

interface KbArticleTableProps {
	view?: View;
	onMultiDelete?(): Promise<void>;
}

export function KbArticleTable(props: KbArticleTableProps) {
	const { cache } = useCache();
	const portalUrl = cache.getPortalURL();

	async function onPublicPortalClick() {
		window.open(portalUrl + "knowledge-base");
	}

	return (
		<EntityTable
			entity={Entities.KBARTICLE}
			view={props.view}
			newEntityUrl="/kb/article/-1"
			editEntityUrl="/kb/article/"
			onMultiDelete={props.onMultiDelete}
			customButtons={[
				{
					label: "Public Portal",
					icon: <BiLinkExternal />,
					onClick: onPublicPortalClick
				}
			]}
		/>
	);
}
