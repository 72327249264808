import { BadgeColor } from "./BadgeColor";

interface BadgeProps {
	label?: string;
	color?: BadgeColor;
}

export function Badge(props: BadgeProps) {
	const colorStyles = props.color ?? BadgeColor.GREY;

	return (
		<span className={colorStyles + " inline-block rounded-full px-3 py-1 text-xs font-bold mr-3"}>{props.label}</span>
	);
}
