import { DateTime } from "luxon";
import { AnnouncementNotification } from "@/Api/genApi.schemas";
import { datetimeToWhenString } from "@shared/Helpers/DateFunctions";

interface AnnouncementContentProps {
	notification: AnnouncementNotification;
	closeNotifications(): void;
}

export function AnnouncementContent(props: AnnouncementContentProps) {
	const ctime = DateTime.fromISO(props.notification.ctime);
	const when = datetimeToWhenString(ctime);

	if (props.notification.messageHtml == null) {
		return;
	}

	return (
		<div>
			<div className="overflow-hidden pt-1 pb-1.5" dangerouslySetInnerHTML={{ __html: props.notification.messageHtml }} />

			<div className="text-xs font-me opacity-80">
				{when}
			</div>
		</div>
	);
}
