import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Button, ButtonBlue } from "@/Components/Button/Button";
import W_CacheDropdown from "@/Components/FormComponents/CacheDropdown/W_CacheDropdown";
import { CacheSlices } from "@/Store/Reducers/cache/CacheTypes";
import { useCache } from "@/Hooks/useCache";
import Modal from "@shared/Components/Modal/Modal";
import ModalButtons from "@shared/Components/Modal/ModalButtons";
import ModalContent from "@shared/Components/Modal/ModalContent";
import { KbArticle } from "@shared/Entities/EntityTypes";
import W_Dropdown from "@shared/Components/FormComponents/Dropdown/W_Dropdown";

interface MoveArticleForm {
	kbId: number;
	folderId: number | null;
}

interface MoveArticleModalProps {
	isOpen: boolean;
	article: KbArticle;
	closeModal(): void;
	moveArticle(kbId: number, folderId: number): Promise<void>;
}

export function MoveArticleModal(props: MoveArticleModalProps) {
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const { cache } = useCache();

	const { control, handleSubmit, reset, watch, setValue } = useForm<MoveArticleForm>(
		{ defaultValues: {
			kbId: props.article.kbId,
			folderId: props.article.kbSectionId
		} }
	);

	useEffect(() => {
		reset({
			kbId: props.article.kbId,
			folderId: props.article.kbSectionId
		});
	}, [props.article, reset]);

	async function onSubmit(data: MoveArticleForm) {
		if (data.folderId == null) {
			return;
		}

		setIsLoading(true);

		await props.moveArticle(data.kbId, data.folderId);

		setIsLoading(false);
		props.closeModal();
	}

	function resetFolderId() {
		setValue("folderId", null);
	}

	const kbId = watch("kbId");

	const folders = cache.KbSections.filter(folder => folder.kbId == kbId);

	const folderOptions = folders.map(folder => {
		return {
			label: folder.name,
			value: folder.id
		};
	});

	return (
		<Modal
			isOpen={props.isOpen}
			onClose={props.closeModal}
			title={"Move KB article"}
		>
			<ModalContent>
				<div className="w-96" />

				<W_CacheDropdown
					control={control}
					onChange={resetFolderId}
					cacheSlice={CacheSlices.Kbs}
					dataname="kbId"
					label="Knowledge base"
					mandatory
				/>

				<W_Dropdown<number>
					control={control}
					options={folderOptions}
					dataname="folderId"
					label="Folder"
					mandatory
				/>
			</ModalContent>
			<ModalButtons>
				<Button onClick={props.closeModal} label="Cancel" />
				<ButtonBlue onClick={() => handleSubmit(onSubmit)()} label="Move" loading={isLoading} />
			</ModalButtons>
		</Modal>

	);
}
