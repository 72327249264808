import { useAppSelector } from "@/Store/hooks";
import { RootState } from "@/Store/store";

const agentSelector = (state: RootState) => state.loggedInAgent.agent;

/**
 * Gets the currently logged in agent or undefined.
 */
export function useOptionalAgent() {
	const loggedInAgent = useAppSelector(agentSelector);

	return loggedInAgent;
}

/**
 * Gets the currently logged in agent. If the agent is not found, throws an error.
 * @throws AgentNotFound
 */
export function useAgent() {
	const loggedInAgent = useAppSelector(agentSelector);

	if (!loggedInAgent) {
		throw new Error("AgentNotFound");
	}

	return loggedInAgent;
}
