import { FeedItemViewer, FeedItemViewerSkeleton } from "@/Pages/home/FeedItemViewer";
import { useGetFeed } from "@/Api/genApi";

export default function Feed() {
	const feedReq = useGetFeed({ query: {
		refetchInterval: 60 * 1000
	} });

	if (feedReq.isLoading) {
		const loadingSkeletons = [];

		for (let i = 0; i < 10; i++) {
			loadingSkeletons.push(<FeedItemViewerSkeleton />);
		}

		return (
			loadingSkeletons
		);
	}

	if (feedReq.isError) {
		// TODO: Could have a generic error box to use.
		return (
			<div>
				Could not load feed.
			</div>
		);
	} else if (feedReq.data != null) {
		return (
			feedReq.data.data.map((feedItem) => (
				<FeedItemViewer key={feedItem.ticketId + feedItem.id} feedItem={feedItem} />
			))
		);
	}
}
